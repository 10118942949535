import React, { useState} from 'react';
import { Route, Routes} from "react-router-dom";
import EmployeeProfile from "../IOT/profile";
import SSOHeader from "../../../../containers/ssoheader";
import MenuList from "../../../../containers/ssoMenu";
import {Cms} from "../../../../constants/routes";
import Telephone from "./telephone/telephone";
import Departments from "./departments/departments";
import CMSPages from "./pages/pages";
import Category from "./category/category";
import CMSForms from "./forms/forms";
import CMSProtocol from "./protocols/protocols";
import CMSReport from "./report/reports";
import CMSTender from "./tender/tender";
import CMSEvents from "./events/events";
import CMSNews from "./news/news";
import CMSSlider from "./slider/slider";
import CMSSetting from "./setting/setting";
import CMSGeneralCategory from "./generalcategory/general";
import CMSSearch from "./search/search";
import CMSSubSearch from "./search/subsearch";
import Upload from "./upload/upload";

function CMS() {
    return (
        <div id="SerivcePage"  >
            <div className={"Menu "}>
                <MenuList page={Cms}   />
            </div>
            <div className={"mainDiv"}>
                <SSOHeader  page={Cms}  />
                <div className={"m-lg-4 mt-lg-4 m-0 mt-3"}>
                <Routes>
                    <Route index element={<CMSSetting />} />
                    <Route path="Profile" element={<EmployeeProfile/>}   />
                    <Route path="TelePhone" element={<Telephone/>}   />
                    <Route path="Departments" element={<Departments />}   />
                    <Route path="Pages" element={<CMSPages />}   />
                    <Route path="Category/:cid" element={<Category />}   />
                    <Route path="Forms" element={<CMSForms />}   />
                    <Route path="Protocols" element={<CMSProtocol />}   />
                    <Route path="Reports" element={<CMSReport />}   />
                    <Route path="Tenders" element={<CMSTender />}   />
                    <Route path="Events" element={<CMSEvents />}   />
                    <Route path="Articles" element={<CMSNews />}   />
                    <Route path="Slider" element={<CMSSlider />}   />

                    <Route path="Setting" element={<CMSSetting />}   />
                    <Route path="Categories" element={<CMSGeneralCategory />}   />
                    <Route path="Search" element={<CMSSearch />}   />
                    <Route path="Search/:cid" element={<CMSSubSearch />}   />
                    <Route path="uploadfiles" element={<Upload />}   />

                </Routes>
                </div>
            </div>
        </div>
    );
}



export default CMS
