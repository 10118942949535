import React, {useEffect, useRef, useState} from "react";
import DataTable from 'react-data-table-component'
import axios from "axios";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Trash, Edit, Eye, Lock} from 'react-feather';
import {authEnum, checkAuthorities, websetting} from "../../../helpers/websetting";
import {SystemSetting} from "../../../constants/system";
import {useTranslation} from "react-i18next";

function Permission(){
    const t = useTranslation()
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [Service, setService] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);


    const [show, setShow] = useState(false);
    const [info, setInfo] = useState(true);
    const [infoData, setInfoData] = useState({});

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }

    const handleShow = () => {
        setShow(true);
    }



    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.apiUrl + 'auth/api/v1/permissions/'+event.target.Id.value,
                    {
                        /*"code": event.target.code.value,*/
                        "name": event.target.name.value,
                        /*"remarks": event.target.remarks.value,*/
                        "description" : event.target.description.value,
                    },
                    {
                        method: "put",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["permissionIdPk"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].name = response.data.name
                    prevState[index].code = response.data.code

                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {
                axios.post(websetting.apiUrl + 'auth/api/v1/permissions/',
                    {
                        "code": event.target.code.value,
                        "name": event.target.name.value,
                        "description" : event.target.description.value,
                        "stpServiceIdFk" : event.target.stpServiceIdFk.value,
                        /*"remarks": event.target.remarks.value,*/
                    },
                    {
                        method: "post",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    if(response.data.errorCode == ""){
                        setData(data.concat(response.data))
                        setShow(false)
                        setLoadingModal(true)
                    }else{
                        alert(response.data.message)
                        setShow(false)
                        setLoadingModal(true)
                    }

                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'auth/api/v1/permissions/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["permissionIdPk"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }

    const edit = (id:any) => {
        setShow(true);
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.apiUrl + 'auth/api/v1/permissions/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }

    const showInfo = (id:any) => {
        setShow(true);
        setInfo(false);
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.apiUrl + 'auth/api/v1/permissions/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }

    const columns = [
        {
            name: t.t("iot.global.permissionName"),
            selector: (row : any) => row.name,
        },{
            name: t.t("iot.global.description"),
            selector: (row : any) => row.description,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div>{checkAuthorities(authEnum.DELETE_PERMISSION) &&<Button variant={"outline-danger"} onClick={()=>del(row.permissionIdPk)} className={"rounded-circle"}><Trash size={15} /></Button>} {checkAuthorities(authEnum.EDIT_PERMISSION) &&<Button onClick={()=>edit(row.permissionIdPk)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button>} {checkAuthorities(authEnum.VIEW_PERMISSION) &&<Button  variant={"dark"}  onClick={()=>showInfo(row.permissionIdPk)} className={"rounded-circle"}><Eye  size={15} /></Button>} </div>,
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'auth/api/v1/permissions/?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {
            alert(error)
            setLoading(false);
        });
    };

    const stpService = () => {
        axios.get(websetting.apiUrl + 'setup-service/api/v1/stpServices/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setService(response.data.servicesList);
        }).catch(function (error) {
        });
    }
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };

    useEffect(() => {
        stpService()
        fetchDta(1); // fetch page 1 of users
    }, []);

    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div className={""}>
            <div className="lightbox">
                <div className={""}>
                    <div className="pagetitle text-uppercase">
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Lock className={"feather"} size={21} />
                                {t.t("iot.global.permissionTitle")}
                            </div>
                            <div className={"d-inline-flex   align-content-end"}>
                                {checkAuthorities(authEnum.ADD_PERMISSION) &&
                                <Button variant={"warning"} className={"text-uppercase bold"}
                                        onClick={() => edit(0)}>+ {t.t("iot.global.newPermission")}</Button>
                                }
                            </div>
                        </div>

                    </div>
                    {checkAuthorities(authEnum.VIEW_PERMISSION) &&
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows}
                               progressComponent={<Spinner className={"my-5"} animation={"border"} variant={"warning"}
                                                           title={"Please Wait"}/>}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data}/>
                    }

                </div>
            </div>

            <Modal size={"lg"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><Lock className={"feather"} /> {t.t("iot.global.permissionTitle")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>
                        {info ?
                            <div>{
                                loadingModal ? <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"} /></div> :

                                    <Row>
                                        <Form.Group hidden controlId="Id" >
                                            <Form.Control type={"hidden"}   value={infos.permissionIdPk}  />
                                        </Form.Group>
                                        <Col md={12}>
                                            <Form.Group className="my-2" controlId="name" >
                                                <Form.Label>{t.t("iot.global.permissionName")}:</Form.Label>
                                                <Form.Control type="text"   defaultValue={infos.name}  required aria-label={t.t("iot.global.permissionName")} aria-describedby="msgInput1" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                        {!infos.permissionIdPk &&
                                        <Col md={12}>
                                            <Form.Group className="my-2" controlId="code">
                                                <Form.Label>{t.t("iot.global.code")}:</Form.Label>
                                                <Form.Control placeholder={"e.g : VIEW_PERMISSION"} type="text" defaultValue={infos.code} required
                                                              aria-label={t.t("iot.global.code")}
                                                              aria-describedby="msgInput2"/>
                                                <Form.Control.Feedback type="invalid" id="msgInput2">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        }

                                        <Col md={12}>
                                            <Form.Group className="my-2" controlId="description">
                                                <Form.Label>{t.t("iot.global.description")}:</Form.Label>
                                                <Form.Control type="text" defaultValue={infos.description}
                                                              aria-label={t.t("iot.global.description")}
                                                              aria-describedby="msgInput20"/>
                                                <Form.Control.Feedback type="invalid" id="msgInput20">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {!infos.permissionIdPk &&
                                        <Col md={12}>
                                            <Form.Group className="my-2" controlId="stpServiceIdFk">
                                                <Form.Label>{t.t("iot.global.stpServicesTitle")}:</Form.Label>
                                                <Form.Select defaultValue={infos.stpServiceIdFk} required
                                                             aria-label={t.t("iot.global.stpServicesTitle")}
                                                             aria-describedby="msgInput2">
                                                    {Object.keys(Service).map((titles, i) => {
                                                        return (
                                                            <option
                                                                selected={infos.stpServiceIdFk == JSON.parse(JSON.stringify(Service[i])).stpServiceIdPk ? true : false}
                                                                value={JSON.parse(JSON.stringify(Service[i])).stpServiceIdPk}>{JSON.parse(JSON.stringify(Service[i])).name}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        }

                                    </Row>
                            }
                            </div>
                            :
                            <div>
                                {
                                    loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                        <div>
                                            <Table     hover>
                                                <tbody>
                                                <tr><td>ID</td><td>{infos.permissionIdPk}</td></tr>
                                                <tr><td>{t.t("iot.global.permissionName")}</td><td>{infos.name}</td></tr>
                                                <tr><td>{t.t("iot.global.roleName")}</td><td>{infos.authority}</td></tr>
                                                <tr><td>{t.t("iot.global.stpServicesTitle")}</td><td>{infos['stpServiceDTO'] && infos['stpServiceDTO'].name}</td></tr>
                                                <tr><td>{t.t("iot.global.description")}</td><td>{infos.description}</td></tr>
                                                <tr><td>{t.t("iot.global.createdBy")}</td><td>{infos.createdBy}</td></tr>
                                                <tr><td>{t.t("iot.global.createdDate")}</td><td>{infos.createDate}</td></tr>
                                                <tr><td>{t.t("iot.global.updatedBy")}</td><td>{infos.updatedBy}</td></tr>
                                                <tr><td>{t.t("iot.global.updatedDate")}</td><td>{infos.updateDate}</td></tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                }
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"}  variant="warning">{t.t("iot.global.save")}</Button>}

                    </Modal.Footer>
                </Form>
            </Modal>

        </div>
    )
}
export default Permission;
