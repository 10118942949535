import React, {Fragment, useEffect, useRef, useState} from "react";
import {Button, Col, Image, Nav, Row, Tab, Table, Tabs} from "react-bootstrap";
import Dashboard from "./pages/dashboard";
import Device from "./pages/device";
import Groups from "./pages/groups";
import StatusPage from "./pages/statuspage";
import FaultEvents from "./pages/faultevents";
import Energy from "./pages/energy";
import {useTranslation} from "react-i18next";
import SLA from "./pages/sla";
import {UserProfile} from "../../../../../helpers/websetting";

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: true,
        },
    },
};


export const data = {
    labels: ['ON', 'DIM', 'OFF', 'Disconnect'],
    datasets: [
        {
            label: '# of Votes',
            data: [12.6,19.4, 62.9, 5.1],
            backgroundColor: [
                'rgba(75, 192, 192, 0.8)',
                'rgba(54, 162, 235, 0.8)',
                'rgba(255, 206, 86, 0.8)',
                'rgba(255, 99, 132, 0.8)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export const data2 = {
    labels: ['Connect', 'Disconnect'],
    datasets: [
        {
            label: '# of Votes',
            data: [166,9],
            backgroundColor: [
                'rgba(75, 192, 192, 0.8)',
                'rgba(255, 99, 132, 0.8)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};
export const data3 = {
    labels: ['ON', 'DIM', 'OFF'],
    datasets: [
        {
            label: '# of Votes',
            data: [22,34, 110],
            backgroundColor: [
                'rgba(75, 192, 192, 0.8)',
                'rgba(255, 206, 86, 0.8)',
                'rgba(255, 99, 132, 0.8)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};





function StreetLighting(){
    const t = useTranslation()
    const tab = React.createRef<HTMLTextAreaElement>();
    const [page,setPage] = useState("Dashboard")
    const [trvId,setTrvId] = useState("")

    const openPage = (page:any) => {
        setPage(page)
    }
    useEffect(() => {
        tab.current?.click()
    }, [localStorage.getItem("lang")]);

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        setTrvId(params.get('trvId') || "1")
    },[])

    return(
        <div  className={"content"}>
            <Row>
                <Col className={"justify-content-center align-items-center align-content-center"}>
                    <Image srcSet={window.location.origin + "/images/icon25.svg"} height={40} />
                    <span className={"pagetitle text-uppercase d-inline-block pt-2 px-2"}>{t.t("iot.global.streetLighting")}</span>
                </Col>
            </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
                <Row>
                    <Col sm={12}>
                        <div className={"position-relative my-3"}>
                            <div className={"lightbox position-relative newTab"}>
                                <div className={"selector"}></div>

                                <Nav    >
                            <Nav.Item as={"li"}>
                                <Nav.Link  ref={tab as React.RefObject<any>} onClick={()=> openPage("Dashboard")} eventKey="tab1"  >{t.t("iot.global.lightingDashboard")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={"li"}>
                                <Nav.Link  onClick={()=> openPage("Devices")} eventKey="tab2">{t.t("iot.global.devicesTitle")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item  as={"li"}>
                                <Nav.Link onClick={()=> openPage("Groups")} eventKey="tab3">{t.t("iot.global.groupsTitle")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={"li"}>
                                <Nav.Link onClick={()=> openPage("StatusReport")} eventKey="tab4">{t.t("iot.global.statusReportTitle")}</Nav.Link>
                            </Nav.Item>
                                    <Nav.Item as={"li"} >
                                        <Nav.Link  onClick={()=> openPage("EnergyConsumption")}  eventKey="tab5">{t.t("iot.global.energyConsumptionTitle")}</Nav.Link>
                                    </Nav.Item>
                            <Nav.Item as={"li"}>
                                <Nav.Link eventKey="tab6" onClick={()=> openPage("FaultEvents")}>{t.t("iot.global.faultEventsTitle")}</Nav.Link>
                            </Nav.Item>

                                    <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab7" onClick={()=> openPage("Alarms")}>{t.t("iot.global.alarms")}</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <hr className={"my-1 shodow-hr"} />
                    </Col>
                    <Col sm={12}>

                        {trvId &&
                        <div className={"p-1"} >
                            {page == "Dashboard" &&  <Dashboard trvId={trvId}  />}
                            {page == "Devices" &&  <Device trvId={trvId} />}
                            {page == "Groups" &&  <Groups trvId={trvId} />}
                            {page == "StatusReport" &&  <StatusPage trvId={trvId}/>}
                            {page == "EnergyConsumption" &&  <Energy trvId={trvId}/>}
                            {page == "FaultEvents" &&  <FaultEvents trvId={trvId}/>}
                            {page == "Alarms" &&  <SLA trvId={trvId}/>}
                        </div>
                        }
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}

export default StreetLighting;
