import React, {useEffect, useState} from "react";
import {
    Accordion,
    Button,
    Card,
    Dropdown,
    FloatingLabel,
    Form, FormControl,
    Image,
    InputGroup,
    useAccordionButton
} from "react-bootstrap";
import {File, Link, Send, Trash2} from "react-feather";
import axios from "axios";
import {ModulaIds, websetting} from "../../../../../helpers/websetting";
import Moment from "moment";

function EmployeeComment(props:any) {

    const [comment,setComment] = useState([])

    const [files,setfiles] = useState([])
    const handleChange = (event: any): void => {
        setfiles(event.target.files)
    }
    const removeFile = (index:any) => {
        const set = new Set(files);
        const result2 = Array.from(set).slice(index,index)
        setfiles(result2);
    }

    const SendReply = (e:any,commentId:any) => {
        e.preventDefault()
        axios.post(websetting.automationUrl  +'AutoCaseComments/AddCommentForDepartment?module=' + ModulaIds.Automation ,
            {
                params: {
                    Comment: e.currentTarget.comment.value,
                    CaseId: props.caseId,
                    ReplyComment: commentId
                },
                //data : "Comment="+e.currentTarget.comment.value+"&CaseId="+ props.caseId + "&ReplyComment="+commentId,
                method: "post",
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'accept: text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            //setComment(response.data)
        }).catch(function (error) {
        });
    }

    useEffect(() => {
        axios.post(websetting.automationUrl  +'AutoCaseComments/GetWithDepartment?module=' + ModulaIds.Automation ,
            {
                caseId: props.caseId,
                page: 1,
                rows: 100
            },
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setComment(response.data)
        }).catch(function (error) {
        });
    }, [props.caseId]);


    return(
        <div>
            <div className={"border mt-3"}>
                <FloatingLabel className={"p-2 pt-3"} controlId="floatingTextarea2" label="اكتب تعليق">
                    <Form.Control
                        as="textarea"
                        placeholder="اكتب تعليق"
                        style={{ height: '80px' }}
                        className={"border-0 shadow-0 box-shadow-0 p-2"}
                    />
                </FloatingLabel>
                <div className={"py-0 d-flex px-2 justify-content-between border-top"}>
                    <div className={"d-inline-flex align-items-center"}>
                        <Form.Label className="btn px-0 btn-none">
                            <Link className="feather mx-1" size={18} />
                            إدراج ملف
                            <Form.Control type="file" multiple hidden={true} onChange={handleChange} />
                        </Form.Label>
                    </div>
                    <div className={"d-inline-flex justify-content-end align-items-center"}>
                        <Button  variant={"dark"} className={"py-1 px-3"}>أضف</Button>
                    </div>
                </div>
                {
                    files.length ==0 ? "" :
                        <div className={"d-block  px-2"}>
                            {Object.keys(files).map((titles, i) => {
                                return(
                                    <div className={"d-flex bg-light bg-opacity-50 py-2 border-top w-100 justify-content-between"}>
                                        <div className={"d-inline-flex"}>
                                            <File className="feather" size={18} />
                                            { files[i]["name"] }
                                        </div>

                                        <div className={"d-inline-flex justify-content-end"}>
                                            <button onClick={()=>removeFile(i)}    >
                                                <Trash2 className="feather" size={17} />
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                }
            </div>
            <div className={"my-3"}>
                <div className="chatBoxs" data-rel="type2">
                    <ul className="chats">
                        {Object.keys(comment).map((titles2, i) => {
                            return (
                                <li className={comment[i]["isRead"] == true ? "item read" : "item unread"}>
                                    <div className="imgChat circle-rounded"  >
                                        <Image srcSet={comment[i]["userImage"]} alt=""  width={30} height={30} />
                                    </div>
                                    <div className="ChatBody">
                                        <div className="txt1">{comment[i]["userName"]}</div>
                                        <div className="txt2">{comment[i]["comment"]}</div>
                                        <div className="txt3">

                                            <Accordion>
                                                <span className="ltr">{Moment(comment[i]["createdOn"]).format(websetting.dateFormat + " HH:mm:ss")}</span> <CustomToggle eventKey={comment[i]["id"]}>اضف تعليق</CustomToggle>

                                            <Accordion.Collapse eventKey={comment[i]["id"]}>
                                                <div className="comment">
                                                    <Form action={""} method={"post"} onSubmit={(e)=>{SendReply(e,comment[i]["id"])}}>
                                                        <Form.Group controlId={"comment"}>
                                                            <InputGroup >

                                                            <Form.Control className={"py-1 h-auto"} aria-label="add comment" />

                                                        <InputGroup.Text className={"p-0"}><Button type={"submit"} style={{borderRadius:"0px"}} variant={"none"} ><Send className={"feather"} size={18} /></Button></InputGroup.Text>
                                                    </InputGroup>
                                                        </Form.Group>
                                                    </Form>
                                                </div>

                                            </Accordion.Collapse>
                                            </Accordion>
                                            {comment[i]["replyCommentList"] &&
                                                <ul className="subChat">
                                                    {Object.keys(comment[i]["replyCommentList"]).map((titles3, j) => {
                                                        return (
                                                            <li>
                                                        <div className="imgChat">
                                                            <Image srcSet={comment[i]["replyCommentList"][j]["userImage"]} alt="" width={30}
                                                                   height={30}/>
                                                        </div>
                                                        <div className="ChatBody">
                                                            <div className="txt1">{comment[i]["replyCommentList"][j]["userName"]}</div>
                                                            <div className="txt2">{comment[i]["replyCommentList"][j]["comment"]}</div>
                                                            <div className="txt3"><span>{Moment(comment[i]["replyCommentList"][j]["createdOn"]).format(websetting.dateFormat + " HH:mm:ss")}</span></div>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>
                                </li>
                            )
                        })
                        }
                    </ul>

                </div>
            </div>
        </div>
    );
}

function CustomToggle(props:any) {
    const decoratedOnClick = useAccordionButton(props.eventKey, () =>
        console.log('totally custom!' + props.eventKey),
    );

    return (
        <Button variant={"none"} className="py-0 addComment" onClick={decoratedOnClick} >اضافة تعليق</Button>
    );
}


export default EmployeeComment