import React, {useEffect, useState} from "react";
import {Lang, websetting} from "./websetting";
import {Dropdown} from "react-bootstrap";
import axios from "axios";
import {Globe} from "react-feather";
import {useLocation, useNavigate} from "react-router-dom";
import i18n from "i18next";
import App from "../App";

export function useTitle(title:any) {
    useEffect(() => {
        const prevTitle = document.title
        document.title = title
        return () => {
            document.title = prevTitle
        }
    })
}

export function hexToRgbA(hex:any,alpha:any){
    let c:any = null;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c = hex.substring(1).split('');
        if(c.length== 3){
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+alpha+')';
    }
    return ""
}

export function UseLang(position:any) {
    const [selectedItem, setSelectedItem] = useState(Lang[0]);
    setdirection(selectedItem.dir);
    const nav = useNavigate()
    const ss = useLocation()
    //const { t, i18n } = useTranslation();

     const setLang = (index:any,click:any) => {
        setSelectedItem(Lang[index])
        localStorage.setItem("dir",Lang[index].dir)
        localStorage.setItem("lang",Lang[index].label)
        localStorage.setItem("title",Lang[index].title)
        localStorage.setItem("langId",index)
         i18n.changeLanguage(Lang[index].label)
         if(click == true){
             <App />
         }
    }
    useEffect(() => {
        if(localStorage.getItem("langId") == null){
            setLang(0,false)
        }else{
            var ind = parseInt(localStorage.getItem("langId") as string)

            if(ind >= Lang.length) {
               ind = 0;
            }

            setSelectedItem(Lang[ind])
            i18n.changeLanguage(Lang[ind].label)
        }
    }, [localStorage.getItem("langId") as string]);


    return(
            <Dropdown   className="headerIcons  headerLang">
                <Dropdown.Toggle as={"a"} href="#" variant="none">
                    <Globe className={"feather mx-1"} size={24} />
                    <span className="d-lg-inline-block d-none">{selectedItem?selectedItem.title:Lang[0].title}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="text-center">
                    {Object.keys(Lang).map((titles, i) => {
                        if(Lang[i].visible) {
                            return (
                                <Dropdown.Item key={"lang" + i} href="#" onClick={() => {
                                    setLang(i, true)
                                }}>{Lang[i].title}</Dropdown.Item>
                            );
                        }
                    })}
                </Dropdown.Menu>
            </Dropdown>
    )
}

export const setdirection = (d : any) => {
    document.dir = d;
    sessionStorage.setItem("dir",d);
}
export const getdirection = () =>{
    if(sessionStorage.getItem("dir")  != null){
        setdirection(sessionStorage.getItem("dir"));
    }
}

export const Upload = async (input:any) => {
    const formData = new FormData(input.currentTarget);
    const file = input.files[0];
    formData.append('file', file, file.name);

    return new Promise(async function (resolve, reject) {
        await axios.post(websetting.apiUrl + 'auth/api/v1/files/',
            formData,
            {
                method: "post",
                headers: {
                    'content-type': "multipart/form-data",
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            resolve(response.data.fileUrl)
        }).catch(function (error) {
             reject(new Error("error"));
        });

    }).then(function(result) {
        return result
    }).catch(function (error) {
        return Promise.reject(new Error("error"));
    });
}



export const UploadCMS = async (input:any) => {
    const formData = new FormData(input.currentTarget);
    const file = input.files[0];
    if(input.files.length != 0) {
        formData.append('File', file, file.name);

        return new Promise(async function (resolve, reject) {
            await axios.post(websetting.cmsUrl + 'PreviewFile/Upload',
                formData,
                {
                    method: "post",
                    headers: {
                        'content-type': "multipart/form-data",
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                resolve( response.data)
            }).catch(function (error) {
                reject(new Error("error"));
            });

        }).then(function (result) {
            return result
        }).catch(function (error) {
            return Promise.reject(new Error("error"));
        });
    }else{
        return ""
    }
}


export const UploadMultiCMS =   (input:any) => {
    const attachment:any = []
    if(input.files.length != 0) {
        return new  Promise( async function (resolve, reject) {
            for (let i = 0; i < input.files.length; i++) {
                console.log(input.files[i].name)
                const formData = new FormData(input.currentTarget);
                formData.append('File', input.files[i], input.files[i].name);

                await axios.post(websetting.cmsUrl + 'PreviewFile/Upload',
                    formData,
                    {
                        method: "post",
                        headers: {
                            'content-type': "multipart/form-data",
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(function (response) {
                    attachment.push(response.data)
                }).catch(function (error) {
                    return Promise.reject(new Error("error"));
                });
            }


            return resolve(attachment)
        }).then(function (result) {
            return result
        }).catch(function (error) {
            return Promise.reject(new Error("error"));
        });


    }else{
        return Promise.resolve("")
    }
}



export const UploadMulti  = async  (input: any) => {
    const formData = new FormData(input.currentTarget);
    for(var i= 0; i< input.files.length ; i++){
        const file = input.files[i];
        formData.append('file', file, file.name);
    }
    return new Promise(async function (resolve, reject) {
        await axios.post(websetting.apiUrl + 'auth/api/v1/files/uploadMultiFiles',
            formData,
            {
                method: "post",
                headers: {
                    'content-type': "multipart/form-data",
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            resolve(response.data.json)
        }).catch(function (error) {
            reject(new Error("error"));
        });

    }).then(function(result) {
        return result
    }).catch(function (error) {
        return Promise.reject(new Error("error"));
    });
}
