import React from 'react';
import { Route, Routes} from "react-router-dom";
import EmployeeProfile from "./profile";
import GeneralDashboard from "./dashboard";
import StreetLighting from "./lighting/lighting";
import WasteService from "./waste/waste";
import SetupSevrices from "../../../Admin/setting/setupService";
import ServiceUsed from "../../../Admin/setting/serviceUsed";
import DeviceType from "../../../Admin/setting/deviceType";
import Vendors from "../../../Admin/setting/vendor";
import DeviceUsed from "../../../Admin/setting/deviceUsed";
import Devices from "../../../Admin/asset/devices";
import Bills from "../../../Admin/asset/bills";
import BillDevice from "../../../Admin/asset/billdevice";
import DeviceHistory from "../../../Admin/asset/devicehistory";
import Permission from "../../../Admin/managment/permissions";
import Roles from "../../../Admin/managment/roles";
import ViewUserRoles from "../../../Admin/managment/viewuserroles";
import ViewRolePermission from "../../../Admin/managment/viewrolepermission";
import Fleet from "./fleet/fleet";
import MenuList from "../../../../containers/ssoMenu";
import SSOHeader from "../../../../containers/ssoheader";
import {IOT} from "../../../../constants/routes";
import Organizations from "../../../Admin/managment/organizations";
import SLA from "./lighting/pages/sla";
import UserManagement from "../../../Admin/managment/usermanagment";
import AssetsManagement from "../../../Admin/asset/assetsmanagment";
import Setting from "../../../Admin/setting/setting";
import SystemSettingPage from "./systemsetting";
import {UserProfile} from "../../../../helpers/websetting";
import ViewUserPermission from "../../../Admin/managment/viewuserpermission";
import Environmental_Monitoring from "./environmental_monitoring/em";


function ServiceHome() {
    return (
        <div id="SerivcePage"  >
            <div className={"Menu "}>
                <MenuList  page={IOT}  />
            </div>
            <div className={"mainDiv"}>
                 <SSOHeader page={IOT} />

                <Routes>
                    <Route path="Profile" element={<EmployeeProfile/>}   />
                    <Route path="Dashboard" element={<GeneralDashboard/>}   />
                    <Route path="Street-Lighting" element={<StreetLighting/>}   />
                    <Route path="Waste-Service" element={<WasteService/>}   />
                    <Route path="Fleet-Service" element={<Fleet/>}   />


                    <Route path="*" element={<GeneralDashboard/>}   />

                    <Route path="SLA" element={<SLA />} />
                    <Route path="SystemSetting" element={<SystemSettingPage organizationId={UserProfile.organizationId} />} />
                    <Route path="Permissions" element={<Permission />} />
                    <Route path="Roles" element={<Roles />} />
                    <Route path="ViewUserRoles/:id" element={<ViewUserRoles />} />

                    <Route path="ViewUserPermission/:id" element={<ViewUserPermission />} />

                    <Route path="ViewRolePermission/:id" element={<ViewRolePermission />} />
                    <Route path="Organizations" element={<Organizations />} />
                    <Route path="userManagement" element={<UserManagement />} />
                    <Route path="assetsManagement" element={<AssetsManagement />} />
                    <Route path="Setting" element={<Setting />} />
                    <Route path="SetupServices" element={<SetupSevrices />} />
                    <Route path="ServiceUsed/:id" element={<ServiceUsed />} />
                    <Route path="DeviceType" element={<DeviceType />} />
                    <Route path="DeviceUsed/:id" element={<DeviceUsed />} />
                    <Route path="Vendors" element={<Vendors />} />
                    <Route path="Bills" element={<Bills />} />
                    <Route path="BillDevice/:bill_id" element={<BillDevice />} />
                    <Route path="Devices" element={<Devices />} />
                    <Route path="DeviceHistory/:id" element={<DeviceHistory />} />
                    <Route path="em" element={<Environmental_Monitoring />} />

                </Routes>
                {UserProfile.userType == "ADMIN" &&
                    <Routes>

                    </Routes>
                }
            </div>
        </div>
    );
}



export default ServiceHome
