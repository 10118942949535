import {Spinner, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Sun} from "react-feather";
import DataTable from "react-data-table-component";
import axios from "axios";
import {websetting} from "../../../../../../helpers/websetting";
import {useTranslation} from "react-i18next";
import {SystemSetting} from "../../../../../../constants/system";

function FaultEvents(prop : any){
    const t = useTranslation()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            name: t.t("iot.global.name"),
            selector: (row : any) => row.name,
        },{
            name: t.t("iot.global.serviceName"),
            selector: (row : any) => row.serviceName,
        },{
            name: t.t("iot.global.groupName"),
            selector: (row : any) => row.groupName,
        },{
            name: t.t("iot.global.deviceType"),
            selector: (row : any) => row.deviceTypeName,
        },{
            name: t.t("iot.global.vendor"),
            selector: (row : any) => row.vendorName,
        },{
            name: t.t("iot.global.connectivity"),
            selector: (row : any) => <div>{row.status ==0 ? <span className={"badge outline-badge-danger"}>{t.t("iot.global.disconnected")}</span> : <span className={"badge outline-badge-success"}>{t.t("iot.global.connected")}</span>  }</div>,
        }
    ];

    const [totalRows, setTotalRows] = useState(0);
    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1

        axios.get(websetting.apiUrl + 'lighting-service/api/v1/devices/'+prop.trvId+'/0/faultsByServiceId/?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {
        });
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };
    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);


    return(
        <div className={"lightbox"}>
            <div className={"pagetitle text-uppercase"}>
                <Sun/>
                <span className={"d-inline-block text-uppercase mx-2"}>{t.t("iot.global.faultEventsTitle")}</span>
            </div>
            <hr className={"shodow-hr"} />
            <div>
                <DataTable progressPending={loading}
                           pagination
                           paginationServer
                           paginationTotalRows={totalRows}
                           progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                           onChangeRowsPerPage={handlePerRowsChange}
                           onChangePage={handlePageChange} columns={columns} data={data} />

            </div>
        </div>
    );
}
export default FaultEvents
