import {Button, Spinner, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {BarChart2, Battery, BatteryCharging, Bell, Calendar, Eye, Sun, Trash2} from "react-feather";
import axios from "axios";
import {websetting} from "../../../../../../helpers/websetting";
import DataTable from "react-data-table-component";
import Moment from "moment";

function Container(prop:any){
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [historydata, setHistorydata] = useState([]);
    const [taskdata, setTaskdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const columns = [
        {
            name: 'Address',
            selector: (row : any) => row.node.containerId,
        },{
            name: 'Fill Level',
            selector: (row : any) => <div>{row.node.measurement && row.node.measurement["fillLevel"]}</div>,
        },{
            name: 'Sensor ID',
            selector: (row : any) => row.node.containerId,
        },{
            name: 'Container ID',
            selector: (row : any) => row.node.containerId,
        },{
            name: 'Last Measurement',
            selector: (row : any) => <div>{row.node.measurement && Moment(row.node.measurement["createdAt"]).format(websetting.dateFormat + " HH:mm:ss")}</div>,
        },{
            name: 'Waste Fraction',
            selector: (row : any) => <div>{row.node.wasteFraction && row.node.wasteFraction["__typename"]}</div>,
        },{
            name: 'Address',
            selector: (row : any) => row.node.locationName,
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.apiUrl + 'waste-service/api/v1/wastehero/containers/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.edges);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);

    return(
        <div className={"lightbox"}>
            <div className={"pagetitle text-uppercase"}>

                <Trash2 className="feather feather-trash-2" />
                <span className={"d-inline-block mx-2"}>CONTAINERS</span>
                <Button variant={"warning"} className="float-end">Containers Types</Button>
                <Button variant={"info"} className="mx-2 float-end">+ Attach sensor to container</Button>
                <Button variant={"dark"} className="float-end">+ New Container</Button>
            </div>
            <hr className={"shodow-hr"} />
            <div>
                {
                    loading ? <div className={"text-center"}><Spinner animation={"border"} className={"my-5 "} variant={"warning"} /></div> : <DataTable pagination paginationPerPage={10} columns={columns} data={data} />
                }
            </div>
        </div>
    );
}
export default Container