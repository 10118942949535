import React, {useEffect, useState} from "react";
import { Col, Image, Nav, Row, Tab} from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import Dashboard from "./pages/dashboard";
import PickupSettings from "./pages/picksetting";
import Inquiries from "./pages/inquiries";
import Vehicles from "./pages/vehicles";
import RouteSchemes from "./pages/RouteSchemes";
import Container from "./pages/container";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};
export const data = {
    labels: ['ON', 'DIM', 'OFF', 'Disconnect'],
    datasets: [
        {
            label: '# of Votes',
            data: [12.6,19.4, 62.9, 5.1],
            backgroundColor: [
                'rgba(75, 192, 192, 0.8)',
                'rgba(54, 162, 235, 0.8)',
                'rgba(255, 206, 86, 0.8)',
                'rgba(255, 99, 132, 0.8)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export const data2 = {
    labels: ['Connect', 'Disconnect'],
    datasets: [
        {
            label: '# of Votes',
            data: [166,9],
            backgroundColor: [
                'rgba(75, 192, 192, 0.8)',
                'rgba(255, 99, 132, 0.8)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};
export const data3 = {
    labels: ['ON', 'DIM', 'OFF'],
    datasets: [
        {
            label: '# of Votes',
            data: [22,34, 110],
            backgroundColor: [
                'rgba(75, 192, 192, 0.8)',
                'rgba(255, 206, 86, 0.8)',
                'rgba(255, 99, 132, 0.8)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};



function WasteService(){
    const [page,setPage] = useState("Dashboard")
    const openPage = (page:any) => {
        setPage(page)
    }
    const tab = React.createRef<HTMLTextAreaElement>();
    useEffect(() => {
        tab.current?.click()
    }, [localStorage.getItem("lang")]);

    return(
        <div  className={"content"}>
            <Row>
                <Col className={"justify-content-center align-items-center align-content-center"}>
                    <Image srcSet={window.location.origin + "/images/smart-bins.jpg"} height={40} />
                    <span className={"pagetitle text-uppercase d-inline-block pt-2"}>Smart Bins</span>
                </Col>
            </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
                <Row>
                    <Col sm={12}>
                        <div className={"position-relative my-3"}>
                            <div className={"lightbox position-relative newTab"}>
                                <div className={"selector"}></div>

                                <Nav>
                                    <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab1" ref={tab as React.RefObject<any>} onClick={()=> openPage("Dashboard")} >Dashboard</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab2" onClick={()=> openPage("Containers")} >Containers</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab3" onClick={()=> openPage("Vehicles")}>Vehicles</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item  as={"li"}>
                                        <Nav.Link eventKey="tab4" onClick={()=> openPage("Inquiries")}>Inquiries</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab5" onClick={()=> openPage("RouteSchemes")}>Route Schemes</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab6" onClick={()=> openPage("PickupSettings")}>Pickup Settings</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <hr className={"my-1 shodow-hr"} />
                    </Col>
                    <Col sm={12}>

                        <div className={"p-1"} >
                            {page == "Dashboard" &&  <Dashboard trvId={"21"}  />}
                            {page == "Containers" &&  <Container trvId={"21"} />}
                            {page == "Vehicles" &&  <Vehicles trvId={"21"} />}
                            {page == "Inquiries" &&  <Inquiries trvId={"21"}/>}
                            {page == "RouteSchemes" &&  <RouteSchemes trvId={"21"}/>}
                            {page == "PickupSettings" &&  <PickupSettings trvId={"21"}/>}
                        </div>

                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}


export default WasteService;
