import React, {Fragment, useEffect, useRef, useState} from "react";
import {Button, Col, Image, Nav, Row, Tab, Table, Tabs} from "react-bootstrap";
import Vehicles from "./pages/Vehicles";
import VehiclesType from "./pages/VehiclesType";
import FleetLocation from "./pages/FleetLocation";
import Driver from "./pages/Driver";
export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};

export const data = {
    labels: ['ON', 'DIM', 'OFF', 'Disconnect'],
    datasets: [
        {
            label: '# of Votes',
            data: [12.6,19.4, 62.9, 5.1],
            backgroundColor: [
                'rgba(75, 192, 192, 0.8)',
                'rgba(54, 162, 235, 0.8)',
                'rgba(255, 206, 86, 0.8)',
                'rgba(255, 99, 132, 0.8)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export const data2 = {
    labels: ['Connect', 'Disconnect'],
    datasets: [
        {
            label: '# of Votes',
            data: [166,9],
            backgroundColor: [
                'rgba(75, 192, 192, 0.8)',
                'rgba(255, 99, 132, 0.8)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};
export const data3 = {
    labels: ['ON', 'DIM', 'OFF'],
    datasets: [
        {
            label: '# of Votes',
            data: [22,34, 110],
            backgroundColor: [
                'rgba(75, 192, 192, 0.8)',
                'rgba(255, 206, 86, 0.8)',
                'rgba(255, 99, 132, 0.8)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
            ],
            borderWidth: 1,
        },
    ],
};




function Fleet(){
    const [page,setPage] = useState("Dashboard")
    const openPage = (page:any) => {
        setPage(page)
    }
    const tab = React.createRef<HTMLTextAreaElement>();
    useEffect(() => {
        tab.current?.click()
    }, [localStorage.getItem("lang")]);

    return(
        <div  className={"content"}>
            <Row>
                <Col className={"justify-content-center align-items-center align-content-center"}>
                    <Image srcSet={window.location.origin + "/images/icon28.svg"} height={40} />
                    <span className={"pagetitle text-uppercase d-inline-block pt-2"}>Fleet Management</span>
                </Col>
            </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
                <Row>
                    <Col sm={12}>
                        <div className={"position-relative my-3"}>
                            <div className={"lightbox position-relative newTab"}>
                                <div className={"selector"}></div>

                                <Nav    >
                                    <Nav.Item as={"li"}>
                                        <Nav.Link ref={tab as React.RefObject<any>} onClick={()=> openPage("VEHICLES")} eventKey="tab1"  >Vehicles</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as={"li"}>
                                        <Nav.Link  onClick={()=> openPage("VEHICLESTYPE")} eventKey="tab2">Vehicles Type</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item  as={"li"}>
                                        <Nav.Link onClick={()=> openPage("FlEETLOCATION")} eventKey="tab3">Fleet Location</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as={"li"}>
                                        <Nav.Link onClick={()=> openPage("DRIVER")} eventKey="tab4">Driver</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as={"li"} >
                                        <Nav.Link  onClick={()=> openPage("VEHICLESPLANNER")}  eventKey="tab5">Vehicles Planner</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <hr className={"my-1 shodow-hr"} />
                    </Col>
                    <Col sm={12}>

                        <div className={"p-1"} >
                            {page == "VEHICLES" &&  <Vehicles trvId={"21"}  />}
                            {page == "VEHICLESTYPE" &&  <VehiclesType trvId={"21"} />}
                            {page == "FlEETLOCATION" &&  <FleetLocation trvId={"21"} />}
                            {page == "DRIVER" &&  <Driver trvId={"21"}/>}
                            {page == "VEHICLESPLANNER" &&  <VehiclesType trvId={"21"}/>}
                        </div>

                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}

export default Fleet;
