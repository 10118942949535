import React, {useEffect, useRef, useState} from "react";
import DataTable from 'react-data-table-component'
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {ChevronDown, ChevronLeft, Edit, Eye, Tool, Trash} from 'react-feather';
import {websetting} from "../../../helpers/websetting";
import {SystemSetting} from "../../../constants/system";
import {useTranslation} from "react-i18next";

function DeviceUsed(){
    const t = useTranslation()
    let { id } = useParams();
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [Devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);

    const [show, setShow] = useState(false);
    const [info, setInfo] = useState(true);
    const [infoData, setInfoData] = useState({});

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }

    const handleShow = () => {
        setShow(true);
    }

    const columns = [
        {
            name: t.t("iot.global.name"),
            selector: (row : any) => row.name,
        },{
            name: t.t("iot.global.min"),
            selector: (row : any) => row.min,
        } ,{
            name: t.t("iot.global.equal"),
            selector: (row : any) => row.equal,
        },{
            name: t.t("iot.global.max"),
            selector: (row : any) => row.max,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.devicePropertyIdPk)} className={"rounded-circle"}><Trash size={15} /></Button> <Button onClick={()=>edit(row.devicePropertyIdPk)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button> <Button  variant={"outline-primary"}  onClick={()=>showInfo(row.devicePropertyIdPk)} className={"rounded-circle"}><Eye  size={15} /></Button> </div>,
        }
    ];


    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.apiUrl + 'setup-service/api/v1/deviceProperties/'+event.target.Id.value,
                    {
                        "deviceTypeIdfk": id,
                        "equal": event.target.equal.value,
                        "max": event.target.max.value,
                        "min": event.target.min.value,
                        "name": event.target.name.value,
                        /*"remarks": event.target.remarks.value,*/
                    } ,
                    {
                        method: "put",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["devicePropertyIdPk"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].equal = response.data.equal
                    prevState[index].max = response.data.max
                    prevState[index].min = response.data.min
                    prevState[index].name = response.data.name
                    //prevState[index].remarks = response.data.remarks

                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {
                axios.post(websetting.apiUrl + 'setup-service/api/v1/deviceProperties/',
                    {
                        "deviceTypeIdfk": id,
                        "equal": event.target.equal.value,
                        "max": event.target.max.value,
                        "min": event.target.min.value,
                        "name": event.target.name.value,
                        /*"remarks": event.target.remarks.value,*/
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };


    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'setup-service/api/v1/deviceProperties/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["devicePropertyIdPk"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }

    const showInfo = (id:any) => {
        setShow(true);
        setInfo(false);
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.apiUrl + 'setup-service/api/v1/deviceProperties/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }


    const edit = (id:any) => {
        setShow(true);
        if (id > 0) {
            setLoadingModal(true)

            axios.get(websetting.apiUrl + 'setup-service/api/v1/deviceProperties/'+id ,
                {
                    method: "get",
                    headers: {
                        //'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }
    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get( websetting.apiUrl + 'setup-service/api/v1/deviceProperties/'+id+'/properties?pageNO='+p+'&pageSize='+SystemSetting.rowInPage ,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)

        }).catch(function (error) {

        });
    };

    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div className={""}>
            <div className="content">
                <div className={"box"}>
                    <div className="pagetitle text-uppercase">
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Tool className={"feather"} size={21} />
                                {t.t("iot.global.devicePropertiesTitle")}
                            </div>
                            <div className={"d-inline-flex align-content-end"}>
                                <Button className={"mx-3 py-1 text-uppercase"}   variant={"warning"}    onClick={()=>edit(0)}>+ {t.t("iot.global.newProperty")}</Button>
                                <Link to={"../Setting?tab=deviceTypes"} >{t.t("iot.global.back")} <ChevronLeft className={" feather feather-chevron-left"} /></Link>
                             </div>
                        </div>

                    </div>
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data} />


                </div>
            </div>

            <Modal size={"lg"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><Tool className={"feather"} /> {t.t("iot.global.devicePropertiesTitle")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>
                        {info ?
                            <div>{
                                loadingModal ? <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"} /></div> :

                                    <Row>
                                        <Form.Group hidden controlId="Id" >
                                            <Form.Control type={"hidden"}   value={infos.devicePropertyIdPk}  />
                                        </Form.Group>
                                        <Col md={12}>
                                            <Form.Group className="my-2" controlId="name" >
                                                <Form.Label>{t.t("iot.global.name")}:</Form.Label>
                                                <Form.Control type="text"   defaultValue={infos.name}  required aria-label={t.t("iot.global.name")} aria-describedby="msgInput1" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="my-2" controlId="min" >
                                                <Form.Label>{t.t("iot.global.min")}:</Form.Label>
                                                <Form.Control type="text"  defaultValue={infos.min} required aria-label={t.t("iot.global.min")} aria-describedby="msgInput2" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput2" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="my-2" controlId="equal" >
                                                <Form.Label>{t.t("iot.global.equal")}:</Form.Label>
                                                <Form.Control type="text"  defaultValue={infos.equal} required aria-label={t.t("iot.global.equal")} aria-describedby="msgInput2" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput2" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="my-2" controlId="max" >
                                                <Form.Label>{t.t("iot.global.max")}:</Form.Label>
                                                <Form.Control type="text"  defaultValue={infos.max} required aria-label={t.t("iot.global.max")} aria-describedby="msgInput2" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput2" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>


                                    </Row>
                            }
                            </div>
                            :
                            <div>
                                {
                                    loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                        <div>
                                            <Table     hover>
                                                <tbody>
                                                <tr><td>ID</td><td>{infos.devicePropertyIdPk}</td></tr>
                                                <tr><td>{t.t("iot.global.name")}</td><td>{infos.name}</td></tr>
                                                <tr><td>{t.t("iot.global.min")}</td><td>{infos.min}</td></tr>
                                                <tr><td>{t.t("iot.global.max")}</td><td>{infos.max}</td></tr>
                                                <tr><td>{t.t("iot.global.equal")}</td><td>{infos.equal}</td></tr>
                                                <tr><td>{t.t("iot.global.createdBy")}</td><td>{infos.createdBy}</td></tr>
                                                <tr><td>{t.t("iot.global.createdDate")}</td><td>{infos.createDate}</td></tr>
                                                <tr><td>{t.t("iot.global.updatedBy")}</td><td>{infos.updatedBy}</td></tr>
                                                <tr><td>{t.t("iot.global.updatedDate")}</td><td>{infos.updateDate}</td></tr>
                                                 </tbody>
                                            </Table>
                                        </div>
                                }
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"}  variant="warning">{t.t("iot.global.save")}</Button>}

                    </Modal.Footer>
                </Form>
            </Modal>

        </div>
    )
}
export default DeviceUsed;