import React, { useState} from "react";
import {useParams} from "react-router-dom";
import {Check} from "react-feather";
import {Button, Col, Form, Row, Table} from "react-bootstrap";

function FormServiceLvel(props:any) {
    let {id}:any = useParams()
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    if( id == "New"){
        id = 0
    }
    return (
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle text-uppercase"}>
                    <Check className={"feather"} />
                    <span className={"d-inline-block mx-2"}>مستوى الخدمة</span>
                    <Button variant={"warning"}   className="text-uppercase bold float-end">حفظ</Button>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    <Row>
                        <Col lg={4}>
                            <Form.Group controlId={"nameHe"}>
                                <Form.Label>الوقت المطلوب لأغلاق الطلب ( ساعة )</Form.Label>
                                <Form.Control  type={"number"} required aria-describedby={"msg_publishDate"}  />
                                <Form.Control.Feedback type="invalid"  id="msg_nameHe">
                                    حقل اجباري
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className={"my-3"} controlId={"nameHe"}>
                                <Form.Label>ارسال اشعار قبل ( ساعه )</Form.Label>
                                <Form.Control type={"number"} required aria-describedby={"msg_publishDate"}  />
                                <Form.Control.Feedback type="invalid"  id="msg_nameHe">
                                    حقل اجباري
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg={8}>
                            <Table responsive>
                                <thead><tr><th>ايام الدوام</th><th>من الساعة</th><th>الى الساعة</th></tr></thead>
                                <tbody>
                                <tr>
                                    <td><Form.Label><Form.Check inline /> السبت</Form.Label></td><td><Form.Control type={"time"}/> </td><td><Form.Control type={"time"}/> </td>
                                </tr>
                                <tr>
                                    <td><Form.Label><Form.Check inline /> احد</Form.Label></td><td><Form.Control type={"time"}/> </td><td><Form.Control type={"time"}/> </td>
                                </tr>
                                <tr>
                                    <td><Form.Label><Form.Check inline /> انثنين</Form.Label></td><td><Form.Control type={"time"}/> </td><td><Form.Control type={"time"}/> </td>
                                </tr>
                                <tr>
                                    <td><Form.Label><Form.Check inline /> ثلاثاء</Form.Label></td><td><Form.Control type={"time"}/> </td><td><Form.Control type={"time"}/> </td>
                                </tr>
                                <tr>
                                    <td><Form.Label><Form.Check inline /> اربعاء</Form.Label></td><td><Form.Control type={"time"}/> </td><td><Form.Control type={"time"}/> </td>
                                </tr>
                                <tr>
                                    <td><Form.Label><Form.Check inline /> خميس</Form.Label></td><td><Form.Control type={"time"}/> </td><td><Form.Control type={"time"}/> </td>
                                </tr>
                                <tr>
                                    <td><Form.Label><Form.Check inline /> جمعة</Form.Label></td><td><Form.Control type={"time"}/> </td><td><Form.Control type={"time"}/> </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>

    )
}
export default FormServiceLvel