import {Button, Spinner, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {BarChart2, Battery, BatteryCharging, Bell, Calendar, Eye, Sun, Zap, ZapOff} from "react-feather";
import axios from "axios";
import {websetting} from "../../../../../../helpers/websetting";
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import {SystemSetting} from "../../../../../../constants/system";

function StatusPage(prop : any){
    const t = useTranslation()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            name: t.t("iot.global.name"),
            selector: (row : any) => row.name,
        },/*{
            name: t.t("iot.global.groupName"),
            selector: (row : any) => row.groupName,
        },*/{
            name: t.t("iot.global.serviceName"),
            selector: (row : any) => row.serviceName,
        },{
            name: t.t("iot.global.deviceType"),
            selector: (row : any) => row.deviceTypeName,
        },{
            name: t.t("iot.global.vendor"),
            selector: (row : any) => row.vendorName,
        },{
            name: t.t("iot.global.connectivity"),
            selector: (row : any) => <div>{row.status == 0 ? <span className={"badge outline-badge-danger"}>{t.t("iot.global.disconnected")}</span> : <span className={"badge outline-badge-success"}>{t.t("iot.global.connected")}</span>  }</div>,
        },{
            name: t.t("iot.global.currentStatus"),
            selector: (row : any) => row.eventDesc,
        },{
            name: t.t("iot.global.rssi"),
            selector: (row : any) => <div>{row.rssi!=null ? row.rssi : "-" }</div>,
        }
    ];
    const [totalRows, setTotalRows] = useState(0);

    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/devices/'+prop.trvId+'/statusReport/?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {

        });
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };
    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);

    return(
        <div className={"lightbox"}>
            <div className={"pagetitle text-uppercase"}>
               <Sun className={"feather"} />
                <span className={"d-inline-block mx-2"}>{t.t("iot.global.statusReportTitle")}</span>
            </div>
            <hr className={"shodow-hr"} />
            <DataTable progressPending={loading}
                       pagination
                       paginationServer
                       paginationTotalRows={totalRows}
                       progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                       onChangeRowsPerPage={handlePerRowsChange}
                       onChangePage={handlePageChange} columns={columns} data={data} />
        </div>

    );
}
export default StatusPage
