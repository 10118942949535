import React, {useEffect, useState} from "react";
import {Check, FilePlus} from "react-feather";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import axios from "axios";
import {ModulaIds, websetting} from "../../../../../helpers/websetting";
import {useNavigate, useParams} from "react-router-dom";
import Moment from "moment";

function FormSetting(props:any) {
    const navigate = useNavigate()
    let {id}:any = useParams()
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    if( id == "New"){
        id = 0
    }

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoading(true);
            setError("");

            if(id == 0) {
                await axios.post(websetting.automationUrl + 'AutoForm/Post?module=' + ModulaIds.Automation,
                    {
                        nameHe: event.target.nameHe.value,
                        nameAr: event.target.nameAr.value ? event.target.nameAr.value : null,
                        publishDate: event.target.publishDate.value,
                        endDate: event.target.endDate.value ? event.target.endDate.value : null,
                        categoryId: event.target.categoryId.value,
                        status: 1
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setLoading(false);
                    if(response.data.id > 0){
                        navigate("../CaseSla/"+response.data.id+"?categoryId="+response.data.categoryId);
                    }
                });
            }else{

            }

        }
    }


    useEffect(() => {
        if(id > 0) {
            setLoading(true)
            axios.get(websetting.automationUrl + 'AutoForm/Get/' + id + '?module=' + ModulaIds.Automation,
                {
                    method: "get",
                    headers: {
                        'accept': 'text/plain',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setData(response.data);
                setLoading(false);
            }).catch(function (error) {

            });
        }else{
            setData([]);
        }
    }, [id]);
    const info =  JSON.parse(JSON.stringify(data))

    return (
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle text-uppercase"}>
                                <FilePlus className={"feather"} />
                                <span className={"d-inline-block mx-2"}>البيانات الاساسية</span>
                            </div>
                <hr className={"shodow-hr"} />
                <div>
                    {loading ? <div className="text-center"><Spinner animation="border"  variant="secondary" role="status" ><span className="visually-hidden">انتظر من فضلك</span></Spinner></div> :

                    <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                        <Row className={"justify-content-center"}>
                            <Col md={9}>
                        <Row className={"pb-5"}>
                            <Col className={"mb-2"} lg={6}>
                                <Form.Group controlId={"nameHe"}>
                                    <Form.Label>الاسم بالعبري</Form.Label>
                                    <Form.Control defaultValue={info && info.nameHe} type={"text"} required aria-describedby={"msg_publishDate"}  />
                                    <Form.Control.Feedback type="invalid"  id="msg_nameHe">
                                        حقل اجباري
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className={"mb-2"} lg={6}>
                                <Form.Group controlId={"nameAr"}>
                                    <Form.Label>الاسم بالعربي</Form.Label>
                                    <Form.Control defaultValue={info && info.nameAr} type={"text"} />
                                </Form.Group>
                            </Col>
                            <Col className={"my-2"} lg={6}>
                                <Form.Group controlId={"publishDate"}>
                                    <Form.Label>تاريخ النشر</Form.Label>
                                    <Form.Control type={"date"} defaultValue={info && Moment(info.publishDate).format(websetting.dateFormat)} required aria-describedby={"msg_publishDate"} />
                                    <Form.Control.Feedback type="invalid"  id="msg_publishDate">
                                        حقل اجباري
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className={"my-2"} lg={6}>
                                <Form.Group  controlId={"endDate"}>
                                    <Form.Label>تاريخ الانتهاء</Form.Label>
                                    <Form.Control type={"date"} defaultValue={info && Moment(info.publishDate).format(info.endDate)}  />
                                    <Form.Control.Feedback type="invalid"  id="msg_endDate">
                                        حقل اجباري
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col className={"my-2"} lg={12}>
                                <Form.Group controlId={"categoryId"}>
                                    <Form.Label>الفئة</Form.Label>
                                    <Form.Select  aria-describedby={"msg_categoryId"} defaultValue={info && info.categoryId}>
                                        <option value={2060}>قسم التحصيل</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid"  id="msg_categoryId">
                                        حقل اجباري
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col className={" mt-3 text-end"} lg={12}>
                                <Button type={"submit"} className={"bold"} variant={"warning"}>حفظ البيانات</Button>
                            </Col>


                        </Row>

                            </Col>
                        </Row>
                    </Form>
                    }
                </div>
            </div>
        </div>
    )
}
export default FormSetting