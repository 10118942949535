import React, {useEffect, useRef, useState} from "react";
import DataTable from 'react-data-table-component'
import {Link} from "react-router-dom";
import axios from "axios";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Trash, Edit, Eye, Tool, Layers, Settings} from 'react-feather';
import {authEnum, checkAuthorities, websetting} from "../../../helpers/websetting";
import {SystemSetting} from "../../../constants/system";
import {useTranslation} from "react-i18next";
import {timezone} from "../../../constants/timezone";
function Organizations(){
    const t = useTranslation()
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [country, setCountry] = useState([]);

    const [show, setShow] = useState(false);
    const [info, setInfo] = useState(true);
    const [infoData, setInfoData] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }

    const handleShow = () => {
        setShow(true);
    }



    const handleSubmit = (event: any) => {



        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.apiUrl + 'auth/api/v1/organizations/'+event.target.Id.value,
                    {
                        /*"countryIdFk":event.target.country.value,*/
                        "description": event.target.Description.value,
                        "name": event.target.Name.value,
                        "timeZone" : event.target.timezone.value,
                        "timeZoneName" : event.target.timezone.options[event.target.timezone.selectedIndex].text
                    },
                    {
                        method: "put",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                     const index = data.findIndex( (array) => {
                        return parseInt(array["organizationIdPk"]) === parseInt(PKid)
                     });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].name = response.data.name
                    prevState[index].description = response.data.description
                    prevState[index].code = response.data.code
                    prevState[index].timeZoneName = response.data.timeZoneName

                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {
                axios.post(websetting.apiUrl + 'auth/api/v1/organizations/',
                    {
                        "countryIdFk":event.target.country.value,
                        "description": event.target.Description.value,
                        "name": event.target.Name.value,
                        "timeZone" : event.target.timezone.value,
                        "timeZoneName" : event.target.timezone.options[event.target.timezone.selectedIndex].text
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'auth/api/v1/organizations/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["organizationIdPk"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }

    const edit = (id:any) => {
        setShow(true);
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.apiUrl + 'auth/api/v1/organizations/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }

    const showInfo = (id:any) => {
        setShow(true);
        setInfo(false);
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.apiUrl + 'auth/api/v1/organizations/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
              }).catch(function (error) {
            });
        }
    }

    const columns = [
        {
            name: t.t("iot.global.organizationName"),
            selector: (row : any) => row.name,
        },{
            name: t.t("iot.global.description"),
            selector: (row : any) => row.description,
        },{
            name: "TimeZone",
            selector: (row : any) => row.timeZoneName,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div>{checkAuthorities(authEnum.DELETE_ORGANIZATION) && <Button variant={"outline-danger"} onClick={()=>del(row.organizationIdPk)} className={"rounded-circle"}><Trash size={15} /></Button>} {checkAuthorities(authEnum.EDIT_ORGANIZATION) &&<Button onClick={()=>edit(row.organizationIdPk)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button>} {checkAuthorities(authEnum.VIEW_ORGANIZATION) &&<Button  variant={"dark"}  onClick={()=>showInfo(row.organizationIdPk)} className={"rounded-circle "}><Eye  size={15} /></Button>} {checkAuthorities(authEnum.ADD_ORGANIZATION) &&<Link   to={"../SystemSetting?orgId="+row.organizationIdPk}      className={"rounded-circle btn btn-sm btn-primary "}><Settings className={"mt-1"}  size={15} /></Link>}</div>,
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'auth/api/v1/organizations?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {
            alert(error)
            setLoading(false);
        });


        axios.get(websetting.apiUrl + 'setup-service/api/v1/countries/',
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }

                ).then(async function (response) {
                setCountry(response.data);
                }).catch(function (error) {

                });

    };

    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);

   const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div className={""}>
            <div className="lightbox">
                <div className={""}>
                    <div className="pagetitle text-uppercase">
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Tool className={"feather"} size={21} />
                                {t.t("iot.global.organizations")}
                            </div>
                            <div className={"d-inline-flex align-content-end"}>
                                {checkAuthorities(authEnum.ADD_ORGANIZATION) &&
                                <Button variant={"warning"} className={"text-uppercase bold"}
                                        onClick={() => edit(0)}>+ {t.t("iot.global.addorganization")}</Button>
                                }
                            </div>
                        </div>

                    </div>
                    {checkAuthorities(authEnum.VIEW_ORGANIZATION) &&
                        <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows}
                               progressComponent={<Spinner className={"my-5"} animation={"border"} variant={"warning"}
                                                           title={"Please Wait"}/>}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data}/>
                    }

                </div>
            </div>

            <Modal size={"lg"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><Tool className={"feather"} /> {t.t("iot.global.organization")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                <Modal.Body>
                    {info ?
                        <div>{
                            loadingModal ? <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"} /></div> :

                        <Row>
                            <Form.Group hidden controlId="Id" >
                                <Form.Control type={"hidden"}   value={infos.organizationIdPk}  />
                            </Form.Group>
                            {!infos.organizationIdPk &&
                            <Col md={12}>
                                <Form.Group className="my-2" controlId="country">
                                    <Form.Label>{t.t("iot.global.country")}:</Form.Label>
                                    <Form.Select required aria-label={t.t("iot.global.country")}
                                                 aria-describedby="msgInput2">
                                        {Object.keys(country).map((titles, i) => {
                                            return (
                                                <option
                                                    selected={infos.countryIdFk == JSON.parse(JSON.stringify(country[i])).countryIdPk ? true : false}
                                                    value={JSON.parse(JSON.stringify(country[i])).countryIdPk}>{JSON.parse(JSON.stringify(country[i])).name}</option>
                                            );
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid" id="msgInput2">
                                        Required Field
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            }

                            <Col md={12}>
                                <Form.Group className="my-2" controlId="Name" >
                                    <Form.Label>{t.t("iot.global.name")}:</Form.Label>
                                    <Form.Control type="text"   defaultValue={infos.name}  required aria-label={t.t("iot.global.name")} aria-describedby="msgInput3" />
                                    <Form.Control.Feedback type="invalid"  id="msgInput3" >
                                        Required Field
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="my-2" controlId="Description" >
                                    <Form.Label>{t.t("iot.global.description")}:</Form.Label>
                                    <Form.Control as={"textarea"} style={{ height: '80px' }} defaultValue={infos.description}  aria-label={t.t("iot.global.description")}    aria-describedby="msgInput3" />
                                    <Form.Control.Feedback type="invalid"  id="msgInput3" >
                                        Required Field
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="my-2" controlId="timezone" >
                                    <Form.Label>Timezone:</Form.Label>
                                    <Form.Select required >
                                    {timezone.map((item,index)=>{
                                        return(
                                            <option selected={item.text == infos.timeZoneName ? true : false} value={item.offset}>{item.text}</option>
                                        )
                                    })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid"  id="msgInput3" >
                                        Required Field
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        }
                        </div>
                        :
                        <div>
                        {
                            loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                <div>
                                    <Table     hover>
                                        <tbody>
                                        <tr><td>ID</td><td>{infos.organizationIdPk}</td></tr>
                                        <tr><td>{t.t("iot.global.name")}</td><td>{infos.name}</td></tr>
                                        <tr><td>timeZone</td><td>{infos.timeZone > 0 ? "UTC+"+ infos.timeZone : "UTC"+infos.timeZone}</td></tr>
                                        <tr><td>timeZoneName</td><td>{infos.timeZoneName}</td></tr>

                                        <tr><td>{t.t("iot.global.description")}</td><td>{infos.description}</td></tr>
                                        <tr><td>{t.t("iot.global.country")}</td><td>{infos['countryDTO'] && infos['countryDTO'].name}</td></tr>
                                        <tr><td>{t.t("iot.global.createdBy")}</td><td>{infos.createdBy}</td></tr>
                                        <tr><td>{t.t("iot.global.createdDate")}</td><td>{infos.createDate}</td></tr>
                                        <tr><td>{t.t("iot.global.updatedDate")}</td><td>{infos.updateDate}</td></tr>
                                        </tbody>
                                    </Table>
                                </div>
                        }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                    {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}

                </Modal.Footer>
                </Form>
            </Modal>

        </div>
    )
}
export default Organizations;
