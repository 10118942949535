import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import { Tool, ChevronLeft} from 'react-feather';
import {authEnum, checkAuthorities, websetting} from "../../../helpers/websetting";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import {useTranslation} from "react-i18next";
const animatedComponents = makeAnimated();

function ViewRolePermission(){
    const t = useTranslation()
    let {id} = useParams()
    const [data, setData] = useState([]);
    const [defData, setdefData] = useState([]);
    const [loading, setLoading] = useState(true);

    const  options:any = [];
    const  defoptions:any = [];
    const handleChange = (op:any) => {
        setdefData(op);
    };

    const save = () => {
        setLoading(true)
        let obj = []
        for(var i =0;i< defData.length;i++){
            obj.push({"permissionIdFk":defData[i]['value'],"roleIdFk":id})
        }
        axios.post(websetting.apiUrl + 'auth/api/v1/rolePermissions/'+id+'/addPermissionsToRole',
            obj,
            {
                method: "post",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setLoading(false)
        }).catch(function (error) {
            setLoading(false)
            alert(error)
        });


    }

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.apiUrl + 'auth/api/v1/permissions/?pageNO=0&pageSize=2000',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            for(var i=0;i< response.data.data.length; i++){
                options.push({ value: response.data.data[i].permissionIdPk , label: response.data.data[i].name })
            }
            setData(options)

            axios.get(websetting.apiUrl + 'auth/api/v1/rolePermissions/'+id+'/permissions/?pageNO=0&pageSize=2000',
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (responses) {
                for(var i=0;i< responses.data.data.length; i++){
                    defoptions.push({  label: responses.data.data[i].permissionName,value: responses.data.data[i].permissionIdFk })
                }
                setdefData(defoptions)
                setLoading(false);

            }).catch(function (error) {
            });

        }).catch(function (error) {
        });
    };


    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);

    return(
        <div className={""}>
            <div className="content">
                <div className={"box"}>
                    <div className="pagetitle text-uppercase">
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Tool className={"feather"} size={21} />
                                {t.t("iot.global.rolePagesPermissions")}
                            </div>
                            <div className={"d-inline-flex align-content-end"}>
                                {checkAuthorities(authEnum.ADD_ROLE_PERMISSIONS) &&
                                <Button variant={"warning"} className={"mx-5 py-1 bold"}
                                        onClick={() => save()}> {t.t("iot.global.save")}</Button>
                                }
                                <Link to={"../userManagement?tab=role"} > {t.t("iot.global.back")} <ChevronLeft className={" feather mt-1 feather-chevron-left"} /></Link>
                            </div>
                        </div>

                    </div>
                    {loading ? <div className={"text-center"}><Spinner animation={"border"} className={"my-5 "} variant={"warning"}/></div> :
                            <Row className={"my-5"}>
                                <Col md={12}>
                                    <Form.Group controlId={"select"} >
                                        <Select
                                            isLoading={loading}
                                            options={data}
                                            value={defData}
                                            closeMenuOnSelect={false}
                                            isClearable={false}
                                            components={animatedComponents} isMulti  onChange={handleChange}
                                        />

                                    </Form.Group>
                                </Col>
                            </Row>
                    }
                </div>
            </div>



        </div>
    )
}
export default ViewRolePermission;
