import {Edit, FilePlus, FileText, PhoneCall, Trash} from "react-feather";
import {Button, Col, Form, Modal, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {Ref, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { editoroptions,  websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {UploadCMS, UploadMultiCMS} from "../../../../../helpers/function";
import SunEditor  from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Moment from "moment";
import {SystemSetting} from "../../../../../constants/system"; // Import Sun Editor's CSS File

function CMSEvents(){
    const t = useTranslation()
    const inputRef = React.createRef<any>();


    const [validated, setValidated] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);

    const [descriptionHe, setdescriptionHe] = useState('');
    const [descriptionAr, setdescriptionAr] = useState('');

    const [detailsHe, setdetailsHe] = useState('');
    const [detailsAr, setdetailsAr] = useState('');
    const [openDetails, setopenDetails] = useState(false);
    const [filesNum, setfilesNum] = useState([]);
    const [attachfile, setattachfile] = useState([]);

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
        setopenDetails(false)
    }

    const OpenArchive = (id:any) => {
        setopenDetails(true)
        edit(id)
    }

    const AddFiles = (v:any) => {
        const f:any = []
        for(var i=0;i < v.target.files.length;i++){
            f.push(v.target.files[i].name)
        }
        setfilesNum(f)
    }
    const deleteAttachFile = (event:any,index:any) => {
        const dt = new DataTransfer();
        const new_filesNum = attachfile.filter((array,ind) => {
            return ind !== index
        });
        setattachfile(new_filesNum)
    }
    const deleteFile = (event:any,index:any) => {
        const dt = new DataTransfer();
        const new_filesNum = filesNum.filter((array,ind) => {
            return ind !== index
        });
        setfilesNum(new_filesNum)

        for(var i = 0; i < new_filesNum.length; i++) {
            dt.items.add(inputRef.current.files[i])
        }
        inputRef.current.files =  dt.files
    }

    const edit = (id:any) => {
        setfilesNum([])
        setattachfile([])
        setdescriptionHe('')
        setdescriptionAr('')
        setdetailsAr('')
        setdetailsHe('')

        setShow(true);
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.cmsUrl + 'CmsEvent/Get?id='+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
                setdescriptionHe(response.data.descriptionHe)
                setdescriptionAr(response.data.descriptionAr)
                setdetailsHe(response.data.detailsHe)
                setdetailsAr(response.data.detailsAr)
                setattachfile(response.data.cmsEventImages)

            }).catch(function (error) {
            });
        }
    }

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const columns = [
        {
            sortable: true,
            name:  "#",
            width:"50px",
            selector: (row : any) => row.id,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.title"),
            selector: (row : any) => row.name,
            style:{
                fontSize:"13px"
            }
        },{
            width:"150px",

            sortable: true,
            name:  t.t("cms.datetime"),
            selector: (row : any) => Moment(row.timing).format(SystemSetting.dateFormat + " - HH:mm") ,
            style:{
                fontSize:"13px"
            }
        }, {
            width:"230px",
            sortable: true,
            name:  "",
            selector: (row : any) => <div onClick={()=>{OpenArchive(row.id)}} >{Moment(Date.now()).isSameOrBefore(row.timing)  ? "" : <span className={"badge badge-success"}>{t.t("cms.detailsWhenFinsih")}</span>}</div> ,
            style:{
                fontSize:"13px"
            }
        }, {
            width:"120px",

            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button>   <Button onClick={()=>edit(row.id)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button></div>,
        }
    ]


    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
        }
    }

    const SubmitSearch = (e:any) => {
        e.preventDefault()
        if(e.target.input.value !== searchtxt){
            setSearchtxt(e.target.input.value)
        }
    }

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.cmsUrl + 'CmsEvent/Delete?id=' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }




    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;



            const formData = new FormData(event.target);
            if(event.target.ImageUrl.value != ''){
                const file = event.target.ImageUrl.files[0];
                formData.append('ImageUrl', file, file.name);
            }

            formData.append('NameHe', event.target.TitleHe.value);
            formData.append('NameAr', event.target.TitleAr.value);
            formData.append('DescriptionHe', descriptionHe);
            formData.append('DescriptionAr', descriptionAr);
            formData.append('DetailsHe', detailsHe);
            formData.append('DetailsAr', detailsAr);
            formData.append('ExternalLink', event.target.ExternalLink.value);
            formData.append('Timing', event.target.Timing.value);
            formData.append('LocationAr', event.target.LocationAr.value);
            formData.append('LocationHe', event.target.LocationHe.value);



            if(PKid > 0){
                formData.append('Id', PKid)
            }


            const fileObject:any = []

            var name = document.getElementsByName('fileUrl[]');
            name.forEach(
                function (input: any, i) {
                    var id: any = document.getElementsByName('fileId[]')[i];
                    if(id.value != ""){
                        fileObject.push({ imageUrl: input.value,id:parseInt(id.value)})
                    }else{
                        fileObject.push({ imageUrl: ''})
                    }
                }
            );


            if(event.target.Id.value > 0){

                UploadMultiCMS(event.target.file).then((value:any) => {

                    var counter=0;
                    for(var i=0; i< fileObject.length;i++){
                        if(fileObject[i].id == null){
                            fileObject[i].imageUrl = value[counter]
                            ++counter
                        }
                    }
                    const j = { "response": fileObject}
                    formData.append("EventImages",JSON.stringify(j))

                    axios.put(websetting.cmsUrl + 'CmsEvent/Put',
                        formData,
                        {
                            method: "put",
                            headers: {
                                'Accept-Language':localStorage.getItem("lang") as string,
                                'content-type': 'multipart/form-data',
                                'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                            }
                        }
                    ).then(async function (response) {
                        const index = data.findIndex( (array) => {
                            return parseInt(array["id"]) === parseInt(PKid)
                        });

                        let prevState = JSON.parse(JSON.stringify(data));
                        prevState[index].title = response.data.title
                        prevState[index].timing = response.data.timing
                        prevState[index].title = Moment(Date.now()).isSameOrBefore(response.data.timing)  ? "" : <span className={"badge badge-success"}>{t.t("cms.detailsWhenFinsih")}</span>

                         setData(prevState)
                        setShow(false)
                        setLoadingModal(true)
                    }).catch(function (error) {
                        setLoadingModal(true)
                    });
                })

            }else {

                UploadMultiCMS(event.target.file).then((value:any) => {

                    for(var i=0; i< fileObject.length;i++){
                        fileObject[i].imageUrl = value[i]
                    }

                    const j = { "response": fileObject}
                    formData.append("EventImages",JSON.stringify(j))
                    axios.post(websetting.cmsUrl + 'CmsEvent/AddForm',
                        formData,
                        {
                            method: "post",
                            headers: {
                                'Accept-Language':localStorage.getItem("lang") as string,
                                'content-type': 'multipart/form-data',
                                'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                            }
                        }
                    ).then(async function (response) {
                        setData(data.concat(response.data))
                        setShow(false)
                        setLoadingModal(true)
                    }).catch(function (error) {
                        setLoadingModal(true)
                    });
                })


            }
        }
    };


    const [totalRows, setTotalRows] = useState(0);
    const [rowperPage, setrowperPage] = useState(SystemSetting.rowInPage);
    const [currpage, setcurrpage] = useState(1);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsEvent/GetEvents?rows=' + rowperPage + "&page=" + page + (searchtxt != "" ? "&search="+searchtxt : ""),
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            setLoading(false);
        }).catch(function (error) {

        });
    };

    const handlePageChange = (page :any) => {
        setcurrpage(page)
        setLoading(true);
        fetchDta(page)
    };
    const handlePerRowsChange = async  (newPerPage:any, page:any) => {
        setLoading(true);
        setrowperPage(newPerPage)
        fetchDta(page)
    }

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, [localStorage.getItem("lang") ,searchtxt]);
    const infos =  JSON.parse(JSON.stringify(infoData))




    return(
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                    <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <FileText className={"feather mx-2"} /> {t.t("cms.web.events")}
                     </span>
                    </div>
                    <span className={"d-inline-flex align-items-center mx-2"}>

                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                        <Form  onSubmit={(e)=>{SubmitSearch(e)}}  action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                    </div>

                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><FilePlus /> {t.t("cms.addEvent")}</Button>
                 </span>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationPerPage={rowperPage}
                               paginationTotalRows={totalRows}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data} />
                </div>
            </div>


            <Modal size={"xl"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><FileText className={"feather"} /> {t.t("cms.web.events")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>
                                    <Form.Group hidden controlId="imageUrls">
                                        <Form.Control type={"hidden"} value={infos.imageUrl}/>
                                    </Form.Group>

                                    {openDetails ?
                                    <Tabs className={"justify-content-center tabsModal"} defaultActiveKey={"tab3"}>

                                        <Tab eventKey={"tab3"} className={"shadow-0"}
                                             title={t.t("cms.detailsWhenFinsih")}>

                                            <div className={"d-none"}>

                                                <Col lg={12}>
                                                    <Form.Group className="my-2" controlId="TitleHe">
                                                        <Form.Label>{t.t("cms.title")} - {t.t("cms.he")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.nameHe}
                                                                      aria-label={t.t("cms.pageTitle")}
                                                                      aria-describedby="msg_titleHe"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleHe">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                    <Form.Group className="my-2" controlId="LocationHe">
                                                        <Form.Label>{t.t("cms.eventLocation")} - {t.t("cms.ar")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.locationAr}
                                                                      aria-label={t.t("cms.eventLocation")}
                                                                      aria-describedby="msg_titleAr"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={12}>
                                                    <Form.Label
                                                        className="mt-2">{t.t("cms.description")} - {t.t("cms.he")}:</Form.Label>
                                                    {JSON.parse(JSON.stringify(infoData)).id != null &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                               defaultValue={infos.descriptionHe} height={"200px"}
                                                               onChange={setdescriptionHe} setOptions={editoroptions}/>
                                                    }

                                                    {JSON.parse(JSON.stringify(infoData)).id == null &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                               defaultValue={""} height={"200px"}
                                                               onChange={setdescriptionHe} setOptions={editoroptions}/>
                                                    }

                                                </Col>


                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Group className="my-2 mt-4" controlId="ImageUrl">
                                                            <Form.Label
                                                                className="my-0">{t.t("cms.image")}:</Form.Label>
                                                            <Form.Control type="file"
                                                                          aria-label={t.t("cms.image")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group className="my-2 mt-4" controlId="Timing">
                                                            <Form.Label
                                                                className="my-0">{t.t("cms.datetime")}:</Form.Label>
                                                            <Form.Control type="datetime-local"

                                                                          defaultValue={infos.timing}
                                                                          aria-label={t.t("cms.datetime")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={4}>
                                                        <Form.Group className="my-2 mt-4" controlId="ExternalLink">
                                                            <Form.Label
                                                                className="my-0">{t.t("cms.outLink")}:</Form.Label>
                                                            <Form.Control type="text" defaultValue={infos.externalLink}
                                                                          aria-label={t.t("cms.outLink")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Col lg={12}>
                                                    <Form.Group className="my-2" controlId="TitleAr">
                                                        <Form.Label>{t.t("cms.title")} - {t.t("cms.ar")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.nameAr}
                                                                      aria-label={t.t("cms.pageTitle")}
                                                                      aria-describedby="msg_titleAr"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                    <Form.Group className="my-2" controlId="LocationAr">
                                                        <Form.Label>{t.t("cms.eventLocation")} - {t.t("cms.ar")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.locationAr}
                                                                      aria-label={t.t("cms.eventLocation")}
                                                                      aria-describedby="msg_titleAr"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>


                                                <Col lg={12}>
                                                    <Form.Label
                                                        className="mt-2">{t.t("cms.description")} - {t.t("cms.ar")}:</Form.Label>

                                                    {JSON.parse(JSON.stringify(infoData)).id != null &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                               defaultValue={infos.descriptionAr} height={"200px"}
                                                               onChange={setdescriptionAr} setOptions={editoroptions}/>
                                                    }

                                                    {JSON.parse(JSON.stringify(infoData)).id == null &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                               defaultValue={""} height={"200px"}
                                                               onChange={setdescriptionAr} setOptions={editoroptions}/>
                                                    }

                                                </Col>



                                            </div>
                                            <Col lg={12}>
                                                <Form.Label
                                                    className="mt-2">{t.t("cms.detailsWhenFinsih")} - {t.t("cms.he")}:</Form.Label>
                                                {JSON.parse(JSON.stringify(infoData)).id != null &&
                                                <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                           defaultValue={infos.detailsHe} height={"200px"}
                                                           onChange={setdetailsHe} setOptions={editoroptions}/>
                                                }

                                                {JSON.parse(JSON.stringify(infoData)).id == null &&
                                                <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                           defaultValue={""} height={"200px"} onChange={setdetailsHe}
                                                           setOptions={editoroptions}/>
                                                }

                                            </Col>
                                            <Col lg={12}>
                                                <Form.Label
                                                    className="mt-2">{t.t("cms.detailsWhenFinsih")} - {t.t("cms.ar")}:</Form.Label>

                                                {JSON.parse(JSON.stringify(infoData)).id != null &&
                                                <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                           defaultValue={infos.detailsAr} height={"200px"}
                                                           onChange={setdetailsAr} setOptions={editoroptions}/>
                                                }

                                                {JSON.parse(JSON.stringify(infoData)).id == null &&
                                                <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                           defaultValue={""} height={"200px"} onChange={setdetailsAr}
                                                           setOptions={editoroptions}/>
                                                }

                                            </Col>

                                        </Tab>
                                        <Tab eventKey={"tab4"} title={t.t("cms.attchImages")}>

                                            <Col md={12} className={"mt-4"}>
                                                <Form.Group className="my-2 " controlId="file">
                                                    <Form.Label className="my-0">{t.t("cms.attchImages")}:</Form.Label>
                                                    <Form.Control ref={inputRef as Ref<any>} type="file" multiple onChange={(v)=>{AddFiles(v)}}
                                                                  aria-label={t.t("cms.attachFiles")}
                                                                  aria-describedby="msg_file"/>
                                                    <Form.Control.Feedback type="invalid" id="msg_file">
                                                        Required Field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            {attachfile &&
                                            <Table borderless>
                                                {attachfile.map((item:any,index:any) => {
                                                    return(
                                                        <tr>
                                                            <td>
                                                                <Row>
                                                                    <Col md={12}>{item.imageUrl}

                                                                        <Form.Group className="my-2 d-none" >
                                                                            <Form.Control name={"fileUrl[]"} defaultValue={item.imageUrl}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                            <Form.Control name={"fileId[]"} defaultValue={item.id}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                        </Form.Group>

                                                                    </Col>

                                                                </Row>

                                                            </td>
                                                            <td width={50}  ><Button variant={"outline-danger"} size={"sm"}  onClick={(e:any)=>{deleteAttachFile(e,index)}} className={"rounded-circle"}><Trash size={15} /></Button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </Table>
                                            }


                                            {filesNum &&
                                            <Table borderless>
                                                {filesNum.map((item,index) => {
                                                    return(
                                                        <tr>
                                                            <td>
                                                                <Row>
                                                                    <Col md={12}>{item}

                                                                        <Form.Group className="my-2 d-none" >
                                                                            <Form.Control name={"fileUrl[]"} defaultValue={item}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                            <Form.Control name={"fileId[]"} defaultValue={""}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                        </Form.Group>
                                                                    </Col>

                                                                </Row>

                                                            </td>
                                                            <td width={50}  ><Button variant={"outline-danger"} size={"sm"}  onClick={(e:any)=>{deleteFile(e,index)}} className={"rounded-circle"}><Trash size={15} /></Button></td>
                                                        </tr>
                                                    )
                                                })}
                                            </Table>
                                            }

                                        </Tab>


                                    </Tabs>

                                        :

                                        <Tabs className={"justify-content-center tabsModal"} defaultActiveKey={"tab1"}>
                                            <Tab eventKey={"tab1"} className={"shadow-0"}
                                                 title={t.t("cms.mainInformation") + " - " + t.t("cms.he")}>
                                                <Col lg={12}>
                                                    <Form.Group className="my-2" controlId="TitleHe">
                                                        <Form.Label>{t.t("cms.title")} - {t.t("cms.he")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.nameHe} required
                                                                      aria-label={t.t("cms.pageTitle")}
                                                                      aria-describedby="msg_titleHe"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleHe">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                    <Form.Group className="my-2" controlId="LocationHe">
                                                        <Form.Label>{t.t("cms.eventLocation")} - {t.t("cms.he")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.locationHe}
                                                                      aria-label={t.t("cms.eventLocation")}
                                                                      aria-describedby="msg_titleAr"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col lg={12}>
                                                    <Form.Label
                                                        className="mt-2">{t.t("cms.description")} - {t.t("cms.he")}:</Form.Label>
                                                    {JSON.parse(JSON.stringify(infoData)).id != null &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                               defaultValue={infos.descriptionHe} height={"200px"}
                                                               onChange={setdescriptionHe} setOptions={editoroptions}/>
                                                    }

                                                    {JSON.parse(JSON.stringify(infoData)).id == null &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                               defaultValue={""} height={"200px"}
                                                               onChange={setdescriptionHe} setOptions={editoroptions}/>
                                                    }

                                                </Col>


                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Group className="my-2 mt-4" controlId="ImageUrl">
                                                            <Form.Label
                                                                className="my-0">{t.t("cms.image")}:</Form.Label>
                                                            <Form.Control type="file"
                                                                          required={infos.imageUrl == null ? true : false}
                                                                          aria-label={t.t("cms.image")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Group className="my-2 mt-4" controlId="Timing">
                                                            <Form.Label
                                                                className="my-0">{t.t("cms.datetime")}:</Form.Label>
                                                            <Form.Control type="datetime-local"
                                                                          required
                                                                          defaultValue={infos.timing}
                                                                          aria-label={t.t("cms.datetime")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={4}>
                                                        <Form.Group className="my-2 mt-4" controlId="ExternalLink">
                                                            <Form.Label
                                                                className="my-0">{t.t("cms.outLink")}:</Form.Label>
                                                            <Form.Control type="text" defaultValue={infos.externalLink}
                                                                          aria-label={t.t("cms.outLink")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Tab>


                                            <Tab eventKey={"tab2"} className={"shadow-0"}
                                                 title={t.t("cms.mainInformation") + " - " + t.t("cms.ar")}>
                                                <Col lg={12}>
                                                    <Form.Group className="my-2" controlId="TitleAr">
                                                        <Form.Label>{t.t("cms.title")} - {t.t("cms.ar")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.nameAr} required
                                                                      aria-label={t.t("cms.pageTitle")}
                                                                      aria-describedby="msg_titleAr"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={12}>
                                                    <Form.Group className="my-2" controlId="LocationAr">
                                                        <Form.Label>{t.t("cms.eventLocation")} - {t.t("cms.ar")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.locationAr}
                                                                      aria-label={t.t("cms.eventLocation")}
                                                                      aria-describedby="msg_titleAr"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={12}>
                                                    <Form.Label
                                                        className="mt-2">{t.t("cms.description")} - {t.t("cms.ar")}:</Form.Label>

                                                    {JSON.parse(JSON.stringify(infoData)).id != null &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                               defaultValue={infos.descriptionAr} height={"200px"}
                                                               onChange={setdescriptionAr} setOptions={editoroptions}/>
                                                    }

                                                    {JSON.parse(JSON.stringify(infoData)).id == null &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"}
                                                               defaultValue={""} height={"200px"}
                                                               onChange={setdescriptionAr} setOptions={editoroptions}/>
                                                    }

                                                </Col>


                                            </Tab>

                                            <Col  className={"d-none"} md={12}>
                                                <Form.Group className="my-2 mt-4" controlId="file">
                                                    <Form.Label className="my-0">{t.t("cms.attachFiles")}:</Form.Label>
                                                    <Form.Control type="file"
                                                                  aria-label={t.t("cms.attachFiles")}
                                                                  aria-describedby="msg_file"/>
                                                    <Form.Control.Feedback type="invalid" id="msg_file">
                                                        Required Field
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                {attachfile &&
                                                <Table borderless>
                                                    {attachfile.map((item:any,index:any) => {
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    <Row>
                                                                        <Col md={12}>{item.imageUrl}

                                                                            <Form.Group className="my-2 d-none" >
                                                                                <Form.Control name={"fileUrl[]"} defaultValue={item.imageUrl}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                                <Form.Control name={"fileId[]"} defaultValue={item.id}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                            </Form.Group>

                                                                        </Col>

                                                                    </Row>

                                                                </td>
                                                                <td width={50}  ><Button variant={"outline-danger"} size={"sm"}  onClick={(e:any)=>{deleteAttachFile(e,index)}} className={"rounded-circle"}><Trash size={15} /></Button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </Table>
                                                }


                                                {filesNum &&
                                                <Table borderless>
                                                    {filesNum.map((item,index) => {
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    <Row>
                                                                        <Col md={12}>{item}

                                                                            <Form.Group className="my-2 d-none" >
                                                                                <Form.Control name={"fileUrl[]"} defaultValue={item}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                                <Form.Control name={"fileId[]"} defaultValue={""}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                            </Form.Group>
                                                                        </Col>

                                                                    </Row>

                                                                </td>
                                                                <td width={50}  ><Button variant={"outline-danger"} size={"sm"}  onClick={(e:any)=>{deleteFile(e,index)}} className={"rounded-circle"}><Trash size={15} /></Button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </Table>
                                                }

                                            </Col>


                                        </Tabs>


                                    }




                                </Row>
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    )
}

export default CMSEvents;
