import React, {useEffect, useState} from "react";
import {Col, Image, Nav, Row, Tab} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import SetupSevrices from "./setupService";
import DeviceType from "./deviceType";
import Vendors from "./vendor";

function Setting(){
    const  t = useTranslation()
    const tab = React.createRef<HTMLTextAreaElement>();
    const [page,setPage] = useState("Services")
    const openPage = (page:any) => {
        setPage(page)
    }
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const tabLink = params.get('tab') || "";
        if(document.getElementById("left-tabs-example-tab-"+tabLink)?.id != null){
            document.getElementById("left-tabs-example-tab-"+tabLink)?.click()
        }else{
            tab.current?.click()
        }
    }, [localStorage.getItem("lang")]);

    return(
        <div  className={"content"}>
            <Row>
                <Col className={"justify-content-center align-items-center align-content-center"}>
                    <Image srcSet={window.location.origin + "/images/icon21.svg"} height={40} />
                    <span className={"pagetitle text-uppercase d-inline-block pt-2 px-2"}>{t.t("iot.global.assetManagement")}</span>
                </Col>
            </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="Services">
                <Row>
                    <Col sm={12}>
                        <div className={"position-relative my-3"}>
                            <div className={"lightbox position-relative newTab"}>
                                <div className={"selector"}></div>

                                <Nav    >
                                    <Nav.Item as={"li"}>
                                        <Nav.Link  ref={tab as React.RefObject<any>}  onClick={()=> openPage("Services")} eventKey="Services">{t.t('iot.global.stpServicesTitle')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item  as={"li"}>
                                        <Nav.Link onClick={()=> openPage("deviceTypes")} eventKey="deviceTypes">{t.t('iot.global.deviceTypesTitle')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item  as={"li"}>
                                        <Nav.Link onClick={()=> openPage("vendors")} eventKey="vendors">{t.t('iot.global.vendorsTitle')}</Nav.Link>
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <hr className={"my-1 shodow-hr"} />
                    </Col>
                    <Col sm={12}>

                        <div className={"p-1"} >
                            {page == "Services" &&  <SetupSevrices   />}
                            {page == "deviceTypes" &&  <DeviceType  />}
                            {page == "vendors" &&  <Vendors  />}
                        </div>

                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}

export default Setting;