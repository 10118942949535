import React, {useEffect, useRef, useState} from "react";
import {ChevronLeft, Settings, Tool} from "react-feather";
import {useTranslation} from "react-i18next";
import {Alert, Button, Col, Form, Spinner,Row} from "react-bootstrap";
import axios from "axios";
import {UserProfile, websetting} from "../../../../helpers/websetting";
import {SystemSetting} from "../../../../constants/system";
import {Upload, useTitle} from "../../../../helpers/function";
import i18n from "i18next";
import {Link} from "react-router-dom";

function SystemSettingPage(prop:any){
    let orgId = "" ;


    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [firstLoading, setfirstLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [validated, setValidated] = useState(false);
    const [orgName, setorgName] = useState(-1);
    const [sysName, setsysName] = useState(-1);
    const [logo, setLogo] = useState(-1);
    const [cover, setCover] = useState(-1);
    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoading(true);
            setError("");
            setSuccess("");

            await axios.post(websetting.apiUrl + 'auth/api/v1/organizationSettings/',
                [{
                    "isActive": true,
                    "key": "orgName",
                    "organizationIdFk": orgId,
                    "valueAr": event.target.orgName_ar.value.trim(),
                    "valueEn": event.target.orgName_en.value.trim(),
                    "valueHe": event.target.orgName_he.value.trim()
                    },{
                        "isActive": true,
                        "key": "sysName",
                        "organizationIdFk": orgId,
                        "valueAr": event.target.sysName_ar.value.trim(),
                        "valueEn": event.target.sysName_en.value.trim(),
                        "valueHe": event.target.sysName_he.value.trim()
                    }],
                 {
                    method: "post",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                if(response.data.error !=""){
                    websetting.title = event.target.sysName_en.value.trim()
                    //useTitle(websetting.title)
                    setSuccess("تم حفظ البيانات")
                    setLoading(false);
                }else{
                    setError(response.data.error)
                    setLoading(false);
                }
                if(event.target.logo.value != ""){
                    setLoading(true);


                    Upload(event.target.logo).then(function(data:any) {
                        websetting.orgLogo =  data.toString()
                         axios.post(websetting.apiUrl + 'auth/api/v1/organizationSettings/',
                            [{
                                "isActive": true,
                                "key": "Logo",
                                "organizationIdFk": orgId,
                                "valueAr": data,
                                "valueEn": data,
                                "valueHe": data
                            }],
                            {
                                method: "post",
                                headers: {
                                    'content-type': 'application/json',
                                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                                }
                            }
                        ).then(async function (response) {
                            setLoading(false);
                        }).catch(function (error) {
                        });
                    })


                }
                if(event.target.cover.value != ""){
                    setLoading(true);
                    Upload(event.target.cover).then(function(data:any) {
                        websetting.coverImage = data.toString()
                        axios.post(websetting.apiUrl + 'auth/api/v1/organizationSettings/',
                            [{
                                "isActive": true,
                                "key": "Cover",
                                "organizationIdFk": orgId,
                                "valueAr": data,
                                "valueEn": data,
                                "valueHe": data
                            }],
                            {
                                method: "post",
                                headers: {
                                    'content-type': 'application/json',
                                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                                }
                            }
                        ).then(async function (response) {
                            setLoading(false);
                        }).catch(function (error) {
                        });
                    })


                }

            }).catch(function (error) {
                setError(error)
                setLoading(false);
            });
        }
    };

    const [data,setdata] = useState([])

    useEffect(()=>{
        setfirstLoading(true)

        if(UserProfile.userType == "ADMIN"){
            const search = window.location.search;
            const params = new URLSearchParams(search);
            if(params.get('orgId') != null){
                orgId = params.get('orgId') || "";
                GetSettings(orgId)
            }else{
                const trvid = params.get('trvId') || "";

                axios.get(websetting.apiUrl + 'auth/api/v1/organizationServices/'+trvid,
                    {
                        method: "get",
                        headers: {
                            'content-type': "multipart/form-data",
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    orgId = response.data.organizationIdFk
                    GetSettings(orgId)
                })

            }
        }else{
            orgId = prop.organizationId
            GetSettings(orgId)
        }


    },[]);


    const GetSettings = (id:any) => {
        axios.get(websetting.apiUrl + 'auth/api/v1/organizationSettings/' + id + '/allSettings',
            {
                method: "get",
                headers: {
                    'content-type': "multipart/form-data",
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setdata(response.data)
            setfirstLoading(false)

            const orgIndex = response.data.findIndex((array: any) => {
                return array["key"] === "orgName"
            });
            const sysIndex = response.data.findIndex((array: any) => {
                return array["key"] === "sysName"
            });
            /*
            const logoIndex = response.data.findIndex( (array:any) => {
                return array["key"] === "Logo"
            });
            const coverIndex = response.data.findIndex( (array:any) => {
                return array["key"] === "Cover"
            });
             */
            //alert(orgIndex)

            i18n.addResourceBundle('he', 'translation', {
                "iot.global.smartCityApp": response.data[sysIndex].valueHe
            });
            i18n.addResourceBundle('ar', 'translation', {
                "iot.global.smartCityApp": response.data[sysIndex].valueAr
            });
            i18n.addResourceBundle('en', 'translation', {
                "iot.global.smartCityApp": response.data[sysIndex].valueEn
            });


            setorgName(orgIndex);
            setsysName(sysIndex);
        }).catch(function (error) {
            setfirstLoading(false)
        });
    }

    if(error !== ""){
        setTimeout(function (){
            setError("");
        },SystemSetting.MsgTimeOut);
    }

    return(
        <div className={"content"}>
            <div className={"lightbox mb-3"}>


                <div className="pagetitle text-uppercase">
                    <div className={"d-flex justify-content-between"}>
                        <div className={"d-inline-flex"}>
                            <Settings className={"feather"} size={21} />
                            {t.t("iot.global.setting")}
                        </div>
                        <div className={"d-inline-flex align-content-end"}>
                            {UserProfile.userType == "ADMIN" &&
                            <Link to={"../userManagement?tab=organization"} >{t.t("iot.global.back")} <ChevronLeft className={" feather feather-chevron-left"} /></Link>
                            }
                        </div>
                    </div>

                </div>

                <hr className={"shodow-hr"}/>
                <Row className={"justify-content-center"}>
                    <Col md={10}>
                        {firstLoading ? <div className="text-center"><Spinner animation="border" variant="warning"
                                                                         role="status"><span
                                className="visually-hidden">انتظر من فضلك</span></Spinner></div> :
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                {success &&
                                <Alert id="AlertMsg" className="text-center" variant="success">{success}</Alert>}
                                {error && <Alert id="AlertMsg" className="text-center" variant="danger">{error}</Alert>}
                                {firstLoading && <div className="text-center"><Spinner animation="border" variant="warning"
                                                                                  role="status"><span
                                    className="visually-hidden">انتظر من فضلك</span></Spinner></div>}

                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="my-3" controlId="orgName_he">
                                            <Form.Label>{t.t("iot.global.OrgNameHe")}</Form.Label>
                                            <Form.Control type="text"  defaultValue={data[orgName]  && data[orgName]['valueHe']} required aria-label={""} aria-describedby="msg1"/>
                                            <Form.Control.Feedback type="invalid" id="msg1">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="my-3" controlId="orgName_en">
                                            <Form.Label>{t.t("iot.global.OrgNameEn")}</Form.Label>
                                            <Form.Control type="text" defaultValue={data[orgName]  && data[orgName]['valueEn']} required aria-label={""} aria-describedby="msg2"/>
                                            <Form.Control.Feedback type="invalid" id="msg2">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="my-3" controlId="orgName_ar">
                                            <Form.Label>{t.t("iot.global.OrgNameAr")}</Form.Label>
                                            <Form.Control type="text" defaultValue={data[orgName] && data[orgName]['valueAr']} required aria-label={""} aria-describedby="msg3"/>
                                            <Form.Control.Feedback type="invalid" id="msg3">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="my-3" controlId="sysName_he">
                                            <Form.Label>{t.t("iot.global.systemNameHe")}</Form.Label>
                                            <Form.Control type="text" defaultValue={data[sysName] && data[sysName]['valueHe']} required aria-label={""} aria-describedby="msg4"/>
                                            <Form.Control.Feedback type="invalid" id="msg4">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="my-3" controlId="sysName_en">
                                            <Form.Label>{t.t("iot.global.systemNameEn")}</Form.Label>
                                            <Form.Control type="text"  defaultValue={data[sysName] && data[sysName]['valueEn']} aria-label={""} aria-describedby="msg5" required/>
                                            <Form.Control.Feedback type="invalid" id="msg5">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="my-3" controlId="sysName_ar">
                                            <Form.Label>{t.t("iot.global.systemNameAr")}</Form.Label>
                                            <Form.Control type="text"  defaultValue={data[sysName] && data[sysName]['valueAr']} aria-label={""} aria-describedby="msg6" required/>
                                            <Form.Control.Feedback type="invalid" id="msg6">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="my-3" controlId="logo">
                                            <Form.Label>{t.t("iot.global.logo")}</Form.Label>
                                            <Form.Control type="file" aria-label={""} aria-describedby="msg7"
                                                          className={"p-2"}/>
                                            <Form.Control.Feedback type="invalid" id="msg7">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-3" controlId="cover">
                                            <Form.Label>{t.t("iot.global.cover")}</Form.Label>
                                            <Form.Control type="file" aria-label={""} aria-describedby="msg8"
                                                          className={"p-2"}/>
                                            <Form.Control.Feedback type="invalid" id="msg8">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <div className={" pb-5"}>
                                    <Button type="submit" variant={"dark"}
                                            className="w-100 mt-4">{t.t("iot.global.save")}</Button>
                                </div>

                            </Form>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default SystemSettingPage
