import React, {useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {Button, Spinner, Tab, Tabs} from "react-bootstrap";
import axios from "axios";
import {Auth, Lang, UserProfile, websetting} from "../../../helpers/websetting";
import { Link } from "react-router-dom";
import Moment from "moment";

function MyServices(){
        const [tabData,settabData] = useState([])

        useEffect(() => {
            axios.get(websetting.apiCitizen + 'CitizenMyCategories/GetMyCategory?moduleId=E6433341-4424-4AFC-894B-AC8FF52F435F',
                {
                    method: "get",
                    headers: {
                        'accept': 'text/plain',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                settabData(response.data)
            }).catch(function (error) {

            });
        }, []);


    return (
            <div className="h-100">
                <div className="pageTitle  mb-3">
                    <h3 className={"bold"} >שירויתים דיגיטליים</h3>
                </div>
                {tabData.length > 0 &&
                <Tabs defaultActiveKey="serivceTab1" id="uncontrolled-tab-example" className=" ">
                    {Object.keys({tabData}).map((titles, i) => {
                        return (
                            <Tab eventKey={"serivceTab" + ( i + 1)}    className="py-4" title={tabData[i]['name']}>
                                <GetService id={tabData[i]['id']} name={tabData[i]['name']} />
                            </Tab>
                        )
                    })}
                </Tabs>
                }
                {
                    tabData.length == 0 &&
                        <div className={"text-center"}>
                            <Spinner animation={"border"} variant={"warning"} ></Spinner>
                        </div>
                }
            </div>
        )

}

function GetService(props:any){
    const columns = [
        {
            name: 'الرقم',
            selector: (row : any) => row.id,
        },{
            name: 'اسم الطلب',
            selector : (row : any) => row.formName,
        },{
            name: 'القسم',
            selector: (row : any) => row.categoryName,
        },{
            name: 'تاريخ التقديم',
            selector: (row : any) => Moment(row.createdOn).format(websetting.dateFormat) ,
        },{
            name: 'الحالة',
            selector: (row : any) => row.statusName,
        },{
            name: '',
            cell : (row : any) => <Link to={"../CaseDetails/"+ row.id} className="btn btn-sm btn-outline-danger btn-rounded ">التفاصيل</Link>,
        }
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.post(websetting.apiCitizen + 'CitizenMyService/GetMyServices',
            {
                categoryId: props.id,
                page: page,
                rows: perPage
            },
            {
                method: "get",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setTotalRows(100);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        setLoading(true);
        axios.post(websetting.apiCitizen + 'CitizenMyService/GetMyServices',
          {
              categoryId: props.id,
              page: page,
              rows: newPerPage
            },
            {
                method: "get",
                headers: {
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setPerPage(newPerPage);
            setLoading(false);
        }).catch(function (error) {
        });
    };
    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);

    return(
        <div>
            <DataTable columns={columns} data={data} progressPending={loading}
                       pagination
                       paginationServer
                       paginationTotalRows={totalRows}
                       progressComponent ={<Spinner  animation={"border"} variant={"warning"} title={"Please Wait"} />}
                       onChangeRowsPerPage={handlePerRowsChange}
                       onChangePage={handlePageChange} />
        </div>
    );

}


export default MyServices;
