import React, { useState} from "react";
import {Check, Sun} from "react-feather";
import {Button, Image, Spinner} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {useParams} from "react-router-dom";
import Moment from "moment";
import {websetting} from "../../../../../helpers/websetting";

function FormApproval(props:any) {
    let {id}:any = useParams()
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    if( id == "New"){
        id = 0
    }

    const columns = [
        {
            sortable: true,
            name: 'اسم الموظف',
            selector: (row : any) => row.nameAr,
            style: {
                fontSize: "14px"
            }
        },{
            sortable: true,
            name: 'المنصب',
            selector: (row : any) => row.nameAr,
            style: {
                fontSize: "14px"
            }
        },{
            sortable: true,
            name: 'الترتيب',
            selector: (row : any) => row.nameAr,
            style: {
                fontSize: "14px"
            }
        },{
            sortable: true,
            name: 'نوع الاعتماد',
            selector: (row : any) => row.nameAr,
            style: {
                fontSize: "14px"
            }
        },{
            sortable: true,
            name: '',
            selector: (row : any) => row.nameAr,
            style: {
                fontSize: "14px"
            }
        },
    ];

    return (
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle text-uppercase"}>
                    <Check className={"feather"} />
                    <span className={"d-inline-block mx-2"}>الاعتمادات</span>
                    <Button variant={"dark"} size={"sm"}  className="text-uppercase float-end">+ اضافة اعتماد جديد</Button>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    {loading ? <div className="text-center"><Spinner animation="border"  variant="secondary" role="status" ><span className="visually-hidden">انتظر من فضلك</span></Spinner></div>:
                    <div>
                        <DataTable columns={columns} data={data} />
                    </div>
                    }

                </div>
            </div>
        </div>
    )
}
export default FormApproval