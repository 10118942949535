import React, {useEffect, useRef, useState} from "react";
import DataTable from 'react-data-table-component'
import {Link} from "react-router-dom";
import axios from "axios";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Trash, Edit, Eye, Tool, Layers} from 'react-feather';
import { websetting} from "../../../helpers/websetting";
import Moment from "moment";
import {SystemSetting} from "../../../constants/system";
import {useTranslation} from "react-i18next";

function Devices(){
    const t = useTranslation()
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);

    const [status, setStatus] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [device, setDevice] = useState([]);

    const [show, setShow] = useState(false);
    const [info, setInfo] = useState(true);
    const [infoData, setInfoData] = useState({});

    const getStatus = (code:any) => {
        axios.get(websetting.apiUrl + 'setup-service/api/v1/domainValues/'+code+'/byDomainCode',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setStatus(response.data.domainValuesList)
        }).catch(function (error) {

        });
    }

    const getVendor = () => {
        axios.get(websetting.apiUrl + 'setup-service/api/v1/vendors/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setVendor(response.data.data);
        }).catch(function (error) {

        });
    }
    const getDevice = () => {
        axios.get(websetting.apiUrl + 'setup-service/api/v1/deviceTypes/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setDevice(response.data.data);
        }).catch(function (error) {

        });
    }

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }

    const handleShow = () => {
        setShow(true);
    }



    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.apiUrl + 'inventory-service/api/v1/invDevices/'+event.target.Id.value,
                    {
                        /*
                        "vendorIdFk": event.target.VendorId.value,
                        "deviceTypeIdFk": event.target.DeviceId.value,
                         */
                        "serialNumber": event.target.Serial.value,
                        "status" : event.target.StatusId.value,
                     },
                    {
                        method: "put",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array:any) => {
                        return parseInt(array["inventoryDeviceIdPk"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));

                    prevState[index].serialNumber = response.data.serialNumber
                    prevState[index].statusDesc = response.data.statusDesc
                    prevState[index].vendorName = response.data.vendorName
                    prevState[index].deviceTypeName = response.data.deviceTypeName

                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {
                axios.post(websetting.apiUrl + 'inventory-service/api/v1/invDevices/',
                    {
                        "vendorIdFk": event.target.VendorId.value,
                        "deviceTypeIdFk": event.target.DeviceId.value,
                        "serialNumber": event.target.Serial.value,
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'inventory-service/api/v1/invDevices/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["inventoryDeviceIdPk"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }

    const edit = (id:any) => {
        setShow(true);
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.apiUrl + 'inventory-service/api/v1/invDevices/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }

    const showInfo = (id:any) => {
        setShow(true);
        setInfo(false);
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.apiUrl + 'inventory-service/api/v1/invDevices/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }

    const columns = [
        {
            name: t.t("iot.global.serialNumber"),
            selector: (row : any) => row.serialNumber,
        },{
            name: t.t("iot.global.status"),
            selector: (row : any) => row.statusDesc,
        },{
            name: t.t("iot.global.vendor"),
            selector: (row : any) => row.vendorName,
        },{
            name: t.t("iot.global.deviceType"),
            selector: (row : any) => row.deviceTypeName,
        },{
            name: t.t("iot.global.createdDate"),
            selector: (row : any) => Moment(row.createDate).format(websetting.dateFormat),
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.inventoryDeviceIdPk)} className={"rounded-circle"}><Trash size={15} /></Button> <Button onClick={()=>edit(row.inventoryDeviceIdPk)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button> <Button  variant={"dark"}  onClick={()=>showInfo(row.inventoryDeviceIdPk)} className={"rounded-circle"}><Eye  size={15} /></Button> <Link to={"../DeviceHistory/"+row.inventoryDeviceIdPk}    className={"btn btn-success  rounded-circle"}><Layers size={15} className={"mt-1"} /></Link></div>,
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        getDevice()
        getVendor()
        getStatus("INV_DEV_STATUS")
        const p = page - 1

        axios.get(websetting.apiUrl + 'inventory-service/api/v1/invDevices/?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {

        });
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);


    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div className={""}>
            <div className="lightbox">
                <div className={""}>
                    <div className="pagetitle text-uppercase">
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Tool className={"feather"} size={21} />
                                {t.t("iot.global.inventoryDevicesTitle")}
                            </div>
                            <div className={"d-inline-flex align-content-end"}>
                                <Button   variant={"warning"}  onClick={()=>edit(0)}>+ {t.t("iot.global.addInvDevice")}</Button>
                            </div>
                        </div>

                    </div>
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data} />

                </div>
            </div>

            <Modal size={"lg"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><Tool className={"feather"} /> {t.t("iot.global.inventoryDevicesTitle")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>
                        {info ?
                            <div>{
                                loadingModal ? <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"} /></div> :

                                    <Row>
                                        <Form.Group hidden controlId="Id" >
                                            <Form.Control type={"hidden"}   value={infos.inventoryDeviceIdPk}  />
                                        </Form.Group>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="Serial" >
                                                <Form.Label>{t.t("iot.global.serialNumber")}:</Form.Label>
                                                <Form.Control type="text"   defaultValue={infos.serialNumber}  required aria-label={t.t("iot.global.serialNumber")} aria-describedby="msgInput1" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {!infos.inventoryDeviceIdPk &&
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="DeviceId">
                                                <Form.Label>{t.t("iot.global.deviceTypeName")}:</Form.Label>
                                                <Form.Select defaultValue={infos.deviceTypeIdFk} required
                                                             aria-label={t.t("iot.global.deviceTypeName")}
                                                             aria-describedby="msgInput2">
                                                    {Object.keys(device).map((titles, i) => {
                                                        return (
                                                            <option
                                                                selected={infos.deviceTypeIdFk == JSON.parse(JSON.stringify(device[i])).deviceTypeIdPk ? true : false}
                                                                value={JSON.parse(JSON.stringify(device[i])).deviceTypeIdPk}>{JSON.parse(JSON.stringify(device[i])).name}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msgInput2">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        }
                                        {infos.inventoryDeviceIdPk &&
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="StatusId">
                                                <Form.Label>{t.t("iot.global.status")}:</Form.Label>
                                                <Form.Select defaultValue={infos.status} required aria-label={t.t("iot.global.inventoryDevicesTitle")} aria-describedby="msgInput6">
                                                    {Object.keys(status).map((titles, i) => {
                                                        return (
                                                            <option  selected={infos.status == JSON.parse(JSON.stringify(status[i])).value ? true : false}
                                                                value={JSON.parse(JSON.stringify(status[i])).value}>{JSON.parse(JSON.stringify(status[i])).name}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msgInput6">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        }
                                        {!infos.inventoryDeviceIdPk &&
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="VendorId">
                                                <Form.Label>{t.t("iot.global.vendorName")}:</Form.Label>
                                                <Form.Select defaultValue={infos.vendorIdFk && infos.vendorIdFk}
                                                             required aria-label={t.t("iot.global.vendorName")}
                                                             aria-describedby="msgInput7">
                                                    {Object.keys(vendor).map((titles, i) => {
                                                        return (
                                                            <option
                                                                selected={infos.vendorIdFk == JSON.parse(JSON.stringify(vendor[i])).vendorIdPk ? true : false}
                                                                value={JSON.parse(JSON.stringify(vendor[i])).vendorIdPk}>{JSON.parse(JSON.stringify(vendor[i])).name}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msgInput7">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        }

                                    </Row>
                            }
                            </div>
                            :
                            <div>
                                {
                                    loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                        <div>
                                            <Table     hover>
                                                <tbody>
                                                <tr><td>ID</td><td>{infos.inventoryDeviceIdPk}</td></tr>
                                                <tr><td>{t.t("iot.global.vendorName")}</td><td>{infos['vendorDTO'] && infos['vendorDTO'].name}</td></tr>
                                                <tr><td>{t.t("iot.global.deviceType")}</td><td>{infos['deviceTypeDTO'] && infos['deviceTypeDTO'].name }</td></tr>
                                                <tr><td>{t.t("iot.global.serialNumber")}</td><td>{infos.serialNumber}</td></tr>
                                                <tr><td>{t.t("iot.global.status")}</td><td>{infos.statusDesc}</td></tr>
                                                <tr><td>{t.t("iot.global.createdBy")}</td><td>{infos.createdBy}</td></tr>
                                                <tr><td>{t.t("iot.global.createdDate")}</td><td>{infos.createDate}</td></tr>
                                                <tr><td>{t.t("iot.global.updatedBy")}</td><td>{infos.updatedBy}</td></tr>
                                                <tr><td>{t.t("iot.global.updatedDate")}</td><td>{infos.updateDate}</td></tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                }
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"}  variant="warning">{t.t("iot.global.save")}</Button>}

                    </Modal.Footer>
                </Form>
            </Modal>

        </div>
    )
}
export default Devices;
