import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, Image, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {Auth, Lang, UserProfile, websetting} from "../../../../helpers/websetting";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {Calendar, Chrome, Home, Mail, Image as ImgIcon, MapPin, Phone, User, Globe} from "react-feather";
import {useTranslation} from "react-i18next";

function EmployeeProfile(){
    const t = useTranslation()
    const [data,setData]  = useState([])
    const [error, setError] = useState('');
    const [validated, setValidated] = useState(false);
    const [msg, setmsg] = useState('');
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [alert, setalert] = useState('');
    const [socialStatus, setsocialStatus] = useState([]);
    const [jobStatus, setjobStatus] = useState([]);


    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
             const userData =  JSON.parse(JSON.stringify(data))

            const formData = new FormData(event.target);

            if(event.target.profilePicture.value != ''){
                const file = event.target.profilePicture.files[0];
                formData.append('profilePicture', file, file.name);
            }


            formData.append('firstName', event.target.firstName.value);
            formData.append('lastName', event.target.lastName.value);
            formData.append('phone', event.target.phone.value);
            formData.append('userIdPk', UserProfile.userIdPk);
            formData.append('email', event.target.email.value);
            formData.append('homeNumber', event.target.homeNumber.value);
            formData.append('idNumber', event.target.idNumber.value);
            formData.append('jobStatus', event.target.jobStatus.value);
            formData.append('socialStatus', event.target.socialStatus.value);
            formData.append('preferredLangCode', event.target.preferredLangCode.value);
            formData.append('jobStatus', event.target.jobStatus.value);
            formData.append('contactBySms', event.target.contactBySms.value == 1 ? "true" : "false");
            formData.append('contactByPhone', event.target.contactByPhone.value == 1 ? "true" : "false");
            formData.append('contactByEmail', event.target.contactByEmail.value == 1 ? "true" : "false");


            setLoading2(true)
            setalert("")
            setmsg("")

            await axios.put(websetting.apiUrl + 'auth/api/v1/user/updateUserProfile',
                formData,
                {
                    method: "put",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setalert("success")
                setmsg(t.t("iot.global.profiledDataSaved"))
                setLoading2(false)
            }).catch(function (error) {
                setalert("danger")
                setmsg("Error")
                setLoading2(false)
            });
        }
    }





    useEffect(() => {
        setLoading(true);
        axios.get(websetting.apiUrl + 'auth/oauth/validate',
            {
                method: "get",
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(function (response) {
            setData(response.data)
            //toast.success('Here we go!', {closeable:true,duration: 2000000,});
             setLoading(false);
        }).catch(function (error) {
            setLoading(false);
        });


        axios.get(websetting.apiUrl + 'setup-service/public/api/v1/setup/JOB_STATUS/domainValues',
            {
                method: "get",
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(function (response) {
            setjobStatus(response.data)
        }).catch(function (error) {
        });


        axios.get(websetting.apiUrl + 'setup-service/public/api/v1/setup/SOCIAL_STATUS/domainValues',
            {
                method: "get",
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(function (response) {
            setsocialStatus(response.data)
        }).catch(function (error) {
        });


    }, []);
    const userData =  JSON.parse(JSON.stringify(data))

    return(
        <div className={"mt-3 pt-3"}>

        <div className="content ">
            <div className={"box"}>

                {loading ?
                    <div className={"text-center"}><Spinner animation={"border"} className={"my-5 "} variant={"warning"} /></div>
                    :
                <div>
                    <div className={"text-center"}>
                    <div className="personalImage overflow-hidden" ><Image srcSet={UserProfile.userImage} width={130} height={130} /> </div>
                    <p className="h3 my-3 text-black-50">{data && userData.user.firstName + " " + userData.user.lastName}</p>
                    </div>

                    <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example" className="justify-content-center ">
                        <Tab eventKey="tab1" className="py-4"  title={t.t("iot.global.accountDetails")}>
                            <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                                <Row className="fs-6 justify-content-center AccountDetails">
                                    <Col md={8}>
                                        <Row>

                                            {loading2 ? <div className={"text-center"}><Spinner variant={"danger"} animation={"border"} /></div> :
                                                <>
                                                    {alert &&
                                                    <div className={"alert alert-"+ alert } >{msg}</div>
                                                    }
                                                </>
                                            }

                                            <Col md={6}>
                                                <Form.Group className="pb-3" controlId={"firstName"} >
                                                    <Form.Label className={"mb-0"}><User className={"feather"} size={18} /> {t.t("iot.global.firstName")}</Form.Label>
                                                    <Form.Control type="text" size={"sm"} defaultValue={data && userData.user.firstName} autoComplete="off" required aria-label="First Name" aria-describedby="fname" />
                                                    <Form.Control.Feedback type="invalid"  id="fname" >
                                                        Enter First Name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                        <Form.Group className="pb-3" controlId={"lastName"} >
                                            <Form.Label className={"mb-0"}><User className={"feather"} size={18} /> {t.t("iot.global.lastName")}</Form.Label>
                                            <Form.Control type="text"  size={"sm"} defaultValue={data && userData.user.lastName} autoComplete="off" required aria-label="Last Name" aria-describedby="lname" />
                                            <Form.Control.Feedback type="invalid"  id="lname" >
                                                Enter Last Name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group className="pb-3"  controlId={"idNumber"} >
                                                    <Form.Label className={"mb-0"}><User className={"feather"} size={18} /> {t.t("TxtIdNumber")}</Form.Label>
                                                    <Form.Control type="text" size={"sm"} defaultValue={data && userData.user.idNumber} autoComplete="off" required aria-label={t.t("TxtIdNumber")} aria-describedby="email2" />
                                                    <Form.Control.Feedback type="invalid"  id="email2" >
                                                        Enter idNumber
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>


                                            <Col md={6}>
                                                <Form.Group className="pb-3"  controlId={"homeNumber"} >
                                                    <Form.Label className={"mb-0"}><Home className={"feather"} size={18} /> {t.t("TxtHomeNo")}</Form.Label>
                                                    <Form.Control type="text" size={"sm"} defaultValue={data && userData.user.homeNumber} autoComplete="off" required aria-label={t.t("TxtHomeNo")} aria-describedby="email3" />
                                                    <Form.Control.Feedback type="invalid"  id="email3" >
                                                        Enter Home No
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>





                                            <Col md={6}>
                                                <Form.Group className="pb-3"  controlId={"email"} >
                                                    <Form.Label className={"mb-0"}><Mail className={"feather"} size={18} /> {t.t("iot.global.email")}</Form.Label>
                                                    <Form.Control type="email" size={"sm"} defaultValue={data && userData.user.email} autoComplete="off" required aria-label="Email" aria-describedby="email" />
                                                    <Form.Control.Feedback type="invalid"  id="email" >
                                                        Enter Email
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="pb-3" controlId={"phone"}  >
                                                    <Form.Label className={"mb-0"}><Phone className={"feather"} size={18} /> {t.t("iot.global.phone")}</Form.Label>
                                                    <Form.Control type="tel" size={"sm"} defaultValue={data && userData.user.phone} autoComplete="off" required aria-label="Last Name" aria-describedby="mobile" />
                                                    <Form.Control.Feedback type="invalid"  id="mobile" >
                                                        Enter Phone
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>


                                            <Col md={6}>
                                                <Form.Group className="pb-3" controlId={"profilePicture"}  >
                                                    <Form.Label className={"mb-0"}><ImgIcon className={"feather"} size={18} /> {t.t("iot.global.profilePicture")}</Form.Label>
                                                    <Form.Control type="file" size={"sm"}   autoComplete="off"   aria-label={t.t("iot.global.profilePicture")} aria-describedby="mobile" />
                                                    <Form.Control.Feedback type="invalid"  id="mobile" >
                                                        Enter Phone
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>




                                            <Col md={6}>
                                                <Form.Group className="pb-3" controlId={"preferredLangCode"}  >
                                                    <Form.Label className={"mb-0"}><Globe className={"feather"} size={18} /> {t.t("iot.global.preferredLangCode")}</Form.Label>
                                                    <Form.Select   size={"sm"}    >
                                                        {
                                                            Lang.map((item:any)=>{
                                                                return(
                                                                    <option selected={userData.user.preferredLangCode == item.label ? true : false} value={item.label}>{item.title}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid"  id="mobile" >
                                                        Enter LangCode
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>







                                            <Col md={6}>
                                                <Form.Group className="pb-3" controlId={"jobStatus"}  >
                                                    <Form.Label className={"mb-0"}><User className={"feather"} size={18} /> {t.t("iot.global.jobStatus")}</Form.Label>
                                                    <Form.Select   size={"sm"}    >
                                                        {
                                                            jobStatus.map((item:any)=>{
                                                                return(
                                                                    <option selected={userData.user.jobStatus == item.id ? true : false} value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid"  id="mobile" >
                                                        Enter LangCode
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group className="pb-3" controlId={"socialStatus"}  >
                                                    <Form.Label className={"mb-0"}><User className={"feather"} size={18} /> {t.t("iot.global.socialStatus")}</Form.Label>
                                                    <Form.Select   size={"sm"}    >
                                                        {
                                                            socialStatus.map((item:any)=>{
                                                                return(
                                                                    <option selected={userData.user.socialStatus == item.id ? true : false}  value={item.id}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid"  id="mobile" >
                                                        Enter LangCode
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>


                                            <Col className="mt-3" >
                                                <div>{t.t("iot.global.getPasswordBy")}</div>
                                                <Form.Group as={"span"} className="" controlId="contactByEmail">
                                                    <Form.Check type={"checkbox"}   inline value={1} aria-label={t.t("iot.global.email")} >
                                                        <Form.Check.Label>{t.t("iot.global.email")}</Form.Check.Label>
                                                        <Form.Check.Input name={"contactByEmail"}  defaultChecked={data && userData.user.contactByEmail}  type={"checkbox"} value={1} />
                                                    </Form.Check>

                                                </Form.Group>

                                                <Form.Group as={"span"} className="" controlId="contactBySms" dir={"ltr"}>
                                                    <Form.Check type={"checkbox"}  inline value={2} aria-label={t.t("iot.global.sms")} >
                                                        <Form.Check.Input name={"contactBySms"}  defaultChecked={data && userData.user.contactBySms} type={"checkbox"}  value={1} />
                                                        <Form.Check.Label>{t.t("iot.global.sms")}</Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>

                                                <Form.Group as={"span"} className="" controlId="contactByPhone" dir={"ltr"}>
                                                    <Form.Check type={"checkbox"}  inline value={2} aria-label={t.t("iot.global.contactByPhone")} >
                                                        <Form.Check.Input name={"contactByPhone"}  defaultChecked={data && userData.user.contactByPhone} type={"checkbox"}  value={1} />
                                                        <Form.Check.Label>{t.t("iot.global.contactByPhone")}</Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>


                                            </Col>






                                            <Col md={12}>
                                                <Button type={"submit"} variant={"dark"} size={"lg"} className={"bold mt-3 w-100"}>{t.t("iot.global.save")}</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Tab>
                        <Tab eventKey="tab2" className="py-4" title={t.t("iot.global.changePassword")} >
                            <ChangePassword/>
                        </Tab>
                        <Tab eventKey="tab3" className="py-4"  title={t.t("iot.global.loggingLog")} >
                            <Logging />
                        </Tab>
                    </Tabs>
                </div>
                }
            </div>
        </div>
        </div>
    )
}




function ChangePassword(){
    const t = useTranslation()
    const navigate = useNavigate()
    const [validated, setValidated] = useState(false);
    const usernameInput = useRef(null);
    const [msg, setmsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setalert] = useState('');

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();

            if((event.target.newPassword.value !== event.target.confirmNewPassword.value) /*|| event.target.newPassword.value.length < 6 */){
                setalert("danger")
                setmsg(t.t("iot.global.mismatchNewPassword"))
                return false
            }
            setLoading(true)
            axios.put(websetting.apiUrl + 'auth/api/v1/user/'+UserProfile.userIdPk+'/changePassword',
                {
                    "cofirmNewPassword": event.target.newPassword.value,
                    "newPassword": event.target.newPassword.value,
                    "oldPassword": event.target.currentPassword.value,
                    "userIdPk": UserProfile.userIdPk
                }, {
                    method: "put",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoading(false)
                setalert("success")
                setmsg(t.t("iot.global.successChangePassword"))
            }).catch(function (error) {
                setLoading(false)
                setalert("danger")
                setmsg(t.t("iot.global.changePasswordAlert"))
            });
        }
    };

    return(
        <Row className="justify-content-center">
            <Col lg={5} md={6}>
                {loading ? <div className={"text-center"}><Spinner variant={"danger"} animation={"border"} /></div> :
                <>
                    {alert &&
                    <div className={"alert alert-"+ alert } >{msg}</div>
                    }
                </>
                }
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Form.Group className="pb-3 fs-6" controlId={"currentPassword"}  >
                        <Form.Label>{t.t("iot.global.currentPassword")}</Form.Label>
                        <Form.Control type="password" placeholder="" autoComplete="off" required aria-label={t.t("iot.global.currentPassword")} aria-describedby="currentPassword" />
                        <Form.Control.Feedback type="invalid"  id="currentPassword" >
                            Enter Current Password
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="pb-3 fs-6" controlId={"newPassword"} >
                        <Form.Label>{t.t("iot.global.newPassword")}</Form.Label>
                        <Form.Control type="password" placeholder="" autoComplete="off" required aria-label={t.t("iot.global.newPassword")} aria-describedby="newPassword" />
                        <Form.Control.Feedback type="invalid"  id="newPassword" >
                            Enter New Password
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="pb-3 fs-6" controlId={"confirmNewPassword"}  >
                        <Form.Label>{t.t("iot.global.confirmNewPassword")}</Form.Label>
                        <Form.Control type="password" placeholder=""  autoComplete="off" required aria-label={t.t("iot.global.confirmNewPassword")} aria-describedby="newPassword2" />
                        <Form.Control.Feedback type="invalid"  id="newPassword2" >
                            Enter Confirm Password
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button security={"ddd"} type={"submit"} variant={"dark"} size={"lg"} className={"bold mt-3 w-100"}>{t.t("iot.global.save")}</Button>
                </Form>
            </Col>
        </Row>
    );
}
function Logging() {
    const t = useTranslation()
    return(
        <div>
            <Table className={"table historyTable align-middle"}>
                <thead>
                <tr>
                    <td>
                        <Chrome size={18}  className="feather" />
                        {t.t("iot.global.browser")}</td>
                    <td>
                        <MapPin size={18}  className="feather" />
                        {t.t("iot.global.userLocation")}
                    </td>
                    <td>
                        <Calendar size={18} className="feather" />
                        {t.t("iot.global.lastUse")}</td>
                    <td></td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <span>{t.t("iot.global.save")}Windows</span>
                        <span>{t.t("iot.global.save")}Chrome</span>
                    </td>
                    <td>
                        <span>Ramallah</span>
                        <span>84.110.215.218</span>
                    </td>
                    <td>
                        <span>May 23, 2020 10:34 PM</span>
                        <span>2 minutes ago</span>
                    </td>
                    <td className={"text-center"}>
                        <a className="blueLink" href={"#"}>Logout of the system</a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Windows</span>
                        <span>Chrome</span>
                    </td>
                    <td>
                        <span>Ramallah</span>
                        <span>84.110.215.218</span>
                    </td>
                    <td>
                        <span>May 23, 2020 10:34 PM</span>
                        <span>2 minutes ago</span>
                    </td>
                    <td className={"text-center"}>
                        <a  className="blueLink"  href={"#"}>Logout of the system</a>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Windows</span>
                        <span>Chrome</span>
                    </td>
                    <td>
                        <span>Ramallah</span>
                        <span>84.110.215.218</span>
                    </td>
                    <td>
                        <span>May 23, 2020 10:34 PM</span>
                        <span>2 minutes ago</span>
                    </td>
                    <td className={"text-center"}>
                        <a  className="blueLink" href={"#"}>Logout of the system</a>
                    </td>
                </tr>
                </tbody>
            </Table>
        </div>
    );
}

export default EmployeeProfile;
