import {Button, Col, Form, Modal, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    Battery,
    BatteryCharging,
    Calendar,
    Edit,
    Eye, PieChart,
    Sun,
     Trash,
} from "react-feather";
import axios from "axios";
import {websetting} from "../../../../../../helpers/websetting";
import DataTable from "react-data-table-component";
import {useTranslation} from "react-i18next";
import {SystemSetting} from "../../../../../../constants/system";
import Moment from "moment";
import {Bar} from "react-chartjs-2";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
function Groups(prop : any){
    const t = useTranslation()
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [device, setdevice] = useState([]);
    const [historydata, setHistorydata] = useState([]);
    const [taskdata, setTaskdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [showAutoSchedual, setshowAutoSchedual] = useState(false);
    const [AddDeviceShow, setAddDeviceShow] = useState(false);
    const [showsumbitgroup, setshowsumbitgroup] = useState(false);
    const [devicedata, setDeviceData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [totalRows2, setTotalRows2] = useState(0);

    const [info, setInfo] = useState(true);
    const [infoData, setInfoData] = useState({});
    const [AutoSchedualData, setAutoSchedualData] = useState({});
    const [groupId, setgroupId] = useState(0);
    const [groupDashboard, setGroupDashboard] = useState([]);

    const closesubmitgroup = () => {
        setshowsumbitgroup(false);
        setValidated(false);
        setLoadingModal(false)
    }
    let selectedGroup = 0

    const CloseAutoSchedual = () => {
        setshowAutoSchedual(false);
        setInfo(true);
        setLoadingModal(false)
        setAutoSchedualData({})
        setgroupId(0)
    }

    const OpenAutoSchedual = (el:any) => {
        const id = el.currentTarget.value
        if(el.currentTarget.checked) {
            setTimeout(()=>{
                setshowAutoSchedual(true);
                //el.currentTarget.checked = true



                /*
                setLoadingModal(true);
                axios.get(websetting.apiUrl + 'lighting-service/api/v1/groups/' + id,
                    {
                        method: "get",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setAutoSchedualData(response.data);
                    setLoadingModal(false);
                }).catch(function (error) {

                });

                 */
            },300)

        }else {
            axios.put(websetting.apiUrl + 'auth/api/v1/organizationServices/1/updateAutoSchedule',
                {
                    "isAuto": false,
                    "trvId": prop.trvId
                },
                {
                    method: "post",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
            }).catch(function (error) {
            });
        }
    }

    const AddAutoSchedual = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            axios.put(websetting.apiUrl + 'auth/api/v1/organizationServices/1/updateAutoSchedule',
                {
                    "afterMins": event.currentTarget.afterMins.value,
                    "beforeMins": event.currentTarget.beforeMins.value,
                    "isAuto": true,
                    "trvId": prop.trvId
                },
                {
                    method: "post",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setshowAutoSchedual(false)
                setLoadingModal(false)
                document.getElementById("isAutoSchedual")!.setAttribute("checked","checked")
            }).catch(function (error) {
                setLoadingModal(false)
            });
        }
    };






    const handleClose = () => {
        setShow(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
        setgroupId(0)
    }
    const handleClose2 = () => {
        setShow4(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
        setgroupId(0)
    }
    const view = (id:any) => {
        setShow(true);
        setLoadingModal(true);
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/groups/'+id,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setInfoData(response.data);
            setLoadingModal(false);
        }).catch(function (error) {

        });
    }
    const edit = (id:any) => {
        setshowsumbitgroup(true);
        if(id != 0) {
            setLoadingModal(true);
            axios.get(websetting.apiUrl + 'lighting-service/api/v1/groups/' + id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setInfoData(response.data);
                setLoadingModal(false);
            }).catch(function (error) {

            });
        }else {
            setInfoData({})
        }
    }
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);

    let options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: '',
            },
        },
    };

    let chartdata1 = {
        labels: [],
        datasets: [
            {
                data: [],
                 backgroundColor: [
                    '#9DE58C',
                    'rgba(255, 206, 86, 1)',
                    '#999999',
                ],
                borderWidth: 1,
            },
        ],
    };
    let chartdata2 = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [
                    '#9DE58C',
                    '#DA5A55',
                ],
                borderWidth: 1,
            },
        ],
    };


    const [chart, setChart] = useState(chartdata1);
    const [chart2, setChart2] = useState(chartdata2);

    const groupdashboard = (id:any) => {
        setShow1(true);
        setLoadingModal(true)
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/streetLightDashboard/'+id+'/group',
            {
                method: "get",
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(function (response) {
            let lb :any = []
            let lb2 :any = []

            lb.push(response.data.onDevicesCount)
            lb.push(response.data.dimDevicesCount)
            lb.push(response.data.offDevicesCount)

            lb2.push(response.data.connectedDevicesCount)
            lb2.push(response.data.disconnectedDevicesCount)

            chartdata1.datasets[0].data = lb
            chartdata1.labels = [t.t("iot.global.on"), t.t("iot.global.dim"), t.t("iot.global.off")]
            setChart(chartdata1)

            chartdata2.datasets[0].data = lb2
            chartdata2.labels = [t.t("iot.global.connected"), t.t("iot.global.disconnected")]
            setChart2(chartdata2)



            setGroupDashboard(response.data)

            setLoadingModal(false)

        }).catch(function (error) {
        });
    }
    const showdevice = (id:any) => {
        selectedGroup = id
        setgroupId(id)
        setShow2(true)
        setLoadingModal(true)
        fetchDeviceData(1)
    }
    const DevicehandlePerRowsChange = async (newPerPage:any, page:any) => {
        setPerPage(newPerPage);
        fetchDeviceData(page)
    };
    const DevicehandlePageChange = (page :any) => {
        fetchDeviceData(page);
    };
    const fetchDeviceData = async (page:any) => {
        const p = page - 1
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/groupDevices/'+selectedGroup+'/devices?pageNO='+p+'&pageSize='+perPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setdevice(response.data.data)
            setTotalRows2(response.data.recordsTotal)
            setLoadingModal(false)
        }).catch(function (error) {
        });
    };


    const AddDeviceClose = () => {
        setAddDeviceShow(false)
        setLoadingModal(false)
    }
    const AddDeviceModal = (id:any) => {
        setAddDeviceShow(true)
        setLoadingModal(true)
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/devices/'+ prop.trvId +'/devicesWithoutGroup',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setDeviceData(response.data);
            setLoadingModal(false)
        }).catch(function (error) {
            setLoadingModal(false)
        });
    }
    const AddDeviceSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            axios.post(websetting.apiUrl + 'lighting-service/api/v1/groupDevices/',
                {
                    devId: event.target.devices.value,
                    grpId: groupId,
                },
                {
                    method: "post",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setdevice(device.concat(response.data))
                setAddDeviceShow(false)
                setLoadingModal(false)
            }).catch(function (error) {
                setLoadingModal(false)
                alert(error)
            });
        }
    };
    const delDevice = (id : any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'lighting-service/api/v1/groupDevices/'+id ,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = device.filter((array) => {
                    return array["groupDeviceIdPk"] !== id
                });
                setdevice(newData)
            }).catch(function (error) {
            });
        }
    }
    const deleteGroup = (id : any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'lighting-service/api/v1/groups/'+id ,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["grpId"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }
    const deleteTask= (id : any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'schedule-service/api/v1/schedule/'+id ,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = taskdata.filter((array) => {
                    return array["scheduleTaskIdPk"] !== id
                });
                setTaskdata(newData)
            }).catch(function (error) {
            });
        }
    }



    const groupchedule = (id:any) => {
        setgroupId(id)
        setShow3(true)
        setLoadingModal(true)
        axios.get(websetting.apiUrl + 'schedule-service/api/v1/schedule/'+id+'/group?pageNO=0&pageSize=10',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setTaskdata(response.data.data);
            setLoadingModal(false)
        }).catch(function (error) {

        });
    }
    const setOn = (id:any) => {
        const index = data.findIndex( (array) => {
            return parseInt(array["grpId"]) === id
        });
        let prevState = JSON.parse(JSON.stringify(data));

        prevState[index].event= "-3"
        setData(prevState)
        axios.post(websetting.apiUrl + 'lighting-service/api/v1/deviceControl/turnOnGroup',
            {
                grpId : id
            },
            {
                method: "post",
                headers: {
                    'user-name-header': "'" + sessionStorage.getItem("username") + "'",
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            const index = data.findIndex( (array) => {
                return parseInt(array["grpId"]) === id
            });
            let prevState = JSON.parse(JSON.stringify(data));
            prevState[index].eventDesc = "On"
            prevState[index].event= "254"
            setData(prevState)
        }).catch(function (error) {

        });
    }
    const setDim = (id:any) => {
        const index = data.findIndex( (array) => {
            return parseInt(array["grpId"]) === id
        });
        let prevState = JSON.parse(JSON.stringify(data));

        prevState[index].event= "-2"
        setData(prevState)
        axios.post(websetting.apiUrl + 'lighting-service/api/v1/deviceControl/turnDimGroup',
            {
                grpId : id
            },
            {
                method: "post",
                headers: {
                    'user-name-header': "'" + sessionStorage.getItem("username") + "'",
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            const index = data.findIndex( (array) => {
                return parseInt(array["grpId"]) === id
            });
            let prevState = JSON.parse(JSON.stringify(data));
            prevState[index].eventDesc = "Dim"
            prevState[index].event= "116"
            setData(prevState)

        }).catch(function (error) {

        });
    }
    const setOff = (id:any) => {
        const index = data.findIndex( (array) => {
            return parseInt(array["grpId"]) === id
        });
        let prevState = JSON.parse(JSON.stringify(data));

        prevState[index].event= "-1"
        setData(prevState)

        axios.post(websetting.apiUrl + 'lighting-service/api/v1/deviceControl/turnOffGroup',
            {
                grpId : id
            },
            {
                method: "post",
                headers: {
                    'user-name-header': "'" + sessionStorage.getItem("username") + "'",
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            const index = data.findIndex( (array) => {
                return parseInt(array["grpId"]) === id
            });
            let prevState = JSON.parse(JSON.stringify(data));
            prevState[index].eventDesc = "Off"
            prevState[index].event= "0"
            setData(prevState)

        }).catch(function (error) {

        });

    }
    const columns = [
        {
            name: t.t("iot.global.name"),
            selector: (row : any) => row.name,
        },{
            name: t.t("iot.global.groupActions"),
            cell : (row : any) => <div><Button variant={"success"} title={t.t("iot.global.turnOn")} onClick={()=>setOn(row.grpId)} className={"rounded-circle"}><BatteryCharging size={15} /></Button> <Button onClick={()=>setDim(row.grpId)} title={t.t("iot.global.dimLight")}  variant={"warning"} className={"rounded-circle"}><Sun size={15} /></Button> <Button  variant={"danger"} title={t.t("iot.global.turnOff")}   onClick={()=>setOff(row.grpId)} className={"rounded-circle "}><Battery  size={15} /></Button></div>,
        },{
            name: t.t("iot.global.groupDevices"),
            selector: (row : any) => <Button onClick={()=>{showdevice(row.grpId)}} variant={"warning"}>{t.t("iot.global.devicesTitle")}</Button>,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div><Button variant={"dark"} title={t.t("iot.global.groupInfo")} onClick={()=>view(row.grpId)} className={"rounded-circle"}><Eye size={15} /></Button> <Button onClick={()=>edit(row.grpId)} title={t.t("iot.global.edit")} variant={"primary"} className={"rounded-circle"}><Edit size={15} /></Button> <Button variant={"danger"} title={t.t("iot.global.delete")} onClick={()=>deleteGroup(row.grpId)} className={"rounded-circle"}><Trash size={15} /></Button> <Button  variant={"success"} title={t.t("iot.global.GroupDashboard")}  onClick={()=>groupdashboard(row.grpId)} className={"rounded-circle "}><PieChart  size={15} /></Button> <Button   onClick={()=>groupchedule(row.grpId)} title={t.t("iot.global.scheduleGroup")} variant={"warning"} className={"rounded-circle"}><Calendar size={15} /></Button></div>,
        }
    ];
    const task_columns = [
        /*{
            name: t.t("iot.global.deviceName"),
            selector: (row : any) => row.deviceName,
        },*/
        {
            name: t.t("iot.global.powerLevel"),
            selector: (row : any) => row.lightingLevelDesc,
        },{
            name: t.t("iot.global.periodType"),
            selector: (row : any) => row.periodTypeDesc,
        },{
            name: t.t("iot.global.executeTime"),
            selector: (row : any) => Moment(row.executeTime).format(websetting.dateFormat + " HH:mm:ss"),
        },{
            name: t.t("iot.global.status"),
            selector: (row : any) => row.taskStatusDesc,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>deleteTask(row.scheduleTaskIdPk)} className={"rounded-circle"}><Trash size={15} /></Button> </div>,
        }
    ];
    const device_columns = [
        {
            name: "Id",
            selector: (row : any) => row.deviceDevKey,
        },{
            name: t.t("iot.global.name"),
            selector: (row : any) => row.deviceName,
        },{
            name: t.t("iot.global.currentStatus"),
            selector: (row : any) => row.deviceEventDesc,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>delDevice(row.groupDeviceIdPk)} className={"rounded-circle"}><Trash size={15} /></Button> </div>,
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/groups/'+prop.trvId+'/groups/?pageNO='+p+'&pageSize='+perPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {
        });
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        setPerPage(newPerPage);
        fetchDta(page)
    };
    const delhistory = (id : any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'lighting-service/api/v1/deviceMessages/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = historydata.filter((array) => {
                    return array["dvmId"] !== id
                });
                setHistorydata(newData)
            }).catch(function (error) {
            });
        }
    }

    const AddTask = () => {
        setShow4(true)
    }
    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.apiUrl + 'schedule-service/api/v1/schedule/'+event.target.Id.value,
                    {
                        devId:groupId,
                        executeTime: event.target.datetime.value,
                        lightingLevel: event.target.power.value,
                        periodType: event.target.period.value,
                    },
                    {
                        method: "put",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = taskdata.findIndex( (array) => {
                        return parseInt(array["scheduleTaskIdPk"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(taskdata));
                    prevState[index].lightingLevelDesc = response.data.lightingLevelDesc
                    prevState[index].periodTypeDesc = response.data.periodTypeDesc
                    prevState[index].executeTime = response.data.executeTime + "T06:34:32.553Z"
                    prevState[index].periodTypeDesc = response.data.periodTypeDesc

                    setTaskdata(prevState)
                    setShow4(false)
                    setLoadingModal(false)
                }).catch(function (error) {
                    setLoadingModal(false)
                });
            }else {
                axios.post(websetting.apiUrl + 'schedule-service/api/v1/schedule/'+groupId+'/group',
                    {
                        executeTime: event.target.datetime.value,
                        lightingLevel: event.target.power.value,
                        periodType: event.target.period.value,
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setTaskdata(taskdata.concat(response.data))
                    setShow4(false)
                    setLoadingModal(false)
                }).catch(function (error) {
                    setLoadingModal(false)
                    alert(error)
                });

            }
        }
    };
    const submitgroup = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.apiUrl + 'lighting-service/api/v1/groups/'+event.target.Id.value,
                    {
                        name: event.target.name.value,
                        trvId: prop.trvId
                    },
                    {
                        method: "put",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["grpId"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].name = response.data.name

                    setData(prevState)
                    setshowsumbitgroup(false)
                    setLoadingModal(false)
                }).catch(function (error) {
                    setLoadingModal(false)
                });
            }else {
                axios.post(websetting.apiUrl + 'lighting-service/api/v1/groups/',
                    {
                        name: event.target.name.value,
                        trvId: prop.trvId
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setshowsumbitgroup(false)
                    setLoadingModal(false)
                }).catch(function (error) {
                    setLoadingModal(false)
                    alert(error)
                });

            }
        }
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, [perPage]);

    const infos =  JSON.parse(JSON.stringify(infoData))
    const grDashboard =  JSON.parse(JSON.stringify(groupDashboard))

    return(
        <div className={"lightbox"}>

            <div className="pagetitle text-uppercase">
                <div className={"d-flex justify-content-between"}>
                    <div className={"d-inline-flex"}>
                        <Sun className={"feather"} />
                        <span className={"d-inline-block mx-2"}>{t.t("iot.global.groupsTitle")}</span>
                    </div>
                    <div className={"d-inline-flex align-content-end"}>
                        <Button variant={"dark"} size={"sm"} onClick={()=>{edit(0)}}  className="text-uppercase bold float-end">+ {t.t("iot.global.newGroup")}</Button>
                    </div>
                </div>
            </div>

            <hr className={"shodow-hr"} />
            <div>
                <DataTable progressPending={loading}
                           pagination
                           paginationServer
                           paginationTotalRows={totalRows}
                           progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                           onChangeRowsPerPage={handlePerRowsChange}
                           onChangePage={handlePageChange} columns={columns} data={data} />
            </div>

            <Modal size={"xl"} centered={true}
                   show={show1}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header className={"bg-light"} closeButton>
                    <Modal.Title><Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.groupInfo")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        {
                            loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                <div>

                                    <Tabs className={"justify-content-center"} defaultActiveKey={"tab1"}>
                                        <Tab eventKey={"tab1"} className={"shadow-0"} title={t.t("iot.global.status")} >
                                            <Bar options={options} data={chart}    />
                                        </Tab>
                                        <Tab eventKey={"tab3"} className={"shadow-0"} title={t.t("iot.global.connectivity")} >
                                            <Bar options={options} data={chart2}    />
                                        </Tab>
                                        <Tab eventKey={"tab2"} title={t.t("iot.global.devicesLocation")} >
                                            <Row className={"py-3"}>
                                                <Col md={12}>
                                                    {grDashboard.devicesList   &&
                                                    <LoadScript googleMapsApiKey={SystemSetting.mapKey}>
                                                        {grDashboard.devicesList.length > 0 &&
                                                        <GoogleMap
                                                            mapContainerStyle={{width: '100%', height: '370px'}}
                                                            center={{
                                                                lat: parseFloat(grDashboard.devicesList[0].latitude),
                                                                lng: parseFloat(grDashboard.devicesList[0].longitude)
                                                            }}
                                                            zoom={19}>
                                                            {Object.keys(grDashboard.devicesList).map((titles, i) => {
                                                                return (
                                                                    <Marker icon={websetting.webUrl + "/images/map/"+(grDashboard.devicesList[i].status == 0 ? "red" : (grDashboard.devicesList[i].status == 1 && grDashboard.devicesList[i].eventDesc == "Off" ? "grey" : "yellow") )+".svg"}   position={{
                                                                        lat: parseFloat(grDashboard.devicesList[i].latitude),
                                                                        lng: parseFloat(grDashboard.devicesList[i].longitude)
                                                                    }}></Marker>
                                                                )
                                                            })}

                                                        </GoogleMap>
                                                        }
                                                    </LoadScript>
                                                    }


                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                </div>
                        }
                    </div>

                </Modal.Body>
            </Modal>

            <Modal size={"xl"} centered={true} show={show} onExited={handleClose} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.groupInfo")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        {
                            loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                <div>
                                    <Table hover>
                                        <thead>
                                        <tr><th style={{width:"50%"}} >{t.t("iot.global.deviceFields")}</th><th className={"text-center"} style={{width:"50%"}}>{t.t("iot.global.value")}</th></tr>
                                        </thead>
                                        <tbody>
                                        <tr><td className={"bg-light"}>ID</td><td className={"text-center"}>{infos.grpId}</td></tr>
                                        <tr><td className={"bg-light"}>{t.t("iot.global.name")}</td><td className={"text-center"}>{infos.name}</td></tr>
                                        <tr><td className={"bg-light"}>{t.t("iot.global.serviceName")}</td><td className={"text-center"}>{infos.organizationServiceDTO != null && infos.organizationServiceDTO.name}</td></tr>
                                        <tr><td className={"bg-light"}>{t.t("iot.global.createdBy")}</td><td className={"text-center"}>{infos.createdBy}</td></tr>
                                        <tr><td className={"bg-light"}>{t.t("iot.global.createdDate")}</td><td className={"text-center"}>{infos.createDate}</td></tr>
                                        <tr><td className={"bg-light"}>{t.t("iot.global.updatedBy")}</td><td className={"text-center"}>{infos.updatedBy}</td></tr>
                                        <tr><td className={"bg-light"}>{t.t("iot.global.updatedDate")}</td><td className={"text-center"}>{infos.updateDate}</td></tr>
                                        </tbody>
                                    </Table>
                                </div>
                        }
                    </div>

                </Modal.Body>
            </Modal>
            <Modal size={"xl"} centered={true} show={show3} onExited={handleClose} onHide={handleClose} backdrop="static">
                <Modal.Header className={"bg-light"} closeButton >
                    <Modal.Title className={"d-flex justify-content-between"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.tasks")}</span>
                            </div>
                            <div className={"d-inline-flex mx-5 align-content-end"}>
                                <Button variant={"warning"}  onClick={()=>{AddTask()}} >{t.t("iot.global.addTask")}</Button>
                            </div>
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {
                            loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                <div>
                                    <Form.Group   controlId={"isAutoSchedual"}>
                                        <Form.Check type="switch" onChange={(value)=>{OpenAutoSchedual(value)}} value={"1"} label={t.t('iot.global.autoScheduling')} />
                                    </Form.Group>
                                    <DataTable pagination paginationPerPage={10} columns={task_columns} data={taskdata} />
                                </div>
                        }
                    </div>

                </Modal.Body>
            </Modal>
            <Modal size={"xl"} centered={true} show={show4} onExited={handleClose2} onHide={handleClose2} backdrop="static">
                <Modal.Header className={"bg-light"} >
                    <Modal.Title className={"d-flex justify-content-between"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.tasks")}</span>
                            </div>
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>

                        <Row>
                            <Form.Group hidden controlId="devId" >
                                <Form.Control type={"hidden"}   value={infos.scheduleTaskIdPk}  />
                            </Form.Group>

                            <Form.Group hidden controlId="Id" >
                                <Form.Control type={"hidden"}   value={infos.scheduleTaskIdPk}  />
                            </Form.Group>
                            <Col md={6}>
                                <Form.Group className="my-2" controlId="power" >
                                    <Form.Label>{t.t("iot.global.powerLevel")}:</Form.Label>
                                    <Form.Select required aria-label={t.t("iot.global.powerLevel")} aria-describedby="msgInput1">
                                        <option value={1}>{t.t("iot.global.off")}</option>
                                        <option value={116}>{t.t("iot.global.dim")}</option>
                                        <option value={254}>{t.t("iot.global.on")}</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                        Required Field
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="my-2" controlId="datetime" >
                                    <Form.Label>{t.t("iot.global.dateAndTime")}:</Form.Label>
                                    <Form.Control type="datetime-local"  defaultValue={infos.code} required aria-label={t.t("iot.global.dateAndTime")} aria-describedby="msgInput2" />
                                    <Form.Control.Feedback type="invalid"  id="msgInput2" >
                                        Required Field
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col md={12}>
                                <Form.Group className="my-2" controlId="period" >
                                    <Form.Label>{t.t("iot.global.periodType")}:</Form.Label>
                                    <Form.Select required aria-label={t.t("iot.global.periodType")} aria-describedby="msgInput1">
                                        <option value={1}>Once</option>
                                        <option value={2}>Periodic</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                        Required Field
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose2}>{t.t("iot.global.close")}</Button>
                        <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Modal size={"xl"} centered={true} show={show2} onExited={handleClose} onHide={handleClose} backdrop="static">
                <Modal.Header className={"bg-light"}  >
                    <Modal.Title className={"d-flex justify-content-between"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.devicesTitle")}</span>
                            </div>
                        </div>
                    </Modal.Title>
                    <div className={"d-inline-flex"}>
                        <Button variant={"dark"} onClick={(e)=>{AddDeviceModal(0)}} className={"mx-1"} size={"sm"}>{t.t("iot.global.addDeviceToGroup")}</Button>
                        <Button variant={"danger"} onClick={(e)=>{setShow2(false)}} size={"sm"} >{t.t("iot.global.close")}</Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <DataTable progressPending={loadingModal}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows2}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={DevicehandlePerRowsChange}
                               onChangePage={DevicehandlePageChange}
                               columns={device_columns} data={device} />
                </Modal.Body>
             </Modal>
            <Modal size={"lg"} centered={true} show={showsumbitgroup} onExited={closesubmitgroup} onHide={closesubmitgroup} backdrop="static">
                <Modal.Header className={"bg-light"} >
                    <Modal.Title className={"d-flex justify-content-between"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.groupsTitle")}</span>
                            </div>
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={submitgroup}>
                    <Modal.Body>

                        {
                            loadingModal ?
                                <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :
                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.grpId}/>
                                    </Form.Group>
                                    <Col md={12}>
                                        <Form.Group className="my-2" controlId="name">
                                            <Form.Label>{t.t("iot.global.name")}:</Form.Label>
                                            <Form.Control defaultValue={infos.name} required aria-label={t.t("iot.global.name")}
                                                          aria-describedby="msgInput1"/>
                                            <Form.Control.Feedback type="invalid" id="msgInput1">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={closesubmitgroup}>{t.t("iot.global.close")}</Button>
                        <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>



            <Modal size={"xl"} centered={true} show={showAutoSchedual}  onExited={CloseAutoSchedual} onHide={CloseAutoSchedual} backdrop="static">
                <Modal.Header className={"bg-light"}  >
                    <Modal.Title className={"d-flex justify-content-between"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.devicesTitle")}</span>
                            </div>
                        </div>
                    </Modal.Title>
                    <div className={"d-inline-flex"}>
                    </div>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={AddAutoSchedual}>
                    <Modal.Body>
                        {
                            loadingModal ?
                                <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="pb-3" controlId={"afterMins"}>
                                            <Form.Label className={"mb-0"}>
                                                <Sun className={"feather"} size={18}/> {t.t("iot.global.afterMins")}
                                            </Form.Label>
                                            <Form.Control size={"sm"} autoComplete="off" required />
                                            <Form.Control.Feedback type="invalid" id="devices">
                                                {t.t("iot.global.devicesTitle")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="pb-3" controlId={"beforeMins"}>
                                            <Form.Label className={"mb-0"}>
                                                <Sun className={"feather"} size={18}/> {t.t("iot.global.beforeMins")}
                                            </Form.Label>
                                            <Form.Control size={"sm"} autoComplete="off" required />
                                            <Form.Control.Feedback type="invalid" id="devices">
                                                {t.t("iot.global.devicesTitle")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                </Row>

                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={CloseAutoSchedual}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"}  variant="warning">{t.t("iot.global.save")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


            <Modal size={"xl"} centered={true} show={AddDeviceShow} onExited={AddDeviceClose} onHide={AddDeviceClose} backdrop="static">
                <Modal.Header className={"bg-light"}  >
                    <Modal.Title className={"d-flex justify-content-between"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.devicesTitle")}</span>
                            </div>
                        </div>
                    </Modal.Title>
                    <div className={"d-inline-flex"}>
                    </div>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={AddDeviceSubmit}>
                <Modal.Body>
                    {
                        loadingModal ?
                            <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"}/>
                            </div> :
                            <Form.Group className="pb-3" controlId={"devices"}>
                                <Form.Label className={"mb-0"}><Sun className={"feather"}
                                                                    size={18}/> {t.t("iot.global.devicesTitle")}
                                </Form.Label>
                                <Form.Select size={"sm"} autoComplete="off" required
                                             aria-label={t.t("iot.global.devicesTitle")} aria-describedby="devices">
                                    <option></option>
                                    {Object.keys(devicedata).map((titles, i) => {
                                        return (
                                            <option
                                                value={JSON.parse(JSON.stringify(devicedata[i])).devId}>{JSON.parse(JSON.stringify(devicedata[i])).name}</option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid" id="devices">
                                    {t.t("iot.global.devicesTitle")}
                                </Form.Control.Feedback>
                            </Form.Group>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={AddDeviceClose}>{t.t("iot.global.close")}</Button>
                    {info &&  <Button type={"submit"}  variant="warning">{t.t("iot.global.save")}</Button>}
                </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
export default Groups
