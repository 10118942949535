import React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

function Profile(){
    return(
        <div className={"horizontal"}>
            <div className="pageTitle d-flex justify-content-between mb-3">
                <h3 className={"d-inline-flex bold"}>אזור אישי</h3>
                <div className={"float-end d-inline-flex  align-items-center   align-content-end"}>
                    <Link className={"py-2 DarkBtn-sm text-white mx-2"} to={"../ProfileForm"}>تعديل الملف الشخصي</Link>
                    <Link className={"DarkBtn-sm  py-2  mx-2"} to={"../ChangePassword"}>تغير كلمة المرور</Link>
                </div>
            </div>

            <Row className={"py-4 justify-content-center align-items-center"}>
                <Col md={2} sm={3} xs={3} className={"border-end "}>
                    <div className={"text-center py-3"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"
                             className="feather color1 feather-user">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                        <div className={"h4 bold my-2  opacity-50"}>
                            מידע אישי
                        </div>
                    </div>
                </Col>
                <Col md={10} sm={9} xs={9}>
                    <Row  className={"h5"}>
                        <Col md={4} xs={12}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"
                                 stroke-linejoin="round" className="feather color2 feather-clipboard">
                                <path
                                    d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                            </svg>

                            תעודת זהות:
                            <br/>
                            <b className={"px-4"}>1111111</b></Col>
                        <Col md={4} xs={12}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"
                                 stroke-linejoin="round" className="feather color2 feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>

                            שם פרטי: <br /><b className={"px-4"}>Qasem</b></Col>
                        <Col md={4} xs={12}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"
                                 stroke-linejoin="round" className="feather color2 feather-user">
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>

                            שם משפחה: <br /><b className={"px-4"}>Khamayse</b></Col>
                    </Row>
                </Col>
            </Row>
            <Row className={"py-4 border-top border-bottom  align-items-center"}>
                <Col md={2} sm={3} xs={3} className={"border-end"}>
                    <div className={"text-center py-3"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"
                             className="feather color1 feather-map-pin">
                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                            <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        <div className={"h4 bold my-2 opacity-50"}>
                            כתובת מגורים
                        </div>
                    </div>
                </Col>
                <Col md={10} sm={9} xs={9}>
                    <Row className={"h5"}>
                        <Col md={4} xs={12}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                 className="feather color2 feather-map-pin">
                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                <circle cx="12" cy="10" r="3"></circle>
                            </svg>

                            ישוב: <br/><b className={"px-4"}>אבטין</b></Col>
                        <Col md={4} xs={12}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"
                                 stroke-linejoin="round" className="feather color2 mx-1 feather-map">
                                <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                                <line x1="8" y1="2" x2="8" y2="18"></line>
                                <line x1="16" y1="6" x2="16" y2="22"></line>
                            </svg>

                            רחוב: <br/><b className={"px-4"}>אלמדינה</b></Col>
                        <Col md={4} xs={12}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"
                                 stroke-linejoin="round" className="feather  color2 mx-1 feather-map">
                                <polygon points="1 6 1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6"></polygon>
                                <line x1="8" y1="2" x2="8" y2="18"></line>
                                <line x1="16" y1="6" x2="16" y2="22"></line>
                            </svg>

                            מס' בית:<br/></Col>
                    </Row>
                </Col>
            </Row>
            <Row className={"py-4 align-items-center"}>
                <Col md={2} sm={3} xs={3} className={"border-end"}>
                    <div className={"text-center py-3"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"
                             className="feather color1 feather-phone">
                            <path
                                d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                        </svg>
                        <div className={"h4 bold my-2 opacity-50"}>
                            התקשרות
                        </div>
                    </div>
                </Col>
                <Col md={10} sm={9} xs={9}>
                    <Row  className={"h5"}>
                        <Col md={4} xs={12}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                                 className="feather color2 feather-phone">
                                <path
                                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                            </svg>

                            טלפון: <br/><b  className={"px-4"}>0503633113</b></Col>
                        <Col md={4} xs={12}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"
                                 stroke-linejoin="round" className="feather color2 feather-mail">
                                <path
                                    d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                <polyline points="22,6 12,13 2,6"></polyline>
                            </svg>

                            אימייל:  <br/><b  className={"px-4"}>gupozuvu@mailinator.com</b></Col>
                        <Col md={4} xs={12}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"
                                 stroke-linejoin="round" className="feather color2 feather-send">
                                <line x1="22" y1="2" x2="11" y2="13"></line>
                                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                            </svg>

                            ערוץ תקשורת מועדף:<br />
                        </Col>
                    </Row>
                </Col>
            </Row>


        </div>
    )
}

export default Profile;