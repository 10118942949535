import React, {useEffect, useState} from "react";
import { Col, Image, Nav, Row, Tab} from "react-bootstrap";
import {Folder} from "react-feather";
import FormSetting from "./formsetting";
import {useParams} from "react-router-dom";
import FormApproval from "./formapproval";
import FormServiceLvel from "./formservicelevel";
import FormNofication from "./formnotifcation";
import FormStatus from "./formstatus";

function CaseSla(){
    const tab = React.createRef<HTMLTextAreaElement>();
    let {id} :any = useParams()

    const [page,setPage] = useState("Setting")
    const openPage = (page:any) => {
        setPage(page)
    }
    useEffect(() => {
        tab.current?.click()
    }, [id]);

    return(
        <div  className={"content"}>
            <Row>
                <Col className={"justify-content-center align-items-center align-content-center"}>
                    <Image srcSet={"http://saasdemo.smsm-it.com/Content/UsersManagement/images/servicesicon/icon11.svg"} height={35} />
                    <span className={"pagetitle text-uppercase mx-1 d-inline-block pt-2"}>النماذج</span>
                </Col>
            </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="tab1">
                <Row>
                    <Col sm={12}>
                        <div className={"position-relative my-3"}>
                            <div className={"lightbox position-relative newTab"}>
                                <div className={"selector"}></div>

                                {id > 0 ?
                                <Nav defaultChecked={true} defaultActiveKey={"tab1"}>
                                        <Nav.Item as={"li"}>
                                            <Nav.Link eventKey="tab1"   ref={tab as React.RefObject<any>} onClick={()=> openPage("Setting")} >البيانات الاساسية</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as={"li"}>
                                            <Nav.Link eventKey="tab2" onClick={() => openPage("FormStatus")}>الحالات</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab3" onClick={()=> openPage("Notifcation")}>الاشعارات</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item  as={"li"}>
                                        <Nav.Link eventKey="tab4" onClick={()=> openPage("ServiceLevel")}>مستوى الخدمة</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab5" onClick={()=> openPage("Approval")}>الاعتمادات</Nav.Link>
                                        </Nav.Item>
                                </Nav> :
                                    <Nav defaultChecked={true} defaultActiveKey={"tab1"}>
                                    <Nav.Item as={"li"}>
                                    <Nav.Link eventKey="tab1" ref={tab as React.RefObject<any>} onClick={()=> openPage("Setting")} >البيانات الاساسية</Nav.Link>
                                    </Nav.Item>
                                    </Nav>
                                    }
                            </div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <hr className={"my-1 shodow-hr"} />
                    </Col>
                    <Col sm={12}>

                        <div className={"p-1"} >
                            {page == "Setting" && <FormSetting id={id} />}
                            {id > 0 &&
                                <div>
                            {page == "FormStatus" && <FormStatus id={id} />}
                            {page == "Notifcation" && <FormNofication id={id} />}
                            {page == "ServiceLevel" && <FormServiceLvel id={id} />}
                            {page == "Approval" && <FormApproval id={id} />}
                                </div>
                            }
                        </div>

                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}


export default CaseSla;
