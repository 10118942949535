import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { Sun} from "react-feather";
import {Row, Col, Dropdown, DropdownButton, Spinner, Button} from "react-bootstrap";
import axios from "axios";
import {websetting} from "../../../../../../helpers/websetting";
import DataTable from "react-data-table-component";
import {Bar} from "react-chartjs-2";

function Energy(prop : any){
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const,
            },
            title: {
                display: false,
                text: '',
            },
        },
    };

    const labels:any = [];
    const chartdata1 = {
        labels,
        datasets: [
            {
                data: [],
                backgroundColor: ['rgba(255, 99, 132, 0.5)','rgba(226, 160, 63, 0.5)','rgba(26, 188, 156, 0.5)'],
            }
        ],
    };
    const [chart, setChart] = useState(chartdata1);

    const t = useTranslation()
    const [report, setReport] = useState({name:t.t("iot.global.lastyear"),id:"Year"})
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([]);

    const GetDataa = (value:any) => {
        setReport(value)
        fetchDta(1,value.id);
    }

    const columns = [
        {
            name: t.t("iot.global.name"),
            selector: (row : any) => row.name,
        },{
            name: t.t("iot.global.energyValue"),
            selector: (row : any) => row.value + "KW.H",
        }
    ];


    const fetchDta = async (page:any,id:any) => {
        setLoading(true)
        const p = page - 1
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/devices/tondo/'+prop.trvId+'/'+id+'/energyConsumption',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setdata(response.data)
            setLoading(false)
            const d1 :any = []
            const l1 :any = []
            for(var i =0;i< response.data.length;i++){
                d1.push(response.data[i].value)
                l1.push(response.data[i].name)
            }
            chartdata1.labels = l1
            chartdata1.datasets[0].data = d1
            setChart(chartdata1)


        }).catch(function (error) {
        });
    }
    useEffect(() => {
        fetchDta(1,report.id); // fetch page 1 of users
    }, []);



    return(
        <div>
        <div className={"lightbox mt-1"}>
             <div className="pagetitle text-uppercase">
                <div className={"d-flex justify-content-between"}>
                    <div className={"d-inline-flex"}>
                        <Sun className={"feather"} />
                        <span className={"d-inline-block mx-2"}>{t.t("iot.global.energyConsumptionTitle")}</span>
                    </div>
                    <div className={"d-inline-flex align-content-end"}>
                        <DropdownButton className={"ms-1 text-center btns"} variant={"danger"} title={report.name}>
                            <div className={"text-center"}>
                                <Dropdown.Item onClick={() => {
                                    GetDataa({name: t.t("iot.global.lastyear"), id: "Year"})
                                }}>{t.t("iot.global.lastyear")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    GetDataa({name: t.t("iot.global.lastmonth"), id: "Month"})
                                }}>{t.t("iot.global.lastmonth")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    GetDataa({name: t.t("iot.global.lastweek"), id: "Week"})
                                }}>{t.t("iot.global.lastweek")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    GetDataa({name: t.t("iot.global.lastday"), id: "Day"})
                                }}>{t.t("iot.global.lastday")}</Dropdown.Item>
                            </div>
                        </DropdownButton>

                     </div>
                </div>
            </div>
            {loading ?  <div className={"my-5 text-center"}><Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} /></div> :
            <Row className={"mt-5"}>
                <Col md={6}>

                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               columns={columns} data={data} />

                </Col>
                <Col md={6}>
                    <div>
                        <Bar options={options} data={chart}   />
                    </div>
                </Col>
            </Row>
            }
        </div>

        </div>
    );
}
export default Energy
