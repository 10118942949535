import React, {useEffect, useState} from 'react';
import {Edit, Folder, FolderPlus, Tool, Trash} from "react-feather";
import DataTable from "react-data-table-component";
import {ModulaIds, websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import MenuList, {reCall} from "../../../../../containers/ssoMenu";
import {prop} from "react-data-table-component/dist/src/DataTable/util";
import {IOT} from "../../../../../constants/routes";
import {useTranslation} from "react-i18next";
import {SystemSetting} from "../../../../../constants/system";

function GetCategory() {
    const t= useTranslation()
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])

    const [validated, setValidated] = useState(false)
    const [Devices, setDevices] = useState([])
    const [show, setShow] = useState(false)
    const [info, setInfo] = useState(true)
    const [infoData, setInfoData] = useState({})
    const [loadingModal, setLoadingModal] = useState(false);


    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }

    const edit = (id:any) => {
        setShow(true);
        if(id !=0){
            setLoadingModal(true)
            axios.get(websetting.automationUrl + 'AutoFormCategories/getbyid/'+id+'?module='+ModulaIds.Automation,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setInfoData(response.data)
                setLoadingModal(false)
            }).catch(function (error) {
            });
        }

    }


    const columns = [
        {
            sortable: true,
            name: t.t("TxtNameHe"),
            selector: (row : any) => row.nameHe,
            style:{
                fontSize:"14px"
            }
        },{
            sortable: true,
            name: t.t("TxtNameAr"),
            selector: (row : any) => row.nameAr,
            style:{
                fontSize:"14px"
            }
        },{
            name: '',
            style:{
                fontSize:"14px"
            },
            cell : (row : any) => <div> <Button onClick={()=>edit(row.id)}    size={"sm"} variant={"info"} className={"rounded-circle"}><Edit size={15} /></Button> </div>,
        }
    ]
    useEffect(() => {
        setLoading(true)
        axios.get(websetting.automationUrl + 'AutoFormCategories?module='+ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data)
            setLoading(false)
        }).catch(function (error) {
        });
    }, []);
    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.automationUrl + 'AutoFormCategories/'+event.target.Id.value+"?module="+ModulaIds.Automation,
                    {
                        "namePri": event.target.namePri.value,
                        "nameSec": event.target.nameSec.value,
                        "iconUrl": event.target.iconUrl.value,
                        "status": event.target.status.value
                    },
                    {
                        method: "put",
                        headers: {
                            'content-type': 'application/json',
                            'accept' : ' application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {

                    const index = data.findIndex( (array) => {
                        return parseInt(array["id"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].nameHe = event.target.namePri.value
                    prevState[index].nameAr = event.target.nameSec.value
                    setData(prevState)

                    setLoadingModal(false)
                    setShow(false)
                }).catch(function (error) {
                    setLoadingModal(false)
                });
            }else {
                axios.post(websetting.automationUrl + 'AutoFormCategories?module='+ModulaIds.Automation,
                    {
                        "namePri": event.target.namePri.value,
                        "nameSec": event.target.nameSec.value,
                        "iconUrl": event.target.iconUrl.value
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {

                    setData(data.concat(response.data))
                    setLoadingModal(false)
                    setShow(false)
                }).catch(function (error) {
                    setLoadingModal(false)
                });

            }
        }
    };
    const infos =  JSON.parse(JSON.stringify(infoData))

    return (
        <div>
            <div className={"lightbox mb-3"}>
                <div className={"pagetitle d-flex justify-content-between  text-uppercase"}>
                    <span className={"d-inline-flex mx-2"}><FolderPlus size={20}  className={"feather me-1"} />{t.t("LblTitle")}</span>
                    <div className={"d-inline-flex "}>
                        <div className={"  position-relative     "}>
                            <Button variant={"dark"} className={"bold"} size={"sm"} onClick={()=>edit(0)}>+ {t.t("BtnCatAddNew")}</Button>
                        </div>
                    </div>
                </div>
                <hr className={"shodow-hr"}/>

                <div>
                    <DataTable
                        progressPending={loading}
                        pagination
                        paginationPerPage={SystemSetting.rowInPage}
                        columns={columns}
                        data={data}
                        progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                    />
                </div>

            </div>

            <Modal size={"lg"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><Folder className={"feather"} /> {t.t("LblTitle")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>
                        {info &&
                            <div>{
                                loadingModal ? <div className={"text-center my-3"}><Spinner animation={"border"}
                                                                                            variant={"danger"}/></div> :

                                    <Row>
                                        <Form.Group hidden controlId="Id">
                                            <Form.Control type={"hidden"} value={infos.id}/>
                                        </Form.Group>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="namePri">
                                                <Form.Label>{t.t("TxtNameHe")}:</Form.Label>
                                                <Form.Control type={"text"} required aria-label={t.t("TxtNameHe")}
                                                              defaultValue={infos.nameHe} aria-describedby="msgInput5"/>
                                                <Form.Control.Feedback type="invalid" id="msgInput1">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="nameSec">
                                                <Form.Label>{t.t("TxtNameAr")}:</Form.Label>
                                                <Form.Control type={"text"} required aria-label={t.t("TxtNameAr")}
                                                              defaultValue={infos.nameAr} aria-describedby="msgInput5"/>
                                                <Form.Control.Feedback type="invalid" id="msgInput5">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="my-2" controlId="iconUrl">
                                                <Form.Label>{t.t("TxtImage")}:</Form.Label>
                                                <Form.Control type="file"  className={"p-2"} aria-label={t.t("TxtImage")} aria-describedby="msgInput2"/>
                                                <Form.Control.Feedback type="invalid" id="msgInput2">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {infos.id > 0 &&
                                        <Col lg={12}>
                                            <Form.Group className="my-2" controlId="status">
                                                <Form.Label>{t.t("TxtStatus")}:</Form.Label>
                                                <Form.Select  className={"p-2"} aria-label={t.t("TxtStatus")} aria-describedby="msgInput2">
                                                    <option value={1}>{t.t("LblActive")}</option>
                                                    <option value={2}>{t.t("LblDeactive")}</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msgInput2">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        }
                                    </Row>
                            }
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={handleClose}>{t.t("LinkClosePopup")}</Button>
                        {info &&  <Button type={"submit"}  variant="warning">{t.t("BtnSave")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    );
}



export default GetCategory