import React, { useState} from "react";
import {FilePlus, Sun} from "react-feather";
import {Button, Spinner} from "react-bootstrap";
import {useParams} from "react-router-dom";

function FormNofication(props:any) {
    let {id}:any = useParams()
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    if( id == "New"){
        id = 0
    }

    return (
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle text-uppercase"}>
                    <FilePlus className={"feather"} />
                    <span className={"d-inline-block mx-2"}>الاشعارات</span>
                    <Button variant={"dark"} size={"sm"}  className="text-uppercase float-end">+ اضافة اشعار جديد</Button>
                </div>


                <hr className={"shodow-hr"} />
                <div>
                    {loading && <div className="text-center"><Spinner animation="border"  variant="secondary" role="status" ><span className="visually-hidden">انتظر من فضلك</span></Spinner></div>}


                </div>
            </div>
        </div>
    )
}
export default FormNofication