import {Edit, Grid, PhoneCall, Trash} from "react-feather";
import {Button, Col, Form, Image, Modal, Nav, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {useEffect,  useState} from "react";
import {useTranslation} from "react-i18next";
import { websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {Bar} from "react-chartjs-2";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {SystemSetting} from "../../../../../constants/system";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {UploadCMS} from "../../../../../helpers/function";
const animatedComponents = makeAnimated();

function Departments(){
    const inputRef = React.createRef<HTMLTextAreaElement>();
    const t = useTranslation()
    const [validated, setValidated] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);
    const [phonelist, setphonelist] = useState([]);
    const [defData, setdefData] = useState([]);
    const  options:any = [];
    const  defoptions:any = [];

    const handleChange = (op:any) => {
        setdefData(op);
    };

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
    }
    const handleShow = () => {
        setShow(true);
    }

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const columns = [
       {
           width:"110px",
           sortable: true,
            name:  t.t("cms.image"),
           cell : (row : any) => <div>{row.imageUrl && <Image srcSet={"https://cors-everywhere.herokuapp.com/"+row.imageUrl} width={40} height={40} />}</div>,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("cms.departmentName"),
            selector: (row : any) => row.name,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("cms.order"),
            selector: (row : any) => row.orderNo,
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button>   <Button onClick={()=>edit(row.id)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button></div>,
        }
        ]


    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
         }
    }
    const SubmitSearch = (e:any) => {
        e.preventDefault()
        if(e.target.input.value !== searchtxt){
            setSearchtxt(e.target.input.value)
        }
    }

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.cmsUrl + 'CmsDepartment/Delete?id=' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }
    const GetPhoneList = () => {
        axios.get(websetting.cmsUrl + 'CmsTelephone/GetAll/GetAll',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            for(var i=0;i< response.data.length; i++){
                options.push({ value: response.data[i].id , label: response.data[i].empName + " - " + response.data[i].empPosition })
            }
            setphonelist(options)
        }).catch(function (error) {

        });
    }
    const edit = (id:any) => {
        setShow(true);
        setdefData([])
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.cmsUrl + 'CmsDepartment/Get?id='+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                for(var i=0;i< response.data.telephoneList.length; i++){
                    defoptions.push({ value: response.data.telephoneList[i].id , label: response.data.telephoneList[i].empName + " - " + response.data.telephoneList[i].empPosition  })
                }
                setdefData(defoptions)
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }
    const handleSubmit = (event: any) => {
        const selectedPhone:any = [];
        const tags:any = [];

        console.log(defData)
        for(var i=0; i< defData.length;i++){
            selectedPhone.push(defData[i]['value'])
        }


        if(event.target.tagHe1.value != ""){
            tags.push({
                "tagHe": event.target.tagHe1.value,
                "tagAr": event.target.tagAr1.value,
                "descriptionHe": event.target.descriptionHe1.value,
                "descriptionAr": event.target.descriptionAr1.value,
                "orderNo": 0
            })
        }
        if(event.target.tagHe2.value != ""){
            tags.push({
                "tagHe": event.target.tagHe2.value,
                "tagAr": event.target.tagAr2.value,
                "descriptionHe": event.target.descriptionHe2.value,
                "descriptionAr": event.target.descriptionAr2.value,
                "orderNo": 0
            })
        }
        if(event.target.tagHe3.value != ""){
            tags.push({
                "tagHe": event.target.tagHe3.value,
                "tagAr": event.target.tagAr3.value,
                "descriptionHe": event.target.descriptionHe3.value,
                "descriptionAr": event.target.descriptionAr3.value,
                "orderNo": 0
            })
        }


        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {


            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            const imageUrl = event.target.imageUrl.value
            const nameAr = event.target.nameAr.value
            const nameHe = event.target.nameHe.value
            const phone = event.target.phone.value
            const fax = event.target.fax.value
            const email =  event.target.email.value
            const descriptionAr = event.target.descriptionAr.value
            const descriptionHe =  event.target.descriptionHe.value
            const formUrl = event.target.formUrl.value
            const paymentUr = event.target.paymentUrl.value
            const orderNo = event.target.orderNo.value != "" ? event.target.orderNo.value : 0


            if(event.target.Id.value > 0){

                if(event.target.file.value != ""){



                        UploadCMS(event.target.file).then(function(url:any) {
                        axios.put(websetting.cmsUrl + 'CmsDepartment/Put',
                            {
                                "id": PKid,
                                "imageUrl": url,
                                "nameAr": nameAr,
                                "nameHe": nameHe,
                                "phone": phone,
                                "fax": fax,
                                "email": email,
                                "descriptionAr": descriptionAr,
                                "descriptionHe": descriptionHe,
                                "formUrl": formUrl,
                                "paymentUrl": paymentUr,
                                "orderNo": orderNo,
                                "tags": tags,
                                "telephoneList": selectedPhone
                            },
                            {
                                method: "put",
                                headers: {
                                    'Accept-Language':localStorage.getItem("lang") as string,
                                    'content-type': 'application/json',
                                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                                }
                            }
                        ).then(async function (response) {
                            const index = data.findIndex( (array) => {
                                return parseInt(array["id"]) === parseInt(PKid)
                            });

                            let prevState = JSON.parse(JSON.stringify(data));
                            prevState[index].name = response.data.name
                            prevState[index].imageUrl = response.data.imageUrl
                            prevState[index].orderNo = response.data.orderNo

                            setData(prevState)
                            setShow(false)
                            setLoadingModal(true)
                        }).catch(function (error) {
                            setLoadingModal(true)
                        });
                    })
                }else{
                    axios.put(websetting.cmsUrl + 'CmsDepartment/Put',
                        {
                            "id": PKid,
                            "imageUrl": imageUrl,
                            "nameAr": nameAr,
                            "nameHe": nameHe,
                            "phone": phone,
                            "fax": fax,
                            "email": email,
                            "descriptionAr": descriptionAr,
                            "descriptionHe": descriptionHe,
                            "formUrl": formUrl,
                            "paymentUrl": paymentUr,
                            "orderNo": orderNo,
                            "tags": tags,
                            "telephoneList": selectedPhone
                        },
                        {
                            method: "put",
                            headers: {
                                'Accept-Language':localStorage.getItem("lang") as string,
                                'content-type': 'application/json',
                                'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                            }
                        }
                    ).then(async function (response) {
                        const index = data.findIndex( (array) => {
                            return parseInt(array["id"]) === parseInt(PKid)
                        });

                        let prevState = JSON.parse(JSON.stringify(data));
                        prevState[index].name = response.data.name
                        prevState[index].imageUrl = response.data.imageUrl
                        prevState[index].orderNo = response.data.orderNo

                        setData(prevState)
                        setShow(false)
                        setLoadingModal(true)
                    }).catch(function (error) {
                        setLoadingModal(true)
                    });
                }


            }else {

                if(event.target.file.value != ""){
                    UploadCMS(event.target.file).then(function(url:any) {
                        axios.post(websetting.cmsUrl + 'CmsDepartment/AddDepartment',
                            {
                                "imageUrl": url,
                                "nameAr": nameAr,
                                "nameHe": nameHe,
                                "phone": phone,
                                "fax": fax,
                                "email": email,
                                "descriptionAr": descriptionAr,
                                "descriptionHe": descriptionHe,
                                "formUrl": formUrl,
                                "paymentUrl": paymentUr,
                                "orderNo": orderNo,
                                "telephoneList": selectedPhone,
                                "tags":tags,
                            },
                            {
                                method: "post",
                                headers: {
                                    'Accept-Language':localStorage.getItem("lang") as string,
                                    'content-type': 'application/json',
                                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                                }
                            }
                        ).then(async function (response) {
                            if(response.data.Success == false){

                                setdefData([])
                                setLoadingModal(false)

                            }else{
                                setData(data.concat(response.data))
                                setShow(false)
                                setLoadingModal(true)
                            }
                        }).catch(function (error) {
                            setLoadingModal(true)
                        });
                    })
                }else{
                    axios.post(websetting.cmsUrl + 'CmsDepartment/AddDepartment',
                        {
                            "imageUrl": null,
                            "nameAr": nameAr,
                            "nameHe": nameHe,
                            "phone": phone,
                            "fax": fax,
                            "email": email,
                            "descriptionAr": descriptionAr,
                            "descriptionHe": descriptionHe,
                            "formUrl": formUrl,
                            "paymentUrl": paymentUr,
                            "orderNo": orderNo,
                            "telephoneList": selectedPhone,
                            "tags":tags,
                        },
                        {
                            method: "post",
                            headers: {
                                'Accept-Language':localStorage.getItem("lang") as string,
                                'content-type': 'application/json',
                                'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                            }
                        }
                    ).then(async function (response) {
                        if(response.data.Success == false){

                            setdefData([])
                            setLoadingModal(false)

                        }else{
                            setData(data.concat(response.data))
                            setShow(false)
                            setLoadingModal(true)
                        }
                    }).catch(function (error) {
                        setLoadingModal(true)
                    });
                }



            }
        }
    };


    const [totalRows, setTotalRows] = useState(0);
    const [rowperPage, setrowperPage] = useState(SystemSetting.rowInPage);
    const [currpage, setcurrpage] = useState(1);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsDepartment/GetAll/GetAll?rows=' + rowperPage + "&page=" + page+ (searchtxt != "" ? "&search="+searchtxt : ""),
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            if(response.headers.pagination != null){
                setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            }
            setLoading(false);
        }).catch(function (error) {

        });
    };
    const handlePageChange = (page :any) => {
        setcurrpage(page)
        setLoading(true);

        fetchDta(page)
    };
    const handlePerRowsChange = async  (newPerPage:any, page:any) => {
        setLoading(true);
        setrowperPage(newPerPage)
        fetchDta(page)
    }




    useEffect(() => {
        GetPhoneList()
        fetchDta(1); // fetch page 1 of users
    }, [localStorage.getItem("lang") ,searchtxt]);
    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
    <div>
        <div className={"lightbox"}>
            <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <Grid className={"feather mx-2"} /> {t.t("cms.web.departments")}
                     </span>
                </div>
                <span className={"d-inline-flex align-items-center mx-2"}>

                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                        <Form  onSubmit={(e)=>{SubmitSearch(e)}}  action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                    </div>

                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><Grid /> {t.t("cms.newdepartment")}</Button>
                 </span>
            </div>
            <hr className={"shodow-hr"} />
            <div>
                <DataTable progressPending={loading}

                           paginationServer
                           paginationPerPage={rowperPage}
                           paginationTotalRows={totalRows}
                           progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                           onChangeRowsPerPage={handlePerRowsChange}
                           onChangePage={handlePageChange} columns={columns} data={data} />
            </div>
        </div>


        <Modal size={"xl"} centered={true}
               show={show}
               onExited={handleClose}
               onHide={handleClose}
               backdrop="static">
            <Modal.Header >
                <Modal.Title><Grid className={"feather"} /> {t.t("cms.web.departments")}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <div>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>
                                    <Form.Group hidden controlId="imageUrl">
                                        <Form.Control type={"hidden"} value={infos.imageUrl}/>
                                    </Form.Group>


                                    <Tabs className={"justify-content-center tabsModal"} defaultActiveKey={"tab1"}>
                                        <Tab eventKey={"tab1"} className={"shadow-0"} title={t.t("cms.mainInformation")} >
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Group className="my-2"  controlId="nameHe">
                                                        <Form.Label className="my-0">{t.t("cms.departmentName")} - {t.t("cms.he")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.nameHe} required
                                                                      aria-label={t.t("cms.departmentName")}
                                                                      aria-describedby="msg_nameHe"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_nameHe">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="my-2" controlId="nameAr">
                                                        <Form.Label className="my-0">{t.t("cms.departmentName")} - {t.t("cms.ar")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.nameAr} required
                                                                      aria-label={t.t("cms.departmentName")}
                                                                      aria-describedby="msg_nameAr"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_nameAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="my-2" controlId="descriptionHe">
                                                        <Form.Label className="my-0">{t.t("cms.description")} - {t.t("cms.he")}:</Form.Label>
                                                        <Form.Control as={"textarea"} style={{ height: '80px' }} defaultValue={infos.descriptionHe} required
                                                                      aria-label={t.t("cms.description")}
                                                                      aria-describedby="msg_descriptionHe"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_descriptionHe">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="my-2" controlId="descriptionAr">
                                                        <Form.Label className="my-0">{t.t("cms.description")} - {t.t("cms.ar")}:</Form.Label>
                                                        <Form.Control as={"textarea"} style={{ height: '80px' }} defaultValue={infos.descriptionAr} required
                                                                      aria-label={t.t("cms.description")}
                                                                      aria-describedby="msg_descriptionAr"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_descriptionAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group className="my-2" controlId="phone">
                                                        <Form.Label className="my-0">{t.t("cms.phone")}:</Form.Label>
                                                        <Form.Control type="tel" defaultValue={infos.phone}
                                                                      aria-label={t.t("cms.phone")}
                                                                      aria-describedby="msg_phone"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_phone">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="my-2" controlId="email">
                                                        <Form.Label className="my-0">{t.t("cms.email")}:</Form.Label>
                                                        <Form.Control type="email" defaultValue={infos.email}
                                                                      aria-label={t.t("cms.email")}
                                                                      aria-describedby="msg_email"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_email">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group className="my-2" controlId="fax">
                                                        <Form.Label className="my-0">{t.t("cms.fax")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.fax}
                                                                      aria-label={t.t("cms.fax")}
                                                                      aria-describedby="msg_fax"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_fax">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="my-2" controlId="formUrl">
                                                        <Form.Label className="my-0">{t.t("cms.formUrl")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.formUrl}
                                                                      aria-label={t.t("cms.formUrl")}
                                                                      aria-describedby="msg_formUrl"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_formUrl">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="my-2" controlId="paymentUrl">
                                                        <Form.Label className="my-0">{t.t("cms.paymentUrl")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.paymentUrl}
                                                                      aria-label={t.t("cms.paymentUrl")}
                                                                      aria-describedby="msg_paymentUrl"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_paymentUrl">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="my-2" controlId="file">
                                                        <Form.Label className="my-0">{t.t("cms.image")}:</Form.Label>
                                                        <Form.Control type="file"
                                                                      aria-label={t.t("cms.image")}
                                                                      aria-describedby="msg_file"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_file">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Group className="my-2" controlId="orderNo">
                                                        <Form.Label className="my-0">{t.t("cms.order")}:</Form.Label>
                                                        <Form.Control type="number" defaultValue={infos.orderNo}
                                                                      aria-label={t.t("cms.order")}
                                                                      aria-describedby="msg_orderNo"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_orderNo">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey={"tab2"} title={t.t("cms.web.phones")} >
                                            <Row>
                                                <Col lg={12}>
                                                    <Form.Group className="my-2 mt-3" controlId="telephoneList">
                                                        <Form.Label className="my-0">{t.t("cms.web.phones")}:</Form.Label>
                                                        <Select
                                                            isLoading={loadingModal}
                                                            options={phonelist}
                                                            value={defData}
                                                            closeMenuOnSelect={false}
                                                            isClearable={false}
                                                             isMulti
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        <Tab eventKey={"tab3"} title={t.t("cms.innerCategory")} >
                                            <Row>

                                                <Col lg={12}>


                                                    <div id={"tagsDiv"} ref={inputRef as React.RefObject<any>} >


                                                        <Row className={"align-content-center  align-items-center"}>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="tagHe1">
                                                                            <Form.Control type="text" defaultValue={infos.tags  &&  (infos.tags[0] !=null ? infos.tags[0].tagHe : "")} placeholder={t.t("cms.departmentName")+ " 1"  + " - " +  t.t("cms.he") }
                                                                                          aria-label={t.t("cms.tagHe")}
                                                                                          aria-describedby="msg_tagHe1"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_tagHe1">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="descriptionHe1">
                                                                            <Form.Control as={"textarea"} placeholder={t.t("cms.description")+ " 1" + " - " +  t.t("cms.he") } style={{ height: '80px' }}
                                                                                          aria-label={t.t("cms.descriptionHe")}
                                                                                          defaultValue={infos.tags  &&  (infos.tags[0] !=null ? infos.tags[0].descriptionHe : "")}
                                                                                          aria-describedby="msg_descriptionHe1"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_descriptionHe1">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="tagAr1">
                                                                            <Form.Control type="text" defaultValue={infos.tags  &&  (infos.tags[0] !=null ? infos.tags[0].tagAr : "")} placeholder={t.t("cms.departmentName")+ " 1"  + " - " +  t.t("cms.ar") }
                                                                                          aria-label={t.t("cms.tagAr")}
                                                                                          aria-describedby="msg_tagAr1"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_tagAr1">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="descriptionAr1">
                                                                            <Form.Control as={"textarea"} defaultValue={infos.tags  &&  (infos.tags[0] !=null ? infos.tags[0].descriptionAr : "")} placeholder={t.t("cms.description")+ " 1"  + " - " +  t.t("cms.ar") } style={{ height: '80px' }}
                                                                                          aria-label={t.t("cms.description" + "-" +  t.t("cms.he") + " 1")}
                                                                                          aria-describedby="msg_descriptionAr1"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_descriptionAr1">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>


                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="tagHe2">
                                                                            <Form.Control type="text" defaultValue={infos.tags  &&  (infos.tags[1] !=null ? infos.tags[1].tagHe : "")} placeholder={t.t("cms.departmentName") + " 2"  + " - " +  t.t("cms.he")}
                                                                                          aria-label={t.t("cms.tagHe")}
                                                                                          aria-describedby="msg_tagHe2"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_tagHe2">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="descriptionHe2">
                                                                            <Form.Control as={"textarea"} defaultValue={infos.tags  &&  (infos.tags[1] !=null ? infos.tags[1].descriptionHe : "")} placeholder={t.t("cms.description") + " 2"  + " - " +  t.t("cms.he")} style={{ height: '80px' }}
                                                                                          aria-label={t.t("cms.descriptionHe")}
                                                                                          aria-describedby="msg_descriptionHe2"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_descriptionHe2">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="tagAr2">
                                                                            <Form.Control type="text" defaultValue={infos.tags  &&  (infos.tags[1] !=null ? infos.tags[1].tagAr : "")} placeholder={t.t("cms.departmentName") + " 2"  + " - " +  t.t("cms.ar")}
                                                                                          aria-label={t.t("cms.tagAr")}
                                                                                          aria-describedby="msg_tagAr2"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_tagAr2">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="descriptionAr2">
                                                                            <Form.Control as={"textarea"} defaultValue={infos.tags  &&  (infos.tags[1] !=null ? infos.tags[1].descriptionAr : "")} placeholder={t.t("cms.description") + " 2"  + " - " +  t.t("cms.ar")} style={{ height: '80px' }}
                                                                                          aria-label={t.t("cms.descriptionAr")}
                                                                                          aria-describedby="msg_descriptionAr2"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_descriptionAr2">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>



                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="tagHe3">
                                                                            <Form.Control type="text" defaultValue={infos.tags  &&  (infos.tags[2] !=null ? infos.tags[2].tagHe  : "") } placeholder={t.t("cms.departmentName") + " 3"  + " - " +  t.t("cms.he")}
                                                                                          aria-label={t.t("cms.tagHe")}
                                                                                          aria-describedby="msg_tagHe3"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_tagHe3">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="descriptionHe3">
                                                                            <Form.Control as={"textarea"} defaultValue={infos.tags  &&  (infos.tags[2] !=null ? infos.tags[2].descriptionHe  : "")} placeholder={t.t("cms.description") + " 3"  + " - " +  t.t("cms.he")} style={{ height: '80px' }}
                                                                                          aria-label={t.t("cms.descriptionHe")}
                                                                                          aria-describedby="msg_descriptionHe3"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_descriptionHe3">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="tagAr3">
                                                                            <Form.Control type="text" defaultValue={infos.tags  &&  (infos.tags[2] !=null ? infos.tags[2].tagAr  : "")} placeholder={t.t("cms.departmentName") + " 3"  + " - " +  t.t("cms.ar")}
                                                                                          aria-label={t.t("cms.tagAr")}
                                                                                          aria-describedby="msg_tagAr3"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_tagAr3">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>

                                                                    <Col md={12}>
                                                                        <Form.Group className="my-2" controlId="descriptionAr3">
                                                                            <Form.Control as={"textarea"} defaultValue={infos.tags  &&  (infos.tags[2] !=null ? infos.tags[2].descriptionAr : "")} placeholder={t.t("cms.description") + " 3"  + " - " +  t.t("cms.ar")} style={{ height: '80px' }}
                                                                                          aria-label={t.t("cms.descriptionAr")}
                                                                                          aria-describedby="msg_descriptionAr3"/>
                                                                            <Form.Control.Feedback type="invalid" id="msg_descriptionAr3">
                                                                                Required Field
                                                                            </Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>


                                                        </Row>



                                                    </div>

                                                </Col>
                                            </Row>
                                        </Tab>

                                    </Tabs>


                                </div>
                            }
                        </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                    {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                </Modal.Footer>
            </Form>
        </Modal>


    </div>
)
}

export default Departments;
