import React, { useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {
    Col,
    Dropdown,
    DropdownButton,
    FloatingLabel,
    Form,
    Image, Nav,
    Offcanvas,
    Row,
    Spinner,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import axios from "axios";
import Moment from 'moment';
import {ModulaIds, websetting} from "../../../../../helpers/websetting";
import {ChevronLeft, Eye, Mail, MapPin, Phone, User} from "react-feather";
import CaseHistory from "./casehistory";
import CitizenComment from "./citizencomment";
import EmployeeComment from "./employeecomment";
import {useTranslation} from "react-i18next";
function CaseDetails(){
    const t = useTranslation()
    let { id } = useParams();
    let cId = "";
    let fId = "";

    const url = window.location.search;
    const params = new URLSearchParams(url);
    cId = params.get('categoryId') || "";
    fId = params.get('formId') || "";

    const [data,setData] = useState([]);
    const [freeQus,setfreeQus] = useState([]);
    const [approval,setApproval] = useState([]);
    const [historyapproval,setHistoryApproval] = useState([]);
    const [users,setUsers] = useState([]);
    const [status,setStatus] = useState([]);
    const [currentstatus,setCurrentstatus] = useState({caseStatus:"",caseStatusColor:"",caseStatusId:""});
    const [currentuser,setCurrentuser] = useState("");
    const [codeJs,setCodeJs] = useState("");

    const [loading,setloading] = useState(true);
    const [show, setShow] = useState(false);
    const [isclose, setisclose] = useState(false);

    const [page,setPage] = useState("EmployeeComment")
    const openPage = (page:any) => {
        setPage(page)
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const ChangeStatus = (d:any) => {
        axios.put(websetting.automationUrl + 'Case/UpdateCaseStatus?&module='+ModulaIds.Automation,
            {
                "caseId": id,
                "statusId": d["id"]
            },
            {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            const json = {caseStatus : d["name"],caseStatusColor:d["color"],caseStatusId:d["id"]}
            setCurrentstatus(json)
            setisclose(response.data.isClosed)
            setPage(response.data.isClosed ? "CitizenComment" : "EmployeeComment")
        }).catch(function (error) {
        });
    }
    const ChangeUsers = (userId:any,name:any) => {
        axios.put(websetting.automationUrl + 'Case/UpdateCaseAssignUser?&module='+ModulaIds.Automation,
            {
                "caseId": id,
                "assignToUserId": userId
            },
            {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setCurrentuser(name)
        }).catch(function (error) {
        });
    }
    const updateQus = (e:any,qusId:any) => {
        axios.post(websetting.automationUrl + 'CaseFormFreeQuestions/Post?&module='+ModulaIds.Automation,
            {
                caseId: id,
                answers: [{
                    id: qusId,
                    answer: e.currentTarget.value != "" ? e.currentTarget.value : " "
                }]
            },
            {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
        }).catch(function (error) {
        });
    }
    const ChangeChannel = (e:any) => {
        axios.put(websetting.automationUrl + 'Case/UpdateChannel?module='+ModulaIds.Automation,
            {
                "caseId": id,
                "requestChannel": e.currentTarget.value
                },{
                method: "put",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
        });
    }

    useEffect(() => {
        axios.get(websetting.apiUrl + 'auth/api/v1/user/EMPLOYEE/getUsersByUserType?pageNO=0&pageSize=1000',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setUsers(response.data.data)
        }).catch(function (error) {
        });
    }, []);
    useEffect(() => {
        setloading(true);
        axios.get(websetting.automationUrl + 'CaseFormFreeQuestions/Get/'+fId+'/'+id+'?module='+ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setfreeQus(response.data)
        }).catch(function (error) {
        });

        axios.get(websetting.automationUrl + 'ConfigFormStatus/Get/'+fId+'?module='+ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setStatus(response.data)
        }).catch(function (error) {
        });
    }, [id]);
    useEffect(() => {
        setloading(true);
        axios.get(websetting.automationUrl + 'Case/Get?caseId='+id+'&module='+ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data)
            setCurrentuser(response.data.assigned)
            currentstatus.caseStatus = response.data.caseStatus
            currentstatus.caseStatusColor = response.data.caseStatusColor
            currentstatus.caseStatusId = response.data.caseStatusId
            setCurrentstatus(currentstatus)

            setisclose(response.data.isClosed)
            setPage(response.data.isClosed ?  "CitizenComment"  : "EmployeeComment")

            axios.get(websetting.apiUrl  +'auth/api/v1/user/'+response.data.customerId,
                {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                //setHistoryApproval(response.data)
            }).catch(function (error) {
            });
            setloading(false)
        }).catch(function (error) {
            setloading(false)
        });

        axios.get(websetting.automationUrl  +'CaseFormApproval/Get?caseId='+id+"&module=" + ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setApproval(response.data)
        }).catch(function (error) {
        });
        axios.get(websetting.automationUrl  +'AutoForm/Get/'+fId+"?module=" + ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setCodeJs(response.data.jsCode)
        }).catch(function (error) {
        });

        axios.get(websetting.automationUrl  +'CaseFormApprovalHistory/Get?caseId='+id+"&module=" + ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setHistoryApproval(response.data)
        }).catch(function (error) {
        });

    }, [localStorage.getItem("lang"),id]);

    const info =  JSON.parse(JSON.stringify(data))

    return(
        <div className={"mb-3"}>

            {loading ? <div className={"mt-5 text-center"}><Spinner title={"Please Waite"} animation="border"  variant={"warning"} ></Spinner></div> :
                <div>
                    <div className={"d-flex justify-content-between"}>
                        <div className={"d-inline-flex"}>
                            {info && <div className={"fs-6 "}><Link to={"../CategoryCase/"+info.categoryId}>{info.categoryName}</Link>   <ChevronLeft size={13} /> <Link to={"../FormCase/"+info.formId+"?categoryId="+info.categoryId}>{info.formName}</Link> </div>}
                        </div>
                        <div className={"d-inline-flex"}>

                        </div>
                    </div>
                    <Row >
                        <Col xl={6} lg={6} className={"py-2"}>
                            <div className={"lightbox fs-6"}>
                            <div className={"d-flex justify-content-between pb-2 border-bottom"}>
                                <div className={"d-inline-flex"}>
                                     <User size={20} className={"feather"} />
                                    الموظف المسؤول:
                                    <DropdownButton  className={"ms-1"} variant={"none"} title={info && info["assigned"]} >
                                        {isclose!=true &&
                                        <div>
                                        {Object.keys(users).map((titles2, i) => {
                                            return (
                                                <Dropdown.Item onClick={()=>{ChangeUsers(users[i]["userIdPk"],users[i]["firstName"] + " " + users[i]["lastName"])}} >{users[i] && users[i]["firstName"] + " " + users[i]["lastName"]}</Dropdown.Item>
                                            )
                                         })
                                        }
                                        </div>
                                        }
                                    </DropdownButton>

                                </div>
                                <div  className={"d-inline-flex align-items-center align-content-end"}>
                                    <span className={"status-dot"} style={{backgroundColor:   currentstatus.caseStatusColor  }}></span>
                                    <DropdownButton className={"ms-1"} variant={"none"} title={currentstatus && currentstatus.caseStatus} >
                                        {Object.keys(status).map((titles2, i) => {
                                            return (
                                                <Dropdown.Item onClick={()=>{ChangeStatus(status[i])}}><span className={"status-dot"} style={{backgroundColor:status[i]["color"]}}></span> {status[i]["name"]}</Dropdown.Item>
                                            )
                                        })
                                        }
                                    </DropdownButton>
                                </div>
                            </div>
                            <div className={"py-4 border-bottom"}>
                                <Row>
                                    <Col>تاريخ التقديم: {info!=null && Moment(info.createdOn).format(websetting.dateFormat)}</Col>
                                    <Col>تاريخ الانتهاء: {info.endDate!=null ? Moment(info.endDate).format(websetting.dateFormat) : "-"}</Col>
                                </Row>
                                <Row>
                                    <Col>مستوى الخدمة/ساعة: {info.slaHour!=null ? info.slaHour : "-"}</Col>
                                    <Col>مستوى الفعلي/ساعة: {info.slaActual!=null ? <span style={{lineHeight:1,direction:"ltr"}} className={"badge badge-danger py-1"}>{info.slaActual}</span> : "-"}</Col>
                                </Row>
                            </div>
                            <div className={"d-flex justify-content-between border-bottom py-3"}>
                                <div className={"d-inline-flex"}>
                                    <div className={"d-inline-flex align-items-center"}>
                                        وصول الطلب من خلال:
                                        <Form.Select disabled={isclose} size={"sm"} onChange={(e)=>{ChangeChannel(e)}} defaultValue={info && info["requestChannel"]} className={"py-2 ps-2 pe-5 w-auto"} style={{height:"inherit",fontSize:"14px",width:"inherit !important"}}>
                                        <option value={1}>انترنت</option>
                                        <option value={2}>هاتف</option>
                                        <option value={3}>بريد الالكتروني</option>
                                        <option value={4}>جلسة</option>
                                    </Form.Select>
                                    </div>
                                    <div className={"d-inline-flex align-items-center mx-2"}>|</div>
                                    <div className={"d-inline-flex align-items-center"}>
                                        رقم الطلب: {id}
                                    </div>
                                </div>
                                <div className={"d-inline-flex align-items-center align-content-end bold"}>
                                    <a  className={"pointer-event"} href={"#"} onClick={handleShow}>
                                        <Eye className={"feather mx-1"} size={20} />
                                        مشاهدة المرفقات</a>
                                </div>
                            </div>
                            </div>
                            <div className={"lightbox fadeInUp my-3"}>
                                <Row className={"fs-6"}>
                                    <Col xl={4} className={"mb-3"} lg={6} sm={6} xs={12}>
                                        <Form.Group>
                                            <Form.Label ><User className={"feather"} size={16}/> الاسم الاول</Form.Label>
                                            <div>מראם</div>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={4} className={"mb-3"} lg={6} sm={6} xs={12}>
                                        <Form.Group>
                                            <Form.Label><User className={"feather"} size={16}/> الاسم الاول</Form.Label>
                                            <div></div>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={4} className={"mb-3"}  lg={6} sm={6} xs={12}>
                                        <Form.Group>
                                            <Form.Label><User className={"feather"} size={16}/>  رقم الهوية</Form.Label>
                                            <div>חסדייה</div>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={4} className={"mb-3"} lg={6} sm={6} xs={12}>
                                        <Form.Group>
                                            <Form.Label><Phone className={"feather"} size={16}/> الموبايل</Form.Label>
                                            <div>312578164</div>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={4}  lg={6} sm={6} xs={12}>
                                        <Form.Group>
                                            <Form.Label><MapPin className={"feather"} size={16}/> العنوان</Form.Label>
                                            <div>0545760775</div>
                                        </Form.Group>
                                    </Col>
                                    <Col xl={4}  lg={6} sm={6} xs={12}>
                                        <Form.Group>
                                            <Form.Label><Mail className={"feather"} size={16}/> البريد الالكتروني</Form.Label>
                                            <div>marams@3-sectors.co.il</div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"lightbox my-3"}>
                                <div className={"pagetitle"}>الاسئلة</div>
                                <div>
                                    <Table responsive>
                                        <thead className={"text-black-50"} >
                                        <tr><th>السؤال</th><th>الجواب</th></tr>
                                        </thead>
                                        <tbody>
                                        {Object.keys(freeQus).map((titles2, i) => {
                                            return(
                                                <tr><td>{freeQus[i]['question']}</td><td><Form.Control className={"py-1"} disabled={isclose} style={{height:"inherit"}} onBlur={(e)=>{updateQus(e,freeQus[i]['id'])}} defaultValue={freeQus[i]['answer']} /></td></tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className={"lightbox my-3"}>
                                <div className={"pagetitle"}>الموافقات</div>
                                <div>
                                    <Table hover responsive>
                                        <thead className={"text-black-50"} >
                                        <tr><th>اسم الموظف</th><th>المنصب</th><th>نوع الاعتماد</th><th>نتيجة الاعتماد</th></tr>
                                        </thead>
                                        <tbody className={"fs-6"}>
                                        {Object.keys(approval).map((titles2, i) => {
                                            return(
                                                <tr >
                                                    <td>{approval[i]["fullNameUser"]}</td>
                                                    <td>{approval[i]["position"]}</td>
                                                    <td>{approval[i]["approvalTypeData"]}</td>
                                                    <td>{approval[i]["resultOfAction"]}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className={"lightbox my-3"}>
                                <div className={"pagetitle"}>سجل الاعتمادات</div>
                                <div>
                                    <Table responsive>
                                        <thead className={"text-black-50"} >
                                        <tr><th>اسم الموظف</th><th>نوع الاعتماد</th><th>نتيجة الاعتماد</th><th>تاريخ الاعتماد</th><th>الملاحظات</th></tr>
                                        </thead>
                                        <tbody className={"fs-6"}>
                                        {Object.keys(historyapproval).map((titles2, i) => {
                                            return(
                                                <tr>
                                                    <td>{historyapproval[i]["employeeName"]}</td>
                                                    <td>{historyapproval[i]["approvalType"]}</td>
                                                    <td>{historyapproval[i]["approvalAction"]}</td>
                                                    <td>{Moment(historyapproval[i]["createdOn"]).format(websetting.dateFormat)}</td>
                                                    <td>{historyapproval[i]["message"]}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} className={"mt-1"}>
                            <div className={"lightbox"}>
                                <Nav className={"border-bottom active-border"} defaultActiveKey={ isclose ? "tab2" : "tab1"}>
                                    {isclose == false &&
                                    <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab1" onClick={() => openPage("EmployeeComment")}>القسم</Nav.Link>
                                    </Nav.Item>
                                    }
                                    <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab2" onClick={()=> openPage("CitizenComment")} >المواطن</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as={"li"}>
                                        <Nav.Link eventKey="tab3" onClick={()=> openPage("CaseHistory")}>سجل النشاطات</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <div className={"p-1"} >
                                    {isclose == false &&
                                    page == "EmployeeComment" && <EmployeeComment caseId={id} formId={info.formId} categoryId={info.categoryId}/>
                                    }
                                    {page == "CitizenComment" &&  <CitizenComment caseId={id} formId={info.formId} categoryId={info.categoryId}  />}
                                    {page == "CaseHistory" &&  <CaseHistory caseId={id} formId={info.formId} categoryId={info.categoryId}  />}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Offcanvas className={"AttachmentCanvas"} placement={"end"} show={show} onHide={handleClose}>
                        <Offcanvas.Header className={"border-bottom"} closeButton>
                            <Offcanvas.Title>
                                <div className={"h3 bold py-0 expand my-0"}>
                                    <Eye className={"feather"} />
                                    المرفقات
                                </div>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Row className={"h-100"}>
                                <Col md={9} className={"h-100"} >
                                    <iframe  src={"http://saasdemo.smsm-it.com/ar/Forms/Form/PreviewAnswer?formId=4103&submissionUrl=22e880c9-9c92-402e-be50-6958f1c809a9&preview=1"} height={"100%"}  width={"100%"} />
                                 </Col>
                                <Col md={3}></Col>
                            </Row>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>

            }

        </div>
    )
}


function GetIframe(props:any){
    const parseHtml = (html :any) => new DOMParser().parseFromString(html, 'text/html').body.innerText;
    return <iframe frameBorder={0}  height={"100%"}  width={"100%"}  srcDoc={parseHtml(props.htmlcode)} />;
}


export default CaseDetails;