import React, {useEffect, useRef, useState} from "react";
import DataTable from 'react-data-table-component'
import {Link} from "react-router-dom";
import axios from "axios";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Trash, Edit, Eye, Tool, Layers, UserX, User, Lock} from 'react-feather';
import {authEnum, checkAuthorities, UserProfile, websetting} from "../../../helpers/websetting";
import {AccountType, SystemSetting} from "../../../constants/system";
import {useTranslation} from "react-i18next";

function Users(){
    const t = useTranslation()
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [country, setCountry] = useState([]);
    const [role, setRole] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);
    const [Organization, setOrganization] = useState([]);
    const [showInvitation, setshowInvitation] = useState(false);

    const [show, setShow] = useState(false);
    const [info, setInfo] = useState(true);
    const [infoData, setInfoData] = useState({});

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }

    const InvitationClose = () => {
        setshowInvitation(false);
        setValidated(false);
    }


    const getOrg = () => {
        axios.get(websetting.apiUrl + 'auth/api/v1/organizations/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setOrganization(response.data.data);
        }).catch(function (error) {
        });
    }
    const getCountry = () => {
        axios.get(websetting.apiUrl + 'setup-service/api/v1/countries/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setCountry(response.data);
        }).catch(function (error) {
        });
    }




    const invite = () => {
        setLoadingModal(false)
        setshowInvitation(true)
    }
    const InvitationSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)

            axios.post(websetting.apiUrl + 'auth/api/v1/user/inviteUser',
                {
                    "email": event.target.email.value,
                    "roleId": event.target.roleId.value,
                    "userType": event.target.userType.value
                },
                {
                    method: "post",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                if(response.data.errorCode == ""){
                    alert("Invitation Send")
                    setshowInvitation(false)
                }else{
                    alert(response.data.message)
                }
                setLoadingModal(false)
            }).catch(function (error) {
                alert(error)
                setLoadingModal(true)
                setshowInvitation(false)
            });

        }
    };



    const handleShow = () => {
        setShow(true);
    }

    const getRole = () => {
        axios.get(websetting.apiUrl + 'auth/api/v1/roles/byParent/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setRole(response.data);
        }).catch(function (error) {

        });
    }

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){

                const formData = new FormData(event.target);
                if(event.target.uploadImage.value != ""){
                    const file = event.target.uploadImage.files[0];
                    formData.append('profilePicture', file, file.name);
                }

                formData.append('firstName', event.target.firstName.value);
                formData.append('lastName', event.target.lastName.value);
                formData.append('email', event.target.email.value);
                formData.append('phone', event.target.phone.value);
                formData.append('organizationIdFk', event.target.Org.value);
                formData.append('countryIdFk', event.target.country.value);


                axios.put(websetting.apiUrl + 'auth/api/v1/user/'+event.target.Id.value,
                    /*{
                        "firstName": event.target.firstName.value,
                        "lastName": event.target.lastName.value,
                        "email": event.target.email.value,
                        "phone": event.target.phone.value,
                        "organizationIdFk" : event.target.Org.value,
                        "countryIdFk" : event.target.country.value,
                    }

                     */
                    formData,
                    {
                        method: "put",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["userIdPk"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].firstName = response.data.firstName
                    prevState[index].lastName = response.data.lastName
                    prevState[index].email = response.data.email
                    prevState[index].username = response.data.username
                    prevState[index].phone = response.data.phone

                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {
                const formData = new FormData(event.target);
                if(event.target.uploadImage.value != ""){
                    const file = event.target.uploadImage.files[0];
                    formData.append('profilePicture', file, file.name);
                }

                formData.append('firstName', event.target.firstName.value);
                formData.append('lastName', event.target.lastName.value);
                formData.append('email', event.target.email.value);
                formData.append('phone', event.target.phone.value);
                formData.append('organizationIdFk', event.target.Org.value);
                formData.append('countryIdFk', event.target.country.value);
                formData.append('userType', event.target.userType.value);
                formData.append('password', event.target.passwords.value);
                formData.append('username', event.target.username.value);


                axios.post(websetting.apiUrl + 'auth/api/v1/user/',
                    formData,
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {

                    if(response.data.errorCode == ""){
                        setData(data.concat(response.data))
                        setShow(false)
                        setLoadingModal(true)




                    axios.post(websetting.apiUrl + 'auth/api/v1/roleUsers/',
                        {
                            "roleIdFk": event.target.roleId.value,
                            "userIdFk": response.data.userIdFk
                        },
                        {
                            method: "post",
                            headers: {
                                'content-type': 'application/json',
                                'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                            }
                        }
                    ).then(async function (response) {
                        setData(data.concat(response.data))
                        setShow(false)
                        setLoadingModal(true)
                    }).catch(function (error) {
                        setLoadingModal(true)
                    });
                    }else{
                        alert(response.data.message)
                        setShow(false)
                        setLoadingModal(true)
                    }
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };





    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'auth/api/v1/user/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["userIdPk"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }

    const edit = (id:any) => {
        setShow(true);
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.apiUrl + 'auth/api/v1/user/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }

    const showInfo = (id:any) => {
        setShow(true);
        setInfo(false);
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.apiUrl + 'auth/api/v1/user/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }

    const columns = [
        {
            name: t.t("iot.global.name"),
            selector: (row : any) => row.firstName + " " + row.lastName,
        },{
            name: t.t("iot.global.username"),
            selector: (row : any) => row.username,
        },{
            name: t.t("iot.global.phone"),
            selector: (row : any) => row.phone,
        },{
            name: t.t("iot.global.email"),
            cell: (row : any) => <span>{row.email}</span>,
        },{
            name: t.t("iot.global.status"),
            selector: (row : any) => <span className={row.statusDesc == "active" ? " badge outline-badge-success" :  " badge outline-badge-danger"}>{row.statusDesc}</span>,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div>{checkAuthorities(authEnum.DELETE_USER) &&<Button variant={"outline-danger"} onClick={()=>del(row.userIdPk)} className={"rounded-circle"}><Trash size={15} /></Button>} {checkAuthorities(authEnum.EDIT_USER) &&<Button onClick={()=>edit(row.userIdPk)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button>} {checkAuthorities(authEnum.VIEW_USER) &&<Button  variant={"dark"}  onClick={()=>showInfo(row.userIdPk)} className={"rounded-circle"}><Eye  size={15} /></Button>} {checkAuthorities(authEnum.VIEW_USER_PERMISSIONS) &&<Link to={"../ViewUserPermission/"+row.userIdPk} title={"View Permission"}    className={"btn  btn-success rounded-circle"}><Lock className={"mt-1"} /></Link>}</div>,
        }
    ];

    const fetchDta = async (page:any) => {

        setLoading(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'auth/api/v1/user/accessUsersByRoleHierarchy?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {

        });
    };

    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };

    useEffect(() => {
        getRole()
        getOrg()
        getCountry()
        fetchDta(1); // fetch page 1 of users
    }, []);


    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div className={""}>
            <div className="lightbox">
                <div className={""}>
                    <div className="pagetitle text-uppercase">
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <User className={"feather"} size={21} />
                                {t.t("iot.global.userTitle")}
                            </div>
                            <div className={"d-inline-flex align-content-end"}>
                                {checkAuthorities(authEnum.ADD_USER) &&
                                <Button variant={"warning"} className={"bold"}
                                        onClick={() => edit(0)}>+ {t.t("iot.global.addUser")}</Button>
                                }
                                {checkAuthorities(authEnum.ADD_USER) &&
                                <Button variant={"primary"} className={"bold mx-1"} onClick={() => invite()}>Invite
                                    Users</Button>
                                }
                            </div>
                        </div>

                    </div>
                    {checkAuthorities(authEnum.VIEW_USER) &&
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows}
                               progressComponent={<Spinner className={"my-5"} animation={"border"} variant={"warning"}
                                                           title={"Please Wait"}/>}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data}/>
                    }
                </div>
            </div>


            <Modal size={"lg"} centered={true}
                   show={showInvitation}
                   onExited={InvitationClose}
                   onHide={InvitationClose}
                   backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><User className={"feather"} />{t.t("iot.global.user")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={InvitationSubmit}>
                    <Modal.Body>

                            <div>{loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Row>

                                    <Col md={12}>
                                        <Form.Group className="my-2" controlId="email">
                                            <Form.Label>{t.t("iot.global.email")}:</Form.Label>
                                            <Form.Control type="email" required aria-label={t.t("iot.global.email")}
                                                          aria-describedby="msgInput1"/>
                                            <Form.Control.Feedback type="invalid" id="msgInput1">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="userType">
                                            <Form.Label>{t.t("iot.global.userType")}:</Form.Label>
                                            <Form.Select required aria-label={t.t("iot.global.userType")}
                                                         aria-describedby="msgInput18">
                                                {Object.keys(AccountType).map((titles, i) => {
                                                    return (
                                                        <option
                                                            selected={infos.userType == AccountType[i] ? true : false}
                                                            value={AccountType[i]}>{AccountType[i]}</option>
                                                    );
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" id="msgInput18">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="roleId">
                                            <Form.Label>{t.t("iot.global.roleName")}:</Form.Label>
                                            <Form.Select required aria-label={t.t("iot.global.roleName")}
                                                         aria-describedby="msgInput1">
                                                {Object.keys(role).map((titles, i) => {
                                                    return (
                                                        <option
                                                            value={JSON.parse(JSON.stringify(role[i])).roleIdPk}>{JSON.parse(JSON.stringify(role[i])).name}</option>
                                                    );
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" id="msgInput1">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                </Row>
                            }
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={InvitationClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"}  variant="warning">{t.t("iot.global.send")}</Button>}

                    </Modal.Footer>
                </Form>
            </Modal>


            <Modal size={"lg"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><User className={"feather"} />{t.t("iot.global.user")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>
                        {info ?
                            <div>{
                                loadingModal ? <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"} /></div> :

                                    <Row>
                                        <Form.Group hidden controlId="Id" >
                                            <Form.Control type={"hidden"}   value={infos.userIdPk}  />
                                        </Form.Group>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="firstName" >
                                                <Form.Label>{t.t("iot.global.firstName")}:</Form.Label>
                                                <Form.Control type="text"   defaultValue={infos.firstName}  required aria-label={t.t("iot.global.firstName")} aria-describedby="msgInput1" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="lastName" >
                                                <Form.Label>{t.t("iot.global.lastName")}:</Form.Label>
                                                <Form.Control type="text"   defaultValue={infos.lastName}  required aria-label={t.t("iot.global.lastName")} aria-describedby="msgInput1" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="username" >
                                                <Form.Label>{t.t("iot.global.username")}:</Form.Label>
                                                <Form.Control type="text"  disabled={infos.username ?  true : false}   defaultValue={infos.username}  required aria-label={t.t("iot.global.username")} aria-describedby="msgInput1" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="email" >
                                                <Form.Label>{t.t("iot.global.email")}:</Form.Label>
                                                <Form.Control type="text"   defaultValue={infos.email}  required aria-label={t.t("iot.global.email")} aria-describedby="msgInput1" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="phone" >
                                                <Form.Label>{t.t("iot.global.phone")}:</Form.Label>
                                                <Form.Control type="text"   defaultValue={infos.phone}  required aria-label={t.t("iot.global.phone")} aria-describedby="msgInput1" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        {!infos.userIdPk &&
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="userType">
                                                <Form.Label>{t.t("iot.global.userType")}:</Form.Label>
                                                <Form.Select required aria-label={t.t("iot.global.userType")}
                                                             aria-describedby="msgInput18">
                                                    {Object.keys(AccountType).map((titles, i) => {
                                                        return (
                                                            <option
                                                                selected={infos.userType == AccountType[i] ? true : false}
                                                                value={AccountType[i]}>{AccountType[i]}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msgInput18">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        }





                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="country" >
                                                <Form.Label>{t.t("iot.global.country")}:</Form.Label>
                                                <Form.Select   required aria-label={t.t("iot.global.country")}    aria-describedby="msgInput1">
                                                    {Object.keys(country).map((items, i) => {
                                                        return(
                                                            <option selected={infos.countryIdFk == JSON.parse(JSON.stringify(country[i])).countryIdPk ? true : false}   value={JSON.parse(JSON.stringify(country[i])).countryIdPk} >{ JSON.parse(JSON.stringify(country[i])).name }</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                        {UserProfile.userType == "ADMIN" ?
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="Org">
                                                <Form.Label>{t.t("iot.global.organizationName")}:</Form.Label>
                                                <Form.Select required aria-label={t.t("iot.global.organizationName")}
                                                             aria-describedby="msgInput1">
                                                    {Object.keys(Organization).map((titles, i) => {
                                                        return (
                                                            <option
                                                                selected={infos.organizationIdFk == JSON.parse(JSON.stringify(Organization[i])).organizationIdPk ? true : false}
                                                                value={JSON.parse(JSON.stringify(Organization[i])).organizationIdPk}>{JSON.parse(JSON.stringify(Organization[i])).name}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msgInput1">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                            :

                                            <Form.Group className="my-2" controlId="Org">
                                                <Form.Control value={UserProfile.organizationId}  type={"hidden"} />
                                            </Form.Group>
                                        }





                                        {!infos.userIdPk &&
                                            <Col md={12}>
                                            <Row  >
                                                <Col md={6}>
                                                    <Form.Group className="my-2" controlId="passwords">
                                                        <Form.Label>{t.t("iot.global.password")}:</Form.Label>
                                                        <Form.Control type="password"  required aria-label={t.t("iot.global.password")} aria-describedby="msgInput1"/>
                                                        <Form.Control.Feedback type="invalid" id="msgInput1">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="my-2" controlId="roleId" >
                                                    <Form.Label>{t.t("iot.global.roleName")}:</Form.Label>
                                                    <Form.Select   required aria-label={t.t("iot.global.roleName")} aria-describedby="msgInput1">
                                                {Object.keys(role).map((titles, i) => {
                                                    return(
                                                    <option  value={JSON.parse(JSON.stringify(role[i])).roleIdPk} >{JSON.parse(JSON.stringify(role[i])).name}</option>
                                                    );
                                                })}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                    </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            </Col>
                                        }
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="uploadImage">
                                                <Form.Label>{"Upload Image"}:</Form.Label>
                                                <Form.Control defaultValue={infos.profilePicture} type={"file"} aria-label={"Upload Image"} />
                                            </Form.Group>
                                        </Col>


                                    </Row>
                            }
                            </div>
                            :
                            <div>
                                {
                                    loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                        <div>
                                            <Table     hover>
                                                <tbody>
                                                <tr><td>ID</td><td>{infos.userIdPk}</td></tr>
                                                <tr><td>{t.t("iot.global.name")}</td><td>{infos.firstName} {infos.lastName}</td></tr>
                                                <tr><td>{t.t("iot.global.username")}</td><td>{infos.username}</td></tr>
                                                <tr><td>{t.t("iot.global.phone")}</td><td>{infos.phone}</td></tr>
                                                <tr><td>{t.t("iot.global.email")}</td><td>{infos.email}</td></tr>
                                                <tr><td>{t.t("iot.global.userType")}</td><td>{infos.userType}</td></tr>
                                                <tr><td>{t.t("iot.global.createdBy")}</td><td>{infos.createdBy}</td></tr>
                                                <tr><td>{t.t("iot.global.createdDate")}</td><td>{infos.createDate}</td></tr>
                                                <tr><td>{t.t("iot.global.updatedDate")}</td><td>{infos.updateDate}</td></tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                }
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"}  variant="warning">{t.t("iot.global.save")}</Button>}

                    </Modal.Footer>
                </Form>
            </Modal>

        </div>
    )
}
export default Users;
