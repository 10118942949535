import DataTable from "react-data-table-component";
import {Button, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    AlertTriangle,
    Bell,
    Calendar,
    Check,
    Edit,
    Eye,
    Loader,
    PieChart,
    Sliders,
    Sun,
    Zap,
    ZapOff
} from "react-feather";
import {SystemSetting} from "../../../../../constants/system";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {websetting} from "../../../../../helpers/websetting";

function Environmental_Monitoring() {
    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);
    const [totalRows, setTotalRows] = useState(0);
    const [data, setData] = useState([]);

    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        setPerPage(newPerPage)
        fetchDta(page)
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };

    const columns = [
        {
            name: "devEUI",
            selector: (row : any) => row['filtered/application/1/device/a84041b2d18394b4/event/up'].devEUI,
        },{
            name: "deviceName",
            selector: (row : any) => row['filtered/application/1/device/a84041b2d18394b4/event/up'].deviceName,
        },{
            name: "humidity",
            selector: (row : any) => row['filtered/application/1/device/a84041b2d18394b4/event/up'].object.humidity,
        },{
            name: "temperature",
            selector: (row : any) => row['filtered/application/1/device/a84041b2d18394b4/event/up'].object.temperature,
        },{
            name: t.t("iot.global.currentStatus"),
            selector: (row : any) => <div><span className="badge outline-badge-success">ON <Zap size={15} /></span></div>,
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'env-monitoring-service/api/v1/device-events/?size='+perPage+'&page='+p+'&sortDirection=DESC',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.content);
            setLoading(false);
            setTotalRows(response.data.totalElements)
        }).catch(function (error) {

        });
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, [perPage]);

    return(
        <div  className={"content"}>
            <div className={"lightbox"}>
                <div className="pagetitle text-uppercase">
                    <div className={"d-flex justify-content-between"}>
                        <div className={"d-inline-flex"}>
                            <Sliders className={"feather"} />
                            <span className={"d-inline-block mx-2"}>{t.t("iot.global.environmentalMonitoring")}</span>
                        </div>
                    </div>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data} />

                </div>
            </div>
        </div>
    )
}
export default Environmental_Monitoring;

