import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import {ModulaIds, websetting} from "../../helpers/websetting";
import {Calendar, Edit2,Edit,Edit3} from "react-feather";
import Moment from "moment";
import {Link} from "react-router-dom";
import {Button, Spinner} from "react-bootstrap";
import {hexToRgbA} from "../../helpers/function";
import {SystemSetting} from "../../constants/system";
import {useTranslation} from "react-i18next";

function Broad(prop) {
    const t = useTranslation()
    const total = prop.lists.length
    let  page =  new Array(total).fill(1,0,total);
    let  page2 =  new Array(total).fill(true,0,total);
    const [paging,setPaging] = useState(page)
    const [visible,setVisible] = useState(page2)
    const [loadings,setloadings] = useState("")
    let columnsFromBackend = prop.lists
    const [columns, setColumns] = useState(columnsFromBackend);

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {

            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);

            //console.log(result.draggableId)
            //console.log(destColumn.id)

            axios.put(websetting.automationUrl + 'Case/UpdateCaseStatus?&module='+ModulaIds.Automation,
                {
                    "caseId":  result.draggableId.replaceAll("item",""),
                    "statusId": destColumn.id.replaceAll("id",""),
                },
                {
                    method: "post",
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
            }).catch(function (error) {
            });



            destItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }
    };


    const LoadMore = (event,statusId,statusIndex) => {
        let load = []
        setloadings(statusId)

        //console.log(statusIndex +" - "+ paging)
        axios.post(websetting.automationUrl + 'Case/GetCaseForForm'  + "?module=" + ModulaIds.Automation,
            {
                "categoryId": parseInt(prop.categoryId),
                "formId": parseInt(prop.formId),
                "page": paging[statusIndex],
                "rows": SystemSetting.rowInPage,
                "caseStatus": statusId.replaceAll("id","")
            },{
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setloadings("")

            if(response.data.length == 0 ){
                visible[statusIndex] = false
                setVisible([])
                setVisible(visible)
            }else {

                if(response.data.length < SystemSetting.rowInPage){
                    visible[statusIndex] = false
                    setVisible([])
                    setVisible(visible)
                }

                const json = []
                for (var i = 0; i < response.data.length; i++) {
                    json.push({
                        id: "item" + response.data[i].id,
                        content: response.data[i].customerName,
                        createdOn: response.data[i].createdOn,
                    })
                }

                const data = columns
                const index = columns.findIndex((array) => {
                    return array["id"] === statusId
                });

                if (paging[index] == 1) {
                    data[index].items = json
                    setColumns([])
                    setColumns(data)
                } else {
                    data[index].items = data[index].items.concat(json)
                    setColumns([])
                    setColumns(data)
                }

                paging[statusIndex] = parseInt(paging[statusIndex]) + 1
                setPaging(paging)
            }

        }).catch(function (error) {
            visible[statusIndex] = false
            setVisible([])
            setVisible(visible)
        });

    }

    return (
        <div style={{ display: "flex", justifyContent: "start", height: "100%" }}>
            <DragDropContext
                onDragEnd={result => onDragEnd(result, columns, setColumns)}
            >
                {Object.entries(columns).map(([columnId, column], index) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center"
                            }}
                            key={columnId}>
                            <div style={{ margin: 8 }}>
                                <div className={"d-flex  align-content-center"} style={{backgroundColor:column.color,padding:"7px 10px"}}>{column.name} <span style={{color:column.color}} className={"kanbanCounter"}>{column.items.length}</span></div>
                                <Droppable droppableId={columnId} key={columnId}>
                                    {(provided, snapshot) => {
                                        return (
                                            <div className={""}>
                                            <div className={"lists  kanban"}
                                                 {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{
                                                    padding: 7,
                                                    borderRadius:"0px",
                                                    width: 270,
                                                 }}>

                                                {column.items.map((item, index) => {
                                                    return (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={item.id}
                                                            index={index}>
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <div
                                                                        className={"task-card"}
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            backgroundColor:hexToRgbA(column.color,0.02),
                                                                            userSelect: "none",
                                                                            padding: "10px",
                                                                            margin: "0 0 8px 0",
                                                                            minHeight: "50px",
                                                                            borderBottom: "3px solid "+column.color,
                                                                             ...provided.draggableProps.style
                                                                        }}
                                                                    >
                                                                        <div className={"d-flex justify-content-between justify-content-center kanBanItemHeader"}><span><Calendar stroke={column.color} size={13}    /> {Moment(item.createdOn).format(websetting.dateFormat)}</span> <Link to={"../CaseDetails/"+item.id.replaceAll("item","")+"?categoryId="+prop.categoryId+"&formId="+prop.formId}><span className={"d-inline-block float-end"}><Edit size={13} stroke={column.color} /></span></Link></div>
                                                                        <div className={"py-3"}>
                                                                            {item.content}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}

                                            </div>
                                                {visible[columnId] &&
                                                <div className={"text-center loadingmore position-relative"} style={{backgroundColor:"#f7f7f7",zIndex:1,marginTop:"-20px",fontSize:"14px",padding:5}}>{loadings == column.id  ? <Spinner animation={"grow"}  size={"sm"} variant={"dark"}></Spinner> : <Button className={"py-0 fs-6"} variant={"none"} onClick={(e)=>{LoadMore(e,column.id,columnId)}}>{t.t("LblLoadMore")}</Button> }</div>
                                                }
                                    </div>
                                    );
                                    }}
                                </Droppable>
                            </div>

                        </div>
                    );
                })}
            </DragDropContext>
        </div>
    );
}







export default Broad;
