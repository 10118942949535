import {Edit, FilePlus, FileText, PhoneCall, Trash} from "react-feather";
import {Button, Col, Form, Modal, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {Ref, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {editoroptions, websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {UploadCMS, UploadMultiCMS} from "../../../../../helpers/function";
import SunEditor from "suneditor-react";
import {SystemSetting} from "../../../../../constants/system";

function CMSTender(){
    const t = useTranslation()

    const inputRef = React.createRef<any>();

    const [validated, setValidated] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);
    const [filesNum, setfilesNum] = useState([]);
    const [attachfile, setattachfile] = useState([]);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [cat, setCat] = useState([]);
    const [descriptionHe, setdescriptionHe] = useState('');
    const [descriptionAr, setdescriptionAr] = useState('');


    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
    }


    const edit = (id:any) => {
        setfilesNum([])
        setattachfile([])
        setShow(true);
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.cmsUrl + 'CmsTender/Get?id='+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
                setattachfile(response.data.files)
                setdescriptionHe(response.data.descriptionHe)
                setdescriptionAr(response.data.descriptionAr)
            }).catch(function (error) {
            });
        }
    }

    const columns = [
        {
            width:"50px",
            sortable: true,
            name:  "#",
            selector: (row : any) => row.id,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.reportName"),
            selector: (row : any) => row.title,
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button>   <Button onClick={()=>edit(row.id)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button></div>,
        }
    ]


    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
        }
    }
    const SubmitSearch = (e:any) => {
        e.preventDefault()
        if(e.target.input.value !== searchtxt){
            setSearchtxt(e.target.input.value)
        }
    }
    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.cmsUrl + 'CmsTender/Delete?id=' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }




    const handleSubmit = (event: any) => {



        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }


        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)


            const PKid = event.target.Id.value;
            const titleHe = event.target.titleHe.value
            const titleAr = event.target.titleAr.value
            const categoryId = event.target.categoryId.value
            const publicationTender = event.target.publicationTender.value
            const deadlineSubmission = event.target.deadlineSubmission.value
            const biddersConferenceTime = event.target.biddersConferenceTime.value
            const deadlineForClarificationQuestions = event.target.deadlineForClarificationQuestions.value
            const purchaseOfTender = event.target.purchaseOfTender.value
            const onlineSumission = event.target.onlineSumission.value


            const fileObject:any = []

            var name = document.getElementsByName('fileHe[]');
            name.forEach(
                function (input: any, i) {
                    var ar: any = document.getElementsByName('fileAr[]')[i];
                    var id: any = document.getElementsByName('fileId[]')[i];
                    var fileUrl: any = document.getElementsByName('fileUrl[]')[i];
                    if(id.value != ""){
                        fileObject.push({titleHe: input.value, titleAr: ar.value, fileUrl: fileUrl.value,id:id.value})
                    }else{
                        fileObject.push({titleHe: input.value, titleAr: ar.value, fileUrl: ''})
                    }
                }
            );


            if(event.target.Id.value > 0){


                UploadMultiCMS(event.target.attachmentFiles).then((value:any) => {

                    var counter=0;
                    for(var i=0; i< fileObject.length;i++){
                        if(fileObject[i].id == null){
                            fileObject[i].fileUrl = value[counter]
                            ++counter
                        }
                    }

                    axios.put(websetting.cmsUrl + 'CmsTender/Put',
                        {
                            "id":PKid,
                            "titleHe" :  titleHe,
                            "titleAr" :  titleAr,
                            "categoryId" :  categoryId,
                            "publicationTender" :  publicationTender,
                            "deadlineSubmission" :  deadlineSubmission == "" ? null : deadlineSubmission,
                            "biddersConferenceTime" : biddersConferenceTime == "" ? null : biddersConferenceTime ,
                            "deadlineForClarificationQuestions" : deadlineForClarificationQuestions == "" ? null : deadlineForClarificationQuestions ,
                            "descriptionAr" :  descriptionAr,
                            "descriptionHe" :  descriptionHe,
                            "onlineSumission" :  onlineSumission,
                            "purchaseOfTender" :  purchaseOfTender,
                            "files": fileObject,
                            "status": 1
                        },
                        {
                            method: "put",
                            headers: {
                                'Accept-Language':localStorage.getItem("lang") as string,
                                'content-type': 'application/json',
                                'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                            }
                        }
                    ).then(async function (response) {
                        const index = data.findIndex( (array) => {
                            return parseInt(array["id"]) === parseInt(PKid)
                        });
                        let prevState = JSON.parse(JSON.stringify(data));
                        prevState[index].title = response.data.title
                        setData(prevState)
                        setShow(false)
                        setLoadingModal(true)
                    }).catch(function (error) {
                        setLoadingModal(true)
                    });

                })

            }else {

                UploadMultiCMS(event.target.attachmentFiles).then((value:any) => {

                    for(var i=0; i< fileObject.length;i++){
                        fileObject[i].fileUrl = value[i]
                    }

                    axios.post(websetting.cmsUrl + 'CmsTender/AddTender',
                        {
                            "titleHe" :  titleHe,
                            "titleAr" :  titleAr,
                            "categoryId" :  categoryId,
                            "publicationTender" :  publicationTender,
                            "deadlineSubmission" :  deadlineSubmission == "" ? null : deadlineSubmission,
                            "biddersConferenceTime" : biddersConferenceTime == "" ? null : biddersConferenceTime ,
                            "deadlineForClarificationQuestions" : deadlineForClarificationQuestions == "" ? null : deadlineForClarificationQuestions ,
                            "descriptionAr" :  descriptionAr,
                            "descriptionHe" :  descriptionHe,
                            "onlineSumission" :  onlineSumission,
                            "purchaseOfTender" :  purchaseOfTender,
                            "files": fileObject,
                            "status": 1

                        },
                        {
                            method: "post",
                            headers: {
                                'Accept-Language':localStorage.getItem("lang") as string,
                                'content-type': 'application/json',
                                'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                            }
                        }
                    ).then(async function (response) {
                        setData(data.concat(response.data))
                        setShow(false)
                        setLoadingModal(true)
                    }).catch(function (error) {
                        setLoadingModal(true)
                    });

                })


            }
        }
    };


    const [totalRows, setTotalRows] = useState(0);
    const [rowperPage, setrowperPage] = useState(SystemSetting.rowInPage);
    const [currpage, setcurrpage] = useState(1);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsTender/GetTenders?rows=' + rowperPage + "&page=" + page + (searchtxt != "" ? "&search="+searchtxt : ""),
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.listTerm);
            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    const handlePageChange = (page :any) => {
        setcurrpage(page)
        setLoading(true);

        fetchDta(page)
    };
    const handlePerRowsChange = async  (newPerPage:any, page:any) => {
        setLoading(true);
        setrowperPage(newPerPage)
        fetchDta(page)
    }

    const GetCats = async () => {
        axios.get(websetting.cmsUrl + 'CmsCategory/GetCategorys?type=5',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setCat(response.data);
        }).catch(function (error) {

        });
    };


    const AddFiles = (v:any) => {
      const f:any = []
      for(var i=0;i < v.target.files.length;i++){
          f.push(v.target.files[i].name)
      }
      setfilesNum(f)
    }

    const deleteFile = (event:any,index:any) => {
        const dt = new DataTransfer();
        const new_filesNum = filesNum.filter((array,ind) => {
            return ind !== index
        });
        setfilesNum(new_filesNum)

        for(var i = 0; i < new_filesNum.length; i++) {
            dt.items.add(inputRef.current.files[i])
        }
        inputRef.current.files =  dt.files
    }

    const deleteAttachFile = (event:any,index:any) => {
        const dt = new DataTransfer();
        const new_filesNum = attachfile.filter((array,ind) => {
            return ind !== index
        });
        setattachfile(new_filesNum)
    }



    useEffect(() => {
        GetCats()
        fetchDta(1); // fetch page 1 of users
    }, [localStorage.getItem("lang") ,searchtxt]);
    const infos =  JSON.parse(JSON.stringify(infoData))




    return(
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                    <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <FileText className={"feather mx-2"} /> {t.t("cms.web.tenders")}
                     </span>
                    </div>
                    <span className={"d-inline-flex align-items-center mx-2"}>

                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                        <Form  onSubmit={(e)=>{SubmitSearch(e)}}  action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                    </div>

                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><FilePlus /> {t.t("cms.addTender")}</Button>
                 </span>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationPerPage={rowperPage}
                               paginationTotalRows={totalRows}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data} />
                </div>
            </div>


            <Modal size={"xl"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><FileText className={"feather"} /> {t.t("cms.web.tenders")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>


                                    <Col md={12}>

                                        <Tabs className={"justify-content-center tabsModal"} defaultActiveKey={"tab1"}>
                                            <Tab eventKey={"tab1"} className={"shadow-0"} title={t.t("cms.mainInformation")} >

                                                <Row className={"mt-4"}>
                                                <Col md={12}>
                                                    <Form.Group className="my-2 " controlId="categoryId">
                                                        <Form.Label className="my-0">{t.t("cms.category")}:</Form.Label>
                                                        {cat &&
                                                        <Form.Select required>
                                                            {
                                                                cat.map((value) => {
                                                                    return (
                                                                        <option selected={infos.categoryId == value['id']  ? true : false }  value={value['id']}>{value['name']}</option>
                                                                    )
                                                                })
                                                            }

                                                        </Form.Select>
                                                        }
                                                        <Form.Control.Feedback type="invalid" id="msg_file">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>





                                                    <Col md={6}>
                                                        <Form.Group className="my-2 " controlId="publicationTender">
                                                            <Form.Label className="my-0">{t.t("cms.publicationTender")}:</Form.Label>
                                                            <Form.Control required type="datetime-local" defaultValue={infos.publicationTender}
                                                                          aria-label={t.t("cms.publicationTender")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="my-2 " controlId="deadlineSubmission">
                                                            <Form.Label className="my-0">{t.t("cms.deadlineSubmission")}:</Form.Label>
                                                            <Form.Control type="datetime-local" defaultValue={infos.deadlineSubmission}
                                                                          aria-label={t.t("cms.deadlineSubmission")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="my-2 " controlId="biddersConferenceTime">
                                                            <Form.Label className="my-0">{t.t("cms.biddersConferenceTime")}:</Form.Label>
                                                            <Form.Control  type="datetime-local" defaultValue={infos.biddersConferenceTime}
                                                                          aria-label={t.t("cms.biddersConferenceTime")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="my-2 " controlId="deadlineForClarificationQuestions">
                                                            <Form.Label className="my-0">{t.t("cms.deadlineForClarificationQuestions")}:</Form.Label>
                                                            <Form.Control  type="datetime-local" defaultValue={infos.deadlineForClarificationQuestions}
                                                                          aria-label={t.t("cms.deadlineForClarificationQuestions")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>


                                                    <Col md={6}>
                                                        <Form.Group className="my-2 " controlId="onlineSumission">
                                                            <Form.Label className="my-0">{t.t("cms.onlineSubmission")}:</Form.Label>
                                                            <Form.Control type="text" defaultValue={infos.onlineSumission}
                                                                          aria-label={t.t("cms.onlineSubmission")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>


                                                    <Col md={6}>
                                                        <Form.Group className="my-2 " controlId="purchaseOfTender">
                                                            <Form.Label className="my-0">{t.t("cms.purchaseOfTender")}:</Form.Label>
                                                            <Form.Control type="text" defaultValue={infos.purchaseOfTender}
                                                                          aria-label={t.t("cms.purchaseOfTender")}
                                                                          aria-describedby="msg_file"/>
                                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                                Required Field
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>


                                                </Row>
                                            </Tab>
                                            <Tab eventKey={"tab2"} className={"shadow-0"} title={t.t("cms.description") + " - "+ t.t("cms.he")} >
                                                <Col md={12}>
                                                    <Form.Group className="my-2" controlId="titleHe">
                                                        <Form.Label>{t.t("cms.tenderName")} - {t.t("cms.he")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.titleHe} required
                                                                      aria-label={t.t("cms.protocolName")}
                                                                      aria-describedby="msg_titleHe"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleHe">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label className="mt-2">{t.t("cms.description")} - {t.t("cms.he")}:</Form.Label>
                                                    {JSON.parse(JSON.stringify(infoData)).id != null  &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"} defaultValue={infos.descriptionHe} height={"320px"}  onChange={setdescriptionHe}  setOptions={editoroptions} />
                                                    }

                                                    {JSON.parse(JSON.stringify(infoData)).id == null  &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"} defaultValue={""}  height={"320px"} onChange={setdescriptionHe}  setOptions={editoroptions} />
                                                    }
                                                </Col>

                                            </Tab>
                                            <Tab eventKey={"tab3"} className={"shadow-0"} title={t.t("cms.description") + " - "+ t.t("cms.ar")} >
                                                <Col md={12}>
                                                    <Form.Group className="my-2" controlId="titleAr">
                                                        <Form.Label>{t.t("cms.tenderName")} - {t.t("cms.ar")}:</Form.Label>
                                                        <Form.Control type="text" defaultValue={infos.titleAr} required
                                                                      aria-label={t.t("cms.protocolName")}
                                                                      aria-describedby="msg_titleAr"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_titleAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={12}>
                                                    <Form.Label className="mt-2">{t.t("cms.description")} - {t.t("cms.ar")}:</Form.Label>
                                                    {JSON.parse(JSON.stringify(infoData)).id != null  &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"} defaultValue={infos.descriptionAr}  height={"320px"} onChange={setdescriptionAr}  setOptions={editoroptions} />
                                                    }

                                                    {JSON.parse(JSON.stringify(infoData)).id == null  &&
                                                    <SunEditor lang={localStorage.getItem("lang") != "he" ? "en" : "he"} defaultValue={""}  height={"320px"} onChange={setdescriptionAr}  setOptions={editoroptions} />
                                                    }                                                </Col>
                                            </Tab>
                                            <Tab eventKey={"tab4"} className={"shadow-0"} title={t.t("cms.attachFiles")} >
                                                <Col md={12} className={"mt-4"}>
                                                    <Form.Group className="my-2 " controlId="attachmentFiles">
                                                        <Form.Label className="my-0">{t.t("cms.attachFiles")}:</Form.Label>
                                                        <Form.Control ref={inputRef as Ref<any>} type="file" multiple onChange={(v)=>{AddFiles(v)}}
                                                                      aria-label={t.t("cms.attachFiles")}
                                                                      aria-describedby="msg_file"/>
                                                        <Form.Control.Feedback type="invalid" id="msg_file">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>


                                                {attachfile &&
                                                <Table borderless>
                                                    {attachfile.map((item:any,index:any) => {
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    <Row>
                                                                        <Col md={12}>{item.fileUrl}</Col>
                                                                        <Col md={6}>
                                                                            <Form.Group className="my-2 " >
                                                                                <Form.Control name={"fileUrl[]"} defaultValue={item.fileUrl}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                                <Form.Control name={"fileId[]"} defaultValue={item.id}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                                <Form.Control name={"fileHe[]"} defaultValue={item.titleHe} required type="text" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <Form.Group className="my-2 " >
                                                                                <Form.Control name={"fileAr[]"}  type="text" defaultValue={item.titleAr} placeholder={t.t("cms.ar")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>

                                                                </td>
                                                                <td width={50}  ><Button variant={"outline-danger"} size={"sm"}  onClick={(e:any)=>{deleteAttachFile(e,index)}} className={"rounded-circle"}><Trash size={15} /></Button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </Table>
                                                }


                                                {filesNum &&
                                                    <Table borderless>
                                                        {filesNum.map((item,index) => {
                                                        return(
                                                            <tr>
                                                                <td>
                                                                    <Row>
                                                                        <Col md={12}>{item}</Col>
                                                                        <Col md={6}>
                                                                            <Form.Group className="my-2 " >
                                                                                <Form.Control name={"fileUrl[]"} defaultValue={""}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                                <Form.Control name={"fileId[]"} defaultValue={""}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                                <Form.Control name={"fileHe[]"} required type="text" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <Form.Group className="my-2 " >
                                                                                <Form.Control name={"fileAr[]"}  type="text" placeholder={t.t("cms.ar")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>

                                                                </td>
                                                                <td width={50}  ><Button variant={"outline-danger"} size={"sm"}  onClick={(e:any)=>{deleteFile(e,index)}} className={"rounded-circle"}><Trash size={15} /></Button></td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </Table>
                                                }




                                            </Tab>
                                        </Tabs>
                                    </Col>

                                </Row>
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    )
}

export default CMSTender;
