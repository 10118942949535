import React, {useEffect, useRef, useState} from "react";
import {ChevronLeft, Edit, Settings, Tool, Trash} from "react-feather";
import {useTranslation} from "react-i18next";
import {Alert, Button, Col, Form, Spinner,Row} from "react-bootstrap";
import axios from "axios";
import i18n from "i18next";
import {Link} from "react-router-dom";
import {websetting} from "../../../../../helpers/websetting";
import Moment from "moment";
import {SystemSetting} from "../../../../../constants/system";
import {UploadMultiCMS} from "../../../../../helpers/function";
import {toast} from "wc-toast";

function CMSSetting(prop:any){
    const t = useTranslation()

    const inputRef = React.createRef<any>();

    const [validated, setValidated] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);
    const [filesNum, setfilesNum] = useState([]);
    const [attachfile, setattachfile] = useState([]);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');


    const handleSubmit = (event: any) => {
        setSuccess('')
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoading(true)

            const ob = [{
                "valueHe": event.target.pageTitleHe.value,
                "valueAr": event.target.pageTitleAr.value,
                "code": "pageTitle",
                "displayPublic": true
            },
                {
                    "valueHe": event.target.pageDescriptionHe.value,
                    "valueAr": event.target.pageDescriptionAr.value,
                    "code": "pageDescription",
                    "displayPublic": true
                },
                {
                    "valueHe": event.target.pageKeywordHe.value,
                    "valueAr": event.target.pageKeywordAr.value,
                    "code": "pageKeyword",
                    "displayPublic": true
                },
                {
                    "valueHe": event.target.email.value,
                    "valueAr": event.target.email.value,
                    "code": "email",
                    "displayPublic": true
                },
                {
                    "valueHe": event.target.facebook.value,
                    "valueAr": event.target.facebook.value,
                    "code": "facebook",
                    "displayPublic": true
                },
                {
                    "valueHe": event.target.whatsapp.value,
                    "valueAr": event.target.whatsapp.value,
                    "code": "whatsapp",
                    "displayPublic": true
                },
                {
                    "valueHe": event.target.instagram.value,
                    "valueAr": event.target.instagram.value,
                    "code": "instagram",
                    "displayPublic": true
                },
                {
                    "valueHe": event.target.Email_SenderName.value,
                    "valueAr": event.target.Email_SenderName.value,
                    "code": "Email_SenderName",
                    "displayPublic": false
                },
                {
                    "valueHe": event.target.Email_ToEmail.value,
                    "valueAr": event.target.Email_ToEmail.value,
                    "code": "Email_ToEmail",
                    "displayPublic": false
                },
                {
                    "valueHe": event.target.Email_Username.value,
                    "valueAr": event.target.Email_Username.value,
                    "code": "Email_Username",
                    "displayPublic": false
                },
                {
                    "valueHe": event.target.Email_Password.value,
                    "valueAr": event.target.Email_Password.value,
                    "code": "Email_Password",
                    "displayPublic": false
                },
                {
                    "valueHe": event.target.Email_Host.value,
                    "valueAr": event.target.Email_Host.value,
                    "code": "Email_Host",
                    "displayPublic": false
                },
                {
                    "valueHe": event.target.Email_Port.value,
                    "valueAr": event.target.Email_Port.value,
                    "code": "Email_Port",
                    "displayPublic": false
                }]


            axios.post(websetting.cmsUrl + 'CmsSystemSetting/OperationSystemSetting',
                ob,
                {
                    method: "post",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setData(response.data)
                setSuccess(t.t('LblDone'))
                setLoading(false)
            }).catch(function (error) {
                setLoading(false)
            });
        }
    };

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsSystemSetting/GetSystemSettings',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);
        }).catch(function (error) {

        });
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, [localStorage.getItem("lang") ,searchtxt]);
    const infos =  JSON.parse(JSON.stringify(infoData))


    const checkCode = (code:any)=>{
        const index =  data.findIndex((value) =>{
                return value["code"] === code
        })
        return index;
    }
    return(
        <div className={""}>
            <div className={"lightbox mb-3"}>


                <div className="pagetitle text-uppercase">
                    <div className={"d-flex justify-content-between"}>
                        <div className={"d-inline-flex"}>
                            <Settings className={"feather"} size={21} />
                            {t.t("cms.web.setting")}
                        </div>

                    </div>

                </div>

                <hr className={"shodow-hr"}/>
                <Row className={"justify-content-center"}>
                    <Col md={12}>
                        {loading ? <div className="text-center"><Spinner animation="border" variant="warning"
                                                                              role="status"><span
                                className="visually-hidden">انتظر من فضلك</span></Spinner></div> :
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                {success &&
                                <Alert id="AlertMsg" className="text-center" variant="success">{success}</Alert>}
                                {error && <Alert id="AlertMsg" className="text-center" variant="danger">{error}</Alert>}
                                {loading && <div className="text-center"><Spinner animation="border" variant="warning"
                                                                                       role="status"><span
                                    className="visually-hidden">انتظر من فضلك</span></Spinner></div>}


                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="pageTitleHe">
                                            <Form.Label>{t.t("cms.webTitle")  + " " + t.t("cms.he") }</Form.Label>
                                            <Form.Control type="text"  defaultValue={data.length > 0 ? data[checkCode("pageTitle")]["valueHe"] : ""} required aria-label={""} aria-describedby="msg1"/>
                                            <Form.Control.Feedback type="invalid" id="msg1">
                                                {t.t("cms.webTitle")  + " " + t.t("cms.he") }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="pageTitleAr">
                                            <Form.Label>{t.t("cms.webTitle")  + " " + t.t("cms.ar") }</Form.Label>
                                            <Form.Control type="text"  defaultValue={data.length > 0 ? data[checkCode("pageTitle")]["valueAr"] : ""} required aria-label={""} aria-describedby="msg2"/>
                                            <Form.Control.Feedback type="invalid" id="msg2">
                                                {t.t("cms.webTitle")  + " " + t.t("cms.ar") }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>


                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="pageDescriptionHe">
                                            <Form.Label>{t.t("cms.webDescription")  + " " + t.t("cms.he") }</Form.Label>
                                            <Form.Control as={"textarea"} type="text" style={{height:80}}  defaultValue={data.length > 0 ? data[checkCode("pageDescription")]["valueHe"] : ""}   aria-label={""} aria-describedby="msg1"/>
                                            <Form.Control.Feedback type="invalid" id="msg1">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="pageDescriptionAr">
                                            <Form.Label>{t.t("cms.webDescription")  + " " + t.t("cms.ar") }</Form.Label>
                                            <Form.Control as={"textarea"} type="text" style={{height:80}}  defaultValue={data.length > 0 ? data[checkCode("pageDescription")]["valueAr"] : ""}   aria-label={""} aria-describedby="msg2"/>
                                            <Form.Control.Feedback type="invalid" id="msg2">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="pageKeywordHe">
                                            <Form.Label>{t.t("cms.webKeyword")  + " " + t.t("cms.he") }</Form.Label>
                                            <Form.Control type="text"  defaultValue={data.length > 0 ? data[checkCode("pageKeyword")]["valueHe"] : ""}   aria-label={""} aria-describedby="msg1"/>
                                            <Form.Control.Feedback type="invalid" id="msg1">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="pageKeywordAr">
                                            <Form.Label>{t.t("cms.webKeyword")  + " " + t.t("cms.ar") }</Form.Label>
                                            <Form.Control type="text" defaultValue={data.length > 0 ? data[checkCode("pageKeyword")]["valueAr"] : ""}    aria-label={""} aria-describedby="msg2"/>
                                            <Form.Control.Feedback type="invalid" id="msg2">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                    <hr className="my-3" />
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="email">
                                            <Form.Label>{t.t("cms.email")}</Form.Label>
                                            <Form.Control type="email" defaultValue={data.length > 0 ? data[checkCode("email")]["valueHe"] : ""} required aria-label={""} aria-describedby="msg1"/>
                                            <Form.Control.Feedback type="invalid" id="msg1">
                                                {t.t("cms.email")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="facebook">
                                            <Form.Label>{t.t("cms.facebook")}</Form.Label>
                                            <Form.Control type="text" defaultValue={data.length > 0 ? data[checkCode("facebook")]["valueHe"] : ""}    aria-label={""} aria-describedby="msg2"/>
                                            <Form.Control.Feedback type="invalid" id="msg2">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="whatsapp">
                                            <Form.Label>{t.t("cms.whatsapp")}</Form.Label>
                                            <Form.Control type="text" defaultValue={data.length > 0 ? data[checkCode("whatsapp")]["valueHe"] : ""}    aria-label={""} aria-describedby="msg1"/>
                                            <Form.Control.Feedback type="invalid" id="msg1">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="instagram">
                                            <Form.Label>{t.t("cms.instagram") }</Form.Label>
                                            <Form.Control type="text"  defaultValue={data.length > 0 ? data[checkCode("instagram")]["valueHe"] : ""}   aria-label={""} aria-describedby="msg2"/>
                                            <Form.Control.Feedback type="invalid" id="msg2">
                                                {t.t("iot.global.username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>





                                    <Col md={12}>
                                        <hr className="my-3" />
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="Email_ToEmail">
                                            <Form.Label>{t.t("cms.Email_ToEmail")}</Form.Label>
                                            <Form.Control type="email"  dir={"ltr"} defaultValue={data.length > 0 ? data[checkCode("Email_ToEmail")]["valueHe"] : ""} required aria-label={""} aria-describedby="msg1"/>
                                            <Form.Control.Feedback type="invalid" id="msg1">
                                                {t.t("cms.Email_ToEmail")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="Email_SenderName">
                                            <Form.Label>{t.t("cms.Email_SenderName")}</Form.Label>
                                            <Form.Control type="text" defaultValue={data.length > 0 ? data[checkCode("Email_SenderName")]["valueHe"] : ""}    aria-label={""} aria-describedby="msg2"/>
                                            <Form.Control.Feedback type="invalid" id="msg2">
                                                {t.t("cms.Email_SenderName")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="Email_Username">
                                            <Form.Label>{t.t("cms.Email_Username")}</Form.Label>
                                            <Form.Control type="text"  dir={"ltr"} defaultValue={data.length > 0 ? data[checkCode("Email_Username")]["valueHe"] : ""}    aria-label={""} aria-describedby="msg2"/>
                                            <Form.Control.Feedback type="invalid" id="msg2">
                                                {t.t("cms.Email_Username")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="Email_Password">
                                            <Form.Label>{t.t("cms.Email_Password")}</Form.Label>
                                            <Form.Control type="password"  dir={"ltr"} defaultValue={data.length > 0 ? data[checkCode("Email_Password")]["valueHe"] : ""} required aria-label={""} aria-describedby="msg1"/>
                                            <Form.Control.Feedback type="invalid" id="msg1">
                                                {t.t("cms.Email_Password")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="Email_Host">
                                            <Form.Label>{t.t("cms.Email_Host")}</Form.Label>
                                            <Form.Control type="text"  dir={"ltr"} defaultValue={data.length > 0 ? data[checkCode("Email_Host")]["valueHe"] : ""}    aria-label={""} aria-describedby="msg1"/>
                                            <Form.Control.Feedback type="invalid" id="msg1">
                                                {t.t("cms.Email_Host")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="Email_Port">
                                            <Form.Label>{t.t("cms.Email_Port") }</Form.Label>
                                            <Form.Control type="text" dir={"ltr"}  defaultValue={data.length > 0 ? data[checkCode("Email_Port")]["valueHe"] : ""}   aria-label={""} aria-describedby="msg2"/>
                                            <Form.Control.Feedback type="invalid" id="msg2">
                                                {t.t("cms.Email_Port")}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>





                                </Row>


                                <div className={" pb-5"}>
                                    <Button type="submit" variant={"dark"} className="w-100 mt-4">{t.t("iot.global.save")}</Button>
                                </div>

                            </Form>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default CMSSetting
