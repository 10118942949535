import {Col, Container, Dropdown, Image, Nav, Navbar, Offcanvas, Row} from "react-bootstrap";
import {ModulaIds, UserProfile, websetting} from "../helpers/websetting";
import {Link, useNavigate} from "react-router-dom";
import {hexToRgbA, UseLang} from "../helpers/function";
import React, {useEffect, useState} from "react";
import {Autmation, domain} from "../constants/routes";
import MenuList from "./ssoMenu";
import {AlertTriangle, Bell, Calendar, Grid, Mail} from "react-feather";
import axios from "axios";
import Moment from "moment";
import {useTranslation} from "react-i18next";

function SSOHeader(prop:any){
const t = useTranslation()
    const [showNotifcation, setShowNotifcation] = useState(false);
    const handleCloseNotifcation = () => setShowNotifcation(false);
    const handleShowNotifcation = () => setShowNotifcation(true);
    const [notifcation, setNotifcation] = useState([]);
    const [unreadNotifcation, setunreadNotifcation] = useState(0);



    const [show, setShow] = useState(false);
    const toggleShow = () => setShow((s) => !s);
    const handleClose = () => setShow(false);
    const navigate = useNavigate()
    const ClearSession = () => {
        sessionStorage.clear();
        window.location.href = domain;
    }


    useEffect(() => {

        //if(prop.page == Autmation){
            axios.get(websetting.apiUrl  +'auth/api/v1/notifications/'+UserProfile.username+'/allNotifications',
                {
                    method: "GET",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setNotifcation(response.data)

                const unread = response.data.filter((array:any) => {
                    return array["isRead"] !== true
                });
                setunreadNotifcation(unread.length)


            }).catch(function (error) {
            });
        //}

    }, []);

    const ShowNotifcation = () => {
        setShowNotifcation(true)
        //if (prop.page == Autmation) {

            axios.get(websetting.apiUrl  +'auth/api/v1/notifications/'+UserProfile.username+'/allNotifications',
                {
                    method: "GET",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setNotifcation(response.data)

                const unread = response.data.filter((array:any) => {
                    return array["isRead"] !== true
                });

                setunreadNotifcation(unread.length)
             }).catch(function (error) {
            });
        //}
    }


    const markasRead = (id:any) => {
        setShowNotifcation(false)
        axios.put(websetting.apiUrl  +'auth/api/v1/notifications/'+id,
            {
                "notificationId": id
            },{
                method: "put",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Accept': 'application/json',
                    'Authorization': 'bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
        }).catch(function (error) {
        });
    }



    return(
        <header className={"py-1 "}>
            <Container fluid={true} className="gx-0 gx-lg-5" >
                <Row  className="gx-0 gx-lg-5">
                    <Col className="my-auto gx-0  align-content-center align-items-center" >
                        <div className="my-auto position-relative">
                            <Navbar bg="none" className="my-auto py-1 float-end  float-lg-none" expand={true}>
                                <Navbar.Collapse  className=" justify-content-start ">
                                    <Nav className=" justify-content-start d-lg-inline-flex d-none">
                                        <Nav.Item>
                                            <Link to={"../"}>
                                                <Grid className={"feather"} size={30}/>
                                            </Link>
                                        </Nav.Item>
                                        <Nav.Item className={"mx-3 position-relative"}>
                                            <Nav.Link onClick={()=>ShowNotifcation()}>
                                            <Bell className={"feather"} size={30} />
                                                {t.t("iot.global.notifications")}
                                                {unreadNotifcation > 0 &&
                                                <span className={"badge align-middle text-center px-0 position-absolute top-0 start-0 badge-danger"} style={{width:17,height:17,borderRadius:15,lineHeight:1.6,fontSize:9.5}}>{unreadNotifcation}</span>
                                                }
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Navbar.Collapse>

                                <Navbar.Collapse  className="s justify-content-end">
                                    <Nav >
                                        <Nav.Item>
                                            <Dropdown align="end"  className="welcome" >
                                                <Dropdown.Toggle as={"a"}  href="#" variant="none">
                                                    <Image id="profileImg3" width={35} height={35} className="rounded-circle"
                                                           srcSet={UserProfile.userImage} />
                                                    <span className="d-lg-inline-block mx-1 d-none">{sessionStorage.getItem("fisrtName")}</span>

                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="text-center">
                                                    <Dropdown.Item as={Link} to="./Profile">{t.t("iot.global.profile")}</Dropdown.Item>
                                                    <Dropdown.Item as={Link} to={websetting.sysUrl} onClick={ClearSession} >{t.t("iot.global.logout")}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link className="px-1 px-lg-3">
                                                <UseLang position={"end"} />
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>

                            <Navbar bg="none" expand="lg" className="p-0 d-lg-none justify-content-center d-inline-block float-start "  >
                                <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={toggleShow} className={"float-start"} />
                                <Navbar.Brand  >
                                    <Link to={"./"}>
                                        <Image srcSet={websetting.orgLogo} height="45px" />
                                    </Link>
                                </Navbar.Brand>
                                <Navbar.Offcanvas
                                    className={"canvusMenu"}
                                    onHide={handleClose}
                                    scroll={false}
                                    backdrop={true}
                                    show={show}
                                    id="offcanvasNavbar"
                                    aria-labelledby="offcanvasNavbarLabel"
                                    placement="start">
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id="offcanvasNavbarLabel">
                                            <Link to={"./"} onClick={() => setShow(false)} >
                                                <Image height="40px" srcSet={websetting.orgLogo} />
                                            </Link>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body className="px-0">
                                        <MenuList page={prop.page} />
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Navbar>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Offcanvas  placement={"end"} show={showNotifcation} onHide={handleCloseNotifcation}>
                <Offcanvas.Header className={"border-bottom bg-light"} closeButton>
                    <Offcanvas.Title>
                        <div className={"h6 bold py-0 expand my-0"}>
                            <Bell size={20} className={"feather"} />
                            {t.t("iot.global.notifications")}
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={notifcation.length > 0 ? "pt-2" : "d-flex flex-column text-center justify-content-center"} >
                    {notifcation.length > 0 ?
                        <div>
                            {Object.keys(notifcation).map((titles2, i) => {
                                return (
                                    <Link to={notifcation[i]["link"]} onClick={()=>{markasRead(notifcation[i]['notificationId'])}}>
                                    <div className={"  py-2"} style={{borderBottom:"1px solid " + hexToRgbA("#2ea37d",0.1),opacity: notifcation[i]["isRead"] ? 0.5 : 1}}>
                                        <div className={"fs-6"} style={{color:"#2ea37d"}}><Calendar fill={hexToRgbA("#2ea37d",0.2)} stroke={"#2ea37d"} className={"  mx-1"} size={12} />{Moment(notifcation[i]["createDate"]).format(websetting.dateFormat)}</div>
                                        <div className={"fs-6"} style={{color:"#3b3f5c"}}><Mail  className={"feather mx-1"} size={12} />{notifcation[i]["message"]}</div>
                                    </div>
                                    </Link>
                                )
                            })
                            }
                        </div>
                        :
                        <span className={"bold"}>
                        <div className={"text-center"}>
                            <AlertTriangle className="feather my-3 color1 feather-alert-triangle" size={30} />
                        </div>
                            {t.t("iot.global.youHave")} 0 {t.t("iot.global.newAlerts")}
                    </span>
                    }

                </Offcanvas.Body>
            </Offcanvas>

        </header>
    )
}
export default SSOHeader
