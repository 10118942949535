import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Col, Dropdown, FloatingLabel, Form, Image, Offcanvas, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import axios from "axios";
import {Lang, websetting} from "../../../helpers/websetting";
import Moment from 'moment';
import DataTable from "react-data-table-component";
function CaseDetails(){
    let { id } = useParams();

    const [categoryName,setcategoryName] = useState('')
    const [createdOn,setcreatedOn] = useState('')
    const [formId,setformId] = useState('')
    const [formName,setformName] = useState('')
    const [roleList,setroleList] = useState('')
    const [statusName,setstatusName] = useState('')
    const [submissionUrl,setsubmissionUrl] = useState('')
    const [loading,setloading] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setloading(true);
        axios.get(websetting.apiCitizen + 'CitizenMyService/GetMyService?caseId=' + id,
            {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setcategoryName(response.data.categoryName)
            setcreatedOn(response.data.createdOn)
            setformId(response.data.formId)
            setformName(response.data.formName)
            setroleList(response.data.roleList)
            setstatusName(response.data.statusName)
            setsubmissionUrl(response.data.submissionUrl)
            setloading(false)
        }).catch(function (error) {
            setloading(false)
        });
    }, []);
    return(
        <div>
            <div className="pageTitle d-flex justify-content-between mb-3">
                <h3 className={"d-inline-flex bold"}>الخدمات الرقمية</h3>
                <Link   className={"float-end d-inline-flex align-content-end"} to={"../MyServices"}> الى الخلف
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         className="feather mx-1 feather-chevron-left">
                    <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                </Link>
            </div>
            {loading ? <div className={"mt-5 text-center"}><Spinner title={"Please Waite"} animation="border"  variant={"warning"} ></Spinner></div> :
                <div>
                <Row >
                    <Col md={6} className={"py-3"}>
                        <div className={"d-flex justify-content-between pb-2 border-bottom"}>
                            <div className={"d-inline-flex"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" className="feather mx-1 feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                                الموظف المسؤول: {roleList && roleList }</div>
                            <div  className={"d-inline-flex align-content-end"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" className="feather mx-1 feather-info">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="16" x2="12" y2="12"></line>
                                    <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                </svg>
                                {statusName && statusName }</div>
                        </div>
                        <div>
                            <div className={"bold py-5 h3 border-bottom"}>{formName && formName }</div>
                        </div>
                        <div className={"d-flex justify-content-between border-bottom py-3"}>
                            <div className={"d-inline-flex"}>
                                <div className={"d-inline-flex"}>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round" className="feather mx-1 feather-calendar">
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                    تاريخ التقديم: {createdOn && Moment(createdOn).format(websetting.dateFormat) }
                                </div>
                                <div className={"d-inline-flex mx-2"}>|</div>
                                <div className={"d-inline-flex"}>
                                    رقم الطلب: {id}
                                </div>
                            </div>
                            <div className={"d-inline-flex align-content-end bold"}>
                                <a  className={"pointer-event"} href={"#"} onClick={handleShow}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" className="feather mx-2 mx-1 feather-eye">
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                                مشاهدة المرفقات</a>
                            </div>
                        </div>
                    </Col>
                    <Col className={"mt-1"} md={6}>
                        <Tabs className={"px-0"}>
                            <Tab eventKey="CaseTab1" onSelect={SendForm} className="py-2" title="المواطن" >
                                <SendNote id={id}  />
                            </Tab>
                            <Tab eventKey="CaseTab2" className="py-2" title="سجل النشاطات" >
                                <History id={id} />
                            </Tab>
                        </Tabs>

                    </Col>
                </Row>
                    <Offcanvas className={"AttachmentCanvas"} placement={"end"} show={show} onHide={handleClose}>
                        <Offcanvas.Header className={"border-bottom"} closeButton>
                            <Offcanvas.Title>
                                <div className={"h3 bold py-0 expand my-0"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                     stroke-linejoin="round" className="feather mx-2 feather-eye">
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                                المرفقات
                                </div>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Row className={"h-100"}>
                                <Col md={9} className={"h-100"} > <iframe src={"http://saasdemoqa.smsm-it.com/ar/Forms/Form/PreviewAnswer?formId="+formId+"&submissionUrl="+ submissionUrl +"&preview=1"} frameBorder={0}  height={"100%"}  width={"100%"}  /></Col>
                                <Col md={3}></Col>
                            </Row>

                        </Offcanvas.Body>
                    </Offcanvas>
                </div>

            }

        </div>
    )
}


function SendNote(props:any) {

     const [files,setfiles] = useState([])
     const handleChange = (event: any): void => {
         setfiles(event.target.files)
    }
    const removeFile = (index:any) => {
        const set = new Set(files);
        const result2 = Array.from(set).slice(index,index)
        setfiles(result2);
    }




    return(
        <div>
            <div className={"border mt-3"}>
                <FloatingLabel className={"p-2 pt-3"} controlId="floatingTextarea2" label="اكتب الملاحظات">
                    <Form.Control
                        className={"border-0 shadow-0 box-shadow-0 p-2"}
                        as="textarea"
                        placeholder="اكتب الملاحظات"
                        style={{ height: '100px' }}
                    />
                </FloatingLabel>
                <div className={"py-2 d-flex px-2 justify-content-between border-top"}>
                    <div className={"d-inline-flex "}>
                        <Form.Label className="btn btn-sm btn-light">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" className="feather mx-1 bold feather-link">
                                <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
                                <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
                            </svg>
                            إدراج ملف

                            <Form.Control type="file" multiple hidden={true} onChange={handleChange} />
                        </Form.Label>
                    </div>
                    <div className={"d-inline-flex justify-content-end"}>
                        <button className={"btn btn-sm btn-dark py-1 px-3"}>أضف</button>
                    </div>
                </div>

                {
                    files.length ==0 ? "" :
                        <div className={"d-block  px-2"}>
                            {Object.keys(files).map((titles, i) => {
                                return(
                                    <div className={"d-flex bg-light bg-opacity-50 py-2 border-top w-100 justify-content-between"}>
                                        <div className={"d-inline-flex"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                 stroke-linecap="round" stroke-linejoin="round"
                                                 className="feather feather-file">
                                                <path
                                                    d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                                                <polyline points="13 2 13 9 20 9"></polyline>
                                            </svg>

                                            { files[i]["name"] }
                                        </div>

                                        <div className={"d-inline-flex justify-content-end"}>
                                            <button onClick={()=>removeFile(i)}    >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                     stroke-linecap="round" stroke-linejoin="round"
                                                     className="feather feather-trash-2">
                                                    <polyline points="3 6 5 6 21 6"></polyline>
                                                    <path
                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                }
            </div>
        </div>
    );
}

function History(props:any){
    const columns = [
        {
            selector: (row : any) => Moment(row.date).format("HH:m:s " + websetting.dateFormat ),
            center:true,
        },{
            cell : (row : any) =>  <div><Image srcSet={row.userPhoto ? row.userPhoto : "http://saasdemoqa.smsm-it.com//Document/1/20211205_1b698828-58b6-4130-ade1-8fdf9e4c3d6c.jpg"} width={25} height={25} className="rounded-circle"/>   {row.roleName}</div>   ,
            style: {
                backgroundColor: 'rgba(187, 204, 221, 0.1)',
                justifyContent:"center"
            }
            },{
            selector: (row : any) => row.message,
        }
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.post(websetting.apiCitizen + 'CitizenServiceHistory/GetCaseHistory',
            {
                caseId: props.id,
                page: page,
                rows: perPage
            },
            {
                method: "post",
                headers: {
                    'Content-Type':'application/json',
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setTotalRows(100);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        setLoading(true);
        axios.post(websetting.apiCitizen + 'CitizenServiceHistory/GetCaseHistory',
            {
                caseId: props.id,
                page: page,
                rows: perPage
            },
            {
                method: "post",
                headers: {
                    'Content-Type':'application/json',
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setPerPage(newPerPage);
            setLoading(false);
        }).catch(function (error) {
        });
    };
    useEffect(() => {
        fetchDta(1);
    }, []);


    return(
      <div>
          {loading ? <div className={"text-center mt-5"}><Spinner animation={"border"} variant={"warning"}/></div>  :
              <DataTable noTableHead={true} data={data} columns={columns}
                         progressPending={loading}
                         pagination
                         paginationServer
                         paginationTotalRows={totalRows}
                         onChangeRowsPerPage={handlePerRowsChange}
                         onChangePage={handlePageChange} />
          }
      </div>
    );
}
function SendForm(){
    return(
        <div>ddd</div>
    )
}
export default CaseDetails;