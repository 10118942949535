import React, { useState} from 'react';
import { Route, Routes} from "react-router-dom";
import EmployeeProfile from "../IOT/profile";
import SSOHeader from "../../../../containers/ssoheader";
import MenuList from "../../../../containers/ssoMenu";
import {Autmation} from "../../../../constants/routes";
import MyRequest from "./requests/myrequest";
import CaseDetails from "./case/caseDetails";
import Casebycategory from "./requests/casebycategory";
import CaseSla from "./form/casesla";
import GetCategory from "./category/getcategory";
import Dashboard from "./dashboard/dashboard";
import CatDashboard from "./dashboard/categorydashboard";
import Kanban from "./requests/kanban";
import CaseByForm from "./requests/casebyform";

function Automation() {
    return (
        <div id="SerivcePage"  >
            <div className={"Menu "}>
                <MenuList page={Autmation}   />
            </div>
            <div className={"mainDiv"}>
                <SSOHeader  page={Autmation}  />
                <div className={"m-lg-4 mt-lg-4 m-0 mt-3"}>
                <Routes>
                    <Route path="Profile" element={<EmployeeProfile/>}   />
                    <Route path="/" element={<MyRequest/>}   />
                    <Route path="CaseDetails/:id" element={<CaseDetails/>}   />
                    <Route path="FormCase/:id" element={<CaseByForm/>}   />
                    <Route path="CategoryCase/:cid" element={<Casebycategory/>}   />
                    <Route path="CaseSla/:id" element={<CaseSla />}   />
                    <Route path="Category" element={<GetCategory />}   />
                    <Route path="DashBoard/:id" element={<Dashboard />}   />
                    <Route path="cDashBoard/:cid" element={<CatDashboard />}   />
                    <Route path="Kanban/:id" element={<Kanban />}   />

                </Routes>
                </div>
            </div>
        </div>
    );
}



export default Automation