import React, {useEffect, useRef, useState} from "react";
import DataTable from 'react-data-table-component'
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Tool, ChevronLeft, Trash, Edit, Eye, Layers} from 'react-feather';
import {authorities, checkAuthorities, websetting} from "../../../helpers/websetting";
import {SystemSetting} from "../../../constants/system";
import {useTranslation} from "react-i18next";

function ServiceUsed(){
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);
    let { id } = useParams();
    const t = useTranslation()

    const [validated, setValidated] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [info, setInfo] = useState(true);
    const [infoData, setInfoData] = useState({});
    const [Organization, setOrganization] = useState([]);
     const getOrg = () => {
        axios.get(websetting.apiUrl + 'auth/api/v1/organizations/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setOrganization(response.data.data);
            //setLoading(false);
        }).catch(function (error) {

        });
    }

    const columns = [
        {
            name: t.t("iot.global.organizationName"),
            selector: (row : any) => row.description,
        },{
            name: t.t("iot.global.name"),
            selector: (row : any) => row.name,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.trvId)} className={"rounded-circle"}><Trash size={15} /></Button> <Button onClick={()=>AddTrsServices(row.trvId)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button>   </div>,
        }
    ];
    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get( websetting.apiUrl + 'auth/api/v1/organizationServices/'+ id +'/byStpServiceId?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {
        });
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };
    useEffect(() => {
         getOrg()
        fetchDta(1); // fetch page 1 of users
    }, []);


    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                const formData = new FormData(event.target);
                if(event.target.uploadImage.value != ""){
                    const file = event.target.uploadImage.files[0];
                    formData.append('imageUrl', file, file.name);
                }

                formData.append('description', event.target.description.value);
                formData.append('isAuto', 'true');
                formData.append('name', event.target.name.value);
                formData.append('nameAr', event.target.nameAr.value);
                formData.append('nameHe', event.target.nameHe.value);
                formData.append('orderBy', event.target.orderBy.value != "" ? event.target.orderBy.value : 0);
                formData.append('stpServiceIdFk', id as string);
                formData.append('trvId', event.target.Id.value);
                formData.append('url', event.target.url.value);

                axios.put(websetting.apiUrl + 'auth/api/v1/organizationServices/'+event.target.Id.value,
                    formData,
                    {
                        method: "put",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    if(response.data.message != null){
                        alert(response.data.message)
                        setLoadingModal(false)
                        return
                    }
                    const index = data.findIndex( (array) => {
                        return parseInt(array["trvId"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].description = response.data.description
                    prevState[index].name = response.data.name

                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)

                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {

                const formData = new FormData(event.target);
                if(event.target.uploadImage.value != ""){
                    const file = event.target.uploadImage.files[0];
                    formData.append('imageUrl', file, file.name);
                }

                formData.append('description', event.target.description.value);
                formData.append('isAuto', 'true');
                formData.append('name', event.target.name.value);
                formData.append('nameAr', event.target.nameAr.value);
                formData.append('nameHe', event.target.nameHe.value);
                formData.append('orderBy', event.target.orderBy.value != "" ? event.target.orderBy.value : 0);
                formData.append('organizationIdFk', event.target.organizationIdFk.value);
                formData.append('stpServiceIdFk', id as string);
                formData.append('url', event.target.url.value);

                axios.post(websetting.apiUrl + 'auth/api/v1/organizationServices/',
                    formData,
                    {
                        method: "post",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    if(response.data.message != null){
                        alert(response.data.message)
                        setLoadingModal(false)
                        return
                    }


                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'auth/api/v1/organizationServices/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["trvId"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }

    const AddTrsServices = (id:any) => {
        setShow(true);
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.apiUrl + 'auth/api/v1/organizationServices/'+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }

    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div className={""}>
            <div className="content">
                <div className={"box"}>
                    <div className="pagetitle text-uppercase">
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Tool className={"feather"} size={21} />
                                {t.t("iot.global.organizationServices")}
                            </div>
                            <div className={"d-inline-flex align-items-center align-content-end"}>
                                <Button variant={"primary"} className={"mx-2"}   onClick={()=>{AddTrsServices(0)}}  >+ Add trs-Services</Button>
                                <Link to={"../Setting"} >{t.t("iot.global.back")} <ChevronLeft className={" feather feather-chevron-left"} /></Link>
                            </div>
                        </div>
                    </div>
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data} />
                </div>

            </div>
            <Modal size={"lg"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><Tool className={"feather"} /> {t.t("iot.global.stpServicesTitle")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>
                        {info ?
                            <div>{
                                loadingModal ? <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"} /></div> :

                                    <Row>
                                        <Form.Group hidden controlId="Id" >
                                            <Form.Control type={"hidden"}   value={infos.trvId}  />
                                        </Form.Group>
                                        <Col md={4}>
                                            <Form.Group className="my-2" controlId="name" >
                                                <Form.Label>{t.t("iot.global.name")} En:</Form.Label>
                                                <Form.Control type="text"   defaultValue={infos.name}  required aria-label={t.t("iot.global.name")} aria-describedby="msgInput1" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="my-2" controlId="nameAr">
                                                <Form.Label>{t.t("iot.global.name")} Ar:</Form.Label>
                                                <Form.Control type="text" defaultValue={infos.nameAr} required
                                                              aria-label={t.t("iot.global.name")} aria-describedby="msgInput2"/>
                                                <Form.Control.Feedback type="invalid" id="msgInput2">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group className="my-2" controlId="nameHe">
                                                <Form.Label>{t.t("iot.global.name")} He:</Form.Label>
                                                <Form.Control type="text" defaultValue={infos.nameHe} required
                                                              aria-label={t.t("iot.global.name")} aria-describedby="msgInput2"/>
                                                <Form.Control.Feedback type="invalid" id="msgInput2">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>


                                        {!infos.trvId &&
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="organizationIdFk">
                                                <Form.Label>{t.t("iot.global.organizationName")}:</Form.Label>
                                                <Form.Select required aria-label={t.t("iot.global.organizationName")}
                                                             aria-describedby="msgInput1">
                                                    {Object.keys(Organization).map((titles, i) => {
                                                        return (
                                                            <option
                                                                selected={infos.organizationIdFk == JSON.parse(JSON.stringify(Organization[i])).organizationIdPk ? true : false}
                                                                value={JSON.parse(JSON.stringify(Organization[i])).organizationIdPk}>{JSON.parse(JSON.stringify(Organization[i])).name}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msgInput1">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        }

                                        <Col lg={6}>
                                            <Form.Group className="my-2" controlId="orderBy" >
                                                <Form.Label>orderBy:</Form.Label>
                                                <Form.Control type={"number"}  defaultValue={infos.orderBy}  aria-label={"orderBy"}    aria-describedby="msgInput3" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput3" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group className="my-2" controlId="url" >
                                                <Form.Label>url:</Form.Label>
                                                <Form.Control type={"text"}  defaultValue={infos.url}  aria-label={"url"} required    aria-describedby="msgInput3" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput3" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                        <Col md={12}>
                                            <Form.Group className="my-2" controlId="uploadImage">
                                                <Form.Label>{"Upload Image"}:</Form.Label>
                                                <Form.Control  type={"file"} aria-label={"Upload Image"} />
                                            </Form.Group>
                                        </Col>

                                        <Col lg={12}>
                                            <Form.Group className="my-2" controlId="description" >
                                                <Form.Label>{t.t("iot.global.description")}:</Form.Label>
                                                <Form.Control required as={"textarea"} style={{ height: '80px' }} defaultValue={infos.description}  aria-label={t.t("iot.global.description")}    aria-describedby="msgInput3" />
                                                <Form.Control.Feedback type="invalid"  id="msgInput3" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                            }
                            </div>
                            :
                            <div>
                                {
                                    loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                        <div>
                                            <Table     hover>
                                                <tbody>
                                                <tr><td>Id</td><td>{infos.stpServiceIdPk}</td></tr>
                                                <tr><td>{t.t("iot.global.name")}</td><td>{infos.name}</td></tr>
                                                <tr><td>{t.t("iot.global.description")}</td><td>{infos.description}</td></tr>
                                                <tr><td>{t.t("iot.global.code")}</td><td>{infos.code}</td></tr>
                                                <tr><td>{t.t("iot.global.createdBy")}</td><td>{infos.createdBy}</td></tr>
                                                <tr><td>{t.t("iot.global.subscribeGateway")}</td><td>{infos.subscribeGateway}</td></tr>
                                                <tr><td>{t.t("iot.global.createdDate")}</td><td>{infos.createDate}</td></tr>
                                                <tr><td>{t.t("iot.global.updatedDate")}</td><td>{infos.updateDate}</td></tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                }
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}

                    </Modal.Footer>
                </Form>
            </Modal>

            </div>
    )
}
export default ServiceUsed;
