import React from "react";

function Dashboard(prop:any){
    return(
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle text-uppercase"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                         stroke-linejoin="round" className="feather feather-map-pin">
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                        <circle cx="12" cy="10" r="3"></circle>
                    </svg>
                    <span className={"d-inline-block mx-2"}>DEVICES LOCATION</span>
                    <div className={"mt-2"}>
                        <iframe width="100%" height="350"
                                src="https://maps.google.com/maps?width=100%&amp;height=400&amp;hl=en&amp;coord=32.719719,35.316925&amp;q=(Ram)&amp;ie=UTF8&amp;t=p&amp;z=11&amp;iwloc=A&amp;output=embed"
                                frameBorder="0" scrolling="no"  ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default  Dashboard