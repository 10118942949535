import React, {useRef, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";

function ChangePassword() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const [validated, setValidated] = useState(false);
    const usernameInput = useRef(null);
    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.target.formIdentify.focus();
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
        }
    };
    return (
        <div className={"horizontal"}>
            <div className="pageTitle d-flex justify-content-between mb-3">
                <h3 className={"d-inline-flex bold"}>החלפת סיסמה</h3>
                <div className={"float-end d-inline-flex  align-items-center   align-content-end"}>
                    <Link className={"py-2 DarkBtn-sm text-white mx-5"} to={"../ProfileForm"}>تعديل الملف الشخصي</Link>
                    <Link   className={" d-inline-flex"} to={"../Profile"}> الى الخلف
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         className="feather mx-1 feather-chevron-left">
                        <polyline points="15 18 9 12 15 6"></polyline>
                    </svg>
                </Link>
                </div>
            </div>

            <div className={"pt-5 text-center"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"
                     className="feather color1 feather-lock">
                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                </svg>
            </div>
            <Row className="justify-content-center py-5">
                <Col lg={5} md={6}>
                    <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                        <Form.Group className="pb-3"  >
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" placeholder="" autoComplete="off" required aria-label="Current Password" aria-describedby="currentPassword" />
                            <Form.Control.Feedback type="invalid"  id="currentPassword" >
                                Enter Current Password
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="pb-3"  >
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" placeholder="" autoComplete="off" required aria-label="New Password" aria-describedby="newPassword" />
                            <Form.Control.Feedback type="invalid"  id="newPassword" >
                                Enter New Password
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="pb-3"  >
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control type="password" placeholder=""  autoComplete="off" required aria-label="Confirm New Password" aria-describedby="newPassword2" />
                            <Form.Control.Feedback type="invalid"  id="newPassword2" >
                                Enter Confirm Password
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Button type="submit" className="DarkBtn w-100 mt-3">Save</Button>
                    </Form>
                </Col>
            </Row>


        </div>
    )
}
export default ChangePassword