import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Container, Form, FormLabel, Row, Spinner} from "react-bootstrap";
import LoginHeader from "./header";
import axios from "axios";
import {ClientCredentialsAuthToken, websetting} from "../../helpers/websetting";
import Login from "./login";
import {Link} from "react-router-dom";
import {useTitle} from "../../helpers/function";
import {useTranslation} from "react-i18next";
import {decode as base64_decode, encode as base64_encode} from 'base-64';

function PageContent(){
    const t = useTranslation()

    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const [done, setDone] = useState('');
    const [city, setCity] = useState([]);
    const [street, setStreet] = useState([]);
    const [personTitle, setPersonTitle] = useState([]);


    const [validated, setValidated] = useState(false);
    useTitle(websetting.title);

    const getPersonTitle = () => {
        axios.get(websetting.apiUrl + 'setup-service/public/api/v1/setup/PERSON_TITLE/domainValues',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'content-type': 'application/json',
                }
            }
        ).then(async function (response) {
            setPersonTitle(response.data)
        }).catch(function (error) {
        });
    }

    const getCity = () => {
        axios.get(websetting.apiUrl + '/setup-service/public/api/v1/setup/cities',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'content-type': 'application/json',
                }
            }
        ).then(async function (response) {
            setCity(response.data)
        }).catch(function (error) {
        });
    }

    const getStreet = (id:any) => {
        setStreet([])
        axios.get(websetting.apiUrl + '/setup-service/public/api/v1/setup/'+id+'/streets',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'content-type': 'application/json',
                }
            }
        ).then(async function (response) {
            setStreet(response.data)
        }).catch(function (error) {
        });
    }


    useEffect(()=>{
        getCity()
        getPersonTitle()
    },[localStorage.getItem("lang")])


    const handleSubmit = (event:any) => {
        const form = event.currentTarget;
         if (form.checkValidity() === false) {
            event.target.FName.focus();
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoading("True");
            setError("");
            setDone("");




            axios.post(websetting.apiUrl + 'auth/oauth/token',
                "grant_type=client_credentials",
                {
                    method: "post",
                    headers: {
                        'Authorization': 'Basic '+ base64_encode(ClientCredentialsAuthToken.ClientId+":"+ClientCredentialsAuthToken.Password)
                    }
                }
            ).then(async function (res) {

                axios.post(websetting.apiUrl + 'auth/public/api/v1/account/registration' ,
                    {
                        "contactByEmail": event.target.contactByEmail.value == 1 ? true : false,
                        "contactBySms" : event.target.contactByEmail.value == 1 ? false : true,
                        "cityIdFk": parseInt(event.target.City.value),
                        "streetIdFk": parseInt(event.target.Street.value),
                        "email": event.target.Email.value,
                        "firstName": event.target.FName.value,
                        "lastName": event.target.LName.value,
                        "homeNumber": event.target.Home.value,
                        "phone": event.target.Mobile.value,
                        "personTitle": event.target.Mr.value,
                        "idNumber": event.target.Ident.value,
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'accept': '*/*',
                            'Authorization': 'Bearer ' + res.data.access_token
                        }
                    }
                ).then(async function (response) {
                    if(response.data == true){
                        setLoading("")
                        setDone(t.t("TxtGoTo"));
                    }else{
                        setLoading("")
                        setError(response.data.errorCode);
                    }
                }).catch(function (errors) {
                    setLoading("")
                    alert(errors)
                });



            }).catch((error)=>{

            })

        }
    };

    return(
        <div className="formDiv slide-top">
                <LoginHeader />

                {done && <Alert className="text-center" variant="success">{done}</Alert>}
                {error && <Alert id="AlertMsg" className="text-center" variant="danger">{error}</Alert>}
                {loading && <div className="text-center"><Spinner animation="border" variant="secondary" role="status"><span
                    className="visually-hidden">انتظر من فضلك</span></Spinner></div>}


                {done &&
                <div className="text-center mt-4"><Link className="DarkBtn w-100" to="/"><span className="h5 font700">{t.t("iot.global.signIn")}</span></Link></div>
                }
                {!done &&
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                <div>

                    <Row>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="FName">
                                <FormLabel className={"m-0"}>{t.t("TxtFirstName")}</FormLabel>
                                <Form.Control type="text"   aria-required="true" required aria-label={t.t("TxtFirstName")} />
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="LName">
                                <FormLabel className={"m-0"}>{t.t("TxtFamilyName")}</FormLabel>
                                <Form.Control type="text"   aria-required="true" required aria-label={t.t("TxtFamilyName")}  />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="Mr">
                                <FormLabel className={"m-0"}>{t.t("TxtPrefix")}</FormLabel>
                                <Form.Select required   aria-label={t.t("TxtPrefix")}>
                                    {Object.keys(personTitle).map((titles, i) => {
                                        return (
                                            <option value={JSON.parse(JSON.stringify(personTitle[i])).value}>{JSON.parse(JSON.stringify(personTitle[i])).name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="Ident">
                                <FormLabel className={"m-0"}>{t.t("TxtIdNumber")}</FormLabel>
                                <Form.Control type="number"   aria-required="true" required aria-label={t.t("TxtIdNumber")} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="City">
                                <FormLabel className={"m-0"}>{t.t("TxtCity")}</FormLabel>
                                <Form.Select required   aria-label={t.t("TxtCity")} onChange={(e)=>{getStreet(e.currentTarget.value)}}>
                                    <option></option>
                                    {Object.keys(city).map((titles, i) => {
                                        return (
                                            <option value={JSON.parse(JSON.stringify(city[i])).cityIdPk}>{JSON.parse(JSON.stringify(city[i])).name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="Street">
                                <FormLabel className={"m-0"}>{t.t("TxtStreet")}</FormLabel>
                                <Form.Select required  aria-label={t.t("TxtStreet")}  >
                                    <option></option>
                                    {Object.keys(street).map((titles, i) => {
                                        return (
                                            <option value={JSON.parse(JSON.stringify(street[i])).streetIdPk}>{JSON.parse(JSON.stringify(street[i])).name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="Home">
                                <FormLabel className={"m-0"}>{t.t("TxtHomeNo")}</FormLabel>
                                <Form.Control type="number"   aria-required="true" required
                                              aria-label={t.t("TxtHomeNo")} />
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="Mobile">
                                <FormLabel className={"m-0"}>{t.t("TxtPhoneNumber")}</FormLabel>
                                <Form.Control type="tel"  aria-required="true" required
                                              aria-label={t.t("TxtPhoneNumber")} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col >
                            <Form.Group className="mt-2" controlId="Email">
                                <FormLabel className={"m-0"}>{t.t("LblEmailE")}</FormLabel>
                                <Form.Control type="email"  aria-required="true" required
                                              aria-label={t.t("LblEmailE")} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3" >
                            <div>{t.t("iot.global.getPasswordBy")}</div>
                            <Form.Group as={"span"} className="" controlId="contactByEmail">
                                <Form.Check type={"radio"}   inline value={1} aria-label={t.t("iot.global.email")} >
                                    <Form.Check.Label>{t.t("iot.global.email")}</Form.Check.Label>
                                    <Form.Check.Input name={"contactByEmail"}  defaultChecked  type={"radio"} value={1} />
                                </Form.Check>

                            </Form.Group>

                            <Form.Group as={"span"} className="" controlId="contactBySms" dir={"ltr"}>
                                <Form.Check type={"radio"}  inline value={2} aria-label={t.t("iot.global.sms")} >
                                    <Form.Check.Input name={"contactByEmail"}  type={"radio"}  value={2} />
                                    <Form.Check.Label>{t.t("iot.global.sms")}</Form.Check.Label>
                                </Form.Check>
                            </Form.Group>


                        </Col>
                    </Row>
                    <Button type="submit" className="DarkBtn w-100 mt-4 mb-4">{t.t("BtnLblFinish")}</Button>
                </div>
                </Form>
                }

        </div>
    )
}


function Registration(){
    return(
        <div className="h-100">
            <Container className="h-100" fluid={true}>
                <Row className="h-100" >
                    <Col className="mx-auto tra my-auto py-3"  xl={6} lg={6} md={6} >
                        <PageContent/>
                    </Col>
                    <Col className={" d-none d-md-block"} xl={6} lg={6} md={6}     style={{backgroundImage:'url('+window.location.origin+'/images/bg.jpg)',backgroundPosition:'fixed',backgroundRepeat:"no-repeat",backgroundSize:"cover"}} >
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Registration;
