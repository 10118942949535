import React, {useEffect, useState} from 'react';
import {Button, Col, Dropdown, DropdownButton, Form, Image, Modal, Row, Spinner, Table} from "react-bootstrap";
import {ChevronLeft, Folder, FolderPlus, Printer} from "react-feather";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {ModulaIds, websetting} from "../../../../../helpers/websetting";
import Moment from "moment";
import { Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,

} from 'chart.js';
import { Doughnut,Bar,Line } from 'react-chartjs-2';
import {useTranslation} from "react-i18next";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


function Dashboard() {
    const t = useTranslation()

    const [loading,setloading] = useState(true);
    const [category, setcategory] = useState([])
    const [form, setform] = useState([])
    const [report, setReport] = useState({name:t.t('LblReportWeekly'),id:1})
    const [reportId, setReportId] = useState({from:null,to:null})
    const [show, setShow] = useState(false)
    const [validated, setValidated] = useState(false)
    const [data, setdata] = useState([])
    let data1 = {
        labels: [t.t("TxtSlaUnCommitted"),t.t("TxtSlaCommitted")],
        datasets: [
            {
                data: [],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],

                borderWidth: 1,
            },
        ],
    };
    const [SLA_Data, setsladata] = useState( data1)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right' as const,
                display: false,
            },
            title: {
                display: true,
            },
        },
    };

    const options2 = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right' as const,
                display: true,
            },
            title: {
                display: true,
            },
        },
    };



    let data2 = {
        labels: [],
        datasets: [],
    };
    const [Service_Data, setserviceData] = useState( data2)



    let data3 = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],

                borderWidth: 1,
            },
        ],
    };
    const [usersData, setUserData] = useState( data3)



    const handleClose = () => {
        setShow(false);
    }

    let {id} = useParams()
    let {cid} = useParams()
    let categoryId = "";
    let firstday = ""
    let lastday = ""



    useEffect(() => {


        axios.get(websetting.automationUrl + 'AutoForm/GetFormWithCategory/' + id + "?module=" + ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }).then(async function (response) {
            setcategory(response.data)
        }).catch(function (error) {
        });

        GetData(report)


    }, [localStorage.getItem("lang"),id]);



    
    const handleSubmit = (e:any) => {
      e.preventDefault()
        const x = t.t("LblFormDate") + " " +e.currentTarget.fromdate.value+  " " + t.t("LblToDate") + " " +e.currentTarget.todate.value
        setShow(false)
        firstday = Moment(e.currentTarget.fromdate.value).format(websetting.dateFormat)
        lastday = Moment(e.currentTarget.todate.value).format(websetting.dateFormat)
        GetData({name:x,id:4})
    }

    const GetData = (d:any) => {
        setReport(d)
        if(d.id == 1){
            var curr = new Date;
            var first = curr.getDate() - curr.getDay();
            var last = first - 6;
            firstday = Moment(new Date(curr.setDate(last)).toUTCString()).format(websetting.dateFormat);
            lastday = Moment(new Date(curr.setDate(first)).toUTCString()).format(websetting.dateFormat);
        }else if(d.id == 2){
            var curr = new Date;
            var first = curr.getDate() - curr.getDay();
            var last = first - 30;
            firstday = Moment(new Date(curr.setDate(last)).toUTCString()).format(websetting.dateFormat);
            lastday = Moment(new Date(curr.setDate(first)).toUTCString()).format(websetting.dateFormat);
        }else if(d.id == 3){
            var curr = new Date;
            var first = curr.getDate() - curr.getDay();
            var last = first - 365;
            firstday = Moment(new Date(curr.setDate(last)).toUTCString()).format(websetting.dateFormat);
            lastday = Moment(new Date(curr.setDate(first)).toUTCString()).format(websetting.dateFormat);
        }

        setloading(true);
        axios.get(websetting.automationUrl + 'AutoDashboardForm/GetGeneral/GetGeneral?FormId='+id+'&StartDate='+firstday+'&EndDate='+lastday+'&module='+ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setdata(response.data)
            const result1 = [] as  any;
            result1.push(response.data.slaAcutal.counterCommitted)
            result1.push(response.data.slaAcutal.counterNotCommitted)
            data1.datasets[0].data = result1
            setsladata(data1)
            const result2 = [] as  any;
            const label2 = [] as  any;
            for(var i=0;i < response.data.requestStatus.dates.length;i++){
                label2.push(response.data.requestStatus.dates[i])
            }
            data2.labels = label2
            for(var i=0;i < response.data.requestStatus.status.length;i++) {
                let innerData = [] as  any;
                for(var ii=0;ii < response.data.requestStatus.status[i].details.length;ii++) {
                    innerData.push(response.data.requestStatus.status[i].details[ii].count)
                }
                const d = {label: response.data.requestStatus.status[i].name, data: innerData,borderColor:response.data.requestStatus.status[i].color, backgroundColor: response.data.requestStatus.status[i].color,pointRadius: 4,fill: false,borderWidth: 1,lineTension: 0} as  any;
                result2.push(d)
            }

            data2.datasets = result2
            setserviceData(data2)

            const label3 = [] as  any;
            const result3 = [] as  any;
            for(var i=0;i < response.data.counterForUser.length;i++){
                label3.push(response.data.counterForUser[i].employeeName)
                result3.push(response.data.counterForUser[i].counter)
            }
            data3.labels = label3
            data3.datasets[0].data = result3
            setUserData(data3)

            setloading(false);
        }).catch(function (error) {
        });
    }

    const info =  JSON.parse(JSON.stringify(category))
    const forms =  JSON.parse(JSON.stringify(form))
    const allData =  JSON.parse(JSON.stringify(data))

    return(
        <div>
            {loading ? <div className={"mt-5 text-center"}><Spinner className={"mt-5 text-center"} title={"Please Waite"} animation="border"
                                                                    variant={"warning"}></Spinner></div> :

                <div>
                    <div className={"text-end mb-2"}>
                        <DropdownButton className={"ms-1 btns"} variant={"danger"} title={report.name}>
                            <div>
                                <Dropdown.Item onClick={() => {
                                    GetData({name: t.t("LblReportWeekly"), id: 1})
                                }}>{t.t("LblReportWeekly")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    GetData({name: t.t("LblReportMonthly"), id: 2})
                                }}>{t.t("LblReportMonthly")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    GetData({name: t.t("LblReportAnnual"), id: 3})
                                }}>{t.t("LblReportAnnual")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setShow(true);
                                }}>{t.t("LblFromToDate")}</Dropdown.Item>
                            </div>
                        </DropdownButton>
                    </div>
                    <Row>
                        <Col lg={6} md={12}>
                            <div className={"newBox  d-inline-block w100 h100 pt-4  position-relative mb-4"}
                                 style={{height: "100px"}}>

                                <div className={"align-middle px-3"}>
                                    {category &&
                                    <span className={"d-inline-flex text-black-50 align-items-center h6"}>

                                        <div>
                                            <div className={""}>
                                                 <Link to={"../CategoryCase/" + info.categoryId}>{info.categoryName}</Link>
                                                  <span>
                                    <ChevronLeft size={13}/>
                                    <Link
                                        to={"../FormCase/" + info.formId + "?categoryId=" + info.categoryId}>{info.formName}</Link>
                                    </span>
                                             </div>
                                         </div>

                                    </span>
                                    }


                                    <div className={"h5 bold"}>{report.name}</div>

                                </div>

                                <div className={"position-absolute me-2 end-0 bottom-0"} style={{width: "30%"}}>
                                    <Image
                                        srcSet={"http://saasdemo.smsm-it.com/Content/Automation/images/automationIcon/man.svg"}
                                        width={"100%"}/>
                                </div>
                            </div>
                            <Row className={"text-center"}>
                                <Col>
                                    <div className={"newBox py-4 px-2"}>
                                        <Image
                                            srcSet={"http://saasdemo.smsm-it.com/Content/Automation/images/mainIcon1.svg"}
                                            width={"70"}/>
                                        <div
                                            className={"h1 my-2 bold"}>{allData.counterForRequest && allData.counterForRequest.allRequest}</div>
                                        <div className={"h5 mt-2 bold"} style={{color: "#787389"}}>{t.t("LblAllRequest")}</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"newBox py-4 px-2"}>
                                        <Image
                                            srcSet={"http://saasdemo.smsm-it.com/Content/Automation/images/mainIcon2.svg"}
                                            width={"70"}/>
                                        <div
                                            className={"h1 my-2 bold"}>{allData.counterForRequest && allData.counterForRequest.openedRequest}</div>
                                        <div className={"h5 mt-2 bold"} style={{color: "#787389"}}>{t.t("LblOpenedRequest")}</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"newBox py-4 px-2"}>
                                        <Image
                                            srcSet={"http://saasdemo.smsm-it.com/Content/Automation/images/mainIcon3.svg"}
                                            width={"70"}/>
                                        <div
                                            className={"h1 my-2 bold"}>{allData.counterForRequest && allData.counterForRequest.closedRequest}</div>
                                        <div className={"h5 mt-2 bold"} style={{color: "#787389"}}>{t.t("LblClosedRequest")}</div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"newBox py-4 px-2"}>
                                        <Image
                                            srcSet={"http://saasdemo.smsm-it.com/Content/Automation/images/mainIcon3.svg"}
                                            width={"70"}/>
                                        <div
                                            className={"h1 my-2 bold"}>{allData.counterForRequest && allData.counterForRequest.pendingRequest}</div>
                                        <div className={"h5 mt-2 bold"} style={{color: "#787389"}}>{t.t("LblPendingRequest")}</div>
                                    </div>
                                </Col>
                            </Row>


                        </Col>
                        <Col lg={6} md={12}>
                            <div className={"newBox p-2 py-3"}>
                                <div className={"pagetitle d-flex justify-content-between  text-uppercase"}>
                                    <span className={"d-inline-flex mx-2"}>{t.t("LblRequestOnStatus")}</span>
                                    <div className={"d-inline-flex "}>
                                        <div className={"  position-relative     "}>
                                            <a href={"#"}><Printer className={"feather"} size={18}/></a>
                                        </div>
                                    </div>
                                </div>

                                <div className={"mx-auto text-center"}>
                                    <div className={"d-inline-block w-100"}  >
                                    <Line options={options2} data={Service_Data} height={108} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className={"my-3"}>
                        <Col lg={8} md={12}>
                            <div className={"newBox p-2 py-3"}>
                                <div className={"pagetitle d-flex justify-content-between  text-uppercase"}>
                                    <span className={"d-inline-flex mx-2"}>{t.t("LblRequestUser")}</span>
                                    <div className={"d-inline-flex "}>
                                        <div className={"  position-relative     "}>
                                            <a href={"#"}><Printer className={"feather"} size={18}/></a>
                                        </div>
                                    </div>
                                </div>

                                <div className={"mx-auto text-center"}>
                                    <div className={"d-inline-block w-100"}  >
                                        <Bar options={options} data={usersData} height={100} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={12}>
                            <div className={"newBox p-2 py-3"}>
                                <div className={"pagetitle d-flex justify-content-between  text-uppercase"}>
                                    <span className={"d-inline-flex mx-2"}>{t.t("LblSla")}</span>
                                    <div className={"d-inline-flex "}>
                                        <div className={"  position-relative     "}>
                                            <a href={"#"}><Printer className={"feather"} size={18}/></a>
                                        </div>
                                    </div>
                                </div>
                                <div className={"mx-auto text-center"}>
                                    <div className={"d-inline-block"} style={{width: "330px", height: "330px"}}>
                                        {SLA_Data &&
                                        <Doughnut data={SLA_Data}/>
                                        }

                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
            }
            <Modal size={"lg"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><Folder className={"feather"} /> {t.t("LblFromToDate")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={(e)=>{handleSubmit(e)}}>
                    <Modal.Body>
                            <div>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="fromdate" >
                                                <Form.Label>{t.t("LblFormDate")}:</Form.Label>
                                                <Form.Control type={"date"} required aria-label="from date" aria-describedby="msgInput5"/>
                                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="todate" >
                                                <Form.Label>{t.t("LblToDate")}:</Form.Label>
                                                <Form.Control type={"date"} required aria-label="to date" aria-describedby="msgInput5"/>
                                                <Form.Control.Feedback type="invalid"  id="msgInput5" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"}  variant="warning">{t.t("BtnShowResult")}</Button>}

                    </Modal.Footer>
                </Form>
            </Modal>



        </div>
    )
}
export default Dashboard