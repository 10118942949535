import React, {useEffect, useState} from "react";
import {Edit, Eye, Trash, Trash2, Truck} from "react-feather";
import {Button, Spinner} from "react-bootstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import {websetting} from "../../../../../../helpers/websetting";

function VehiclesType(prop:any){
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [historydata, setHistorydata] = useState([]);
    const [taskdata, setTaskdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const del = (id:any) => {
    }
    const showInfo = (id:any) => {
    }
    const edit = (id:any) => {
    }

    const columns = [
        {
            name: 'Name',
            selector: (row : any) => row.node.name,
        },{
            name: 'Address',
            selector: (row : any) => row.node.name,
        },{
            name: 'Inquiry Type',
            selector: (row : any) => <div>{row.node.inquiryTyping && row.node.inquiryTyping["name"]}</div>,
        },{
            name: 'Status',
            selector: (row : any) => row.node.status,
        },{
            name: 'Priority',
            selector: (row : any) => row.node.priority,
        },{
            name: 'Create At',
            selector: (row : any) => row.node.createdAt,
        },{
            name: 'DeadLine',
            selector: (row : any) => row.node.deadline,
        },{
            name: 'Actions',
            cell : (row : any) => <div><Button variant={"danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button> <Button onClick={()=>edit(row.id)}  variant={"info"} className={"rounded-circle"}><Edit size={15} /></Button>  </div>,
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.apiUrl + 'waste-service/api/v1/wastehero/inquiries/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.edges);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    useEffect(() => {
        //fetchDta(1); // fetch page 1 of users
    }, []);

    return(
        <div className={"lightbox"}>
            <div className={"pagetitle text-uppercase"}>
                <Truck className={"feather"} />
                <span className={"d-inline-block text-uppercase mx-2"}>Vehicles Type</span>
            </div>
            <hr className={"shodow-hr"} />
            <div>
                {
                    loading ? <div className={"text-center"}><Spinner animation={"border"} className={"my-5 "} variant={"warning"} /></div> : <DataTable pagination paginationPerPage={10} columns={columns} data={data} />
                }
            </div>
        </div>

    );
}



export default VehiclesType