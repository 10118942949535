import React, {useEffect, useState} from "react";
import {Col, Image, Nav, Row, Tab} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Organizations from "./organizations";
import Users from "./users";
import Permission from "./permissions";
import Roles from "./roles";
import {authEnum, checkAuthorities, UserProfile} from "../../../helpers/websetting";

function UserManagement(){
    const  t = useTranslation()
    const tab = React.createRef<HTMLTextAreaElement>();
    const [page,setPage] = useState("user")
    const openPage = (page:any) => {
        setPage(page)
    }
    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const tabLink = params.get('tab') || "";
        if(document.getElementById("left-tabs-example-tab-"+tabLink)?.id != null){
            document.getElementById("left-tabs-example-tab-"+tabLink)?.click()
        }else{
            tab.current?.click()
        }
    }, [localStorage.getItem("lang")]);

    return(
        <div  className={"content"}>
            <Row>
                <Col className={"justify-content-center align-items-center align-content-center"}>
                    <Image srcSet={window.location.origin + "/images/icon4.svg"} height={40} />
                    <span className={"pagetitle text-uppercase d-inline-block pt-2 px-2"}>{t.t("iot.global.userManagement")}</span>
                </Col>
            </Row>

            <Tab.Container id="left-tabs-example" defaultActiveKey="user">
                <Row>
                    <Col sm={12}>
                        <div className={"position-relative my-3"}>
                            <div className={"lightbox position-relative newTab"}>
                                <div className={"selector"}></div>

                                <Nav    >
                                    <Nav.Item as={"li"}>
                                        <Nav.Link  ref={tab as React.RefObject<any>}  onClick={()=> openPage("user")} eventKey="user">{t.t('iot.global.userTitle')}</Nav.Link>
                                    </Nav.Item>
                                    {checkAuthorities(authEnum.VIEW_ROLE) &&
                                    <Nav.Item as={"li"}>
                                        <Nav.Link onClick={() => openPage("role")}
                                                  eventKey="role">{t.t('iot.global.roleTitle')}</Nav.Link>
                                    </Nav.Item>
                                    }
                                    {checkAuthorities(authEnum.VIEW_PERMISSION) &&
                                    <Nav.Item as={"li"}>
                                        <Nav.Link onClick={() => openPage("permission")}
                                                  eventKey="permission">{t.t('iot.global.permissionTitle')}</Nav.Link>
                                    </Nav.Item>
                                    }
                                    {checkAuthorities(authEnum.VIEW_ORGANIZATION) &&
                                    <Nav.Item as={"li"}>
                                        <Nav.Link onClick={() => openPage("organization")}
                                                  eventKey="organization">{t.t('iot.global.organization')}</Nav.Link>
                                    </Nav.Item>
                                    }
                                </Nav>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <hr className={"my-1 shodow-hr"} />
                    </Col>
                    <Col sm={12}>

                        <div className={"p-1"} >
                            {page == "organization" &&  <Organizations   />}
                            {page == "user" &&  <Users  />}
                            {page == "role" &&  <Roles />}
                            {page == "permission" &&  <Permission />}
                        </div>

                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
}

export default UserManagement;
