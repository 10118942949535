import {Edit, FilePlus, FileText, PhoneCall, Trash} from "react-feather";
import {Button, Col, Form, Modal, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {Ref, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { editoroptions,  websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {UploadCMS} from "../../../../../helpers/function";
import SunEditor  from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {SystemSetting} from "../../../../../constants/system"; // Import Sun Editor's CSS File

function CMSForms(){
    const t = useTranslation()


    const [validated, setValidated] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);

    const [descriptionHe, setdescriptionHe] = useState('');
    const [descriptionAr, setdescriptionAr] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [cat, setCat] = useState([]);


    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
    }


    const edit = (id:any) => {
        setdescriptionHe('')
        setdescriptionAr('')

        setShow(true);
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.cmsUrl + 'CmsOnlineForm/Get?id='+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
                setdescriptionHe(response.data.descriptionHe)
                setdescriptionAr(response.data.descriptionAr)
            }).catch(function (error) {
            });
        }
    }

    const columns = [
        {
            width:"50px",
            sortable: true,
            name:  "#",
            selector: (row : any) => row.id,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.formName"),
            selector: (row : any) => row.title,
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button>   <Button onClick={()=>edit(row.id)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button></div>,
        }
    ]


    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
        }
    }
    const SubmitSearch = (e:any) => {
        e.preventDefault()
        if(e.target.input.value !== searchtxt){
            setSearchtxt(e.target.input.value)
        }
    }

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.cmsUrl + 'CmsOnlineForm/Delete?id=' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }




    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;



            const formData = new FormData(event.target);
            if(event.target.File.value != ''){
                const file = event.target.File.files[0];
                formData.append('File', file, file.name);
            }
            formData.append('TitleHe', event.target.TitleHe.value);
            formData.append('TitleAr', event.target.TitleAr.value);
            formData.append('CategoryId', event.target.CategoryId.value);
            if(event.target.Outlink.value != ""){
                formData.append('Outlink', event.target.Outlink.value);
            }
            if(event.target.OrderNo.value != "") {
                formData.append('OrderNo', event.target.OrderNo.value);
            }

            if(event.target.Id.value > 0){
                formData.append('Id', PKid);

                axios.put(websetting.cmsUrl + 'CmsOnlineForm/Put',
                    formData,
                    {
                        method: "put",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["id"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].title = response.data.title

                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {

                axios.post(websetting.cmsUrl + 'CmsOnlineForm/AddForm',
                    formData,
                    {
                        method: "post",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };

    const [totalRows, setTotalRows] = useState(0);
    const [rowperPage, setrowperPage] = useState(SystemSetting.rowInPage);
    const [currpage, setcurrpage] = useState(1);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsOnlineForm/GetForms?rows=' + rowperPage + "&page=" + page + (searchtxt != "" ? "&search="+searchtxt : ""),
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            setLoading(false);
        }).catch(function (error) {

        });
    };

    const handlePageChange = (page :any) => {
        setcurrpage(page)
        setLoading(true);

        fetchDta(page)
    };
    const handlePerRowsChange = async  (newPerPage:any, page:any) => {
        setLoading(true);
        setrowperPage(newPerPage)
        fetchDta(page)
    }


        const GetCats = async () => {
        axios.get(websetting.cmsUrl + 'CmsCategory/GetCategorys?type=2',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setCat(response.data);
        }).catch(function (error) {

        });
    };



    useEffect(() => {
        GetCats()
        fetchDta(1); // fetch page 1 of users
    }, [localStorage.getItem("lang") ,searchtxt]);
    const infos =  JSON.parse(JSON.stringify(infoData))




    return(
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                    <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <FileText className={"feather mx-2"} /> {t.t("cms.web.forms")}
                     </span>
                    </div>
                    <span className={"d-inline-flex align-items-center mx-2"}>

                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                        <Form  onSubmit={(e)=>{SubmitSearch(e)}}  action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                    </div>

                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><FilePlus /> {t.t("cms.addForm")}</Button>
                 </span>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationPerPage={rowperPage}
                               paginationTotalRows={totalRows}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data} />
                </div>
            </div>


            <Modal size={"xl"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><FileText className={"feather"} /> {t.t("cms.web.forms")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>
                                    <Form.Group hidden controlId="FileUrl">
                                        <Form.Control type={"hidden"} value={infos.imageUrl}/>
                                    </Form.Group>
                                    <Col md={6}>
                                    <Form.Group className="my-2" controlId="TitleHe">
                                        <Form.Label>{t.t("cms.formName")} - {t.t("cms.he")}:</Form.Label>
                                        <Form.Control type="text" defaultValue={infos.titleHe} required
                                                      aria-label={t.t("cms.formName")+ " - " +t.t("cms.he")}
                                                      aria-describedby="msg_titleHe"/>
                                        <Form.Control.Feedback type="invalid" id="msg_titleHe">
                                            Required Field
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="TitleAr">
                                            <Form.Label>{t.t("cms.formName")} - {t.t("cms.ar")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.titleAr} required
                                                          aria-label={t.t("cms.formName") + " - " + t.t("cms.ar")}
                                                          aria-describedby="msg_titleHe" />
                                            <Form.Control.Feedback type="invalid" id="msg_titleHe">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2 mt-4" controlId="CategoryId">

                                            <Form.Label className="my-0">{t.t("cms.category")}:</Form.Label>
                                            {cat &&
                                            <Form.Select required>
                                                {
                                                    cat.map((value) => {
                                                        return (
                                                            <option selected={infos.categoryId == value['id']  ? true : false }  value={value['id']}>{value['name']}</option>
                                                        )
                                                    })
                                                }

                                            </Form.Select>
                                            }
                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2 mt-4" controlId="OrderNo">
                                            <Form.Label className="my-0">{t.t("cms.order")}:</Form.Label>
                                            <Form.Control type="number" defaultValue={infos.orderNo}
                                                          aria-label={t.t("cms.order")}
                                                          aria-describedby="msg_file"/>
                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2 mt-4" controlId="Outlink">
                                            <Form.Label className="my-0">{t.t("cms.outLink")}:</Form.Label>
                                            <Form.Control type="url" defaultValue={infos.outlink}
                                                          aria-label={t.t("cms.outLink")}
                                                          aria-describedby="msg_file"/>
                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2 mt-4" controlId="File">
                                            <Form.Label className="my-0">{t.t("cms.attachFiles")}:</Form.Label>
                                            <Form.Control type="file"
                                                          aria-label={t.t("cms.attachFiles")}
                                                          aria-describedby="msg_file"/>
                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>




                                </Row>
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    )
}

export default CMSForms;
