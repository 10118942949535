import React, {useEffect, useRef, useState} from "react";
import DataTable from 'react-data-table-component'
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {Trash, Edit, Eye, Tool, Lock, Users, ChevronLeft} from 'react-feather';
import {authEnum, checkAuthorities, UserProfile, websetting} from "../../../helpers/websetting";
import {SystemSetting} from "../../../constants/system";
import {useTranslation} from "react-i18next";

function ViewUserRoles(){
    const t = useTranslation()
    let {id} = useParams()
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [users, setUsers] = useState([]);

    const [show, setShow] = useState(false);
    const [info, setInfo] = useState(true);
    const [infoData, setInfoData] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }

    const handleShow = () => {
        setShow(true);
    }



    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            axios.post(websetting.apiUrl + 'auth/api/v1/roleUsers/',
                    {
                        "roleIdFk": id,
                        "userIdFk": event.target.parentId.value,
                        /*"remarks": event.target.remarks.value,*/
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                    userAvailable()
                }).catch(function (error) {
                    setLoadingModal(true)
                });

        }
    };

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'auth/api/v1/roleUsers/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["roleUserIdPK"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }

    const edit = (id:any) => {
        setShow(true);
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.apiUrl + 'auth/api/v1/roleUsers/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }



    const columns = [
        {
            name: t.t("iot.global.username"),
            selector: (row : any) => row.userUsername,
        },{
            name: t.t("iot.global.name"),
            selector: (row : any) => row.userFirstName + " " + row.userLastName ,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div>{checkAuthorities(authEnum.DELETE_ROLE_USER) && <Button variant={"outline-danger"} onClick={()=>del(row.roleUserIdPK)} className={"rounded-circle"}><Trash size={15} /></Button>}</div>
        }
    ];


    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'auth/api/v1/roleUsers/'+id+'/users/?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {

        });
    };


    const userAvailable = () => {
        if(UserProfile.username != "") {
            axios.get(websetting.apiUrl + 'auth/api/v1/user/' + UserProfile.username + '/' + id + '/available',
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setUsers(response.data);
            }).catch(function (error) {

            });
        }
    }

    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };

    useEffect(() => {
        userAvailable()
        fetchDta(1); // fetch page 1 of users
    }, []);

    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div className={""}>
            <div className="content">
                <div className={"box"}>
                    <div className="pagetitle text-uppercase">
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Tool className={"feather"} size={21} />
                                {t.t("iot.global.roleUsers")}
                            </div>
                            <div className={"d-inline-flex align-content-end"}>
                                {checkAuthorities(authEnum.ADD_ROLE_USER) &&
                                    <Button size={"sm"} variant={"info"} className={"mx-3 py-1 bold"}
                                        onClick={() => edit(0)}>+ {t.t("iot.global.newRoleUser")}</Button>
                                }
                                <Link to={"../userManagement?tab=role"} >{t.t("iot.global.back")} <ChevronLeft className={" feather feather-chevron-left"} /></Link>
                            </div>
                        </div>

                    </div>
                    {checkAuthorities(authEnum.VIEW_ROLE_USERS) &&
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows}
                               progressComponent={<Spinner className={"my-5"} animation={"border"} variant={"warning"}
                                                           title={"Please Wait"}/>}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data}/>
                    }

                </div>
            </div>

            <Modal size={"lg"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><Tool className={"feather"} /> {t.t("iot.global.roleUsers")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>
                        {info ?
                            <div>{
                                loadingModal ? <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"} /></div> :

                                    <Row>
                                        <Form.Group hidden controlId="Id" >
                                            <Form.Control type={"hidden"}   value={infos.roleUserIdPK}  />
                                        </Form.Group>


                                        <Col md={12}>
                                            <Form.Group className="my-2" controlId="parentId" >
                                                <Form.Label>{t.t("iot.global.username")}:</Form.Label>
                                                <Form.Select defaultValue={infos.userIdFk != null && infos.userIdFk} required aria-label={t.t("iot.global.username")} aria-describedby="msgInput2">
                                                    <option></option>
                                                    {Object.keys(users).map((titles, i) => {
                                                        return(
                                                            <option  value={JSON.parse(JSON.stringify(users[i])).userIdPk} >{JSON.parse(JSON.stringify(users[i])).firstName + " " + JSON.parse(JSON.stringify(users[i])).lastName}</option>
                                                        );
                                                    })}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid"  id="msgInput2" >
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                    </Row>
                            }
                            </div>
                            :
                            <div>
                                {
                                    loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                        <div>


                                        </div>
                                }
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"}  variant="warning">{t.t("iot.global.save")}</Button>}

                    </Modal.Footer>
                </Form>
            </Modal>

        </div>
    )
}
export default ViewUserRoles;
