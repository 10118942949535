import React, {useEffect, useState} from 'react';
import {authorities, modules, UserProfile, websetting} from "../../helpers/websetting";
import {Button, Col, Container, Dropdown, Image, Modal, Offcanvas, Row, Spinner} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {hexToRgbA, UseLang, useTitle} from "../../helpers/function";
import '../../assets/css/serives.css'
import {Link, Route, Routes} from "react-router-dom";
import ServiceHome from "./Pages/IOT/Home";
import {Autmation, Cms, domain, IOT} from "../../constants/routes";
import Automation from "./Pages/Automation/Home";
import {useTranslation} from "react-i18next";
import {
    AlertTriangle,
    Bell,
    Calendar,
    Eye,
    Mail,
    Settings
} from "react-feather";
import Moment from "moment";
import i18n from "i18next";
import axios from "axios";
import {SystemSetting} from "../../constants/system";
import DataTable from "react-data-table-component";
import CMS from "./Pages/CMS/Home";

function SerivesPage() {
    const [loading,setLoading] = useState(false)
    const [webSetting, setwebSetting] = useState(websetting);

    useEffect(()=>{
        setLoading(true)
        if(UserProfile.organizationId != null  && UserProfile.organizationId != "") {
            axios.get(websetting.apiUrl + 'auth/api/v1/organizationSettings/' + UserProfile.organizationId + '/allSettings',
                {
                    method: "get",
                    headers: {
                        'content-type': "multipart/form-data",
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {

                const orgIndex = response.data.findIndex((array: any) => {
                    return array["key"] === "orgName"
                });
                const sysIndex = response.data.findIndex((array: any) => {
                    return array["key"] === "sysName"
                });
                const logoIndex = response.data.findIndex((array: any) => {
                    return array["key"] === "Logo"
                });
                const coverIndex = response.data.findIndex((array: any) => {
                    return array["key"] === "Cover"
                });


                i18n.addResourceBundle('he', 'translation', {
                    "iot.global.smartCityApp": response.data[sysIndex].valueHe
                });
                i18n.addResourceBundle('ar', 'translation', {
                    "iot.global.smartCityApp": response.data[sysIndex].valueAr
                });
                i18n.addResourceBundle('en', 'translation', {
                    "iot.global.smartCityApp": response.data[sysIndex].valueEn
                });


                websetting.orgLogo = SystemSetting.publicUploadImage + response.data[logoIndex].valueEn
                websetting.coverImage = SystemSetting.publicUploadImage + response.data[coverIndex].valueEn
                websetting.title = response.data[sysIndex].valueEn

                //if(UserProfile.userType == "ADMIN")

                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
            });
        }else{
            setLoading(false);
        }
    },[webSetting])

    return (
        <div className={"h-100"}>
            {loading ?
                <div  className={"h-100 w-100"}><Row className={"h-100 justify-content-center gx-0 align-items-center"}><Col className={"text-center"}><Spinner   animation={"border"} variant={"warning"}  /></Col></Row></div>
                :
            <Routes>
                <Route path="/" element={<AllServices coverImage={webSetting.coverImage} orgLogo={webSetting.orgLogo}  />} />
                <Route path={IOT + "/*"} element={<ServiceHome/>}   />
                <Route path={Autmation + "/*"} element={<Automation />}   />
                <Route path={Cms + "/*"} element={<CMS />}   />

            </Routes>
            }
        </div>
    );
}


function AllServices(props:any){
    const [loading, setLoading] = useState(false);
    const t = useTranslation()
    const [show, setShow] = useState(false);
    const [data,setdata] = useState([])
    const [org,setOrg] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);


    const [showNotifcation, setShowNotifcation] = useState(false);
    const handleCloseNotifcation = () => setShowNotifcation(false);
    const handleShowNotifcation = () => setShowNotifcation(true);
    const [notifcation, setNotifcation] = useState([]);
    const [unreadNotifcation, setunreadNotifcation] = useState(0);
    const [loadingModal, setLoadingModal] = useState(false);
    const [code, setSelectedCode] = useState("");

    useEffect(() => {
        if(UserProfile.username != ""){
            axios.get(websetting.apiUrl  +'auth/api/v1/notifications/'+UserProfile.username+'/allNotifications',
                {
                    method: "GET",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setNotifcation(response.data)

                const unread = response.data.filter((array:any) => {
                    return array["isRead"] !== true
                });
                setunreadNotifcation(unread.length)
            }).catch(function (error) {
            });
        }

        setLoading(true);
        if(UserProfile.userType == "ADMIN"){
            axios.get(websetting.apiUrl + 'setup-service/api/v1/stpServices/',
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': "multipart/form-data",
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setdata(response.data.servicesList)
                modules.pop()
                modules.push(response.data.servicesList)
                setLoading(false);
            }).catch(function (error) {
                setLoading(false);
            });
        }else{
            if(UserProfile.organizationId != null  && UserProfile.organizationId != "") {
                axios.get(websetting.apiUrl + 'auth/api/v1/home/'+UserProfile.organizationId,
                    {
                        method: "get",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': "multipart/form-data",
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setdata(response.data)
                    modules.pop()
                    modules.push(response.data)
                    setLoading(false);
                }).catch(function (error) {
                    setLoading(false);
                });
            }
        }
    }, [localStorage.getItem("lang")]);

    const ShowNotifcation = () => {
        setShowNotifcation(true)
        axios.get(websetting.apiUrl  +'auth/api/v1/notifications/'+UserProfile.username+'/allNotifications',
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setNotifcation(response.data)

            const unread = response.data.filter((array:any) => {
                return array["isRead"] !== true
            });

            setunreadNotifcation(unread.length)
        }).catch(function (error) {
        });
    }

    const markasRead = (id:any) => {
        setShowNotifcation(false)
        axios.put(websetting.apiUrl  +'auth/api/v1/notifications/'+id,
            {
                "notificationId": id
            },{
                method: "put",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Accept': 'application/json',
                    'Authorization': 'bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
        }).catch(function (error) {
        });
    }


    useTitle(websetting.title);

    const ClearSession = () => {
        sessionStorage.clear();
        window.location.href = domain;
    }
    const handleClose = () => {
        setShow(false);
        setLoadingModal(false)
    }
    const showTab = (code:any) => {
        setSelectedCode(code)
        if(UserProfile.userType == "ADMIN") {
            setShow(true);
            if(code != ""){
                fetchDta(1,code);
            }
        }
    }

    const fetchDta =  (page:any,codeVal:any) => {
        setLoadingModal(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'auth/api/v1/organizationServices/' + codeVal + '/getServiceCustomers?pageNO=' + p + '&pageSize=' + perPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
            ).then(async function (response) {
                setOrg(response.data.data);
                setLoadingModal(false);
                setTotalRows(response.data.recordsTotal)
            }).catch(function (error) {
            });
    }
    const handlePageChange = (page :any) => {
        fetchDta(page,code);
    }
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        setPerPage(newPerPage);
        fetchDta(page,code)
    }

    const columns = [
        {
            name: t.t("iot.global.name"),
            selector: (row : any) => row.organizationName,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div><Link    className={" btn btn-sm btn-danger"} to={row.url + "?trvId="+ row.trvId}><Eye size={15} /></Link></div>,
        }
    ];

    return(
        <div id="home" className="h-100 ">
            <Container fluid className="h-100">
                <Row className="h-100">
                    <div className="ServiceBg  bg" style={{backgroundImage:'url('+websetting.coverImage+')'}}></div>
                    <Col lg={8} md={12} xs={12} className="text-right h-100">
                        <header className="ServiceHeader pt-2 px-lg-5 px-3 px-md-3">
                            <Dropdown className="d-inline-block mx-3">
                                <Dropdown.Toggle as={"a"} className="mobile-white" href="#" variant="none" id="seriveProfile">
                                    <Image srcSet={UserProfile.userImage} className="full-rounded" />
                                    <span className={"px-2 fs-6"}>{sessionStorage.getItem("fisrtName")}</span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="text-center">
                                    <Dropdown.Item key={"p1"} as={Link} to="./iot/Profile">{t.t("iot.global.profile")}</Dropdown.Item>
                                    <Dropdown.Item key={"p2"} as={Link} to={websetting.sysUrl} onClick={ClearSession} >{t.t("iot.global.logout")}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="d-inline-block fs-6 ms-3">
                                <UseLang />
                            </div>

                            <div className="d-inline-block mx-3 mobile-white notifcation" id="notifcation">
                                <Button variant={"none"}  onClick={()=>{setShowNotifcation(true)}} className="d-block p-0" title="Notifications">
                                    <Bell className={notifcation.length > 0 ? "feather shake feather-bell" : "feather  feather-bell"} />
                                    {unreadNotifcation > 0 &&
                                    <span className="budge counter">{unreadNotifcation}</span>
                                    }
                                </Button>
                            </div>
                        </header>
                        <Row className="ServiceBody">
                            <Col className="gx-0 gx-lg-3 my-lg-auto mt-auto">
                                <div className="my-3  px-lg-5 px-2 px-md-2 px-0">
                                    <div className="d-lg-inline-block d-block text-lg-end mx-1 text-center align-middle">
                                        <Image srcSet={websetting.orgLogo} height="70px"></Image>
                                    </div>
                                    <div className="d-lg-inline-block d-block     align-middle">
                                        <h6 className="h5 p-0 m-0 text-uppercase text-black-50 mt-3 mt-lg-0 mobile-white">{t.t("iot.global.welcome")}</h6>
                                        <h3 className="p-0 m-0 h4 text-uppercase mobile-white">{t.t("iot.global.smartCityApp")}</h3>
                                    </div>
                                </div>


                                {loading ?
                                    <div className="text-center py-5">
                                        <Spinner animation="border" variant="warning" role="status"><span className="visually-hidden">انتظر من فضلك</span></Spinner></div> :


                                    <div className="services ">
                                        <div id="Services" className="my-lg-3 px-lg-5 px-md-2 px-0 my-0">
                                            {data.length > 0 &&
                                            <OwlCarousel className='owl-carousel  owl-theme' margin={0} items={1}>
                                                <Row>
                                                    <Col>
                                                        <Row>
                                                            {Object.keys(data).map((titles2, i) => {
                                                                return (
                                                                    <Col sm={3} xs={6}  >
                                                                        {data[i]['code'] != "ENVIRONMENTAL_MONITORING"

                                                                            ?
                                                                            <Link to={data[i]['isLocked'] ? "#" : (data[i]['url'] != null ? data[i]['url'] + "?trvId="+data[i]['id'] : "#")} onClick={()=>{showTab(data[i]['code'])}}>
                                                                                <div className={data[i]['isLocked'] ? "box lock" : "box"}>
                                                                                    <div className=" align-middle">
                                                                                        <Image srcSet={data[i]['imageUrl'] ? data[i]['imageUrl'] : data[i]['picture']} alt=""></Image>
                                                                                        <h4 className="text-center">{data[i]['name']}</h4>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>

                                                                            :

                                                                            <Link to={data[i]['isLocked'] ? "#" : "iot/em"} >
                                                                                <div className={data[i]['isLocked'] ? "box lock" : "box"}>
                                                                                    <div className=" align-middle">
                                                                                        <Image srcSet={data[i]['imageUrl'] ? data[i]['imageUrl'] : data[i]['picture']} alt=""></Image>
                                                                                        <h4 className="text-center">{data[i]['name']}</h4>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        }

                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </OwlCarousel>
                                            }
                                        </div>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Offcanvas  placement={"end"} show={showNotifcation} onHide={handleCloseNotifcation}>
                <Offcanvas.Header className={"border-bottom bg-light"} closeButton>
                    <Offcanvas.Title>
                        <div className={"h6 bold py-0 expand my-0"}>
                            <Bell size={20} className={"feather"} />
                            {t.t("iot.global.notifications")}
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={notifcation.length > 0 ? "pt-2" : "d-flex flex-column text-center justify-content-center"} >
                    {notifcation.length > 0 ?
                        <div>
                            {Object.keys(notifcation).map((titles2, i) => {
                                return (
                                    <Link to={notifcation[i]["link"]} onClick={()=>{markasRead(notifcation[i]['notificationId'])}}>
                                        <div className={"  py-2"} style={{borderBottom:"1px solid " + hexToRgbA("#2ea37d",0.1),opacity: notifcation[i]["isRead"] ? 0.5 : 1}}>
                                            <div className={"fs-6"} style={{color:"#2ea37d"}}><Calendar fill={hexToRgbA("#2ea37d",0.2)} stroke={"#2ea37d"} className={"  mx-1"} size={12} />{Moment(notifcation[i]["createDate"]).format(websetting.dateFormat)}</div>
                                            <div className={"fs-6"} style={{color:"#3b3f5c"}}><Mail  className={"feather mx-1"} size={12} />{notifcation[i]["message"]}</div>
                                        </div>
                                    </Link>
                                )
                            })
                            }
                        </div>
                        :
                        <span className={"bold"}>
                        <div className={"text-center"}>
                            <AlertTriangle className="feather my-3 color1 feather-alert-triangle" size={30} />
                        </div>
                            {t.t("iot.global.youHave")} 0 {t.t("iot.global.newAlerts")}
                    </span>
                    }

                </Offcanvas.Body>
            </Offcanvas>


            <Modal size={"lg"} centered={true} show={show} onExited={handleClose} onHide={handleClose} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><Settings className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.selectOrganization")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <DataTable progressPending={loadingModal}
                                   pagination
                                   paginationServer
                                   paginationTotalRows={totalRows}
                                   progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                                   onChangeRowsPerPage={handlePerRowsChange}
                                   onChangePage={handlePageChange} columns={columns} data={org} />

                    </div>
                </Modal.Body>
            </Modal>


        </div>
    );
}


export default SerivesPage;
