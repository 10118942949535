import {Edit, Grid, PhoneCall, Trash} from "react-feather";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {useEffect,  useState} from "react";
import {useTranslation} from "react-i18next";
import { websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {useParams} from "react-router-dom";

function Category(){
    const t = useTranslation()
    let { cid } = useParams();
    const types = [t.t("cms.web.articles"),t.t("cms.web.forms"),t.t("cms.web.protocols"),t.t("cms.web.reports"),t.t("cms.web.tenders")]

    const [validated, setValidated] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
    }
    const handleShow = () => {
        setShow(true);
    }

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const columns = [
        {
            width:"50px",
            sortable: true,
            name: "#",
            selector: (row : any) => row.id,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.categoryTitle"),
            selector: (row : any) => row.name,
            style:{
                fontSize:"13px"
            }
        },
        {
            sortable: true,
            name: t.t("cms.order"),
            selector: (row : any) => row.orderNo,
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button>   <Button onClick={()=>edit(row.id)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button></div>,
        }
    ]


    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
        }
    }
    const SubmitSearch = (e:any) => {
        e.preventDefault()
        if(e.target.input.value !== searchtxt){
            setSearchtxt(e.target.input.value)
        }
    }

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.cmsUrl + 'CmsCategory/Delete?id=' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }

    const edit = (id:any) => {
        setShow(true);
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.cmsUrl + 'CmsCategory/Get?id='+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }
    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.cmsUrl + 'CmsCategory/Put',
                    {
                        "id":PKid,
                        "nameHe": event.target.nameHe.value,
                        "nameAr": event.target.nameAr.value,
                        "type": cid,
                        "orderNo": event.target.orderNo.value != "" ? event.target.orderNo.value : 0,
                    },
                    {
                        method: "put",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["id"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].name = response.data.name
                    prevState[index].orderNo = response.data.orderNo
                     setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {
                axios.post(websetting.cmsUrl + 'CmsCategory/AddCategory',
                    {
                        "nameHe": event.target.nameHe.value,
                        "nameAr": event.target.nameAr.value,
                        "type": cid,
                        "orderNo": event.target.orderNo.value != "" ? event.target.orderNo.value : 0,
                    },
                    {
                        method: "post",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsCategory/GetCategorys?type=' + cid + (searchtxt != "" ? "&search="+searchtxt : ""),
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            //setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            setLoading(false);
        }).catch(function (error) {

        });
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, [localStorage.getItem("lang") ,searchtxt]);
    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div>
            <div></div>
            <div className={"lightbox"}>
                <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                    <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <Grid className={"feather mx-2"} /> {t.t("cms.web.categories")} - {types[parseInt(cid as string) - 1]}
                     </span>
                    </div>
                    <span className={"d-inline-flex align-items-center mx-2"}>

                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                        <Form  onSubmit={(e)=>{SubmitSearch(e)}}  action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                    </div>

                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><Grid /> {t.t("cms.addCategory")}</Button>
                 </span>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    <DataTable
                        progressPending={loading}
                        pagination
                        paginationServer
                        progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                        columns={columns}
                        data={data}
                    />
                </div>
            </div>


            <Modal size={"xl"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><Grid className={"feather"} /> {t.t("cms.web.categories")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>


                                    <Col md={12}>
                                        <Form.Group className="my-2" controlId="nameHe">
                                            <Form.Label>{t.t("cms.categoryTitle") + " - " + t.t("cms.he")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.nameHe} required
                                                          aria-label={t.t("cms.categoryTitle")}
                                                          aria-describedby="msg_empNameHe"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameHe">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="my-2" controlId="nameAr">
                                            <Form.Label>{t.t("cms.categoryTitle") + " - " + t.t("cms.ar")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.nameAr} required
                                                          aria-label={t.t("cms.categoryTitle")}
                                                          aria-describedby="msg_empNameAr"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group className="my-2" controlId="orderNo">
                                            <Form.Label>{t.t("cms.order")}:</Form.Label>
                                            <Form.Control type="number" defaultValue={infos.orderNo}
                                                          aria-label={t.t("cms.order")}
                                                          aria-describedby="msg_orderNo"/>
                                            <Form.Control.Feedback type="invalid" id="msg_orderNo">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    )
}

export default Category;
