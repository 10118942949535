import {Edit, Grid, Trash} from "react-feather";
import {Button, Col, Form, Image, Modal, Nav, Row, Spinner, Tab} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {useEffect,  useState} from "react";
import {useTranslation} from "react-i18next";
import { websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {useParams} from "react-router-dom";
import {Tree} from "react-arborist";

function CMSGeneralCategory(){
    const t = useTranslation()
    const tab = React.createRef<HTMLTextAreaElement>();
    let {id} :any = useParams()


    const types = [t.t("cms.web.articles"),t.t("cms.web.forms"),t.t("cms.web.protocols"),t.t("cms.web.reports"),t.t("cms.web.tenders")]

    const [validated, setValidated] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);
    const [type, settype] = useState("1");
    const [withcategory, setWithCategory] = useState(false);
    const [template, setTemplate] = useState(true);
    const [subData, setsubData] = useState([]);
    const [parent, setParent] = useState([]);
    const [data2, setdata2] = useState({});


    const [display,setdisplay] = useState("0")


    const MainCategoryId = [
        {
            apiUrl: "CmsDepartment/GetAll/GetAll?rows=300",
            withCategory: true,
            title : t.t("cms.web.departments"),
            id : 1,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=1&rows=300",
            withCategory: true,
            title : t.t("cms.web.articles"),
            id : 2,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=2&rows=300",
            withCategory: true,
            title : t.t("cms.web.forms"),
            id : 3,
        },{
            apiUrl: "",
            data: [{id:1, title: t.t("cms.event1")},{id:2,title: t.t("cms.event2")},{id:3,title: t.t("cms.event3")}],
            withCategory: true,
            title : t.t("cms.web.events"),
            id : 4,
        },{
            apiUrl: "CmsPage/GetStaticPagess?rows=300",
            withCategory: true,
            title : t.t("cms.web.pages"),
            id : 5,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=3&rows=300",
            withCategory: true,
            title : t.t("cms.web.protocols"),
            id : 6,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=4&rows=300",
            withCategory: true,
            title : t.t("cms.web.reports"),
            id : 7,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=5&rows=300",
            withCategory: true,
            title : t.t("cms.web.tenders"),
            id : 8,
        },{
            apiUrl: "",
            withCategory: false,
            title : t.t("cms.web.contactus"),
            id : 9,
        },{
            apiUrl: "",
            withCategory: false,
            title : t.t("cms.web.phones"),
            id : 10,
        },
    ];


    const GetDataByAPI = async (url:string) => {
        setsubData([])
        axios.get(websetting.cmsUrl + url,
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            const d:any = [{id:0,title:t.t("cms.displayAll")}]
            setsubData(d.concat(response.data) );
         }).catch(function (error) {

        });
    };

    const GetType = (type:any) => {
        settype(type)
        setWithCategory(false)
    }

    const GetSubCategory = (catId:any) => {
        if(catId == "0"){
            setWithCategory(false)
            setTemplate(true)
        }else{
            const index = MainCategoryId.findIndex((value) => {
                return value.id === parseInt(catId)
            })
            if(MainCategoryId[index].withCategory == true){
                setWithCategory(true)
            }else{
                setWithCategory(false)
            }
            setTemplate(false)
            if(MainCategoryId[index].apiUrl != ""){
                GetDataByAPI(MainCategoryId[index].apiUrl)
            }else  if(MainCategoryId[index].data != null ){
                setsubData(MainCategoryId[index].data as any) ;
            }

        }
     }

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
    }
    const handleShow = () => {
        setShow(true);
    }

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const columns = [
        {
            width:"50px",
            sortable: true,
            name: "#",
            selector: (row : any) => row.id,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.categoryTitle"),
            selector: (row : any) => row.name,
            style:{
                fontSize:"13px"
            }
        },
        {
            sortable: true,
            name: t.t("cms.order"),
            selector: (row : any) => row.orderNo,
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button>   <Button onClick={()=>edit(row.id)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button></div>,
        }
    ]

    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
        }
    }
    const SubmitSearch = (e:any) => {
        e.preventDefault()
        if(e.target.input.value !== searchtxt){
            setSearchtxt(e.target.input.value)
        }
    }

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.cmsUrl + 'CmsGeneralCategory/Delete?id=' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }
    const edit = (id:any) => {
        GetParent()
        setWithCategory(false)
        setTemplate(true)
        setsubData([])
        settype("1")
        setShow(true);
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.cmsUrl + 'CmsGeneralCategory/Get?id='+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
                GetType(response.data.typeNextUrl as string)
                GetSubCategory(response.data.mainCategoryId)
            }).catch(function (error) {
            });
        }
    }

    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;


            const formData = new FormData(event.target);
            if(event.target.ImageUrl != undefined) {
                if (event.target.ImageUrl.value != '') {
                    const file = event.target.ImageUrl.files[0];
                    formData.append('ImageUrl', file, file.name);
                }
            }
            if(event.target.BackgroundUrl != undefined) {
                if (event.target.BackgroundUrl.value != '') {
                    const file = event.target.BackgroundUrl.files[0];
                    formData.append('BackgroundUrl', file, file.name);
                }
            }

            let display = []
            if(event.target.Display1.checked){
                display.push("1")
            }
            if(event.target.Display2.checked){
                display.push("2")
            }
            if(event.target.Display3.checked){
                display.push("3")
            }

            formData.append('NameHe', event.target.NameHe.value);
            formData.append('NameAr', event.target.NameAr.value);
            formData.append('typeNextUrl', event.target.typeNextUrl.value);
            if(event.target.ExternalLink !== undefined) {
                formData.append('ExternalLink', event.target.ExternalLink.value);
            }
            if(event.target.MainCategoryId !== undefined) {
                formData.append('MainCategoryId', event.target.MainCategoryId.value);
            }
            if(event.target.SubCategoryId !== undefined) {
                if(event.target.SubCategoryId.value != 0) {
                    formData.append('SubCategoryId', event.target.SubCategoryId.value);
                }
            }
            if(event.target.ParentId.value != 0){
                formData.append('ParentId', event.target.ParentId.value);
            }

            formData.append('OrderNo', event.target.OrderNo.value);
            formData.append('Display', display.join(","));
            if(event.target.Template !== undefined) {
                formData.append('Template', event.target.Template.value);
            }
            formData.append('Id', event.target.Id.value);


            if(event.target.Id.value > 0){
                axios.put(websetting.cmsUrl + 'CmsGeneralCategory/Put',
                    formData,
                    {
                        method: "put",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["id"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].name = response.data.name
                    prevState[index].orderNo = response.data.orderNo
                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {
                axios.post(websetting.cmsUrl + 'CmsGeneralCategory/AddGeneralCategory',
                    formData,
                    {
                        method: "post",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };

    const openPage = (display:any) => {
            setdisplay(display)

            if (parseInt(display.replace("tab", "")) == 0) {
                fetchDta2(); // fetch page 1 of users
            } else {
                fetchDta(display); // fetch page 1 of users
            }
    }

    const fetchDta = async (dis:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsGeneralCategory/GetGeneralCategoryByDisplayAndChild?display='+dis.replace("tab",""),
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            //setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            setLoading(false);
        }).catch(function (error) {

        });
    };

    const fetchDta2 = async () => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsGeneralCategory/GetGeneralCategorysTrees',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            let dd:any = []
            response.data.forEach((item:any,index:any)=>{

                let dd2:any = []
                item.children.forEach((item2:any,index2:any)=> {

                    let dd3:any = []
                    item2.children.forEach((item3:any,index3:any)=> {

                        let dd4:any = []
                        item3.children.forEach((item4:any,index4:any)=> {

                            let dd5:any = []
                            item4.children.forEach((item5:any,index5:any)=> {
                                const d5 = {id:item5.id as string,isOpen:true,index:5,name:item5.name as string,child:item5.children.length,children:[]}
                                dd5.push(d5)
                            })

                            const d4 = {id:item4.id as string,isOpen:true,index:4,name:item4.name as string,child:item4.children.length,children:dd5}
                            dd4.push(d4)
                        })

                        const d3 = {id:item3.id as string,isOpen:true,index:3,name:item3.name as string,child:item3.children.length,children:dd4}
                        dd3.push(d3)
                    })

                    const d2 = {id:item2.id as string,isOpen:true,index:2,name:item2.name as string,child:item2.children.length,children:dd3}
                    dd2.push(d2)
                })

                const d = {id:item.id as string,index:1,isOpen:true,name:item.name as string,child:item.children.length,children:dd2}
                dd.push(d)
            })


            //console.log(dd)

            setdata2({name: "HomePage", id:"HomePage",index:0,child:dd.length,isOpen:true,children: dd})
            setLoading(false);
        }).catch(function (error) {

        });
    };




    const GetParent = async () => {
        axios.get(websetting.cmsUrl + 'CmsGeneralCategory/GetCategorieWithoutSubCategory',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            if(response.data != ""){
                setParent(response.data);
            }
        }).catch(function (error) {

        });
    };


    const Open = (id:any,isOpen:any) => {
        const d:any = (data2 as any).children
        const dd = d.filter((item:any)=>{
            return item.id == id
        })
        //console.log(dd)
    }

    useEffect(() => {
        tab.current?.click()
    }, [localStorage.getItem("lang")]);

    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div>

            <Tab.Container id="left-tabs-example" defaultActiveKey="tab0"  >

            <div className={"lightbox position-relative mb-3 newTab"}>
                <div className={"selector"}></div>

            <Nav  >
                <div className={"selectors"}></div>
                <Nav.Item as={"li"}>
                    <Nav.Link eventKey="tab0"   ref={tab as React.RefObject<any>} onClick={()=> openPage("tab0")} >{t.t('cms.allCategory')}</Nav.Link>
                </Nav.Item>
                <Nav.Item as={"li"}>
                    <Nav.Link eventKey="tab1"   onClick={() => openPage("tab1")}>{t.t('cms.headerCategory')}</Nav.Link>
                </Nav.Item>
                <Nav.Item as={"li"}>
                    <Nav.Link eventKey="tab2"  onClick={()=> openPage("tab2")}>{t.t('cms.boxesCategory')}</Nav.Link>
                </Nav.Item>
                <Nav.Item  as={"li"}>
                    <Nav.Link eventKey="tab3"   onClick={()=> openPage("tab3")}>{t.t('cms.robinCategory')}</Nav.Link>
                </Nav.Item>
            </Nav>
            </div>

            <div className={"lightbox"}>
                <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                    <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <Grid className={"feather mx-2"} /> {t.t("cms.web.categories")}
                     </span>
                    </div>
                    <span className={"d-inline-flex align-items-center mx-2"}>

                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                        <Form  onSubmit={(e)=>{SubmitSearch(e)}}  action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                    </div>

                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><Grid /> {t.t("cms.addCategory")}</Button>
                 </span>
                </div>
                <hr className={"shodow-hr"} />

                <div className={"position-relative  text-center"}>

                    {parseInt(display.replace("tab","")) >= 1 ?
                        <DataTable
                            progressPending={loading}
                            pagination
                            paginationServer
                            progressComponent={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"}/>}
                            columns={columns}
                            data={data}
                        />
                        :
                        <div dir={"rtl"}>
                            {loading ?

                                <div className={"text-center"}> <Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"}/></div>
                                :
                                <>
                                    {data2 &&
                                    <Tree className="react-aborist" onToggle={(id, isOpen) => {
                                        Open(id, isOpen)
                                    }} width={700} height={1900} disableDrag={true} disableDrop={true} indent={24}
                                          data={JSON.parse(JSON.stringify(data2))} rowHeight={35}>
                                        {
                                            ({innerRef, data, styles, handlers, state, tree}: any) => {
                                                return (
                                                    <div ref={innerRef} style={styles.row} className={" isOpen"}
                                                         onClick={(e) => handlers.select(e)}>

                                                        {data.child > 0 ?
                                                            <div
                                                                className={"row-contents  justify-content-between d-flex"}
                                                                style={{paddingRight: data.index * 20}}>
                                                                <div className={"d-inline-flex"}>
                                                                    <button onClick={handlers.toggle}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="12" height="12" viewBox="0 0 24 24"
                                                                             fill="none"
                                                                             stroke="#999" stroke-width="2"
                                                                             stroke-linecap="round"
                                                                             stroke-linejoin="round">
                                                                            <polyline
                                                                                points="6 9 12 15 18 9"></polyline>
                                                                        </svg>
                                                                    </button>
                                                                    <i className={"me-2"}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="16" height="16" viewBox="0 0 24 24"
                                                                             fill="cornflowerblue"
                                                                             stroke="cornflowerblue" stroke-width="2"
                                                                             stroke-linecap="round"
                                                                             stroke-linejoin="round" className="folder"
                                                                             fill-opacity="0.5">
                                                                            <path
                                                                                d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                                                                        </svg>
                                                                    </i><span>{data.name} </span>
                                                                </div>
                                                                <span className={"  d-inline-flex"}><Button
                                                                    variant={"outline-danger"} onClick={() => {
                                                                    del(data.id)
                                                                }} className={"rounded-circle"}><Trash
                                                                    size={15}/></Button>   <Button onClick={() => {
                                                                    edit(data.id)
                                                                }} variant={"outline-info"}
                                                                                                   className={"rounded-circle"}><Edit
                                                                    size={15}/></Button></span>


                                                            </div>

                                                            :
                                                            <div
                                                                className={"row-contents justify-content-between d-flex"}
                                                                style={{paddingRight: data.index * 20}}>
                                                                <div className={"d-inline-flex"}>
                                                                    <div className="spacer"></div>
                                                                    <i className={"me-1"}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                             width="16" height="16" viewBox="0 0 24 24"
                                                                             fill="none"
                                                                             stroke="#333" stroke-width="2"
                                                                             stroke-linecap="round"
                                                                             stroke-linejoin="round"
                                                                             className="file"
                                                                             stroke-opacity="0.4">
                                                                            <path
                                                                                d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                                            <polyline
                                                                                points="14 2 14 8 20 8"></polyline>
                                                                            <line x1="16" y1="13" x2="8" y2="13"></line>
                                                                            <line x1="16" y1="17" x2="8" y2="17"></line>
                                                                            <polyline points="10 9 9 9 8 9"></polyline>
                                                                        </svg>
                                                                    </i>
                                                                    <span>{data.name} </span>
                                                                </div>
                                                                <span className={"  d-inline-flex"}><Button
                                                                    variant={"outline-danger"} onClick={() => {
                                                                    del(data.id)
                                                                }} className={"rounded-circle"}><Trash
                                                                    size={15}/></Button>   <Button onClick={() => {
                                                                    edit(data.id)
                                                                }} variant={"outline-info"}
                                                                                                   className={"rounded-circle"}><Edit
                                                                    size={15}/></Button></span>
                                                            </div>
                                                        }

                                                    </div>
                                                )
                                            }
                                        }
                                    </Tree>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
            </Tab.Container>

            <Modal size={"xl"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><Grid className={"feather"} /> {t.t("cms.web.categories")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>


                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="NameHe">
                                            <Form.Label>{t.t("cms.categoryTitle") + " - " + t.t("cms.he")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.nameHe} required
                                                          aria-label={t.t("cms.categoryTitle")}
                                                          aria-describedby="msg_empNameHe"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameHe">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="NameAr">
                                            <Form.Label>{t.t("cms.categoryTitle") + " - " + t.t("cms.ar")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.nameAr} required
                                                          aria-label={t.t("cms.categoryTitle")}
                                                          aria-describedby="msg_empNameAr"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="typeNextUrl">
                                            <Form.Label>{t.t("cms.typeNextUrl")}</Form.Label>
                                            <Form.Select onChange={(v)=>{GetType(v.currentTarget.value)}}  defaultValue={infos.nameAr} required
                                                          aria-label={t.t("cms.typeNextUrl")}
                                                          aria-describedby="msg_empNameAr">
                                                <option value={"1"} selected={infos.typeNextUrl == 1 ? true : false} >{t.t("cms.innerCat")}</option>
                                                <option value={"2"} selected={infos.typeNextUrl == 2 ? true : false} >{t.t("cms.outerCat")}</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>


                                    {type == "1" ?

                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="MainCategoryId">
                                                <Form.Label>{t.t("cms.MainCategoryId")}</Form.Label>
                                                <Form.Select    onChange={(v)=>{GetSubCategory(v.currentTarget.value)}} defaultValue={infos.nameAr} required
                                                             aria-label={t.t("cms.MainCategoryId")}
                                                             aria-describedby="msg_empNameAr">
                                                    {MainCategoryId &&
                                                    <option value={"0"}>{t.t('cms.mainCategory')}</option>
                                                    }
                                                    {MainCategoryId.map((item)=>{
                                                        return(
                                                            <option selected={parseInt(infos.mainCategoryId) == item.id ? true : false } value={item.id}>{item.title}</option>
                                                        )
                                                    })
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        :
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="ExternalLink">
                                                <Form.Label>{t.t("cms.ExternalLink")}</Form.Label>
                                                <Form.Control defaultValue={infos.externalLink} required
                                                              aria-label={t.t("cms.ExternalLink")}
                                                              aria-describedby="msg_empNameAr"/>
                                                <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    }


                                    {type == "1" &&

                                        <Col md={12}>
                                        <Row>


                                            {withcategory &&
                                            <Col md={template ? 6 : 12}>
                                                <Form.Group className="my-2" controlId="SubCategoryId" >
                                                    <Form.Label>{t.t("cms.SubCategoryId")}</Form.Label>
                                                    <Form.Select defaultValue={infos.nameAr} required
                                                                 aria-label={t.t("cms.SubCategoryId")}
                                                                 aria-describedby="msg_empNameAr">
                                                        {
                                                            subData.map((value:any) => {
                                                                return(
                                                                    <option  selected={infos.subCategoryId == value.id ? true : false} value={value.id}>{value.title != null ? value.title : value.name }</option>
                                                                )
                                                            })
                                                        }

                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                        Required Field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            }

                                            {template &&
                                                <Col md={12}>
                                                    <Row className={"justify-content-center align-items-center"}>
                                                        <Col md={withcategory ? 6 : 8}>
                                                            <Form.Group className="my-2" controlId="Template">
                                                                <Form.Label>{t.t("cms.Template")}</Form.Label>
                                                                <Form.Select defaultValue={infos.template} required onChange={(v)=>{document.getElementById("imageTemplate")!.setAttribute("srcSet",window.location.origin + "/images/template/p"+v.currentTarget.value+".png")}}
                                                                             aria-label={t.t("cms.Template")}
                                                                             aria-describedby="msg_empNameAr">
                                                                    <option value={"1"} selected={infos.template == 1 ? true : false} >{t.t("cms.tabs")}</option>
                                                                    <option value={"2"} selected={infos.template == 2 ? true : false} >{t.t("cms.box1")}</option>
                                                                    <option value={"3"} selected={infos.template == 3 ? true : false} >{t.t("cms.box2")}</option>
                                                                    <option value={"4"} selected={infos.template == 4 ? true : false} >{t.t("cms.box3")}</option>
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                                    Required Field
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={4} className={"text-center"}>
                                                            <Image id={"imageTemplate"}  srcSet={window.location.origin + "/images/template/p"+(infos.template ? infos.template : 1 )+".png"}  style={{maxHeight:85,maxWidth:"100%"}}  />
                                                        </Col>
                                                    </Row>

                                                </Col>


                                            }

                                            <Col md={template ? 6 : 12}>
                                                <Form.Group className="my-2" controlId="ImageUrl">
                                                    <Form.Label>{t.t("cms.ImageUrl")}</Form.Label>
                                                    <Form.Control type={"file"}
                                                                  aria-label={t.t("cms.ImageUrl")}
                                                                  aria-describedby="msg_empNameAr"/>
                                                    <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                        Required Field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            {template &&
                                            <Col md={6}>
                                                <Form.Group className="my-2" controlId="BackgroundUrl">
                                                    <Form.Label>{t.t("cms.BackgroundUrl")}</Form.Label>
                                                    <Form.Control type={"file"}
                                                                  aria-label={t.t("cms.BackgroundUrl")}
                                                                  aria-describedby="msg_empNameAr"/>
                                                    <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                        Required Field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            }

                                        </Row>
                                        </Col>
                                    }


                                    {type == "2" &&
                                    <Col md={12}>
                                        <Form.Group className="my-2" controlId="ImageUrl">
                                            <Form.Label>{t.t("cms.ImageUrl")}</Form.Label>
                                            <Form.Control type={"file"}
                                                          aria-label={t.t("cms.ImageUrl")}
                                                          aria-describedby="msg_empNameAr"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    }

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="ParentId">
                                            <Form.Label>{t.t("cms.ParentId")}:</Form.Label>
                                            <Form.Select defaultValue={infos.nameAr} required
                                                         aria-label={t.t("cms.ParentId")}
                                                         aria-describedby="msg_empNameAr">
                                                <option value={0}>{t.t("cms.main")}</option>
                                                {
                                                    parent.map((item:any) => {
                                                        return(
                                                            <>
                                                            <option selected={infos.parentId ==  item.id ? true : false} value={item.id}>{item.name}</option>

                                                            {
                                                                item.children.map((item2: any) => {
                                                                    return (
                                                                        <>
                                                                            <option selected={infos.parentId ==  item2.id ? true : false} value={item2.id}>&nbsp;&nbsp;&nbsp;   - {item2.name}</option>
                                                                            {
                                                                                item2.children.map((item3: any) => {
                                                                                    return (
                                                                                        <option selected={infos.parentId ==  item3.id ? true : false} value={item3.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      -- {item3.name}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid" id="msg_orderNo">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="OrderNo">
                                            <Form.Label>{t.t("cms.order")}:</Form.Label>
                                            <Form.Control type="number" defaultValue={infos.orderNo}
                                                          aria-label={t.t("cms.order")}
                                                          aria-describedby="msg_orderNo"/>
                                            <Form.Control.Feedback type="invalid" id="msg_orderNo">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} >

                                        <div className="mt-2">{t.t("cms.display")}:</div>
                                        <Form.Group as={"span"} className="" controlId="Display1">
                                            <Form.Check type={"checkbox"}   inline value={1} aria-label={t.t("cms.header")} >
                                                <Form.Check.Label>{t.t("cms.header")}</Form.Check.Label>
                                                <Form.Check.Input name={"Display1"}  defaultChecked={infos.display && ((infos.display as string).includes("1") ? true : false)  }  type={"checkbox"} value={1} />
                                            </Form.Check>
                                        </Form.Group>
                                        <Form.Group as={"span"} className="" controlId="Display2" dir={"ltr"}>
                                            <Form.Check type={"checkbox"}  inline value={2} aria-label={t.t("cms.boxs")} >
                                                <Form.Check.Input name={"Display2"} defaultChecked={infos.display && ((infos.display as string).includes("2") ? true : false)  }  type={"checkbox"}  value={2} />
                                                <Form.Check.Label>{t.t("cms.boxs")}</Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>
                                        <Form.Group as={"span"} className="" controlId="Display3" dir={"ltr"}>
                                            <Form.Check type={"checkbox"}  inline value={3} aria-label={t.t("cms.rules")} >
                                                <Form.Check.Input name={"Display3"} defaultChecked={infos.display && ((infos.display as string).includes("3") ? true : false) }  type={"checkbox"}  value={3} />
                                                <Form.Check.Label>{t.t("cms.rules")}</Form.Check.Label>
                                            </Form.Check>
                                        </Form.Group>

                                    </Col>
                                </Row>
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    )
}






export default CMSGeneralCategory;
