import {Button, Spinner, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Edit, Eye, Trash, Truck} from "react-feather";
import Moment from "moment";
import {websetting} from "../../../../../../helpers/websetting";
import axios from "axios";
import DataTable from "react-data-table-component";

function Vehicles(prop:any){
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [historydata, setHistorydata] = useState([]);
    const [taskdata, setTaskdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const del = (id:any) => {
    }
    const showInfo = (id:any) => {
    }
    const edit = (id:any) => {
    }

    const columns = [
        {
            name: 'Name',
            selector: (row : any) => row.node.name,
        },{
            name: 'Status',
            selector: (row : any) => row.node.status,
        },{
            name: 'Type',
            selector: (row : any) => <div>{row.node.vehicleType && row.node.vehicleType.name}</div>,
        },{
            name: 'Driver',
            selector: (row : any) => <div></div>,
        },{
            name: 'Action',
            cell : (row : any) => <div><Button variant={"danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button> <Button onClick={()=>edit(row.id)}  variant={"info"} className={"rounded-circle"}><Edit size={15} /></Button> <Button  variant={"dark"}  onClick={()=>showInfo(row.id)} className={"rounded-circle"}><Eye  size={15} /></Button> </div>,
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.apiUrl + 'waste-service/api/v1/wastehero/vehicles/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.edges);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);

    return(
        <div className={"lightbox"}>
            <div className={"pagetitle text-uppercase"}>
                <Truck className={"feather"} />
                <span className={"d-inline-block mx-2 text-uppercase"}>Vehicles</span>
                <Button variant={"dark"} className="text-uppercase float-end">Vehicles Types</Button>
                <Button variant={"warning"}  className="text-uppercase mx-2 float-end">+ Add Vehicle</Button>
            </div>
            <hr className={"shodow-hr"} />
            <div>
                {
                    loading ? <div className={"text-center"}><Spinner animation={"border"} className={"my-5 "} variant={"warning"} /></div> : <DataTable pagination paginationPerPage={10} columns={columns} data={data} />
                }
            </div>
        </div>
    );
}
export default Vehicles