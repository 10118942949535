var apiUrlVar = process.env.REACT_APP_API_URL;
if(!apiUrlVar) {
    //apiUrlVar = "http://localhost:5555/";
    apiUrlVar = "http://localhost:8080/";
}
var appLang = process.env.REACT_APP_LANG;
if(!appLang) {
    appLang = "ar,en,he";
}

export const Langtxt:any = () => {
    var LangArr = [
        {
            title:"العربية",
            dir : "rtl",
            label : "ar",
            visible : true
        },
        {
            title : "English",
            dir : "ltr",
            label : "en",
            visible : true
        },
        {
            title : "עברית",
            dir : "rtl",
            label : "he",
            visible : true
        }
    ];
    var arr:any = []
    const stringLang = appLang as string
    stringLang.split(",").forEach((v:any)=>{
        var d = LangArr.filter((l:any)=>{
            if( l.label == v.trim() && l.visible == true){
                arr.push(l)
            }
            return l.label == v.trim() && l.visible == true
        })
    })
    return arr
}

export const Lang  = Langtxt()

export const websetting = {
    webUrl : "",
    title:"SMSM Portal",
    sysName: "بوابه الخدمات",
    apiUrl : "https://sso.reine.muni.il/",
    automationUrl : "https://cors-everywhere.herokuapp.com/http://automationmodules.eu-north-1.elasticbeanstalk.com/api/v1/",
    apiCitizen : "https://cors-everywhere.herokuapp.com/http://automationservice-env-dev.eu-north-1.elasticbeanstalk.com/api/v1/",
    cmsUrl : "https://smamgate.com/api/v1/",
    orgName : "منصة SMSM",
    orgLogo : window.location.origin+"/images/logo.png",
    sysUrl : "https://sso.reine.muni.il/",
    dateFormat : "YYYY-MM-DD",
    coverImage : window.location.origin+'/images/bg.jpg'
};

export const CredentialsAuthToken = {
    ClientId : "smsm-iot",
    Password : "SM$M-!@T_2o!0"
}

// For Public - registration
export const ClientCredentialsAuthToken = {
    ClientId : "smsm-portal-client-credentials",
    Password : "SM$M-P0RT@L-CR{D{NT!@L$-!@T_2o22"
}

export const authorities=[{}]
export const checkAuthorities = (auth:any) => {
    const index = authorities.findIndex( (array) => {
        return array === auth
    });
    if(index >= 1){
        return true
    }else{
        return false
    }
}

export const editoroptions = {
    buttonList: [
        ["undo", "redo"],
        ["bold", "underline", "italic", "strike","align","list"],
        ["fontSize", "formatBlock", "paragraphStyle", "blockquote"],
        ["subscript", "superscript"],
        ["fontColor", "hiliteColor",], "textStyle", "removeFormat",
        ["outdent", "indent"],
        ["horizontalRule", "lineHeight"],
        ["table", "link", "image", "video", "imageGallery"],
        ["codeView"]
    ]
}




export const authEnum = {
    ADD_VENDOR:"ADD_VENDOR",
    ADD_LOCATION:"ADD_LOCATION",
    VIEW_DEVICE_TYPE:"VIEW_DEVICE_TYPE",
    EDIT_INV_BILL_DEVICE:"EDIT_INV_BILL_DEVICE",
    ADD_INV_DEVICE:"ADD_INV_DEVICE",
    VIEW_ROLE_PERMISSIONS:"VIEW_ROLE_PERMISSIONS",
    ADD_USER:"ADD_USER",
    DELETE_TRS_SERVICE:"DELETE_TRS_SERVICE",
    ADD_STREET_SERVICE_GROUP_DEVICE:"ADD_STREET_SERVICE_GROUP_DEVICE",
    ADD_ORGANIZATION:"ADD_ORGANIZATION",
    EDIT_VEHICLE:"EDIT_VEHICLE",
    EDIT_VEHICLE_PLANNER:"EDIT_VEHICLE_PLANNER",
    ADD_STREET_SERVICE_SCHEDULE_GROUP:"ADD_STREET_SERVICE_SCHEDULE_GROUP",
    EDIT_TRS_SERVICE:"EDIT_TRS_SERVICE",
    VIEW_DOMAIN_VALUE:"VIEW_DOMAIN_VALUE",
    EDIT_ROLE:"EDIT_ROLE",
    DELETE_BILL:"DELETE_BILL",
    EDIT_DEVICE_TYPE:"EDIT_DEVICE_TYPE",
    VIEW_STREET_SERVICE_GROUP_DASHBOARD:"VIEW_STREET_SERVICE_GROUP_DASHBOARD",
    DELETE_STREET_SERVICE_SCHEDULE_GROUP:"DELETE_STREET_SERVICE_SCHEDULE_GROUP",
    VIEW_INV_BILL_DEVICE:"VIEW_INV_BILL_DEVICE",
    ADD_VEHICLE:"ADD_VEHICLE",
    VIEW_PERMISSION:"VIEW_PERMISSION",
    VIEW_USER:"VIEW_USER",
    ADD_STP_SERVICE:"ADD_STP_SERVICE",
    VIEW_INV_DEVICE:"VIEW_INV_DEVICE",
    DELETE_VEHICLE_TYPE:"DELETE_VEHICLE_TYPE",
    EDIT_USER:"EDIT_USER",
    VIEW_ROLE_USERS:"VIEW_ROLE_USERS",
    ADD_VEHICLE_TYPE:"ADD_VEHICLE_TYPE",
    EDIT_DRIVER:"EDIT_DRIVER",
    VIEW_VEHICLE_TYPE:"VIEW_VEHICLE_TYPE",
    VIEW_DEVICE_PROPERTY:"VIEW_DEVICE_PROPERTY",
    SYNC_STREET_SERVICE_DEVICES:"SYNC_STREET_SERVICE_DEVICES",
    DELETE_ORGANIZATION:"DELETE_ORGANIZATION",
    VIEW_STREET_SERVICE_DETAILS:"VIEW_STREET_SERVICE_DETAILS",
    EDIT_LOCATION:"EDIT_LOCATION",
    ADD_DRIVER:"ADD_DRIVER",
    DELETE_STREET_SERVICE_GROUP:"DELETE_STREET_SERVICE_GROUP",
    VIEW_STREET_SERVICE_STATUS_REPORT:"VIEW_STREET_SERVICE_STATUS_REPORT",
    DELETE_STREET_SERVICE_DEVICE:"DELETE_STREET_SERVICE_DEVICE",
    DELETE_INV_DEVICE:"DELETE_INV_DEVICE",
    DELETE_VEHICLE_PLANNER:"DELETE_VEHICLE_PLANNER",
    VIEW_BILL:"VIEW_BILL",
    DELETE_STREET_SERVICE_SCHEDULE_DEVICE:"DELETE_STREET_SERVICE_SCHEDULE_DEVICE",
    ADD_PERMISSION:"ADD_PERMISSION",
    VIEW_USER_PERMISSIONS:"VIEW_USER_PERMISSIONS",
    DELETE_ROLE_USER:"DELETE_ROLE_USER",
    VIEW_TRS_SERVICE:"VIEW_TRS_SERVICE",
    EDIT_BILL:"EDIT_BILL",
    EDIT_PERMISSION:"EDIT_PERMISSION",
    ADD_VEHICLE_PLANNER:"ADD_VEHICLE_PLANNER",
    VIEW_STREET_SERVICE_SCHEDULE_DEVICE:"VIEW_STREET_SERVICE_SCHEDULE_DEVICE",
    ADD_ROLE_PERMISSIONS:"ADD_ROLE_PERMISSIONS",
    ADD_TRS_SERVICE:"ADD_TRS_SERVICE",
    VIEW_STREET_SERVICE_ENERGY_CONSUMPTION:"VIEW_STREET_SERVICE_ENERGY_CONSUMPTION",
    DELETE_DRIVER:"DELETE_DRIVER",
    DELETE_INV_BILL_DEVICE:"DELETE_INV_BILL_DEVICE",
    DELETE_VEHICLE:"DELETE_VEHICLE",
    ADD_DEVICE_PROPERTY:"ADD_DEVICE_PROPERTY",
    EDIT_STREET_SERVICE_GROUP:"EDIT_STREET_SERVICE_GROUP",
    VIEW_ORGANIZATION:"VIEW_ORGANIZATION",
    DELETE_USER_PERMISSIONS:"DELETE_USER_PERMISSIONS",
    CONTROLLING_STREET_SERVICE_DEVICE:"CONTROLLING_STREET_SERVICE_DEVICE",
    VIEW_STREET_SERVICE_DEVICE_DASHBOARD:"VIEW_STREET_SERVICE_DEVICE_DASHBOARD",
    ADD_STREET_SERVICE_GROUP:"ADD_STREET_SERVICE_GROUP",
    ADD_USER_PERMISSIONS:"ADD_USER_PERMISSIONS",
    EDIT_INV_DEVICE:"EDIT_INV_DEVICE",
    EDIT_ORGANIZATION:"EDIT_ORGANIZATION",
    VIEW_STREET_SERVICE_SCHEDULE_GROUP:"VIEW_STREET_SERVICE_SCHEDULE_GROUP",
    ADD_STREET_SERVICE_SCHEDULE_DEVICE:"ADD_STREET_SERVICE_SCHEDULE_DEVICE",
    ADD_STREET_SERVICE_DEVICE:"ADD_STREET_SERVICE_DEVICE",
    VIEW_LOCATION:"VIEW_LOCATION",
    DELETE_DEVICE_PROPERTY:"DELETE_DEVICE_PROPERTY",
    ADD_BILL:"ADD_BILL",
    EDIT_STP_SERVICE:"EDIT_STP_SERVICE",
    DELETE_ROLE:"DELETE_ROLE",
    EDIT_VENDOR:"EDIT_VENDOR",
    VIEW_STREET_SERVICE_GROUP_DEVICES:"VIEW_STREET_SERVICE_GROUP_DEVICES",
    ADD_INV_BILL_DEVICE:"ADD_INV_BILL_DEVICE",
    ADD_DEVICE_TYPE:"ADD_DEVICE_TYPE",
    DELETE_ROLE_PERMISSIONS:"DELETE_ROLE_PERMISSIONS",
    VIEW_VEHICLE:"VIEW_VEHICLE",
    VIEW_VEHICLE_PLANNER:"VIEW_VEHICLE_PLANNER",
    EDIT_VEHICLE_TYPE:"EDIT_VEHICLE_TYPE",
    VIEW_STP_SERVICE:"VIEW_STP_SERVICE",
    VIEW_ROLE:"VIEW_ROLE",
    ADD_ROLE:"ADD_ROLE",
    DELETE_PERMISSION:"DELETE_PERMISSION",
    DELETE_LOCATION:"DELETE_LOCATION",
    VIEW_STREET_SERVICE_DEVICES_DASHBOARD:"VIEW_STREET_SERVICE_DEVICES_DASHBOARD",
    EDIT_STREET_SERVICE_DEVICE:"EDIT_STREET_SERVICE_DEVICE",
    VIEW_STREET_SERVICE_DEVICE_MESSAGES:"VIEW_STREET_SERVICE_DEVICE_MESSAGES",
    VIEW_STREET_SERVICE_DEVICES:"VIEW_STREET_SERVICE_DEVICES",
    VIEW_DRIVER:"VIEW_DRIVER",
    VIEW_FLEET_SERVICE:"VIEW_FLEET_SERVICE",
    VIEW_STREET_SERVICE_GROUP:"VIEW_STREET_SERVICE_GROUP",
    DELETE_USER:"DELETE_USER",
    ADD_ROLE_USER:"ADD_ROLE_USER",
    DELETE_STP_SERVICE:"DELETE_STP_SERVICE",
    DELETE_DEVICE_TYPE:"DELETE_DEVICE_TYPE",
    VIEW_STREET_SERVICE_FAULTS_MANAGEMENT:"VIEW_STREET_SERVICE_FAULTS_MANAGEMENT",
    CONTROLLING_STREET_SERVICE_GROUP:"CONTROLLING_STREET_SERVICE_GROUP",
    EDIT_DEVICE_PROPERTY:"EDIT_DEVICE_PROPERTY",
    DELETE_VENDOR:"DELETE_VENDOR",
    VIEW_VENDOR:"VIEW_VENDOR",
    DELETE_STREET_SERVICE_DEVICE_MESSAGE:"DELETE_STREET_SERVICE_DEVICE_MESSAGE"
}

export const UserProfile = {
    username:"",
    name:"",
    email : "",
    phone : "",
    userType:"",
    organizationId:"",
    ownerId:"",
    userIdPk:"",
    userImage:window.location.origin+"/images/profile.png"
};
export const Auth = {
    access_token:"",
    expires_in:"",
    jti : "",
    refresh_token : "",
    token_type:"",
};

export let modules = [{}]



export const ModulaIds = {
    "Automation" : "e6433341-4424-4afc-894b-ac8ff52f435f",
    "Citizen" : "e6433341-4424-4afc-894b-ac8ff52f435f"
}
