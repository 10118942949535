import {ChevronLeft, Edit, Grid, Search, Trash} from "react-feather";
import {Button, Col, Form, Image, Modal, Nav, Row, Spinner, Tab} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {useEffect,  useState} from "react";
import {useTranslation} from "react-i18next";
import { websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {Link, useParams} from "react-router-dom";

function CMSSubSearch(){
    const t = useTranslation()
    const tab = React.createRef<HTMLTextAreaElement>();
    let {cid} :any = useParams()


    const types = [t.t("cms.web.articles"),t.t("cms.web.forms"),t.t("cms.web.protocols"),t.t("cms.web.reports"),t.t("cms.web.tenders")]

    const [validated, setValidated] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);
    const [type, settype] = useState("1");
    const [withcategory, setWithCategory] = useState(false);
    const [template, setTemplate] = useState(true);
    const [subData, setsubData] = useState([]);
    const [parent, setParent] = useState([]);
    const [mainCat, setmainCat] = useState("0");


    const [display,setdisplay] = useState("0")


    const MainCategoryId = [
        {
            apiUrl: "CmsDepartment/GetAll/GetAll?rows=300",
            withCategory: true,
            title : t.t("cms.web.departments"),
            id : 1,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=1&rows=300",
            withCategory: true,
            title : t.t("cms.web.articles"),
            id : 2,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=2&rows=300",
            withCategory: true,
            title : t.t("cms.web.forms"),
            id : 3,
        },{
            apiUrl: "",
            data: [{id:1, title: t.t("cms.event1")},{id:2,title: t.t("cms.event2")},{id:3,title: t.t("cms.event3")}],
            withCategory: true,
            title : t.t("cms.web.events"),
            id : 4,
        },{
            apiUrl: "CmsPage/GetStaticPagess?rows=300",
            withCategory: true,
            title : t.t("cms.web.pages"),
            id : 5,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=3&rows=300",
            withCategory: true,
            title : t.t("cms.web.protocols"),
            id : 6,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=4&rows=300",
            withCategory: true,
            title : t.t("cms.web.reports"),
            id : 7,
        },{
            apiUrl: "CmsCategory/GetCategorys?type=5&rows=300",
            withCategory: true,
            title : t.t("cms.web.tenders"),
            id : 8,
        },{
            apiUrl: "",
            withCategory: false,
            title : t.t("cms.web.contactus"),
            id : 9,
        },{
            apiUrl: "",
            withCategory: false,
            title : t.t("cms.web.phones"),
            id : 10,
        },
    ];


    const GetDataByAPI = async (url:string) => {
        setsubData([])
        axios.get(websetting.cmsUrl + url,
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            const d:any = [{id:0,title:t.t("cms.displayAll")}]
            setsubData(d.concat(response.data) );
        }).catch(function (error) {

        });
    };

    const GetType = (type:any) => {
        settype(type)
        setWithCategory(false)
    }

    const GetSubCategory = (catId:any) => {
        setmainCat(catId)
        if(catId == "0"){
            setWithCategory(false)
            setTemplate(true)
        }else{
            const index = MainCategoryId.findIndex((value) => {
                return value.id === parseInt(catId)
            })
            if(MainCategoryId[index].withCategory == true){
                setWithCategory(true)
            }else{
                setWithCategory(false)
            }
            setTemplate(false)
            if(MainCategoryId[index].apiUrl != ""){
                GetDataByAPI(MainCategoryId[index].apiUrl)
            }else  if(MainCategoryId[index].data != null ){
                setsubData(MainCategoryId[index].data as any) ;
            }

        }
    }

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
    }
    const handleShow = () => {
        setShow(true);
    }

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const columns = [
        {
            width:"50px",
            sortable: true,
            name: "#",
            selector: (row : any) => row.id,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.categoryTitle"),
            selector: (row : any) => row.name,
            style:{
                fontSize:"13px"
            }
        },
        {
            sortable: true,
            name: t.t("cms.order"),
            selector: (row : any) => row.orderNo,
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button>   <Button onClick={()=>edit(row.id)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button></div>,
        }
    ]

    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
        }
    }
    const SubmitSearch = (e:any) => {
        e.preventDefault()
        if(e.target.input.value !== searchtxt){
            setSearchtxt(e.target.input.value)
        }
    }

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.cmsUrl + 'CmsPublicSubQuestion/Delete?id=' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }
    const edit = (id:any) => {

        setWithCategory(false)
        setTemplate(true)
        setsubData([])
        settype("1")
        setShow(true);
        setmainCat("0")
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.cmsUrl + 'CmsPublicSubQuestion/Get?id='+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
                GetType(response.data.typeNextUrl as string)
                GetSubCategory(response.data.mainCategoryId)
            }).catch(function (error) {
            });
        }
    }

    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;



            if(event.target.Id.value > 0){
                axios.put(websetting.cmsUrl + 'CmsPublicSubQuestion/Put',
                    {
                        "id": PKid,
                        "nameHe": event.target.nameHe.value,
                        "nameAr": event.target.nameAr.value,
                        "typeNextUrl": parseInt(event.target.typeNextUrl.value),
                        "externalLink": event.target.externalLink !== undefined ? event.target.externalLink.value : "",
                        "mainCategoryId": event.target.mainCategoryId !== undefined ? parseInt(event.target.mainCategoryId.value) : 0 ,
                        "subCategoryId": event.target.subCategoryId !== undefined ? parseInt(event.target.subCategoryId.value) : 0,
                        "mainQuestionId": parseInt(cid),
                        "orderNo" : event.target.orderNo.value != "" ? parseInt(event.target.orderNo.value) : 0
                    }
                    ,
                    {
                        method: "put",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["id"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].name = response.data.name
                    prevState[index].orderNo = response.data.orderNo
                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {
                axios.post(websetting.cmsUrl + 'CmsPublicSubQuestion/AddQuestion',
                    {
                        "nameHe": event.target.nameHe.value,
                        "nameAr": event.target.nameAr.value,
                        "typeNextUrl": parseInt(event.target.typeNextUrl.value),
                        "externalLink": event.target.externalLink !== undefined ? event.target.externalLink.value : "",
                        "mainCategoryId": event.target.mainCategoryId !== undefined ? parseInt(event.target.mainCategoryId.value) : 0 ,
                        "subCategoryId": event.target.subCategoryId !== undefined ? parseInt(event.target.subCategoryId.value) : 0,
                        "mainQuestionId": parseInt(cid),
                        "orderNo" : event.target.orderNo.value != "" ? parseInt(event.target.orderNo.value) : 0
                    },
                    {
                        method: "post",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };



    const fetchDta = async () => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsPublicSubQuestion/GetSubQuestions?mainQuestionId='+cid,
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data)
            setLoading(false);
        }).catch(function (error) {

        });
    };




    const GetParent = async () => {
        axios.get(websetting.cmsUrl + 'CmsGeneralCategory/GetGeneralCategorysTrees',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            if(response.data != ""){
                setParent(response.data);
            }
        }).catch(function (error) {

        });
    };



    useEffect(() => {

        fetchDta(); // fetch page 1 of users
        GetParent()
    }, [localStorage.getItem("lang")]);

    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
        <div>

                <div className={"lightbox"}>
                    <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                        <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <Search className={"feather mx-2"} /> {t.t("cms.web.quickSearch")}
                     </span>
                        </div>
                        <span className={"d-inline-flex align-items-center mx-2"}>

                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                        <Form  onSubmit={(e)=>{SubmitSearch(e)}}  action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                    </div>

                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><Grid /> {t.t("cms.addCategory")}</Button>
                                <Link to={"../Search"} >{t.t("iot.global.back")} <ChevronLeft className={" feather feather-chevron-left"} /></Link>
                 </span>
                    </div>
                    <hr className={"shodow-hr"} />

                    <div className={"position-relative  text-center"}>
                            <div dir={"rtl"}>

                                    <Row>

                                        <Col md={12}>

                                            <DataTable
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                progressComponent={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"}/>}
                                                columns={columns}
                                                data={data}
                                            />
                                        </Col>



                                    </Row>

                            </div>

                    </div>
                </div>

            <Modal size={"xl"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><Grid className={"feather"} /> {t.t("cms.web.categories")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="nameHe">
                                            <Form.Label>{t.t("cms.categoryTitle") + " - " + t.t("cms.he")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.nameHe} required
                                                          aria-label={t.t("cms.categoryTitle")}
                                                          aria-describedby="msg_empNameHe"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameHe">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="nameAr">
                                            <Form.Label>{t.t("cms.categoryTitle") + " - " + t.t("cms.ar")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.nameAr} required
                                                          aria-label={t.t("cms.categoryTitle")}
                                                          aria-describedby="msg_empNameAr"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="typeNextUrl">
                                                <Form.Label>{t.t("cms.typeNextUrl")}</Form.Label>
                                                <Form.Select onChange={(v) => {
                                                    GetType(v.currentTarget.value)
                                                }} defaultValue={infos.nameAr} required
                                                             aria-label={t.t("cms.typeNextUrl")}
                                                             aria-describedby="msg_empNameAr">
                                                    <option value={"1"}
                                                            selected={infos.typeNextUrl == 1 ? true : false}>{t.t("cms.innerCat")}</option>
                                                    <option value={"2"}
                                                            selected={infos.typeNextUrl == 2 ? true : false}>{t.t("cms.outerCat")}</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>


                                        {type == "1" ?

                                            <Col md={6}>
                                                <Form.Group className="my-2" controlId="mainCategoryId">
                                                    <Form.Label>{t.t("cms.MainCategoryId")}</Form.Label>
                                                    <Form.Select onChange={(v) => {
                                                        GetSubCategory(v.currentTarget.value)
                                                    }} defaultValue={infos.nameAr} required
                                                                 aria-label={t.t("cms.MainCategoryId")}
                                                                 aria-describedby="msg_empNameAr">
                                                        <option value={"0"}>{t.t('cms.mainCategory')}</option>
                                                        {MainCategoryId.map((item) => {
                                                            return (
                                                                <option
                                                                    selected={parseInt(infos.mainCategoryId) == item.id ? true : false}
                                                                    value={item.id}>{item.title}</option>
                                                            )
                                                        })
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                        Required Field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            :
                                            <Col md={6}>
                                                <Form.Group className="my-2" controlId="externalLink">
                                                    <Form.Label>{t.t("cms.ExternalLink")}</Form.Label>
                                                    <Form.Control defaultValue={infos.externalLink} required
                                                                  aria-label={t.t("cms.ExternalLink")}
                                                                  aria-describedby="msg_empNameAr"/>
                                                    <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                        Required Field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        }


                                        {type == "1" &&

                                        <Col md={12}>
                                            <Row>


                                                {withcategory &&
                                                <Col md={template ? 6 : 12}>
                                                    <Form.Group className="my-2" controlId="subCategoryId">
                                                        <Form.Label>{t.t("cms.SubCategoryId")}</Form.Label>
                                                        <Form.Select defaultValue={infos.subCategoryId} required
                                                                     aria-label={t.t("cms.SubCategoryId")}
                                                                     aria-describedby="msg_empNameAr">
                                                            {
                                                                subData.map((value: any) => {
                                                                    return (
                                                                        <option
                                                                            selected={infos.subCategoryId == value.id ? true : false}
                                                                            value={value.id}>{value.title != null ? value.title : value.name}</option>
                                                                    )
                                                                })
                                                            }

                                                        </Form.Select>
                                                        <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                            Required Field
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                }

                                            </Row>
                                        </Col>
                                        }

                                    {infos &&
                                    <>
                                        {mainCat == "0" &&
                                        <>
                                            {type == "1" &&
                                            <Col md={6}>
                                                <Form.Group className="my-2" controlId="subCategoryId">
                                                    <Form.Label>{t.t("cms.ParentId")}:</Form.Label>
                                                    <Form.Select defaultValue={infos.nameAr} required
                                                                 aria-label={t.t("cms.ParentId")}
                                                                 aria-describedby="msg_empNameAr">
                                                        {parent &&
                                                        <option value={0}>{t.t("cms.main")}</option>
                                                        }
                                                        {
                                                            parent.map((item: any) => {
                                                                return (
                                                                    <>
                                                                        <option
                                                                            selected={parseInt(infos.subCategoryId) == parseInt(item.id) ? true : false}
                                                                            value={item.id}>{item.name}</option>

                                                                        {
                                                                            item.children.map((item2: any) => {
                                                                                return (
                                                                                    <>
                                                                                        <option
                                                                                            selected={parseInt(infos.subCategoryId) == parseInt(item2.id) ? true : false}
                                                                                            value={item2.id}>&nbsp;&nbsp;&nbsp;   - {item2.name}</option>
                                                                                        {
                                                                                            item2.children.map((item3: any) => {
                                                                                                return (
                                                                                                    <option
                                                                                                        selected={parseInt(infos.subCategoryId) == parseInt(item3.id) ? true : false}
                                                                                                        value={item3.id}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      -- {item3.name}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid" id="msg_orderNo">
                                                        Required Field
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            }
                                        </>

                                        }
                                    </>
                                    }

                                        <Col md={type != "1" ? 12 :6}>
                                            <Form.Group className="my-2" controlId="orderNo">
                                                <Form.Label>{t.t("cms.order")}:</Form.Label>
                                                <Form.Control type="number" defaultValue={infos.orderNo}
                                                              aria-label={t.t("cms.order")}
                                                              aria-describedby="msg_orderNo"/>
                                                <Form.Control.Feedback type="invalid" id="msg_orderNo">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                </Row>
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    )
}






export default CMSSubSearch;
