import React, {useEffect, useRef, useState} from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import {Alert, Button, Col, Container, Form, FormLabel, Row, Spinner} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import LoginHeader from "./header";
import axios from "axios";
import {websetting} from "../../helpers/websetting";
import {SystemSetting} from "../../constants/system";
import {useTitle} from "../../helpers/function";
import {useTranslation} from "react-i18next";


function PageContent() {
    const t = useTranslation()
    const navigate = useNavigate()

    const inputRef = React.createRef<HTMLTextAreaElement>();
    const inputRef2 = React.createRef<HTMLTextAreaElement>();

    useTitle(websetting.title);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenValue = params.get('token') || "";

    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const [done, setDone] = useState('');
    const [validated, setValidated] = useState(false);

    const [token, setToken] = useState(tokenValue);
    const [validToken, setValidToken] = useState('');

    useEffect(()=>{
        setLoading("true")
        if (token !== "" && validToken =="") {
            axios.get(websetting.apiUrl + 'auth/public/api/v1/account/'+token+'/invitationToken' ,
                {
                    method: "get",
                    headers: {
                        'accept': '*/*'
                    }
                }
            ).then(async function (response) {
                if(response.data.length != 0 && response.data.userIdPk !== "" && response.status == 200){
                    setValidToken("true");
                }else{
                    setError("Invalid Token")
                    setValidToken("");
                }
                setLoading("")
            }).catch(function (error) {
                setError("Invalid Token")
                setLoading("")
                setTimeout(function (){
                    navigate("../")
                },2000)
                setValidToken("");
            });
        }else{
            setLoading("")
        }
    },[token])


    const resetPassword = async (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.target.Password.focus();
            event.preventDefault();
            event.stopPropagation();
        }

        const password = inputRef.current;
        const password2 = inputRef2.current;

        if ( (password2?.value != password?.value) ) {
            event.target.Password.focus();
            setError(t.t("iot.global.mismatchResetPassword"));
            return false
        } else {
            setValidated(true);
            if (form.checkValidity() === true) {
                event.preventDefault();
                setError("");
                setLoading("true");
                axios.put(websetting.apiUrl + 'auth/public/api/v1/account/confirmUserInvitation' ,
                    {
                        "cofirmNewPassword": password2?.value,
                        "firstName": event.target.firstName.value,
                        "lastName": event.target.phone.value,
                        "newPassword": password?.value,
                        "phone": event.target.phone.value,
                        "token": token,
                        "username": event.target.username.value
                },
                    {
                        method: "put",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'accept': '*/*'
                        }
                    }
                ).then(async function (response) {
                    if(response.status == 200){
                        setLoading("")
                        setDone("")
                        setError("")
                        if(response.data === "true" || response.data === true){
                            setDone("Done..")
                            setTimeout(function (){
                                window.location.href = "./";
                            },SystemSetting.MsgTimeOut);
                        }else{
                            setError(response.data.errorCode)
                        }
                    }else{
                        setLoading("")
                    }
                }).catch(function (error) {
                    setError("Error Request")
                    setLoading("")
                });
            }
        }
    }


    if (error !== "") {
        setTimeout(function () {
            setError("");
        }, SystemSetting.MsgTimeOut);
    }

    return (


        <div className="formDiv slide-top">
            <LoginHeader/>

            {done && <Alert className="text-center" variant="success">{done}</Alert>}
            {error && <Alert id="AlertMsg" className="text-center" variant="danger">{error}</Alert>}
            {loading && <div className="text-center"><Spinner animation="border" variant="secondary" role="status"><span
                className="visually-hidden">انتظر من فضلك</span></Spinner></div>}


            <Form noValidate validated={validated} onSubmit={resetPassword}>

                {validToken &&
                <div>
                    <Row>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="firstName">
                                <FormLabel className={"m-0"}>{t.t("TxtFirstName")}</FormLabel>
                                <Form.Control type="text" aria-required="true" required
                                              aria-label={t.t("TxtFirstName")}/>
                            </Form.Group>
                        </Col>
                        <Col md={12} lg={6}>
                            <Form.Group className="mt-2" controlId="lastName">
                                <FormLabel className={"m-0"}>{t.t("TxtFamilyName")}</FormLabel>
                                <Form.Control type="text" aria-required="true" required
                                              aria-label={t.t("TxtFamilyName")}/>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="my-3" controlId="username">
                                <FormLabel className={"m-0"}>{t.t("iot.global.username")}</FormLabel>
                                <Form.Control type="text" required ref={inputRef as React.RefObject<any>}
                                              autoComplete="off" defaultValue=""
                                              aria-label={t.t("iot.global.username")} />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="my-3" controlId="phone">
                                <FormLabel className={"m-0"}>{t.t("iot.global.phone")}</FormLabel>
                                <Form.Control type="tel" required ref={inputRef as React.RefObject<any>}
                                              autoComplete="off" defaultValue="" aria-label={t.t("iot.global.phone")}
                                               />
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="my-3" controlId="newPassword">
                                <FormLabel className={"m-0"}>{t.t("iot.global.newPassword")}</FormLabel>
                                <Form.Control type="password" required ref={inputRef as React.RefObject<any>}
                                              placeholder={t.t("iot.global.newPassword")} autoComplete="off"
                                              defaultValue=""
                                              aria-label={t.t("iot.global.newPassword")}
                                              aria-describedby="msgPassword"/>
                                <Form.Control.Feedback type="invalid" id="msgPassword">
                                    {t.t("iot.global.newPassword")}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="my-3" controlId="cofirmNewPassword">
                                <FormLabel className={"m-0"}>{t.t("iot.global.confirmNewPassword")}</FormLabel>
                                <Form.Control type="password" ref={inputRef2 as React.RefObject<any>} required
                                              placeholder={t.t("iot.global.confirmNewPassword")} autoComplete="off"
                                              defaultValue=""
                                              aria-label={t.t("iot.global.confirmNewPassword")}
                                              aria-describedby="msgNewPassword"/>
                                <Form.Control.Feedback type="invalid" id="msgNewPassword">
                                    {t.t("iot.global.confirmNewPassword")}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button type="submit" className="DarkBtn w-100 mt-3">{t.t("BtnLblFinish")}</Button>
                </div>
                }
            </Form>


        </div>
    )
}



function InviteUserForm(){
    return(
        <div className="h-100">
            <Container className="h-100" fluid={true}>
                <Row className="h-100" >
                    <Col className="mx-auto my-auto py-3"  xl={6} lg={6} md={6} >
                        <PageContent/>
                    </Col>
                    <Col className={" d-none d-md-block"} xl={6} lg={6} md={6}   style={{backgroundImage:'url('+window.location.origin+'/images/bg.jpg)',backgroundPosition:'fixed',backgroundRepeat:"no-repeat",backgroundSize:"cover"}}  >
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default InviteUserForm;
