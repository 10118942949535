import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Dropdown, DropdownButton, Form, Offcanvas, Row, Spinner} from "react-bootstrap";
import {Edit3, Eye, Link, PieChart, Sun, Trash2} from "react-feather";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {websetting} from "../../../../../../helpers/websetting";
import {SystemSetting} from "../../../../../../constants/system";

function SLA(prop:any){
    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [popuploading, setpopuploading] = useState(false);

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [logicId, setLogicId] = useState(0);

    const [devicetype, setdevicetype] = useState([]);
    const [Property, setProperty] = useState([]);
    const [compare , setcompare] = useState([]);
    const [value, setvalue] = useState([]);
    const [Someone , setSomeone] = useState([]);
    const [by , setby] = useState([]);
    const [message  , setmessage ] = useState([]);


    const [devicetypeLabel, setdevicetypeLabel] = useState({name:"",id:0});
    const [PropertyLabel, setPropertyLabel] = useState({name:"",id:0});
    const [compareLabel , setcompareLabel] = useState({name:"",id:0});
    const [valueLabel, setvalueLabel] = useState({name:"",id:0});
    const [SomeoneLabel , setSomeoneLabel] = useState({name:"",id:0});
    const [byLabel , setbyLabel] = useState({name:"",id:0});
    const [messageLabel  , setmessageLabel ] = useState("");


    const handleClose = () => {
        setShow(false);
        setLogicId(0)
        setPropertyLabel({name: t.t("iot.global.property"),id:0})
        setvalueLabel({name: t.t("iot.global.value"),id:0})
        setSomeoneLabel( {name: t.t("iot.global.someone"),id:0})
        setbyLabel({name: t.t("iot.global.by"),id:0})
        setmessageLabel(t.t("iot.global.message"))
        setdevicetypeLabel({name: t.t("iot.global.deviceType"),id:0})
        setcompareLabel({name: t.t("iot.global.compare"),id:0})
    }
    const handleShow = () => {
        setShow(true);
    }

    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/busniessLogic/?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.busniessLogicsList);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    const compareVal = [t.t("iot.global.isGrater"),t.t("iot.global.isLess"),t.t("iot.global.isEqual")]
    const valueVal = [t.t("iot.global.max"),t.t("iot.global.min"),t.t("iot.global.equal")]
    const byVal = [t.t("iot.global.email"),t.t("iot.global.message"),t.t("iot.global.system")]



    useEffect(() => {
        fetchDta(1);
        axios.get(websetting.apiUrl + 'setup-service/api/v1/deviceTypes/?pageNO='+0+'&pageSize=100',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setdevicetype(response.data.data);
        }).catch(function (error) {
        });

        axios.get(websetting.apiUrl + 'auth/api/v1/user/EMPLOYEE/getUsersByUserType?pageNO=0&pageSize=100',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setby(response.data.data);
        }).catch(function (error) {
        });
    }, []);

    useEffect(() => {
        setPropertyLabel({name: t.t("iot.global.property"),id:0})
        setvalueLabel({name: t.t("iot.global.value"),id:0})
        setSomeoneLabel( {name: t.t("iot.global.someone"),id:0})
        setbyLabel({name: t.t("iot.global.by"),id:0})
        setmessageLabel(t.t("iot.global.message"))
        setdevicetypeLabel({name: t.t("iot.global.deviceType"),id:0})
        setcompareLabel({name: t.t("iot.global.compare"),id:0})
    }, [localStorage.getItem("lang")]);

    const Edit = (id:any) => {
        setLogicId(id)
        setShow(true)
        if(id != 0 ) {
            setpopuploading(true)
            axios.get(websetting.apiUrl + 'lighting-service/api/v1/busniessLogic/' + id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                getDeviceProp(response.data.deviceTypeIdFk)
                setPropertyLabel({name: response.data.devicePropertyDTO.name, id: response.data.devicePropertyIdFk})
                setvalueLabel({name: valueVal[response.data.value - 1], id: response.data.value})
                setSomeoneLabel({
                    name: response.data.userDTO.firstName + " " + response.data.userDTO.lastName,
                    id: response.data.userIdFk
                })
                setbyLabel({name: byVal[response.data.byUsing - 1], id: response.data.byUsing})
                setmessage(response.data.message)
                setmessageLabel(response.data.message)
                setdevicetypeLabel({name: response.data.deviceTypeDTO.name, id: response.data.deviceTypeIdFk})
                setcompareLabel({name: compareVal[response.data.comparator - 1], id: response.data.comparator})
                setpopuploading(false)
            }).catch(function (error) {
            });
        }

    }
    const Del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'lighting-service/api/v1/busniessLogic/' + id ,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["logicIdPk"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }
    const selectDeviceType = (value:any) => {
        setProperty([])
        setdevicetypeLabel({name:value["name"],id:value["deviceTypeIdPk"]})
        setPropertyLabel({name:t.t("iot.global.property"),id:0})
        getDeviceProp(value["deviceTypeIdPk"])
    }

    const getDeviceProp = (id:any) => {
        axios.get(websetting.apiUrl + 'setup-service/api/v1/deviceProperties/'+id+'/properties?pageNO='+0+'&pageSize=100',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setProperty(response.data.data);
        }).catch(function (error) {
        });
    }
    const Save = () => {

            if(byLabel.id == 0 || compareLabel.id==0 || PropertyLabel.id==0 || devicetypeLabel.id==0 || messageLabel=="" || SomeoneLabel.id==0 || valueLabel.id==0){
                return false
            }


            setpopuploading(true)
        if(logicId == 0){
            axios.post(websetting.apiUrl + 'lighting-service/api/v1/busniessLogic/',
                {
                    "byUsing": byLabel.id,
                    "comparator": compareLabel.id,
                    "devicePropertyIdFk": PropertyLabel.id,
                    "deviceTypeIdFk": devicetypeLabel.id,
                    "message": messageLabel,
                    "userIdFk": SomeoneLabel.id,
                    "value": valueLabel.id
                },
                {
                    method: "post",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setData(data.concat(response.data))
                setShow(false)
                setpopuploading(false)
            }).catch(function (error) {
            });
        }else{
            axios.put(websetting.apiUrl + 'lighting-service/api/v1/busniessLogic/'+logicId,
                {
                    "byUsing": byLabel.id,
                    "comparator": compareLabel.id,
                    "devicePropertyIdFk": PropertyLabel.id,
                    "deviceTypeIdFk": devicetypeLabel.id,
                    "message": messageLabel,
                    "userIdFk": SomeoneLabel.id,
                    "value": valueLabel.id,
                    logicIdPk:logicId
                },
                {
                    method: "post",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {

                const index = data.findIndex( (array) => {
                    return parseInt(array["logicIdPk"]) === logicId
                });

                let prevState = JSON.parse(JSON.stringify(data));
                prevState[index].message = response.data.message
                setData(prevState)
                setShow(false)
                setpopuploading(false)
            }).catch(function (error) {
            });
        }

    }

    return(
        <div>
            <div  className={""}>
                <Row>
                    <Col className={"mb-3"} lg={12}>
                        <div className="lightbox">
                            <div className="pagetitle text-uppercase">
                                <div className={"d-flex justify-content-between"}>
                                    <div className={"d-inline-flex"}>
                                        <Link className="feather" size={24} />
                                        <span className={"d-inline-block bold h1 mx-2"} style={{fontSize:25}}>{t.t("iot.global.alarms")}</span>
                                    </div>
                                    <div className={"d-inline-flex align-content-end"}>
                                        <Button variant="dark" size={"sm"} onClick={()=>{Edit(0)}}   className="float-end bold  text-uppercase">+ {t.t("iot.global.addProcess")}</Button>
                                    </div>
                                </div>
                            </div>

                            {loading ?
                                <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :

                                <div>
                                    <Row className={"my-4"}>
                                        {Object.keys(data).map((titles2, i) => {
                                            return(
                                                <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                                                    <div className={"gBox gBox2"}>
                                                    <div className={"gBoxHeader"}><Button className={"btn-rounded py-1"} onClick={()=>{Edit(data[i]["logicIdPk"])}} variant={"outline-info"}><Edit3 /></Button> <Button onClick={()=>{Del(data[i]["logicIdPk"])}} className={"btn-rounded py-1"} variant={"outline-danger"}><Trash2 /></Button></div>
                                                    <div className={"gBoxBody text-justify"}  >{data[i]["message"]}</div>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </div>

            <Offcanvas className={"slaCanvas px-3 mx-5"} placement={"bottom"} show={show} onHide={handleClose}>
                <Offcanvas.Header className={"border-bottom mb-3 justify-content-between"}  >
                    <Offcanvas.Title>
                        <div className={"h3 bold py-0 text-uppercase expand my-0"}>
                            <Link className={"feather mx-2"} />
                            {t.t("iot.global.addProcess")}
                        </div>
                    </Offcanvas.Title>
                    <div className={"d-inline-flex align-content-end"}>
                        <Button variant="warning" onClick={()=>{Save()}}   className="float-end bold   text-uppercase"> {t.t("iot.global.save")}</Button>
                        <Button variant="outline-dark" onClick={handleClose}    className="float-end bold mx-2 text-uppercase"> {t.t("iot.global.close")}</Button>
                    </div>

                </Offcanvas.Header>
                <Offcanvas.Body >
                    {popuploading ?
                        <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"}/></div> :

                        <div className={"text-center mt-5 h3 "}>
                            {t.t("iot.global.when")}

                            <DropdownButton className={"mx-2 fixedH d-inline-block"} variant={"none"}
                                            title={devicetypeLabel.name}>
                                {Object.keys(devicetype).map((titles2, i) => {
                                    return (
                                        <Dropdown.Item onClick={() => {
                                            selectDeviceType(devicetype[i])
                                        }} className={"text-center bold"}>{devicetype[i]["name"]}</Dropdown.Item>
                                    )
                                })}
                            </DropdownButton>


                            {t.t("iot.global.and")}

                            <DropdownButton className={"mx-2 fixedH d-inline-block"} variant={"none"}
                                            title={PropertyLabel.name}>
                                {Object.keys(Property).map((titles2, i) => {
                                    return (
                                        <Dropdown.Item onClick={() => {
                                            setPropertyLabel({
                                                name: Property[i]["name"],
                                                id: Property[i]["devicePropertyIdPk"]
                                            })
                                        }} className={"text-center bold"}>{Property[i]["name"]}</Dropdown.Item>
                                    )
                                })}
                            </DropdownButton>

                            <DropdownButton className={"mx-2  d-inline-block"} variant={"none"}
                                            title={compareLabel.name}>
                                <Dropdown.Item onClick={() => {
                                    setcompareLabel({name: t.t("iot.global.isGrater"), id: 1})
                                }} className={"text-center bold"}>{t.t("iot.global.isGrater")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setcompareLabel({name: t.t("iot.global.isEqual"), id: 3})
                                }} className={"text-center bold"}>{t.t("iot.global.isEqual")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setcompareLabel({name: t.t("iot.global.isLess"), id: 2})
                                }} className={"text-center bold"}>{t.t("iot.global.isLess")}</Dropdown.Item>
                            </DropdownButton>

                            {t.t("iot.global.than")}

                            <DropdownButton className={"mx-2 fixedH text-center d-inline-block"} variant={"none"}
                                            title={valueLabel.name}>
                                <Dropdown.Item onClick={() => {
                                    setvalueLabel({name: t.t("iot.global.max"), id: 1})
                                }} className={"text-center bold"}>{t.t("iot.global.max")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setvalueLabel({name: t.t("iot.global.equal"), id: 3})
                                }} className={"text-center bold"}>{t.t("iot.global.equal")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setvalueLabel({name: t.t("iot.global.min"), id: 2})
                                }} className={"text-center bold"}>{t.t("iot.global.min")}</Dropdown.Item>
                            </DropdownButton>


                            {t.t("iot.global.notify")}
                            <DropdownButton className={"mx-2 fixedH d-inline-block"} variant={"none"}
                                            title={SomeoneLabel.name}>
                                {Object.keys(by).map((titles2, i) => {
                                    return (
                                        <Dropdown.Item onClick={() => {
                                            setSomeoneLabel({
                                                name: by[i]['firstName'] + " " + by[i]['lastName'],
                                                id: by[i]['userIdPk']
                                            })
                                        }}
                                                       className={"text-center bold"}>{by[i]['firstName'] + " " + by[i]['lastName']}</Dropdown.Item>
                                    )
                                })}
                            </DropdownButton>

                            <DropdownButton className={"mx-2 text-center d-inline-block"} variant={"none"}
                                            title={byLabel.name}>
                                <Dropdown.Item onClick={() => {
                                    setbyLabel({name: t.t("iot.global.email"), id: 1})
                                }} className={"text-center bold"}>{t.t("iot.global.email")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setbyLabel({name: t.t("iot.global.message"), id: 2})
                                }} className={"text-center bold"}>{t.t("iot.global.message")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => {
                                    setbyLabel({name: t.t("iot.global.system"), id: 3})
                                }} className={"text-center bold"}>{t.t("iot.global.system")}</Dropdown.Item>
                            </DropdownButton>

                            . {t.t("iot.global.withThis")}

                            <DropdownButton autoClose={"outside"} className={"mx-2 message d-inline-block"}
                                            variant={"none"} title={messageLabel}>
                                <Dropdown.Item className={"p-0"}>

                                    <textarea className={"form-control"}  onBlur={(e) => {
                                        setmessageLabel(e.currentTarget.value)
                                    }} style={{width: 200, height: 100}}>{messageLabel}</textarea>
                                 </Dropdown.Item>
                            </DropdownButton>
                        </div>
                    }
                </Offcanvas.Body>
            </Offcanvas>

        </div>
    )
}
export default SLA
