import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Image, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {Doughnut} from "react-chartjs-2";
import axios from "axios";
import {Lang, websetting} from "../../../../helpers/websetting";
import {useTranslation} from "react-i18next";
import {  MapPin, PieChart, Sun, Trash2, Truck, Video, Wifi} from "react-feather";
 import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import {SystemSetting} from "../../../../constants/system";



export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'right' as const,
        },
        title: {
            display: true,
        },
    },
};

function GeneralDashboard(){



     const t= useTranslation()
    const [loading,setloading] = useState(true)


    const [data,setdata] = useState([])
    const connectivityLabels = [t.t("iot.global.connected"), t.t("iot.global.disconnected")]
    const statusLabels = [t.t("iot.global.on"), t.t("iot.global.dim"), t.t("iot.global.off")]
     let data2 = {
        labels: connectivityLabels,
        datasets: [
            {
                label: '# of Votes',
                data: [],
                backgroundColor: [
                    '#9DE58C',
                    '#DA5A55',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    '#DA5A55',
                ],
                borderWidth: 0,
            },
        ],
    };
     let data3 = {
        labels: statusLabels,
        datasets: [
            {
                label: '# of Votes',
                data: [],
                backgroundColor: [
                    '#9DE58C',
                    'rgba(255, 206, 86, 1)',
                    '#999999',
                ],
                borderColor: [
                    '#9DE58C',
                    'rgba(255, 206, 86, 1)',
                    '#999999',
                ],
                borderWidth: 0,
            },
        ],
    };
    const [connectivity, setconnectivity] = useState( data2)
    const [status, setStatus] = useState( data3)

    useEffect(() => {
        setloading(true)
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/streetLightDashboard/general',
            {
                method: "get",
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(function (response) {
            setdata(response.data)
            const d1 :any = [response.data.onDevicesCount,response.data.dimDevicesCount,response.data.offDevicesCount]
            data3.datasets[0].data = d1
            setconnectivity(data3)

            const d2 :any = [response.data.connectedDevicesCount,response.data.disconnectedDevicesCount]
            data2.datasets[0].data = d2
            setStatus(data2)

            setloading(false)
        }).catch(function (error) {
        });
    },  [localStorage.getItem("lang")]);

    const info = JSON.parse(JSON.stringify(data))
    return(
        <div  className={"content dashboard"}>
            {loading ? <div className={"my-5 text-center"}><Spinner className={"my-5"}  variant={"warning"} animation={"border"}  /></div>
                :
                <div>
                    <Row>
                        <Col lg={5}>
                            <Row>
                                <Col className={"mb-3"} lg={12}>
                                    <div className="box">
                                        <div className={"pagetitle text-uppercase"}>
                                            <PieChart className="feather" size={24}/>
                                            <span
                                                className={"d-inline-block mx-2"}>{t.t("iot.global.activeVsDeactive")}</span>
                                        </div>

                                        <Tabs className={"justify-content-center"} defaultActiveKey={"t1"}>
                                            <Tab title={t.t("iot.global.connectivity")} eventKey={"t1"}>
                                                <div className={"d-flex flex   justify-content-center"}>
                                                    <div style={{height: 300, width: 300}}
                                                         className={"d-inline-flex justify-content-center my-auto"}>
                                                        <Doughnut options={options} data={connectivity}></Doughnut>
                                                    </div>
                                                </div>
                                            </Tab>
                                            <Tab title={t.t("iot.global.status")} eventKey={"t2"}>
                                                <div className={"d-flex flex   justify-content-center"}>
                                                    <div style={{height: 300, width: 300}}
                                                         className={"d-inline-flex justify-content-center my-auto"}>
                                                        <Doughnut options={options} data={status}></Doughnut>
                                                    </div>
                                                </div>
                                            </Tab>
                                        </Tabs>

                                    </div>
                                </Col>
                                <Col className={"mb-3"} lg={12}>
                                    <div className="box">
                                        <div className={"pagetitle text-uppercase"}>
                                            <Trash2 className={"feather"} size={24}/>
                                            <span className={"d-inline-block mx-2"}>{t.t("iot.global.smartBins")}</span>
                                        </div>
                                        <Row className={"text-center align-items-center"}>
                                            <Col lg={3}>
                                                <Image srcSet={window.location.origin + "/images/smart-bins.jpg"}
                                                       height="80px"/>
                                            </Col>
                                            <Col lg={9}>
                                                <Row className={"align-items-center"}>
                                                    <Col lg={12}>
                                                        <Row className={"align-items-center"}>
                                                            <Col lg={6}>
                                                                <div className={"bold"}>21</div>
                                                                <div>{t.t("iot.global.smartBins")}</div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className={"bold"}>3</div>
                                                                <div>50% {">"} {t.t("iot.global.trashLevel")}</div>
                                                                <div className={"bold"}>18</div>
                                                                <div>75% {">"} {t.t("iot.global.trashLevel")}</div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={7}>
                            <Row>
                                <Col className={"mb-3"} lg={12}>
                                    <div className="box">
                                        <div className={"pagetitle text-uppercase"}>

                                            <Sun className={"feather"} size={24}/>
                                            <span
                                                className={"d-inline-block mx-2"}>{t.t("iot.global.smartLighting")}</span>
                                        </div>
                                        <Row className={"text-center align-items-center"}>
                                            <Col lg={3}>
                                                <Image srcSet={window.location.origin + "/images/icon25.svg"}
                                                       height="80px"/>
                                            </Col>
                                            <Col lg={9}>
                                                <Row>

                                                    <Col lg={12}>
                                                        <Row>
                                                            <Col lg={4}>
                                                                <div
                                                                    className={"bold"}>{info && info.numberOfDevices}</div>
                                                                <div>{t.t("iot.global.totalLights")}</div>
                                                                <div className={"d-none bold"}>6:38</div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div
                                                                    className={"bold"}>{info && info.connectedDevicesCount}</div>
                                                                <div>{t.t("iot.global.connected")}</div>
                                                                <div className={"d-none bold"}>16:42</div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div
                                                                    className={"bold"}>{info && info.disconnectedDevicesCount}</div>
                                                                <div>{t.t("iot.global.disconnected")}</div>
                                                                <div className={"d-none bold"}>16:42</div>
                                                            </Col>


                                                            <Col className={"d-none"} lg={4}>
                                                                <div className={"bold"}>9</div>
                                                                <div>{t.t("iot.global.disconnected")}</div>
                                                                <div className={"d-none bold"}>16:42</div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>


                                    </div>
                                </Col>
                                <Col className={"mb-3"} lg={12}>
                                    {info &&
                                    <div className="box">
                                        <div className={"pagetitle mb-2 text-uppercase"}>
                                            <MapPin className={"feather"} size={24}/>
                                            <span
                                                className={"d-inline-block mx-2"}>{t.t("iot.global.devicesLocation")}</span>
                                        </div>

                                        {info.devicesList &&
                                        <LoadScript googleMapsApiKey={SystemSetting.mapKey}>
                                            <GoogleMap
                                                mapContainerStyle={{width: '100%', height: '370px'}}
                                                center={{
                                                    lat: parseFloat(info.devicesList[0].latitude),
                                                    lng: parseFloat(info.devicesList[0].longitude)
                                                }}
                                                zoom={19}>
                                                {Object.keys(info.devicesList).map((titles, i) => {
                                                    return (
                                                        <Marker
                                                            icon={websetting.webUrl + "/images/map/" + (info.devicesList[i].status == 0 ? "red" : (info.devicesList[i].status == 1 && info.devicesList[i].eventDesc == "Off" ? "grey" : "yellow")) + ".svg"}
                                                            position={{
                                                                lat: parseFloat(info.devicesList[i].latitude),
                                                                lng: parseFloat(info.devicesList[i].longitude)
                                                            }}></Marker>
                                                    )
                                                })}
                                            </GoogleMap>
                                        </LoadScript>
                                        }
                                    </div>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={"mb-3"} lg={6}>
                            <div className="box">
                                <div className={"pagetitle text-uppercase"}>
                                    <Video className={"feather"} size={24}/>
                                    <span className={"d-inline-block mx-2"}>{t.t("iot.global.smartCameras")}</span>
                                </div>
                                <Row className={"text-center align-items-center"}>
                                    <Col lg={3}>
                                        <Image srcSet={window.location.origin + "/images/icon27.svg"} height="55px"/>
                                    </Col>
                                    <Col lg={9}>
                                        <Row className={"align-items-center"}>
                                            <Col>
                                                <div className={"bold"}>63</div>
                                                <div>{t.t("iot.global.ipCameras")}</div>
                                            </Col>
                                            <Col>
                                                <Row className={"align-items-center"}>
                                                    <Col lg={12}>
                                                        <div className={"bold"}>61</div>
                                                        <div>{t.t("iot.global.activeIPCameras")}</div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className={"bold"}>2</div>
                                                        <div>{t.t("iot.global.activeEvents")}</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                        <Col className={"mb-3"} lg={6}>
                            <div className="box">
                                <div className={"pagetitle text-uppercase"}>
                                    <Wifi className={"feather"} size={24}/>
                                    <span className={"d-inline-block mx-2"}>{t.t("iot.global.wifiHotspots")}</span>
                                </div>
                                <Row className={"text-center align-items-center"}>
                                    <Col lg={3}>
                                        <Image srcSet={window.location.origin + "/images/wifi-hotspots.jpg"}
                                               height="60px"/>
                                    </Col>
                                    <Col lg={9}>
                                        <Row className={"align-items-center"}>
                                            <Col>
                                                <div className={"bold"}>102</div>
                                                <div>{t.t("iot.global.wifiHotspots")}</div>
                                            </Col>
                                            <Col>
                                                <Row className={"align-items-center"}>
                                                    <Col lg={12}>
                                                        <div className={"bold"}>89</div>
                                                        <div>{t.t("iot.global.activeHotspots")}</div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className={"bold"}>5334</div>
                                                        <div>{t.t("iot.global.connectedDevices")}</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col className={"mb-3 mt-1"} lg={6}>
                            <div className="box">
                                <div className={"pagetitle text-uppercase"}>
                                    <Sun className={"feather"} size={24}/>
                                    <span className={"d-inline-block mx-2"}>{t.t("iot.global.environmentalData")}</span>
                                </div>
                                <Row className={"text-center mt-2"}>
                                    <Col lg={6} className={"mb-3"}>
                                        <Row>
                                            <Col><Image srcSet={window.location.origin + "/images/temp.jpg"}
                                                        height="50px"/></Col>
                                            <Col>
                                                <div className={"bold"}>6.5 C</div>
                                                <div>{t.t("iot.global.temperature")}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6} className={"mb-3"}>
                                        <Row>
                                            <Col><Image srcSet={window.location.origin + "/images/noise.jpg"}
                                                        height="50px"/></Col>
                                            <Col>
                                                <div className={"bold"}>60.0 dB</div>
                                                <div>{t.t("iot.global.noise")}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <hr/>
                                    <Col lg={6} className={"mb-4"}>
                                        <Row>
                                            <Col><Image srcSet={window.location.origin + "/images/humidity.jpg"}
                                                        height="50px"/></Col>
                                            <Col>
                                                <div className={"bold"}>41%</div>
                                                <div>{t.t("iot.global.humidity")}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6} className={"mb-3"}>
                                        <Row>
                                            <Col><Image srcSet={window.location.origin + "/images/cloud.jpg"}
                                                        height="50px"/></Col>
                                            <Col>
                                                <div className={"bold"}>41%</div>
                                                <div>{t.t("iot.global.clouds")}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <hr/>
                                    <Col lg={6} className={"mb-3"}>
                                        <Row>
                                            <Col><Image srcSet={window.location.origin + "/images/light.jpg"}
                                                        height="50px"/></Col>
                                            <Col>
                                                <div className={"bold"}>1947.9 lux</div>
                                                <div>{t.t("iot.global.light")}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={6} className={"mb-3"}>
                                        <Row>
                                            <Col><Image srcSet={window.location.origin + "/images/NO2.jpg"}
                                                        height="50px"/></Col>
                                            <Col>
                                                <div className={"bold"}>67.1 pgb</div>
                                                <div>NO2</div>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>

                            </div>
                        </Col>
                        <Col className={"mb-3"} lg={6}>
                            <div className="box">
                                <div className={"pagetitle text-uppercase"}>
                                    <Truck className={"feather"} size={24}/>
                                    <span className={"d-inline-block mx-2"}>{t.t("iot.global.parkingSpots")}</span>
                                </div>
                                <Row className={"text-center"}>
                                    <Col lg={3}>
                                        <Image srcSet={window.location.origin + "/images/icon28.svg"} height="45px"/>
                                    </Col>
                                    <Col lg={9}>
                                        <Row>
                                            <Col>
                                                <div className={"bold"}>12</div>
                                                <div>{t.t("iot.global.parkingSpots")}</div>
                                            </Col>
                                            <Col>
                                                <div className={"bold"}>150</div>
                                                <div>{t.t("iot.global.thisWeek")}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <table className={"table table-hover"}>
                                    <tbody>
                                    <tr>
                                        <td width="50px" className={"bold"}>24</td>
                                        <td>{t.t("iot.global.parkingMeters")}</td>
                                    </tr>
                                    <tr>
                                        <td className={"bold"}>36</td>
                                        <td>{t.t("iot.global.signBoards")}</td>
                                    </tr>
                                    <tr>
                                        <td className={"bold"}>350</td>
                                        <td>{t.t("iot.global.parkingLots")}</td>
                                    </tr>
                                    <tr>
                                        <td className={"bold"}>126</td>
                                        <td>{t.t("iot.global.parkingLights")}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
}



export default GeneralDashboard;
