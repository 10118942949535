import React, {useEffect, useRef, useState} from "react";
import {
    ChevronLeft,
    Clipboard,
    Columns,
    Eye,
    Layers,
    Menu, PieChart,
    Search,
    Settings,
    Sliders,
} from "react-feather";
import {Button, Col, Dropdown, Form, Row, Spinner} from "react-bootstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import {ModulaIds, websetting} from "../../../../../helpers/websetting";
import Moment from "moment";
import {Link, useParams} from "react-router-dom";
import {SystemSetting} from "../../../../../constants/system";
import {useTranslation} from "react-i18next";
function Casebycategory(){
    const t = useTranslation()
    let {id} = useParams()
    let {cid} = useParams()
    let categoryId = "";
    if(cid == null){
        const url = window.location.search;
        const params = new URLSearchParams(url);
        categoryId = params.get('categoryId') || "";
    }else{
        categoryId = cid
    }

    const [category, setcategory] = useState([])

    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [search, setSearch] = useState({
        startDate: null,
        endDate: null,
        requesterName: null,
        requesterIdNo: null,
        caseId: null,
        search: null,
        assignUserId: null,
        statusTypeId: null,
        formId: null,
    });
    const [form,setForm] = useState([]);
    const [user,setUser] = useState([]);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const columns = [
        {
            sortable: true,
            name: t.t("LblNumber"),
            cell: (row : any) => <div>{row.id}</div>,
            style:{
                "fontSize":"13px",
            }
        },
        {
            sortable: true,
            name: t.t("LblRequesterName"),
            selector: (row : any) => row.customerName,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("LblForm"),
            selector: (row : any) => row.formName,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("LblCreatedOn"),
            selector: (row : any) => Moment(row.createdOn).format(websetting.dateFormat),
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("LblEndate"),
            selector: (row : any) => row.endDate!=null ? Moment(row.endDate).format(websetting.dateFormat) : "-",
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("LblEndate"),
            cell: (row : any) => <span><span className={"status-dot"} style={{backgroundColor:row.caseStatusColor,width:5,height:5}}></span> {row.caseStatus}</span>,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("LblAssginedToUser") ,
            selector: (row : any) => row.assigned,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("LblSlaTime"),
            selector: (row : any) => row.slaHour,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("LblSlaActule"),
            selector: (row : any) => <div>{row.slaActual ? <span className={"badge py-1 outline-badge-danger"} style={{direction:"ltr",lineHeight:1}}>{row.slaActual}</span> : "-"}</div>,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("LblSlaDone"),
            selector: (row : any) => <div>{row.slaDone}</div>,
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Link to={"../CaseDetails/"+row.id+"?categoryId="+row.categoryId+"&formId="+row.formId}  title={t.t("LblEdit")} className={"btn btn-sm btn-warning rounded-circle"}><Eye className={"mt-1"} size={15} /></Link></div>,
        }
    ]

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.post(websetting.automationUrl + 'Case/GetRequestForCategory?module='+ModulaIds.Automation,
            {
                search: search.search != null ? search.search : null,
                startDate: search.startDate != null ? search.startDate : null,
                endDate: search.endDate != null ? search.endDate : null,
                requesterName: search.requesterName != null ? search.requesterName : null,
                requesterIdNo: search.requesterIdNo != null ? search.requesterIdNo : null,
                caseId: search.caseId != null ? search.caseId : null,
                assignUserId: search.assignUserId != null ? search.assignUserId : null,
                statusTypeId: search.statusTypeId != null ? search.statusTypeId : null,
                formId: search.formId != null ? search.formId : null,
                page: page,
                rows: perPage,
                categoryId: cid,
            },
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        setLoading(true);
        axios.post(websetting.automationUrl + 'Case/GetRequestForCategory?module='+ModulaIds.Automation,
            {
                search: search.search != null ? search.search : null,
                startDate: search.startDate != null ? search.startDate : null,
                endDate: search.endDate != null ? search.endDate : null,
                requesterName: search.requesterName != null ? search.requesterName : null,
                requesterIdNo: search.requesterIdNo != null ? search.requesterIdNo : null,
                caseId: search.caseId != null ? search.caseId : null,
                assignUserId: search.assignUserId != null ? search.assignUserId : null,
                statusTypeId: search.statusTypeId != null ? search.statusTypeId : null,
                formId: search.formId != null ? search.formId : null,
                page: page,
                rows: newPerPage,
                categoryId: cid,
            },
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setPerPage(newPerPage);
            setLoading(false);
        }).catch(function (error) {
        });
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, [localStorage.getItem("lang"),id]);

    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
            search.search = e.currentTarget.value
            search.startDate = null
            search.endDate = null
            search.requesterName = null
            search.requesterIdNo = null
            search.caseId = null
            search.assignUserId = null
            search.statusTypeId = null
            search.formId = null

                fetchDta(1); // fetch page 1 of users
        }
     }
     const advanceSearch = (e:any) => {
         search.search = null
         if(e.currentTarget.startDate.value != ""){
             search.startDate = e.currentTarget.startDate.value
         }else{
             search.startDate = null
         }
         if(e.currentTarget.endDate.value != ""){
             search.endDate = e.currentTarget.endDate.value
         }else{
             search.endDate = null
         }
         if(e.currentTarget.requesterName.value != ""){
             search.requesterName = e.currentTarget.requesterName.value
         }else{
             search.requesterName = null
         }
         if(e.currentTarget.requesterIdNo.value != ""){
             search.requesterIdNo = e.currentTarget.requesterIdNo.value
         }else{
             search.requesterIdNo = null
         }
         if(e.currentTarget.caseId.value != ""){
             search.caseId = e.currentTarget.caseId.value
         }else{
             search.caseId = null
         }

         if(e.currentTarget.formId.value != ""){
             search.formId = e.currentTarget.formId.value
         }else{
             search.formId = null
         }

         if(e.currentTarget.statusTypeId.value != ""){
             search.statusTypeId = e.currentTarget.statusTypeId.value
         }else{
             search.statusTypeId = null
         }

         if(e.currentTarget.assignUserId.value != ""){
             search.assignUserId = e.currentTarget.assignUserId.value
         }else{
             search.assignUserId = null
         }


         fetchDta(1);
         e.preventDefault()

     }
    useEffect(() => {
        axios.get(websetting.automationUrl + 'AutoFormCategories/getbyid/' + categoryId + "?module=" + ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
                ).then(async function (response) {
                setcategory(response.data)
                }).catch(function (error) {
        });
    }, [localStorage.getItem("lang"),id]);



    axios.get(websetting.apiUrl + 'auth/api/v1/user/EMPLOYEE/getUsersByUserType?pageNO=0&pageSize=100',
        {
            method: "get",
            headers: {
                'Accept-Language':localStorage.getItem("lang") as string,
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
            }
        }
    ).then(async function (response) {
        setUser(response.data.data)
    }).catch(function (error) {
    });


    const info =  JSON.parse(JSON.stringify(category))


    return(
        <div>
            <div className={"lightbox mb-3"}>
                <div className={"pagetitle d-flex justify-content-between  text-uppercase"}>
                    <span className={"d-inline-flex align-items-center mx-2"}><Clipboard className={"feather"} /> {t.t("LblMyTasks")}
                        {category &&
                        <span className={"d-inline-flex text-black-50 align-items-center fs-6 px-3"}>
                                    <div>
                                            {category && <div><Link
                                                to={"../CategoryCase/" + info.id}>{info.nameAr}</Link>
                                            </div>}
                                        </div>

                                </span>
                        }
                    </span>
                    <div className={"d-inline-flex align-items-center"}>
                        <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                            <Form    action={""} method={"post"}  >
                                <Form.Group controlId={"input"}>
                                    <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                                </Form.Group>
                            </Form>
                            <Button variant={"none"} onClick={()=>setShowSearch(showSearch ? false : true)} href={"#"} className={"position-absolute p-0 end-0 top-50 me-2 translate-middle-x translate-middle-y"} style={{zIndex:3}}><Sliders /></Button>
                        </div>
                    </div>
                </div>
                {showSearch &&
                <div>
                    <hr className={"shodow-hr"}/>
                    <Form onSubmit={(e)=>{advanceSearch(e)}} method={"post"} >
                        <Row>
                           <Col xl={10}>
                               <Row>
                                   <Col >
                                       <Form.Group controlId={"startDate"}  >
                                           <Form.Control   size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"date"} placeholder={t.t("LblFormDate")}/>
                                       </Form.Group>
                                   </Col>
                                   <Col>
                                       <Form.Group  controlId={"endDate"}>
                                           <Form.Control  size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"date"}
                                                          placeholder={t.t("LblToDate")}/>
                                       </Form.Group>
                                   </Col>
                                   <Col>
                                       <Form.Group  controlId={"requesterName"}>
                                           <Form.Control  size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"text"}
                                                          placeholder={t.t("LblRequesterName")}/>
                                       </Form.Group>
                                   </Col>
                                   <Col>
                                       <Form.Group  controlId={"requesterIdNo"}>
                                           <Form.Control size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"text"}
                                                         placeholder={t.t("LbRequesterIdNo")}/>
                                       </Form.Group>
                                   </Col>
                                   <Col>
                                       <Form.Group  controlId={"caseId"}>
                                           <Form.Control size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"text"}
                                                         placeholder={t.t("LblNumber")}/>
                                       </Form.Group>
                                   </Col>
                               </Row>
                               <Row className={"mt-2"}>
                                   <Col>
                                   <Form.Group  controlId={"assignUserId"}>
                                       <Form.Select size={"sm"} style={{height: "inherit",fontSize:"14px"}}>
                                           <option value="">{t.t("LblAssginedToUser")}</option>
                                           {Object.keys(user).map((titles2, i) => {
                                               return (
                                                   <option value={user[i]["userIdPk"]}>{user[i] && user[i]["firstName"] + " " + user[i]["lastName"]}</option>
                                                )
                                           })
                                           }
                                       </Form.Select>
                                   </Form.Group>
                                   </Col>
                                   <Col>
                                       <Form.Group  controlId={"statusTypeId"}>
                                           <Form.Select size={"sm"} style={{height: "inherit",fontSize:"14px"}}>
                                               <option value="">{t.t("LblCaseStatus")}</option>
                                               <option value="1">{t.t("LblOpenedRequest")}</option>
                                               <option value="2">{t.t("LblClosedRequest")}</option>
                                               <option value="3">{t.t("LblPendingRequest")}</option>
                                           </Form.Select>
                                       </Form.Group>
                                   </Col>
                                   <Col>
                                       <Form.Group  controlId={"formId"}>
                                           <Form.Select size={"sm"} style={{height: "inherit",fontSize:"14px"}}>
                                               <option value="">{t.t("LblChooseForm")}</option>
                                               {Object.keys(form).map((titles2, i) => {
                                                   return (
                                                       <option value={form[i]["id"]}>{form[i] && form[i]["name"]}</option>
                                                   )
                                               })
                                               }
                                           </Form.Select>
                                       </Form.Group>
                                   </Col>
                               </Row>
                           </Col>
                            <Col xl={2}>
                                <Button type={"submit"} variant={"dark"} aria-label={"search"}
                                         className={"w-100"}><Search/></Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                }
            </div>

            <div className={"lightbox"}>
            <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                <div className={"d-inline-flex align-items-center"}>
                    <a className={"btn btn-sm btn-outline-success px-1 pe-2 btn-rounded"}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="256" className={"mx-1"} height="256" viewBox="0 0 256 256"  stroke="none" fill="currentColor" >
                            <g transform="translate(128 128) scale(0.72 0.72)">
                                <g  transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)" >
                                    <path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 4 C 22.393 4 4 22.393 4 45 s 18.393 41 41 41 s 41 -18.393 41 -41 S 67.607 4 45 4 z"  transform=" matrix(1 0 0 1 0 0) "   />
                                    <path d="M 45 69.345 c -7.954 0 -15.337 -3.969 -19.751 -10.617 c -0.611 -0.92 -0.36 -2.162 0.56 -2.772 c 0.92 -0.613 2.162 -0.36 2.772 0.56 c 3.671 5.529 9.809 8.83 16.419 8.83 c 6.61 0 12.748 -3.301 16.419 -8.83 c 0.61 -0.921 1.85 -1.173 2.772 -0.56 c 0.92 0.61 1.171 1.853 0.56 2.772 C 60.337 65.376 52.953 69.345 45 69.345 z"  transform=" matrix(1 0 0 1 0 0) "  />
                                    <path d="M 25.238 42.268 c -0.68 0 -1.343 -0.347 -1.718 -0.973 c -0.567 -0.948 -0.259 -2.176 0.688 -2.743 l 7.424 -4.444 l -7.424 -4.444 c -0.948 -0.567 -1.256 -1.795 -0.688 -2.743 c 0.566 -0.948 1.794 -1.258 2.743 -0.688 l 10.291 6.16 c 0.604 0.361 0.973 1.013 0.973 1.716 s -0.369 1.355 -0.973 1.716 l -10.291 6.16 C 25.941 42.176 25.587 42.268 25.238 42.268 z"  transform=" matrix(1 0 0 1 0 0) "   />
                                    <path d="M 64.762 42.268 c -0.35 0 -0.703 -0.092 -1.025 -0.285 l -10.29 -6.16 c -0.604 -0.361 -0.973 -1.013 -0.973 -1.716 s 0.369 -1.355 0.973 -1.716 l 10.29 -6.16 c 0.95 -0.567 2.176 -0.259 2.743 0.688 c 0.567 0.948 0.259 2.176 -0.688 2.743 l -7.424 4.444 l 7.424 4.444 c 0.947 0.567 1.256 1.795 0.688 2.743 C 66.105 41.92 65.442 42.268 64.762 42.268 z" transform=" matrix(1 0 0 1 0 0) "   />
                                </g>
                            </g>
                        </svg>
                        <span className={"d-none bold d-md-inline-block"}>{t.t("SlaGood")}</span>
                    </a>
                    <a className={"btn btn-sm btn-outline-warning mx-1 px-1 pe-2 btn-rounded"}>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" className={"mx-1"} height="24" viewBox="0 0 256 256" stroke="none" fill="currentColor" opacity={1} >
                            <g transform="translate(128 128) scale(0.72 0.72)" >
                                <g  transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)" >
                                    <path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 4 C 22.393 4 4 22.393 4 45 s 18.393 41 41 41 s 41 -18.393 41 -41 S 67.607 4 45 4 z"  transform=" matrix(1 0 0 1 0 0) "  />
                                    <circle cx="31" cy="35.55" r="5"  transform="  matrix(1 0 0 1 0 0) "/>
                                    <circle cx="59" cy="35.55" r="5"  transform="  matrix(1 0 0 1 0 0) "/>
                                    <path d="M 63.085 63.621 h -36.17 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 36.17 c 1.104 0 2 0.896 2 2 S 64.189 63.621 63.085 63.621 z"  transform=" matrix(1 0 0 1 0 0) " />
                                </g>
                            </g>
                        </svg>
                        <span className={"d-none bold d-md-inline-block"}>{t.t("SlaWarning")}</span>
                    </a>
                    <a className={"btn btn-sm btn-outline-danger px-1 pe-2 btn-rounded"}>
                        <svg xmlns="http://www.w3.org/2000/svg"  version="1.1" width="256" className={"mx-1"} height="256" viewBox="0 0 256 256" stroke="none" fill="currentColor" >
                            <g transform="translate(128 128) scale(0.72 0.72)" >
                                <g transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)" >
                                    <path d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z M 45 4 C 22.393 4 4 22.393 4 45 s 18.393 41 41 41 s 41 -18.393 41 -41 S 67.607 4 45 4 z" transform=" matrix(1 0 0 1 0 0) "   />
                                    <circle cx="30.344" cy="33.274" r="5.864"  transform="  matrix(1 0 0 1 0 0) "/>
                                    <circle cx="59.663999999999994" cy="33.274" r="5.864"    transform="  matrix(1 0 0 1 0 0) "/>
                                    <path d="M 72.181 65.49 c -0.445 0 -0.893 -0.147 -1.265 -0.451 c -7.296 -5.961 -16.5 -9.244 -25.916 -9.244 c -9.417 0 -18.62 3.283 -25.916 9.244 c -0.854 0.7 -2.115 0.572 -2.814 -0.283 c -0.699 -0.855 -0.572 -2.115 0.283 -2.814 C 24.561 55.398 34.664 51.795 45 51.795 c 10.336 0 20.438 3.604 28.447 10.146 c 0.855 0.699 0.982 1.959 0.283 2.814 C 73.335 65.239 72.76 65.49 72.181 65.49 z" transform=" matrix(1 0 0 1 0 0) "   />
                                </g>
                            </g>
                        </svg>
                        <span className={"d-none bold d-md-inline-block"}>{t.t("SlaBad")}</span>
                    </a>
                </div>
                <span className={"d-inline-flex align-items-center mx-2"}>
                    <Link to={"../cDashBoard/"+cid}  className={"btn btn-info mx-2 btn-sm btn-rounded"}><PieChart /> Dashboard</Link>
                 </span>
            </div>
            <hr className={"shodow-hr"} />
            <div>
                <DataTable progressPending={loading}
                           pagination
                           paginationServer
                           paginationTotalRows={totalRows}
                           progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                           onChangeRowsPerPage={handlePerRowsChange}
                           onChangePage={handlePageChange} columns={columns} data={data} />
            </div>
        </div>
        </div>
    )
}

export default Casebycategory;