import React, {useEffect, useState} from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import {Lang, UserProfile, websetting} from "../../helpers/websetting";
import {UseLang, useTitle} from "../../helpers/function";
import {
    Container,
    Dropdown,
    Image,
    Row,
    Col,
    Navbar,
    Nav,
    Offcanvas,
    ListGroup
} from "react-bootstrap";
import {Link, Route, Router, Routes, useNavigate,} from "react-router-dom";
import '../../assets/css/citizen.css'
import Profile from "./Pages/Profile";
import MyServices from "./Pages/MyServices";
import Main from "./Pages/Main";
import CaseDetails from "./Pages/CaseDetails";
import ChangePassword from "./Pages/ChangePassword";
import ProfileForm from "./Pages/ProfileForm";



function CitizenPage(){
    const [show, setShow] = useState(false);
    const toggleShow = () => setShow((s) => !s);
    const handleClose = () => setShow(false);

    const [showNotifcation, setShowNotifcation] = useState(false);
    const handleCloseNotifcation = () => setShowNotifcation(false);
    const handleShowNotifcation = () => setShowNotifcation(true);


    const navigate = useNavigate()


    const ClearSession = () => {
        sessionStorage.clear();
        navigate("../");
    }
    return(
        <div id="CitizenPortal">
            <header>
                <Container className="gx-0 gx-lg-5">
                    <Row className="gx-0 gx-lg-5">
                        <Col className="my-auto gx-0  align-content-center align-items-center">
                            <div className="my-auto position-relative">
                                <Navbar bg="none" className="my-auto py-1 float-end float-lg-none" expand={true}>
                                    <Navbar.Collapse id="basic-navbar-nav" className="s">
                                        <Nav className="float-end me-lg-0 me-2">
                                            <Nav.Item>
                                                <Dropdown align="start" className="welcome">
                                                    <Dropdown.Toggle as={"a"} href="#" variant="none">
                                                        <Image id="profileImg3" width={35} height={35}
                                                               className="rounded-circle"
                                                               srcSet={UserProfile.userImage}/>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="text-center">
                                                        <Dropdown.Item href="#">Profile</Dropdown.Item>
                                                        <Dropdown.Item href="#">Setting</Dropdown.Item>
                                                        <Dropdown.Item as={Link} to="../"
                                                                       onClick={ClearSession}>Logout</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link as={Link} className="px-1 px-lg-3" to={"Profile"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24"
                                                         fill="none" stroke="currentColor" stroke-width="2"
                                                         stroke-linecap="round"
                                                         stroke-linejoin="round" className="feather mx-md-1 feather-user">
                                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                        <circle cx="12" cy="7" r="4"></circle>
                                                    </svg>
                                                    <span className="d-lg-inline-block d-none">החשבון שלי</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link href={"#"} onClick={handleShowNotifcation} className="px-1 px-lg-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24"
                                                         fill="none" stroke="currentColor" stroke-width="2"
                                                         stroke-linecap="round"
                                                         stroke-linejoin="round" className="feather mx-md-1 feather-bell">
                                                        <path
                                                            d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                                                    </svg>
                                                    <span className="d-lg-inline-block d-none">התראות</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className="px-1 px-lg-3">
                                                    <UseLang position={"end"} />
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link href="#" onClick={ClearSession} className="px-1 px-lg-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                         viewBox="0 0 24 24"
                                                         fill="none" stroke="currentColor" stroke-width="2"
                                                         stroke-linecap="round"
                                                         stroke-linejoin="round"
                                                         className="feather feather-log-out">
                                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                                        <polyline points="16 17 21 12 16 7"></polyline>
                                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                                    </svg>
                                                    <span className="d-lg-inline-block d-none">התנתקות</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Nav className="float-start">
                                            <Nav.Item className="lastseen text-black-50 d-none d-lg-flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24"
                                                     fill="none" stroke="currentColor" stroke-width="2"
                                                     stroke-linecap="round"
                                                     stroke-linejoin="round" className="feather mx-md-1 feather-eye">
                                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                    <circle cx="12" cy="12" r="3"></circle>
                                                </svg>
                                                ביקורך האחרון : <span className="f-700" id="LastLogIn">2021-12-5 17:13:48</span>
                                            </Nav.Item>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>

                                <Navbar bg="none" expand="lg" dir={"rtl"} className="p-0 d-lg-none d-inline-block float-start">
                                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={toggleShow}/>
                                    <Navbar.Brand>
                                        <Link to={"./"}>
                                            <Image srcSet={websetting.orgLogo}/>
                                        </Link>
                                    </Navbar.Brand>
                                    <Navbar.Offcanvas
                                        onHide={handleClose}
                                        scroll={false}
                                        backdrop={true}
                                        show={show}
                                        id="offcanvasNavbar"
                                        aria-labelledby="offcanvasNavbarLabel"
                                        placement="end">
                                        <Offcanvas.Header closeButton>
                                            <Offcanvas.Title id="offcanvasNavbarLabel">
                                                <Link to={"./"} onClick={() => setShow(false)}>
                                                    <Image height="40px" srcSet={websetting.orgLogo}/>
                                                </Link>
                                            </Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body className="px-0">
                                            <ListGroup className="CitizenMenu">
                                                <ListGroup.Item><Link onClick={() => setShow(false)}
                                                                      to={"./Profile"}>החשבון
                                                    שלי</Link></ListGroup.Item>
                                                <ListGroup.Item><Link onClick={() => setShow(false)}
                                                                      to={"./MyServices"}>שירויתים דיגיטליים</Link></ListGroup.Item>
                                            </ListGroup>
                                        </Offcanvas.Body>
                                    </Navbar.Offcanvas>
                                </Navbar>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
            <section>
                <Container>
                    <Row className="my-lg-4 mt-lg-5 my-3  ">
                        <Col className="d-lg-block d-none">
                            <Link to={"./"}>
                                <Image srcSet={websetting.orgLogo} height="70"/>
                                <h2 className="d-inline-flex me-2">פלטפורמת עיר חכמה</h2>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mainDiv d-flex">
                                <div className="MenuBar d-lg-block d-none py-4">
                                    <div className="text-center">
                                        <Link to={"Profile"}>
                                            <Image id="profileImg3" width="45%" height="45%"
                                                   className="rounded-circle" srcSet={UserProfile.userImage}/>
                                        </Link>
                                    </div>
                                    <ListGroup className="my-4">
                                        <ListGroup.Item><Link to={"./Profile"}>החשבון שלי</Link></ListGroup.Item>
                                        <ListGroup.Item><Link to={"./MyServices"}>שירויתים
                                            דיגיטליים</Link></ListGroup.Item>
                                    </ListGroup>
                                </div>
                                <div className="PageContent p-lg-4 p-3">
                                    <Routes>
                                        <Route path="*" element={<Main/>}></Route>
                                        <Route path="/" element={<Main/>}></Route>
                                        <Route path="Profile" element={<Profile/>}></Route>
                                        <Route path="MyServices" element={<MyServices/>}></Route>
                                        <Route path="CaseDetails/:id" element={<CaseDetails/>}></Route>

                                        <Route path="ChangePassword" element={<ChangePassword/>}></Route>
                                        <Route path="ProfileForm" element={<ProfileForm/>}></Route>
                                    </Routes>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>

            <Offcanvas  placement={"end"} show={showNotifcation} onHide={handleCloseNotifcation}>
                <Offcanvas.Header className={"border-bottom bg-light"} closeButton>
                    <Offcanvas.Title>
                        <div className={"h6 bold py-0 expand my-0"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                 viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2"
                                 stroke-linecap="round"
                                 stroke-linejoin="round" className="feather mx-md-1 feather-bell">
                                <path
                                    d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                            </svg>
                            الاشعارات
                        </div>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={"d-flex justify-content-center align-items-center"} >
                    <span className={"bold"}>
                        <div className={"text-center"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" className="feather my-3 color1 feather-alert-triangle">
                                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                <line x1="12" y1="9" x2="12" y2="13"></line>
                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                            </svg>
                        </div>
                        لا يوجد اشعارات
                    </span>
                </Offcanvas.Body>
            </Offcanvas>

        </div>

    )
}





export default CitizenPage;