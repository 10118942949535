import {Edit, FilePlus, FileText, PhoneCall, Trash} from "react-feather";
import {Button, Col, Form, Image, Modal, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {Ref, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {editoroptions, ModulaIds, websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {ro} from "suneditor/src/lang";

function Upload(){
    const t = useTranslation()


    const [validated, setValidated] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);


    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
    }




    const columns = [
        {
            width:"50px",
            sortable: true,
            name:  "#",
            selector: (row : any) => row.id,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.fileDescription"),
            cell: (row : any) => <div>{row.nameHe}</div>,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.fileType"),
            cell: (row : any) => <div>{getExtension(row.fileUrl)}</div>,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.fileUpload"),
            cell: (row : any) => <Button variant={"danger"} size={"sm"} onClick={()=>{saveLink(row.fileUrl)}} >{t.t("cms.copy")}</Button>,
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button></div>,
        }
    ]

    function getExtension(filename:any) {
        return filename.split('.').pop()
    }
    const saveLink = (url:any) => {

        const textField = document.createElement('input')
        textField.value = url
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        alert("تم نسخ الرابط بنجاح, يمكنك مشاركته من خلال اللصق")
    }

    const edit = (id:any) => {
        setShow(true);
    }


    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.post(websetting.cmsUrl + 'ConfigCmsFile/DeleteFile/'+id+'?module='+ModulaIds.Automation ,
                {},{
                    method: "post",
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }




    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;



            const formData = new FormData(event.target);
            //const file = event.target.File.files[0];

            axios.post(websetting.cmsUrl + 'ConfigCmsFile/AddFile',
                formData,
                {
                    method: "post",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setData(data.concat(response.data))
                setShow(false)
                setLoadingModal(true)
            }).catch(function (error) {
                setLoadingModal(true)
            });
        }
    };



    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'ConfigCmsFile/Get?module='+ModulaIds.Automation+"&page="+page,
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);
        }).catch(function (error) {

        });
    };





    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []       );
    const infos =  JSON.parse(JSON.stringify(infoData))




    return(
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                    <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <FilePlus className={"feather mx-2"} /> {t.t("cms.uploadcenter")}
                     </span>
                    </div>
                    <span className={"d-inline-flex align-items-center mx-2"}>



                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><FilePlus /> {t.t("cms.addFile")}</Button>
                 </span>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    <DataTable
                        progressPending={loading}
                        pagination
                        paginationServer
                        progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                        columns={columns}
                        data={data}
                    />
                </div>
            </div>


            <Modal size={"xl"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><FileText className={"feather"} /> {t.t("cms.uploadcenter")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>
                                    <Form.Group hidden controlId="FileUrl">
                                        <Form.Control type={"hidden"} value={infos.imageUrl}/>
                                    </Form.Group>
                                    <Col md={12}>
                                        <Form.Group className="my-2 mt-4" controlId="fileDescription">
                                            <Form.Label className="my-0">{t.t("cms.fileDescription")} He:</Form.Label>
                                            <Form.Control required type="text" name={"NameHe"}
                                                          aria-label={t.t("cms.fileDescription")}
                                                          aria-describedby="msg_fileDescription"/>
                                            <Form.Control.Feedback type="invalid" id="msg_fileDescription">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="my-2 mt-4" controlId="fileDescription">
                                            <Form.Label className="my-0">{t.t("cms.fileDescription")} Ar:</Form.Label>
                                            <Form.Control type="text" name={"NameAr"}
                                                          aria-label={t.t("cms.fileDescription")}
                                                          aria-describedby="msg_fileDescription"/>
                                            <Form.Control.Feedback type="invalid" id="msg_fileDescription">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                        <Form.Group className="my-2 mt-4" controlId="File">
                                            <Form.Label className="my-0">{t.t("cms.fileUpload")}:</Form.Label>
                                            <Form.Control type="file" required name={"File"}
                                                          aria-label={t.t("cms.fileUpload")}
                                                          aria-describedby="msg_file"/>
                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>







                                </Row>
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    )
}

export default Upload;
