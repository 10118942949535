import {Button, Col, Form, Modal, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    AlertTriangle,
    BarChart2,
    Bell,
    Calendar,
    Check, Edit,
    Eye,
    Loader,
    PieChart,
    Sun,
    Tool,
    Trash,
    Zap,
    ZapOff
} from "react-feather";
import axios from "axios";
import {websetting} from "../../../../../../helpers/websetting";
import DataTable from "react-data-table-component";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Moment from "moment";
import {useTranslation} from "react-i18next";
import {SystemSetting} from "../../../../../../constants/system";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {set} from "react-hook-form";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);



function Device(prop : any){
    const t = useTranslation()
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [sync, setsync] = useState([]);
    const [serviceVendor, setServiceVendor] = useState([]);
    const [deviceType, setDeviceType] = useState([]);

    const [historydata, setHistorydata] = useState([]);
    const [taskdata, setTaskdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [showAddevive, setshowAddevive] = useState(false);
    const [info, setInfo] = useState(true);
    const [infoData, setInfoData] = useState({});
    const [infoDevice, setinfoDevice] = useState({});
    const [devId, setDevId] = useState(0);
    const [DeviceDashboard, setDeviceDashboard] = useState([]);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);
    const [loadingSync, setLoadingSync] = useState(false);

    useEffect(()=>{
        axios.get(websetting.apiUrl + "auth/api/v1/serviceVendors/"+prop.trvId+"/getServiceVendors",
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setServiceVendor(response.data.serviceVendorsList);
        }).catch(function (error) {
        });


        axios.get(websetting.apiUrl + "setup-service/api/v1/deviceTypes?pageNO=0&pageSize=300",
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setDeviceType(response.data.data);
        }).catch(function (error) {
        });


    },[])

    const OpenAddevice = (id:any) => {
        if(id != ""){
            setLoadingModal(true)
            axios.get(websetting.apiUrl + 'lighting-service/api/v1/devices/'+id,
                {
                    method: "get",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setinfoDevice(response.data);
                setLoadingModal(false)
            }).catch(function (error) {
            });
        }

      setshowAddevive(true)
    }

    let options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: '',
            },
        },
    };

    let chartdata1 = {
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
                borderWidth: 1,
            },
        ],
    };
    const [chart, setChart] = useState(chartdata1);

    const handleClose = () => {
        setShow(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setDevId(0)
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }
    const handleClose2 = () => {
        setShow4(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
    }
    const closeAddevice = () => {
        setshowAddevive(false);
        setValidated(false);
        setInfo(true);
        setLoadingModal(false)
        setInfoData({})
        setinfoDevice({})
    }

    const view = (id:any) => {
        setShow(true);
        setLoadingModal(true);
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/devices/'+id,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setInfoData(response.data);
            setLoadingModal(false);
        }).catch(function (error) {

        });
    }

    const devicedashboard = (id:any) => {
        setShow1(true);
        setLoadingModal(true)
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/streetLightDashboard/'+id+'/device',
            {
                method: "get",
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(function (response) {
            let lb :any = []
            let color :any = []
            let value :any = []

            for(var i=0; i< response.data.messagesList.length ;i++){
                lb.push(response.data.messagesList[i].eventDesc);
                color.push(response.data.messagesList[i].color);
                value.push(response.data.messagesList[i].value);
                //console.log(response.data.messagesList[i].eventDesc);
            }

            chartdata1.datasets[0].data = value
            chartdata1.datasets[0].backgroundColor = color
            chartdata1.labels = lb
            setChart(chartdata1)
            setDeviceDashboard(response.data)

            setLoadingModal(false)

        }).catch(function (error) {
        });
    }
    const devicemsg = (id:any) => {
        setShow2(true)
        fetchLog(id,1)
    }
    const fetchLog = async (id:any,page:any) => {
        setLoadingModal(true)
        const p = page - 1
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/deviceMessages/' + id + '/logs/?pageNO=' + p + '&pageSize=' + SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setHistorydata(response.data.data);
            setLoadingModal(false)
        }).catch(function (error) {

        });
    }
    const deviceschedule = (id:any) => {
        setDevId(id)
        setShow3(true)
        setLoadingModal(true)
        axios.get(websetting.apiUrl + 'schedule-service/api/v1/schedule/' + id,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setTaskdata(response.data.lightingTasksList);
            setLoadingModal(false)
        }).catch(function (error) {

        });
    }
    const setOn = (id:any) => {
        const index = data.findIndex( (array) => {
            return parseInt(array["devId"]) === id
        });
        let prevState = JSON.parse(JSON.stringify(data));

        prevState[index].eventDesc= "-3"
        setData(prevState)
        axios.post(websetting.apiUrl + 'lighting-service/api/v1/deviceControl/turnOnDevice',
            {
                devId : id
            },
            {
                method: "post",
                headers: {
                    'user-name-header': "'" + sessionStorage.getItem("username") + "'",
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            const index = data.findIndex( (array) => {
                return parseInt(array["devId"]) === id
            });
            let prevState = JSON.parse(JSON.stringify(data));
            prevState[index].eventDesc = "ON"
            setData(prevState)
        }).catch(function (error) {

        });
    }
    const setDim = (id:any) => {
        const index = data.findIndex( (array) => {
            return parseInt(array["devId"]) === id
        });
        let prevState = JSON.parse(JSON.stringify(data));

        prevState[index].eventDesc= "-2"
        setData(prevState)
        axios.post(websetting.apiUrl + 'lighting-service/api/v1/deviceControl/turnDimDevice',
            {
                devId : id
            },
            {
                method: "post",
                headers: {
                    'user-name-header': "'" + sessionStorage.getItem("username") + "'",
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            const index = data.findIndex( (array) => {
                return parseInt(array["devId"]) === id
            });
            let prevState = JSON.parse(JSON.stringify(data));
            prevState[index].eventDesc = "DIM"
            setData(prevState)

        }).catch(function (error) {

        });
    }
    const setOff = (id:any) => {
        const index = data.findIndex( (array) => {
            return parseInt(array["devId"]) === id
        });
        let prevState = JSON.parse(JSON.stringify(data));

        prevState[index].eventDesc= "-1"
        setData(prevState)

        axios.post(websetting.apiUrl + 'lighting-service/api/v1/deviceControl/turnOffDevice',
            {
                devId : id
            },
            {
                method: "post",
                headers: {
                    'user-name-header': "'" + sessionStorage.getItem("username") + "'",
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            const index = data.findIndex( (array) => {
                return parseInt(array["devId"]) === id
            });
            let prevState = JSON.parse(JSON.stringify(data));
            prevState[index].eventDesc = "OFF"
            setData(prevState)

        }).catch(function (error) {

        });

    }
    const Sync = (id:any) => {
        //setLoading(true);
        setLoadingSync(true)
        setsync([])
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/devices/'+id+'/syncIoTDevices',
            {
                method: "get",
                headers: {
                    'user-name-header': "'" + sessionStorage.getItem("username") + "'",
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            //setLoading(false);
            checkSync()
        }).catch(function (error) {
            //setLoading(false)
            checkSync()
            //alert(error)
        });
    }
    const columns = [
        {
            name: t.t("iot.global.name"),
            selector: (row : any) => row.name,
        },{
            name: t.t("iot.global.deviceType"),
            selector: (row : any) => row.deviceTypeName,
        },{
            name: t.t("iot.global.vendor"),
            selector: (row : any) => row.vendorName,
        },{
            name: t.t("iot.global.currentStatus"),
            selector: (row : any) => <div> {row.eventDesc == -1 && <Spinner variant={"danger"} animation={"grow"} size={"sm"} />} { row.eventDesc == -2 && <Spinner variant={"warning"} animation={"grow"} size={"sm"} />}{row.eventDesc == -3 && <Spinner variant={"success"} animation={"grow"} size={"sm"} /> }{row.eventDesc === "OFF" && <span className="badge outline-badge-danger">{row.eventDesc} <ZapOff size={15} /></span>} {row.eventDesc === "ON" && <span className="badge outline-badge-success">{row.eventDesc} <Zap size={15} /></span>} {row.eventDesc === "DIM" && <span className="badge outline-badge-warning">{row.eventDesc} <Sun size={15} /></span>}</div>,
        },{
            name: t.t("iot.global.lightActions"),
            cell : (row : any) => <div>{row.status == 1 && <div><Button variant={"outline-success"} title={t.t("iot.global.turnOn")} onClick={()=>setOn(row.devId)} className={"rounded-circle"}><Zap size={15} /></Button> <Button onClick={()=>setDim(row.devId)} title={t.t("iot.global.dimLight")} variant={"outline-warning"} className={"rounded-circle"}><Sun size={15} /></Button> <Button  variant={"outline-danger"} title={t.t("iot.global.turnOff")} onClick={()=>setOff(row.devId)} className={"rounded-circle "}><ZapOff  size={15} /></Button></div> }</div>,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div><Button title={t.t("iot.global.view")}  variant={"primary"} onClick={()=>OpenAddevice(row.devId)} className={"rounded-circle"}><Edit size={15} /></Button> <Button title={t.t("iot.global.view")}  variant={"danger"} onClick={()=>view(row.devId)} className={"rounded-circle"}><Eye size={15} /></Button> <Button onClick={()=>devicedashboard(row.devId)} title={t.t("iot.global.deviceDashboard")}  variant={"warning"} className={"rounded-circle"}><PieChart size={15} /></Button> <Button  variant={"success"} title={t.t("iot.global.deviceMessagesTitle")} onClick={()=>devicemsg(row.devId)} className={"rounded-circle "}><Bell  size={15} /></Button> <Button title={t.t("iot.global.scheduleDeviceTitle")}   onClick={()=>deviceschedule(row.devId)}  variant={"dark"} className={"rounded-circle"}><Calendar size={15} /></Button></div>,
        }
    ];
    const delhistory = (id : any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'lighting-service/api/v1/deviceMessages/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = historydata.filter((array) => {
                    return array["dvmId"] !== id
                });
                setHistorydata(newData)
            }).catch(function (error) {
            });
        }
    }
    const delTask = (id : any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.apiUrl + 'schedule-service/api/v1/schedule/' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = taskdata.filter((array) => {
                    return array["scheduleTaskIdPk"] !== id
                });
                setTaskdata(newData)
            }).catch(function (error) {
            });
        }
    }

    const history_columns = [
        {
            name: t.t("iot.global.message"),
            selector: (row : any) => row.message,
        },{
            name: t.t("iot.global.event"),
            selector: (row : any) => <div>{row.eventDesc}</div>,
        },{
            name: t.t("iot.global.createdBy"),
            selector: (row : any) => row.createdBy,
        },{
            name: t.t("iot.global.createdDate"),
            selector: (row : any) => Moment(row.createDate).format(websetting.dateFormat + " HH:mm:ss"),
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>delhistory(row.dvmId)} className={"rounded-circle"}><Trash size={15} /></Button> </div>,
        }
    ];
    const task_columns = [
        {
            name: t.t("iot.global.powerLevel"),
            selector: (row : any) => row.lightingLevelDesc,
        },{
            name: t.t("iot.global.periodType"),
            selector: (row : any) => row.periodType,
        },{
            name: t.t("iot.global.executeTime"),
            selector: (row : any) => Moment(row.executeTime).format(websetting.dateFormat + "HH:mm:ss"),
        },{
            name: t.t("iot.global.status"),
            selector: (row : any) => row.taskStatusDesc,
        },{
            name: t.t("iot.global.actions"),
            cell : (row : any) =>  <div><Button variant={"outline-danger"} onClick={()=>delTask(row.scheduleTaskIdPk)} className={"rounded-circle"}><Trash size={15} /></Button> </div>,
        }
    ];
    const [totalRows, setTotalRows] = useState(0);
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        setPerPage(newPerPage)
        fetchDta(page)
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };

    const checkSync = () => {
        setLoadingSync(true)
        setsync([])
        axios.get(websetting.apiUrl + 'auth/api/v1/organizationServices/'+prop.trvId+'/serviceSyncInfo',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setsync(response.data)
            setLoadingSync(false)
        }).catch(function (error) {
        });
    }
    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/devices/'+prop.trvId+'/serviceDevices/?pageNO='+p+'&pageSize='+perPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
            setTotalRows(response.data.recordsTotal)
        }).catch(function (error) {

        });
    };
    useEffect(() => {
        checkSync()
        fetchDta(1); // fetch page 1 of users
    }, [perPage]);
    const AddTask = () => {
        setShow4(true)
    }
    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.apiUrl + 'schedule-service/api/v1/schedule/'+event.target.Id.value,
                    {
                        devId:devId,
                        executeTime: event.target.datetime.value,
                        lightingLevel: event.target.power.value,
                        periodType: event.target.period.value,
                    },
                    {
                        method: "put",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = taskdata.findIndex( (array) => {
                        return parseInt(array["scheduleTaskIdPk"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(taskdata));
                    prevState[index].lightingLevelDesc = response.data.lightingLevelDesc
                    prevState[index].periodTypeDesc = response.data.periodTypeDesc
                    prevState[index].executeTime = response.data.executeTime + "T06:34:32.553Z"
                    prevState[index].periodTypeDesc = response.data.periodTypeDesc

                    setTaskdata(prevState)
                    setShow4(false)
                    setLoadingModal(false)
                }).catch(function (error) {
                    setLoadingModal(false)
                });
            }else {
                axios.post(websetting.apiUrl + 'schedule-service/api/v1/schedule/',
                    {
                        devId:devId,
                        executeTime: event.target.datetime.value,
                        lightingLevel: event.target.power.value,
                        periodType: event.target.period.value,
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setTaskdata(taskdata.concat(response.data))
                    setShow4(false)
                    setLoadingModal(false)
                }).catch(function (error) {
                    setLoadingModal(false)
                    alert(error)
                });

            }
        }
    };


    const submitAddevice = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.devId.value;
            if(event.target.devId.value > 0){
                axios.put(websetting.apiUrl + 'lighting-service/api/v1/devices/'+PKid+'/',
                    {
                        "devKey":event.target.devKey.value,
                        "isParent": event.target.isParent.value == 1 ? true : false,
                        "latitude":event.target.latitude.value,
                        "longitude":event.target.longitude.value,
                        "name":event.target.name.value
                    },
                    {
                        method: "put",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {

                    const index = data.findIndex( (array) => {
                        return parseInt(array["devId"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].name = response.data.name
                    prevState[index].eventDesc = response.data.eventDesc
                    prevState[index].status = response.data.status

                    setData(prevState)

                    setshowAddevive(false)
                    setLoadingModal(false)
                }).catch(function (error) {
                    setshowAddevive(false)
                    setLoadingModal(false)
                });
            }else {
                axios.post(websetting.apiUrl + 'lighting-service/api/v1/devices/',
                    {
                        "trvId":parseInt(prop.trvId),
                        "serviceVendorIdFk":parseInt(event.target.serviceVendorIdFk.value),
                        "devKey":event.target.devKey.value,
                        "deviceTypeIdFk":parseInt(event.target.deviceTypeIdFk.value),
                        "isParent": event.target.isParent.value == 1 ? true : false,
                        "latitude":event.target.latitude.value,
                        "longitude":event.target.longitude.value,
                        "name":event.target.name.value
                    },
                    {
                        method: "post",
                        headers: {
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setshowAddevive(false)
                    setLoadingModal(false)
                }).catch(function (error) {
                    setshowAddevive(false)
                    setLoadingModal(false)
                });
            }
        }
    };




    const infos =  JSON.parse(JSON.stringify(infoData))
    const devDashboard =  JSON.parse(JSON.stringify(DeviceDashboard))
    const syncObj =  JSON.parse(JSON.stringify(sync))
    const infoDev =  JSON.parse(JSON.stringify(infoDevice))

    return(
        <div className={"lightbox"}>

            <div className="pagetitle text-uppercase">
                <div className={"d-flex justify-content-between"}>
                    <div className={"d-inline-flex"}>
                        <Sun className={"feather"} />
                        <span className={"d-inline-block mx-2"}>{t.t("iot.global.devicesTitle")}</span>
                    </div>
                    <div className={"d-inline-flex align-content-end"}>
                        {
                            syncObj.syncStatus  &&
                             <Button size={"sm"} className={"rounded-circle mx-2"} onClick={()=>{checkSync()}} variant={syncObj.syncStatus == "COMPLETED" ? "success" : syncObj.syncStatus == "IN_PROGRESS" ? "warning" :  "danger"} title={syncObj.syncStatus == "COMPLETED" ? syncObj.syncDate : syncObj.syncStatus}>{syncObj.syncStatus == "COMPLETED" ? <Check /> : (syncObj.syncStatus == "IN_PROGRESS" ?  <Loader  /> :   <AlertTriangle/>) }</Button>


                        }
                        {loadingSync &&
                        <Spinner animation={"border"}    className={"mx-2"} variant={"warning"}  title={"Please Wait"} />
                        }
                        <Button variant="dark" size={"sm"}  onClick={()=>Sync(prop.trvId)} className="float-end bold mx-2 text-uppercase">+ {t.t("iot.global.syncDevices")}</Button>
                        <Button variant="primary" size={"sm"}  onClick={()=>OpenAddevice("")} className="float-end bold  text-uppercase">+ {t.t("iot.global.addDevice")}</Button>
                    </div>
                </div>
            </div>


            <hr className={"shodow-hr"} />
            <div>
                <DataTable progressPending={loading}
                           pagination
                           paginationServer
                           paginationTotalRows={totalRows}
                           progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                           onChangeRowsPerPage={handlePerRowsChange}
                           onChangePage={handlePageChange} columns={columns} data={data} />

            </div>

            <Modal size={"xl"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title><Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.deviceInfo")}</span></Modal.Title>
                </Modal.Header>
                    <Modal.Body>

                            <div>
                                {
                                    loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                        <div>
                                            <Table hover>
                                                <thead>
                                                <tr><th style={{width:"25%"}} >{t.t("iot.global.deviceFields")}</th><th className={"text-center"} style={{width:"25%"}}>{t.t("iot.global.value")}</th><th style={{width:"25%"}}>{t.t("iot.global.deviceFields")}</th><th className={"text-center"} style={{width:"25%"}}>{t.t("iot.global.value")}</th></tr>
                                                </thead>
                                                <tbody>
                                                <tr><td className={"bg-light"}>ID</td><td className={"text-center"}>{infos.devId}</td><td className={"bg-light"}>{t.t("iot.global.name")}</td><td className={"text-center"}>{infos.name}</td></tr>
                                                <tr><td className={"bg-light"}>{t.t("iot.global.serviceName")}</td><td className={"text-center"}>{infos.organizationServiceDTO != null && infos.organizationServiceDTO.name}</td><td className={"bg-light"}>{t.t("iot.global.groupName")}</td><td className={"text-center"}>{infos.groupDTO!=null && infos.groupDTO.name}</td></tr>
                                                <tr><td className={"bg-light"}>{t.t("iot.global.vendorName")}</td><td className={"text-center"}>{infos.vendorDTO!=null && infos.vendorDTO.name}</td><td className={"bg-light"}>{t.t("iot.global.deviceType")}</td><td className={"text-center"}>{infos.deviceTypeDTO != null && infos.deviceTypeDTO.name}</td></tr>
                                                <tr><td className={"bg-light"}>{t.t("iot.global.address")}</td><td className={"text-center"}>{infos.address}</td><td className={"bg-light"}>{t.t("iot.global.city")}</td><td className={"text-center"}>{infos.city}</td></tr>
                                                <tr><td className={"bg-light"}>{t.t("iot.global.vendor")}</td><td className={"text-center"}>{infos.street}</td><td className={"bg-light"}>{t.t("iot.global.serialNumber")}</td><td className={"text-center"}>{infos.serialNumber}</td></tr>
                                                <tr><td className={"bg-light"}>{t.t("iot.global.latitude")}</td><td className={"text-center"}>{infos.latitude}</td><td className={"bg-light"} >{t.t("iot.global.longitude")}</td><td className={"text-center"}>{infos.longitude}</td></tr>
                                                <tr><td className={"bg-light"}>{t.t("iot.global.event")}</td><td className={"text-center"}>{infos.eventDesc}</td><td className={"bg-light"}>{t.t("iot.global.status")}</td><td className={"text-center"}>{infos.status}</td></tr>
                                                <tr><td className={"bg-light"}>{t.t("iot.global.rssi")}</td><td className={"text-center"}>{infos.rssi}</td><td className={"bg-light"}>{t.t("iot.global.deviceKey")}</td><td className={"text-center"}>{infos.devKey}</td></tr>
                                                <tr><td className={"bg-light"}>{t.t("iot.global.createdBy")}</td><td className={"text-center"}>{infos.createdBy}</td><td className={"bg-light"}>{t.t("iot.global.createdDate")}</td><td className={"text-center"}>{infos.createDate}</td></tr>
                                                <tr><td className={"bg-light"}>{t.t("iot.global.updatedDate")}</td><td className={"text-center"}>{infos.updateDate}</td><td className={"bg-light"}>{t.t("iot.global.remarks")}</td><td className={"text-center"}>{infos.remarks}</td></tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                }
                            </div>

                    </Modal.Body>
            </Modal>

            <Modal size={"xl"} centered={true}
                   show={show1}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header className={"bg-light"} closeButton>
                    <Modal.Title><Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.deviceInfo")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        {
                            loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                <div>

                                    <Tabs className={"justify-content-center"} defaultActiveKey={"tab1"}>
                                        <Tab eventKey={"tab1"} className={"shadow-0"} title={t.t("iot.global.deviceMessagesChart")} >
                                            <Bar options={options} data={chart}    />
                                        </Tab>
                                        <Tab eventKey={"tab2"} title={t.t("iot.global.devicesLocation")} >
                                            <Row className={"py-3"}>
                                                <Col md={12}>

                                                    {devDashboard.device  &&
                                                    <LoadScript googleMapsApiKey={SystemSetting.mapKey}>
                                                        <GoogleMap
                                                            mapContainerStyle={{width: '100%', height: '350px'}}
                                                            center={{
                                                                lat: parseFloat(devDashboard.device.latitude),
                                                                lng: parseFloat(devDashboard.device.longitude)
                                                            }}
                                                            zoom={18}>
                                                            <Marker icon={websetting.webUrl + "/images/map/"+(devDashboard.device.status == 0 ? "red" : (devDashboard.device.status == 1 && devDashboard.device.eventDesc == "Off" ? "grey" : "yellow") )+".svg"}   position={{
                                                                lat: parseFloat(devDashboard.device.latitude),
                                                                lng: parseFloat(devDashboard.device.longitude)
                                                            }}></Marker>
                                                        </GoogleMap>
                                                    </LoadScript>
                                                    }


                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                </div>
                        }
                    </div>

                </Modal.Body>
            </Modal>


            <Modal size={"xl"} centered={true}
                   show={show2}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header className={"bg-light"} closeButton>
                    <Modal.Title><Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.deviceMessagesTitle")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        {
                            loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                <div>
                                    <DataTable pagination paginationPerPage={10} columns={history_columns} data={historydata} />
                                </div>
                        }
                    </div>

                </Modal.Body>
            </Modal>

            <Modal size={"xl"} centered={true}
                   show={show3}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header className={"bg-light"} closeButton >
                    <Modal.Title className={"d-flex justify-content-between"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.tasks")}</span>
                            </div>
                            <div className={"d-inline-flex mx-5 align-content-end"}>
                                <Button variant={"warning"}  onClick={()=>{AddTask()}} >{t.t("iot.global.addTask")}</Button>
                            </div>
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>
                        {
                            loadingModal ? <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"} /></div> :
                                <div>
                                    <DataTable pagination paginationPerPage={10} columns={task_columns} data={taskdata} />
                                </div>
                        }
                    </div>

                </Modal.Body>
            </Modal>

            <Modal size={"xl"} centered={true}
                   show={show4}
                   onExited={handleClose2}
                   onHide={handleClose2}
                   backdrop="static">
                <Modal.Header className={"bg-light"} >
                    <Modal.Title className={"d-flex justify-content-between"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.tasks")}</span>
                            </div>
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                <Modal.Body>

                    <Row>
                        <Form.Group hidden controlId="devId" >
                            <Form.Control type={"hidden"}   value={infos.scheduleTaskIdPk}  />
                        </Form.Group>

                        <Form.Group hidden controlId="Id" >
                            <Form.Control type={"hidden"}   value={infos.scheduleTaskIdPk}  />
                        </Form.Group>
                        <Col md={6}>
                            <Form.Group className="my-2" controlId="power" >
                                <Form.Label>{t.t("iot.global.powerLevel")}:</Form.Label>
                                <Form.Select required aria-label={t.t("iot.global.powerLevel")} aria-describedby="msgInput1">
                                    <option value={1}>{t.t("iot.global.off")}</option>
                                    <option value={116}>{t.t("iot.global.dim")}</option>
                                    <option value={254}>{t.t("iot.global.on")}</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                    Required Field
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="my-2" controlId="datetime" >
                                <Form.Label>{t.t("iot.global.dateAndTime")}:</Form.Label>
                                 <Form.Control type="datetime-local"  defaultValue={infos.code} required aria-label={t.t("iot.global.dateAndTime")} aria-describedby="msgInput2" />
                                <Form.Control.Feedback type="invalid"  id="msgInput2" >
                                    Required Field
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col md={12}>
                            <Form.Group className="my-2" controlId="period" >
                                <Form.Label>{t.t("iot.global.periodType")}:</Form.Label>
                                <Form.Select required aria-label={t.t("iot.global.periodType")} aria-describedby="msgInput1">
                                    <option value={1}>Once</option>
                                    <option value={2}>Periodic</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid"  id="msgInput1" >
                                    Required Field
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>


                    </Row>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose2}>{t.t("iot.global.close")}</Button>
                    <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>
                </Modal.Footer>
                </Form>
            </Modal>




            <Modal size={"xl"} centered={true}
                   show={showAddevive}
                   onExited={closeAddevice}
                   onHide={closeAddevice}
                   backdrop="static">
                <Modal.Header className={"bg-light"} >
                    <Modal.Title className={"d-flex justify-content-between"}>
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Sun className={"feather"} /> <span className={"text-uppercase "}>{t.t("iot.global.devicesTitle")}</span>
                            </div>
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={submitAddevice}>
                    <Modal.Body>
                        {
                            loadingModal ?
                                <div className={"text-center my-5"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :
                                <Row>
                                    <Form.Group hidden controlId="devId">
                                        <Form.Control type={"hidden"} value={infoDev.devId}/>
                                    </Form.Group>


                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="name">
                                            <Form.Label>{t.t("iot.global.deviceName")}:</Form.Label>
                                            <Form.Control type={"text"} required defaultValue={infoDev.name}/>
                                            <Form.Control.Feedback type="invalid" id="msgInput1">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="devKey">
                                            <Form.Label>{t.t("iot.global.devKey")}:</Form.Label>
                                            <Form.Control type={"text"} required defaultValue={infoDev.devKey}/>
                                            <Form.Control.Feedback type="invalid" id="msgInput1">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    {!infoDev.devId &&
                                    <>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="serviceVendorIdFk">
                                                <Form.Label>{t.t("iot.global.serviceVendor")}:</Form.Label>
                                                <Form.Select required aria-label={t.t("iot.global.serviceVendor")}
                                                             aria-describedby="msgInput1">
                                                    {
                                                        serviceVendor.map((item: any) => {
                                                            return (
                                                                <option
                                                                    value={item.serviceVendorIdPk}>{item.description}</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msgInput1">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="my-2" controlId="deviceTypeIdFk">
                                                <Form.Label>{t.t("iot.global.deviceType")}:</Form.Label>
                                                <Form.Select required aria-label={t.t("iot.global.deviceType")}
                                                             aria-describedby="msgInput1">
                                                    {
                                                        deviceType.map((item: any) => {
                                                            return (
                                                                <option value={item.deviceTypeIdPk}>{item.name}</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid" id="msgInput1">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </>
                                    }
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="latitude">
                                            <Form.Label>{t.t("iot.global.latitude")}:</Form.Label>
                                            <Form.Control type={"text"} defaultValue={infoDev.latitude}/>
                                            <Form.Control.Feedback type="invalid" id="msgInput1">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="longitude">
                                            <Form.Label>{t.t("iot.global.longitude")}:</Form.Label>
                                            <Form.Control type={"text"} defaultValue={infoDev.longitude}/>
                                            <Form.Control.Feedback type="invalid" id="msgInput1">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="isParent">
                                            <Form.Check className={"d-inline"} value={"1"}
                                                        defaultChecked={infoDev.isParent}/>
                                            <Form.Label className={"d-inline mx-2"}>{t.t("TxtParent")}</Form.Label>
                                            <Form.Control.Feedback type="invalid" id="msgInput1">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>


                                </Row>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={closeAddevice}>{t.t("iot.global.close")}</Button>
                        <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>



        </div>

    );
}
export default Device
