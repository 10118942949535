import React, {useEffect, useRef, useState} from "react";
import DataTable from 'react-data-table-component'
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import { Spinner} from "react-bootstrap";
import { ChevronLeft,Tool } from 'react-feather';
import {websetting} from "../../../helpers/websetting";
import Moment from "moment";
import {SystemSetting} from "../../../constants/system";
import {useTranslation} from "react-i18next";

function DeviceHistory(){
    const t = useTranslation()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    let { id } = useParams();
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(SystemSetting.rowInPage);

    const columns = [
        {
            name: t.t("iot.global.BomDeviceStatus"),
            selector: (row : any) => row.statusDesc,
        },{
            name: t.t("iot.global.deviceID"),
            selector: (row : any) => row.billDeviceIdPk,
        } ,{
            name: t.t("iot.global.createdBy"),
            selector: (row : any) => row.createdBy,
        },{
            name: t.t("iot.global.createdDate"),
            selector: (row : any) => Moment(row.createDate).format(websetting.dateFormat),
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        const p = page - 1
        axios.get( websetting.apiUrl + 'inventory-service/api/v1/BomDevices/'+id+'/history?pageNO='+p+'&pageSize='+SystemSetting.rowInPage,
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.data);
            setLoading(false);
        }).catch(function (error) {

        });
    };

    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        fetchDta(page)
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);


    return(
        <div className={""}>
            <div className="content">
                <div className={"box"}>
                    <div className="pagetitle text-uppercase">
                        <div className={"d-flex justify-content-between"}>
                            <div className={"d-inline-flex"}>
                                <Tool className={"feather"} size={21} />
                                {t.t("iot.global.invDeviceHistory")}
                            </div>
                            <div className={"d-inline-flex align-content-end"}>
                                <Link to={"../assetsManagement?tab=devices"} >{t.t("iot.global.back")} <ChevronLeft className={" feather feather-chevron-left"} /></Link>
                            </div>
                        </div>

                    </div>
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationTotalRows={totalRows}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data} />

                </div>
            </div>
        </div>
    )
}
export default DeviceHistory;