import {Button, Form, Image, ListGroup, Nav, Navbar, NavItem, NavLink, Spinner} from "react-bootstrap";
import {ModulaIds, UserProfile, websetting} from "../helpers/websetting";
import {Link, Navigate, useHref, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Autmation, Cms, domain, IOT} from "../constants/routes";
import axios from "axios";
import {FilePlus, FolderPlus, LogOut} from "react-feather";
import {useTranslation} from "react-i18next";
import {Simulate} from "react-dom/test-utils";

export  const reCall = () => {
    MenuList(null)
}

function MenuList(prop:any){
    const menulist = React.createRef<HTMLTextAreaElement>();
    const t = useTranslation()
    const [category, setCategory] = useState([]);
    const [loading, setloading] = useState(false);
    const [logo, setlogo] = useState(websetting.orgLogo);

    const [show, setShow] = useState(false);
    const toggleShow = () => setShow((s) => !s);
    const handleClose = () => setShow(false);
    const navigate = useNavigate()
    const ClearSession = () => {
        sessionStorage.clear();
        window.location.href = domain;
    }
    const Open = (e:any) => {
        e.currentTarget.classList.toggle('open')
    }
    const Search = (e:any) => {
        menulist.current?.childNodes.forEach((i)=>{
            const search = i.textContent != null ?  i.textContent : ""
            if(search.search(new RegExp(e.currentTarget.value, "i")) < 0){
                //console.log(i.textContent)
            }else{

            }
         })
    }

    useEffect(() => {
        if(prop.page == Autmation){
            setloading(true)
            const fetchDta = async (page:any) => {
                axios.get(websetting.automationUrl + 'AutoFormCategories/getCategroyWithForms/'+ModulaIds.Automation,
                    {
                        method: "get",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setCategory(response.data)
                    setloading(false)
                }).catch(function (error) {
                    setloading(false)
                });
            };
            fetchDta(1)
        }
    }, [localStorage.getItem("lang")]);

    useEffect(() => {
        setlogo(websetting.orgLogo)
    },[websetting.orgLogo]);

    return(
        <div  ref={menulist as React.RefObject<any>}>
            <div className={"HeaderMenu px-2 mt-4 mx-auto text-center"}>
                <div className={"MenuLogo m-auto rounded-circle d-flex overflow-hidden"}>
                    <div className={"my-auto"}>
                        <Link to="./">
                            <Image  width={"100%"}   className="p-2" srcSet={logo} />
                        </Link>
                    </div>
                </div>
                <div className="mt-3"><Link to="./" className=" underline   pt-0 m-0" >{t.t("iot.global.smartCityApp")}</Link></div>
                <Button variant={"none"}  onClick={ClearSession} className="underline py-0 mb-1  mb-3 text-white" style={{fontSize:"15px"}} >
                    <LogOut className={"svg-white  mx-1"} width={17} height={17} />
                    {t.t("iot.global.logout")}
                </Button>
                <Form.Control type="text" placeholder={t.t("iot.global.search")}  onKeyUp={(e)=>{Search(e)}}   className="searchServ d-none text-white my-2"   autoComplete="off"  aria-label={t.t("iot.global.search")}  />
            </div>

             {prop.page == IOT &&

            <ListGroup as={"ul"} className="CRMMenu p-0 m-0"   >
                <ListGroup.Item as={"li"}><Link  onClick={() => setShow(false)} to={"../"}>{t.t("iot.global.home")}</Link></ListGroup.Item>
                {UserProfile.userType == "EMPLOYEE" &&
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)}
                                                to={"./SystemSetting"}>{t.t("iot.global.sysSetting")}</Link></ListGroup.Item>
                }
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Dashboard"}>{t.t("iot.global.generalDashboardTitle")}</Link></ListGroup.Item>
                <ListGroup.Item className={"d-none"} as={"li"}><Link onClick={() => setShow(false)} to={".." + Autmation} >{t.t("iot.global.digitalServices")}</Link></ListGroup.Item>

                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Street-Lighting"}>{t.t("iot.global.smartLighting")}</Link></ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Waste-Service"}>{t.t("iot.global.wasteManagement")}</Link></ListGroup.Item>

                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Fleet-Service"}>{t.t("iot.global.fleetService")}</Link></ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./userManagement"}>{t.t("iot.global.userManagement")}</Link></ListGroup.Item>

                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./assetsManagement"}>{t.t("iot.global.inventorymanagment")}</Link></ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Setting"}>{t.t("iot.global.assetManagement")}</Link></ListGroup.Item>



            </ListGroup>
            }


            {prop.page == Cms &&

            <ListGroup as={"ul"} className="CRMMenu p-0 m-0"   >
                <ListGroup.Item as={"li"}><Link  onClick={() => setShow(false)} to={"./Setting"}>{t.t("cms.web.setting")}</Link></ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Categories"}>{t.t("cms.web.categories")}</Link></ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Search"}>{t.t("cms.web.quickSearch")}</Link></ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Departments"}>{t.t("cms.web.departments")}</Link></ListGroup.Item>
                <ListGroup.Item className={"havesub"} onClick={(e) => {Open(e)}} as={"li"}><Link onClick={() => setShow(false)} to={"./Articles"}>{t.t("cms.web.articles")}</Link>
                    <ListGroup className={"px-0"} as={"ul"}>
                        <ListGroup.Item style={{fontSize: "14px"}} as={"li"}>
                            <Link onClick={() => setShow(false)} to={"./Category/1"} >{t.t("cms.addCategory")}</Link>
                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>

                <ListGroup.Item className={"havesub"} onClick={(e) => {Open(e)}} as={"li"}><Link onClick={() => setShow(false)} to={"./Forms"}>{t.t("cms.web.forms")}</Link>
                    <ListGroup className={"px-0"} as={"ul"}>
                        <ListGroup.Item style={{fontSize: "14px"}} as={"li"}>
                            <Link onClick={() => setShow(false)} to={"./Category/2"} >{t.t("cms.addCategory")}</Link>
                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>

                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Events"}>{t.t("cms.web.events")}</Link></ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Pages"}>{t.t("cms.web.pages")}</Link></ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./Slider"}>{t.t("cms.slider")}</Link></ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./TelePhone"}>{t.t("cms.web.phones")}</Link></ListGroup.Item>

                <ListGroup.Item className={"havesub"} onClick={(e) => {Open(e)}} as={"li"}><Link onClick={() => setShow(false)} to={"./Protocols"}>{t.t("cms.web.protocols")}</Link>
                    <ListGroup className={"px-0"} as={"ul"}>
                        <ListGroup.Item style={{fontSize: "14px"}} as={"li"}>
                            <Link onClick={() => setShow(false)} to={"./Category/3"} >{t.t("cms.addCategory")}</Link>
                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>

                <ListGroup.Item className={"havesub"} onClick={(e) => {Open(e)}} as={"li"}><Link onClick={() => setShow(false)} to={"./Reports"}>{t.t("cms.web.reports")}</Link>
                    <ListGroup className={"px-0"} as={"ul"}>
                        <ListGroup.Item style={{fontSize: "14px"}} as={"li"}>
                            <Link onClick={() => setShow(false)} to={"./Category/4"} >{t.t("cms.addCategory")}</Link>
                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>

                <ListGroup.Item className={"havesub"} onClick={(e) => {Open(e)}} as={"li"}><Link onClick={() => setShow(false)} to={"./Tenders"}>{t.t("cms.web.tenders")}</Link>
                    <ListGroup className={"px-0"} as={"ul"}>
                        <ListGroup.Item style={{fontSize: "14px"}} as={"li"}>
                            <Link onClick={() => setShow(false)} to={"./Category/5"} >{t.t("cms.addCategory")}</Link>
                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)} to={"./uploadfiles"}>{t.t("cms.uploadcenter")}</Link></ListGroup.Item>

            </ListGroup>
            }

            {prop.page == Autmation &&
            <div>
                {loading ?
                    <div className={"text-center mt-5"}><Spinner   variant={"warning"} animation={"border"}/>
                    </div> : <div>
                        {category &&
                        <ListGroup as={"ul"} className="CRMMenu p-0 m-0">
                            {Object.keys(category).map((titles, i) => {
                                return (
                                    <ListGroup.Item key={"i" + i} onClick={(e) => {
                                        Open(e)
                                    }} className={"havesub"} as={"li"}>
                                        <a href={"#"}>{category[i]['name']}</a>
                                        <ListGroup className={"px-0"} as={"ul"}>
                                            {Object.keys(category[i]['froms']).map((titles2, j) => {
                                                return (
                                                    <ListGroup.Item style={{fontSize: "14px"}} key={"j" + j}
                                                                    as={"li"}><Link onClick={() => setShow(false)}
                                                                                    to={"./FormCase/" + category[i]['froms'][j]['id'] + "?categoryId=" + category[i]["id"]}>{category[i]['froms'][j]['name']}</Link></ListGroup.Item>
                                                )
                                            })}
                                            <ListGroup.Item as={"li"}><Link onClick={() => setShow(false)}
                                                                            to={"./CaseSla/New?categoryId=" + category[i]['id']}><FilePlus
                                                size={14}/> {t.t("LblNewForm")}</Link></ListGroup.Item>

                                        </ListGroup>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                        }
                        <ListGroup as={"ul"} className="CRMMenu border-top-0 p-0 m-0" style={{fontSize: "0.9rem"}}>
                            <ListGroup.Item key={"e" + 100} as={"li"}>
                                <Link to={"./Category"}><FolderPlus size={16}/> {t.t("iot.global.categories")} </Link>
                            </ListGroup.Item>
                        </ListGroup>

                    </div>
                }

            </div>
            }
        </div>
    );
}
export default MenuList
