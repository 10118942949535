import {Edit, FilePlus, FileText, PhoneCall, Trash} from "react-feather";
import {Button, Col, Form, Modal, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {Ref, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {   websetting} from "../../../../../helpers/websetting";
import axios from "axios";
import {UploadCMS, UploadMultiCMS} from "../../../../../helpers/function";
import Moment from "moment";
import {SystemSetting} from "../../../../../constants/system";

function CMSProtocol(){
    const t = useTranslation()

    const inputRef = React.createRef<any>();

    const [validated, setValidated] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);
    const [filesNum, setfilesNum] = useState([]);
    const [attachfile, setattachfile] = useState([]);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [cat, setCat] = useState([]);


    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
    }


    const edit = (id:any) => {
        setfilesNum([])
        setattachfile([])

        setShow(true);
        if(id > 0){
            setLoadingModal(true)
            axios.get(websetting.cmsUrl + 'CmsReportProt/Get?id='+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
                setattachfile(response.data.files)

            }).catch(function (error) {
            });
        }
    }

    const columns = [
        {
            width:"50px",

            sortable: true,
            name:  "#",
            selector: (row : any) => row.id,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.protocolName"),
            selector: (row : any) => row.title,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name:  t.t("cms.date"),
            selector: (row : any) => Moment(row.date).format(SystemSetting.dateFormat),
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button>   <Button onClick={()=>edit(row.id)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button></div>,
        }
    ]


    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
        }
    }
    const SubmitSearch = (e:any) => {
        e.preventDefault()
        if(e.target.input.value !== searchtxt){
            setSearchtxt(e.target.input.value)
        }
    }

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.cmsUrl + 'CmsReportProt/Delete?id=' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }




    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;




            const titleHe =  event.target.titleHe.value
            const titleAr = event.target.titleAr.value
            const categoryId = event.target.categoryId.value
            const date = event.target.date.value



            const fileObject:any = []

            var name = document.getElementsByName('fileHe[]');
            name.forEach(
                function (input: any, i) {
                    var ar: any = document.getElementsByName('fileAr[]')[i];
                    var id: any = document.getElementsByName('fileId[]')[i];
                    var fileUrl: any = document.getElementsByName('fileUrl[]')[i];
                    if(id.value != ""){
                        fileObject.push({titleHe: input.value, titleAr: ar.value, fileUrl: fileUrl.value,id:id.value})
                    }else{
                        fileObject.push({titleHe: input.value, titleAr: ar.value, fileUrl: ''})
                    }
                }
            );


            if(event.target.Id.value > 0){
                UploadMultiCMS(event.target.attachmentFiles).then((value:any) => {
                    var counter=0;
                    for(var i=0; i< fileObject.length;i++){
                        if(fileObject[i].id == null){
                            fileObject[i].fileUrl = value[counter]
                            ++counter
                        }
                    }
                    axios.put(websetting.cmsUrl + 'CmsReportProt/Put',
                    {
                        "id" : parseInt(PKid),
                        "titleHe": titleHe,
                        "titleAr": titleAr,
                        "categoryId": categoryId,
                        "date": date,
                        "status": 1,
                        "files": fileObject
                    },
                    {
                        method: "put",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["id"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].title = response.data.title
                    prevState[index].date = Moment(response.data.date).format(SystemSetting.dateFormat)

                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
                })
            }else {
                UploadMultiCMS(event.target.attachmentFiles).then((value:any) => {

                for(var i=0; i< fileObject.length;i++){
                    fileObject[i].fileUrl = value[i]
                }

                axios.post(websetting.cmsUrl + 'CmsReportProt/AddReport',
                    {
                        "titleHe": titleHe,
                        "titleAr": titleAr,
                        "categoryId": categoryId,
                        "date": date,
                        "status": 1,
                        "files": fileObject

                    },
                    {
                        method: "post",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
                })
            }
        }
    };


    const [totalRows, setTotalRows] = useState(0);
    const [rowperPage, setrowperPage] = useState(SystemSetting.rowInPage);
    const [currpage, setcurrpage] = useState(1);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsReportProt/GetReports?type=3&rows=' + rowperPage + "&page=" + page + (searchtxt != "" ? "&search="+searchtxt : ""),
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    const handlePageChange = (page :any) => {
        setcurrpage(page)
        setLoading(true);

        fetchDta(page)
    };
    const handlePerRowsChange = async  (newPerPage:any, page:any) => {
        setLoading(true);
        setrowperPage(newPerPage)
        fetchDta(page)
    }

    const GetCats = async () => {
        axios.get(websetting.cmsUrl + 'CmsCategory/GetCategorys?type=3',
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setCat(response.data);
        }).catch(function (error) {

        });
    };

    const AddFiles = (v:any) => {
        const f:any = []
        for(var i=0;i < v.target.files.length;i++){
            f.push(v.target.files[i].name)
        }
        setfilesNum(f)
    }

    const deleteFile = (event:any,index:any) => {
        const dt = new DataTransfer();
        const new_filesNum = filesNum.filter((array,ind) => {
            return ind !== index
        });
        setfilesNum(new_filesNum)

        for(var i = 0; i < new_filesNum.length; i++) {
            dt.items.add(inputRef.current.files[i])
        }
        inputRef.current.files =  dt.files
    }

    const deleteAttachFile = (event:any,index:any) => {
        const dt = new DataTransfer();
        const new_filesNum = attachfile.filter((array,ind) => {
            return ind !== index
        });
        setattachfile(new_filesNum)
    }


    useEffect(() => {
        GetCats()
        fetchDta(1); // fetch page 1 of users
    }, [localStorage.getItem("lang") ,searchtxt]);
    const infos =  JSON.parse(JSON.stringify(infoData))




    return(
        <div>
            <div className={"lightbox"}>
                <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                    <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <FileText className={"feather mx-2"} /> {t.t("cms.web.protocols")}
                     </span>
                    </div>
                    <span className={"d-inline-flex align-items-center mx-2"}>

                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                        <Form  onSubmit={(e)=>{SubmitSearch(e)}}  action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                    </div>

                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><FilePlus /> {t.t("cms.addProtocols")}</Button>
                 </span>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    <DataTable progressPending={loading}
                               pagination
                               paginationServer
                               paginationPerPage={rowperPage}
                               paginationTotalRows={totalRows}
                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                               onChangeRowsPerPage={handlePerRowsChange}
                               onChangePage={handlePageChange} columns={columns} data={data} />
                </div>
            </div>


            <Modal size={"xl"} centered={true}
                   show={show}
                   onExited={handleClose}
                   onHide={handleClose}
                   backdrop="static">
                <Modal.Header >
                    <Modal.Title><FileText className={"feather"} /> {t.t("cms.web.protocols")}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Tabs className={"justify-content-center tabsModal"} defaultActiveKey={"tab1"}>
                                    <Tab eventKey={"tab1"} className={"shadow-0"} title={t.t("cms.mainInformation")} >
                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>
                                    <Form.Group hidden controlId="FileUrl">
                                        <Form.Control type={"hidden"} value={infos.imageUrl}/>
                                    </Form.Group>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="titleHe">
                                            <Form.Label>{t.t("cms.protocolName")} - {t.t("cms.he")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.titleHe} required
                                                          aria-label={t.t("cms.protocolName")}
                                                          aria-describedby="msg_titleHe"/>
                                            <Form.Control.Feedback type="invalid" id="msg_titleHe">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="titleAr">
                                            <Form.Label>{t.t("cms.protocolName")} - {t.t("cms.ar")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.titleAr} required
                                                          aria-label={t.t("cms.protocolName")}
                                                          aria-describedby="msg_titleHe"/>
                                            <Form.Control.Feedback type="invalid" id="msg_titleHe">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2 mt-4" controlId="categoryId">

                                            <Form.Label className="my-0">{t.t("cms.category")}:</Form.Label>
                                            {cat &&
                                            <Form.Select required>
                                                {
                                                    cat.map((value) => {
                                                        return (
                                                            <option selected={infos.categoryId == value['id']  ? true : false }  value={value['id']}>{value['name']}</option>
                                                        )
                                                    })
                                                }

                                            </Form.Select>
                                            }
                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>


                                    <Col md={6}>
                                        <Form.Group className="my-2 mt-4" controlId="date">
                                            <Form.Label className="my-0">{t.t("cms.date")}:</Form.Label>
                                            <Form.Control type="date" required defaultValue={infos.date && Moment(infos.date).format(SystemSetting.dateFormat)}
                                                          aria-label={t.t("cms.date")}
                                                          aria-describedby="msg_file"/>
                                            <Form.Control.Feedback type="invalid" id="msg_file">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>


                                </Row>
                                    </Tab>


                                    <Tab eventKey={"tab4"} className={"shadow-0"} title={t.t("cms.attachFiles")} >
                                        <Col md={12} className={"mt-4"}>
                                            <Form.Group className="my-2 " controlId="attachmentFiles">
                                                <Form.Label className="my-0">{t.t("cms.attachFiles")}:</Form.Label>
                                                <Form.Control ref={inputRef as Ref<any>} type="file" multiple onChange={(v)=>{AddFiles(v)}}
                                                              aria-label={t.t("cms.attachFiles")}
                                                              aria-describedby="msg_file"/>
                                                <Form.Control.Feedback type="invalid" id="msg_file">
                                                    Required Field
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>


                                        {attachfile &&
                                        <Table borderless>
                                            {attachfile.map((item:any,index:any) => {
                                                return(
                                                    <tr>
                                                        <td>
                                                            <Row>
                                                                <Col md={12}>{item.fileUrl}</Col>
                                                                <Col md={6}>
                                                                    <Form.Group className="my-2 " >
                                                                        <Form.Control name={"fileUrl[]"} defaultValue={item.fileUrl}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                        <Form.Control name={"fileId[]"} defaultValue={item.id}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                        <Form.Control name={"fileHe[]"} defaultValue={item.titleHe} required type="text" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Form.Group className="my-2 " >
                                                                        <Form.Control name={"fileAr[]"}  type="text" defaultValue={item.titleAr} placeholder={t.t("cms.ar")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>

                                                        </td>
                                                        <td width={50}  ><Button variant={"outline-danger"} size={"sm"}  onClick={(e:any)=>{deleteAttachFile(e,index)}} className={"rounded-circle"}><Trash size={15} /></Button></td>
                                                    </tr>
                                                )
                                            })}
                                        </Table>
                                        }


                                        {filesNum &&
                                        <Table borderless>
                                            {filesNum.map((item,index) => {
                                                return(
                                                    <tr>
                                                        <td>
                                                            <Row>
                                                                <Col md={12}>{item}</Col>
                                                                <Col md={6}>
                                                                    <Form.Group className="my-2 " >
                                                                        <Form.Control name={"fileUrl[]"} defaultValue={""}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                        <Form.Control name={"fileId[]"} defaultValue={""}  type="hidden" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                        <Form.Control name={"fileHe[]"} required type="text" placeholder={t.t("cms.he")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Form.Group className="my-2 " >
                                                                        <Form.Control name={"fileAr[]"}  type="text" placeholder={t.t("cms.ar")} aria-label={t.t("cms.attachFiles")} aria-describedby="msg_file"/>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>

                                                        </td>
                                                        <td width={50}  ><Button variant={"outline-danger"} size={"sm"}  onClick={(e:any)=>{deleteFile(e,index)}} className={"rounded-circle"}><Trash size={15} /></Button></td>
                                                    </tr>
                                                )
                                            })}
                                        </Table>
                                        }




                                    </Tab>

                                </Tabs>
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                        {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>


        </div>
    )
}

export default CMSProtocol;
