import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import './assets/css/style.css';
import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import Login from "./pages/Home/login";
import ForgetPassword from "./pages/Home/forget";
import Registration from "./pages/Home/registration";
import {authorities, Lang, UserProfile, websetting} from "./helpers/websetting";
import SerivesPage from "./pages/Employee/homeservices";
import CitizenPage from "./pages/Citizen/Home";
import Error404 from "./pages/Home/error";
import { Citizen, SsoHome} from "./constants/routes";
import './i18n';
import i18n from "i18next";
import axios from "axios";
import {Col, Row, Spinner} from "react-bootstrap";
import InviteUserForm from "./pages/Home/inviteuserform";

function App() {
    const navigate = useNavigate()
    const location = useLocation();
    const [loading,setLoading] = useState(true)
    const [isLogin, setLogin] = useState(false);
    const [mainPage, setMainPage] = useState("");

    useEffect(() => {
        if( sessionStorage.getItem("expires_in") != null){
            let remain = (parseInt(sessionStorage.getItem("expires_in") as string) + parseInt(sessionStorage.getItem("expires") as string))   - Math.floor(Date.now() / 1000)
            setTimeout(function (){
                if((parseInt(sessionStorage.getItem("expires_in") as string) + parseInt(sessionStorage.getItem("expires") as string))   - Math.floor(Date.now() / 1000) < 1 ) {
                    sessionStorage.clear()
                    navigate("./")
                    setLoading(false)
                    return
                }
                },remain * 1000)
        }
    }, [sessionStorage.getItem("expires_in")]);

    useEffect(() => {
        document.title = websetting.title
        if(localStorage.getItem("langId") == null){
            i18n.changeLanguage(Lang[0].label)
        }else{
            var ind = parseInt(localStorage.getItem("langId") as string)

            if(ind >= Lang.length) {
               ind = 0;
            }

            i18n.changeLanguage(Lang[ind].label)
        }
    }, [localStorage.getItem("langId")]);




    const checkValidate = async () => {
        setLoading(true)
        setLogin(false)
        if (sessionStorage.getItem("access_token") != "") {
            await axios.get(websetting.apiUrl + 'auth/oauth/validate',
                {
                    method: "get",
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(function (resProfile) {
                if (resProfile.data.user.username != "") {
                    sessionStorage.setItem("username", resProfile.data.user.username);
                    sessionStorage.setItem("fisrtName", resProfile.data.user.firstName);
                    sessionStorage.setItem("lastName", resProfile.data.user.lastName);
                    UserProfile.userType = resProfile.data.user.userType;
                    UserProfile.username = resProfile.data.user.username;
                    UserProfile.organizationId = resProfile.data.user.organizationIdFk;
                    UserProfile.userType = resProfile.data.user.userType;
                    UserProfile.userIdPk = resProfile.data.user.userIdPk
                    authorities.pop()
                    for (var i = 0; i < resProfile.data.authorities.length; i++) {
                        const v: any = resProfile.data.authorities[i];
                        authorities.push(v)
                    }

                    if(location['pathname'] == "/" || location['pathname'] == "/ForgetPassword" || location['pathname'] == "/Registration" || location['pathname'] == "/inviteUserForm" ){
                        if(resProfile.data.user.userType == "ADMIN" || resProfile.data.user.userType == "EMPLOYEE") {
                            navigate(SsoHome);
                        }else if(resProfile.data.user.userType == "CITIZEN") {
                            navigate(Citizen);
                        }
                    }
                    setLogin(true)
                }

                 setLoading(false);
            }).catch(function (error) {
                sessionStorage.clear()
                navigate("./");
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }


    useEffect(()=>{
        if(sessionStorage.getItem("access_token") != null && sessionStorage.getItem("access_token") != ""){
            checkValidate()
        }else {
            if(location['pathname'] != "/" && location['pathname'] != "/ForgetPassword" && location['pathname'] != "/Registration"    && location['pathname'] != "/inviteUserForm" ){
                navigate("./");
            }
            setLoading(false);
        }
    },[])


    return (
        <div className={"h-100"}>
            {loading ?
                <div className={"h-100 "}><Row className={"h-100 justify-content-center gx-0 align-items-center"}><Col
                    className={"text-center"}><Spinner animation={"border"} variant={"warning"}/></Col></Row></div>
                :
                <div className={"h-100"}>
                    {sessionStorage.getItem("access_token") == null &&
                    <Routes>
                        <Route index element={<Login/>}/>
                        <Route path="ForgetPassword" element={<ForgetPassword/>}/>
                        <Route path="Registration" element={<Registration/>}/>
                        <Route path="inviteUserForm" element={<InviteUserForm />}/>
                    </Routes>
                    }
                    {sessionStorage.getItem("access_token") != null &&
                    <Routes>
                        <Route path={Citizen + "/*"} element={<CitizenPage/>}/>
                        <Route path={SsoHome + "/*"} element={<SerivesPage/>}/>
                    </Routes>
                    }
                    <Routes>
                        <Route path="Error404" element={<Error404/>}/>
                    </Routes>
                </div>
            }
        </div>
     );
}



export default App;
