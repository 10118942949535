import React from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {websetting} from "../../helpers/websetting";
import '../../assets/css/login.css';
import {useTranslation} from "react-i18next";
import {UseLang} from "../../helpers/function";

function LoginHeader(){
    const t = useTranslation()

    return(
        <div>
            <div className={"top-0 mt-3 position-absolute"}><UseLang  /></div>

        <div className="mb-5 text-center logoDiv ">
            <Link to="../" ><Image alt="HomePage" className="mb-3" src={websetting.orgLogo} /></Link>
            <h1 className="bold">{t.t("iot.global.welcome")}</h1>
            <h4 className="bold" style={{color:"gray"}} >{t.t("portalName")} - {t.t("orgName")}</h4>
        </div>
        </div>
    )
}
export default LoginHeader;
