import {Edit,  PhoneCall,  Trash} from "react-feather";
import {Button, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import DataTable from "react-data-table-component";
import React, {useEffect,  useState} from "react";
import {useTranslation} from "react-i18next";
import { websetting} from "../../../../../helpers/websetting";
import axios from "axios";

function Telephone(){
    const t = useTranslation()
    const [validated, setValidated] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [loadingModal, setLoadingModal] = useState(false);
    const [show, setShow] = useState(false);
    const [infoData, setInfoData] = useState({});
    const [info, setInfo] = useState(true);

    const handleClose = () => {
        setShow(false);
        setValidated(false);
        setLoadingModal(false)
        setInfo(true);
        setInfoData({})
    }
    const handleShow = () => {
        setShow(true);
    }

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const columns = [
       {
           width:"50px",
           sortable: true,
            name:  t.t("cms.empNo"),
            selector: (row : any) => row.empNo,
            style:{
                fontSize:"13px"
            }
        },
        {
            sortable: true,
            name: t.t("cms.empName"),
            selector: (row : any) => row.empName,
            style:{
                fontSize:"13px"
            }
        },
        {
            sortable: true,
            name: t.t("cms.empPosition") ,
            selector: (row : any) => row.empPosition,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("cms.phone"),
            selector: (row : any) => row.phone,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("cms.email"),
            selector: (row : any) => row.email,
            style:{
                fontSize:"13px"
            }
        },{
            name: '',
            style:{
                fontSize:"13px"
            },
            cell : (row : any) => <div><Button variant={"outline-danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button>   <Button onClick={()=>edit(row.id)}  variant={"outline-info"} className={"rounded-circle"}><Edit size={15} /></Button></div>,
        }
        ]


    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
         }
    }

    const SubmitSearch = (e:any) => {
        e.preventDefault()
        if(e.target.input.value !== searchtxt){
            setSearchtxt(e.target.input.value)
        }
    }

    const del = (id:any) => {
        if(window.confirm('Are you sure you wish to delete this item?')) {
            axios.delete(websetting.cmsUrl + 'CmsTelephone/Delete?id=' + id,
                {
                    method: "delete",
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                const newData = data.filter((array) => {
                    return array["id"] !== id
                });
                setData(newData)
            }).catch(function (error) {
            });
        }
    }

    const edit = (id:any) => {
        setShow(true);
        if(id > 0){
            setLoadingModal(true)

            axios.get(websetting.cmsUrl + 'CmsTelephone/Get?id='+id,
                {
                    method: "get",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setLoadingModal(false)
                setInfoData(response.data)
            }).catch(function (error) {
            });
        }
    }
    const handleSubmit = (event: any) => {



        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoadingModal(true)
            const PKid = event.target.Id.value;
            if(event.target.Id.value > 0){
                axios.put(websetting.cmsUrl + 'CmsTelephone/Put/',
                    {
                        "id":PKid,
                    "empNo": event.target.empNo.value,
                    "empNameAr": event.target.empNameAr.value,
                    "empNameHe": event.target.empNameHe.value,
                    "empPositionAr": event.target.empPositionAr.value,
                    "empPositionHe": event.target.empPositionHe.value,
                    "phone": event.target.phone.value,
                    "email": event.target.email.value,
                    "orderNo": event.target.orderNo.value
                    },
                    {
                        method: "put",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    const index = data.findIndex( (array) => {
                        return parseInt(array["id"]) === parseInt(PKid)
                    });

                    let prevState = JSON.parse(JSON.stringify(data));
                    prevState[index].empNo = response.data.empNo
                    prevState[index].empName = response.data.empName
                    prevState[index].empPosition = response.data.empPosition
                    prevState[index].email = response.data.email
                    prevState[index].phone = response.data.phone

                    setData(prevState)
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });
            }else {
                axios.post(websetting.cmsUrl + 'CmsTelephone/AddTelephone',
                    {
                        "empNo": event.target.empNo.value,
                        "empNameAr": event.target.empNameAr.value,
                        "empNameHe": event.target.empNameHe.value,
                        "empPositionAr": event.target.empPositionAr.value,
                        "empPositionHe": event.target.empPositionHe.value,
                        "phone": event.target.phone.value,
                        "email": event.target.email.value,
                        "orderNo": event.target.orderNo.value
                    },
                    {
                        method: "post",
                        headers: {
                            'Accept-Language':localStorage.getItem("lang") as string,
                            'content-type': 'application/json',
                            'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                        }
                    }
                ).then(async function (response) {
                    setData(data.concat(response.data))
                    setShow(false)
                    setLoadingModal(true)
                }).catch(function (error) {
                    setLoadingModal(true)
                });

            }
        }
    };

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.cmsUrl + 'CmsTelephone/GetAll/GetAll'+ (searchtxt != "" ? "?search="+searchtxt : ""),
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            //setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            setLoading(false);
        }).catch(function (error) {

        });
    };

    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, [localStorage.getItem("lang") ,searchtxt]);
    const infos =  JSON.parse(JSON.stringify(infoData))

    return(
    <div>
        <div className={"lightbox"}>
            <div className={"pagetitle d-flex justify-content-between text-uppercase"}>
                <div className={"d-inline-flex align-items-center"}>
                     <span className={"d-inline-flex align-items-center mx-2"}>
                         <PhoneCall className={"feather mx-2"} /> {t.t("cms.web.phones")}
                     </span>
                </div>
                <span className={"d-inline-flex align-items-center mx-2"}>

                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden btn-rounded"}>
                        <Form  onSubmit={(e)=>{SubmitSearch(e)}}  action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                    </div>

                    <Button  onClick={()=>{edit(0)}} variant={"info"} size={"sm"}  className={" mx-2 btn-rounded"}><PhoneCall /> {t.t("cms.addphone")}</Button>
                 </span>
            </div>
            <hr className={"shodow-hr"} />
            <div>
                <DataTable
                    progressPending={loading}
                    pagination
                    paginationServer
                    progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                    columns={columns}
                    data={data}
                />
            </div>
        </div>


        <Modal size={"xl"} centered={true}
               show={show}
               onExited={handleClose}
               onHide={handleClose}
               backdrop="static">
            <Modal.Header >
                <Modal.Title><PhoneCall className={"feather"} /> {t.t("cms.web.phones")}</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                <Modal.Body>

                        <div>
                            {loadingModal ?
                                <div className={"text-center my-3"}><Spinner animation={"border"} variant={"danger"}/>
                                </div> :

                                <Row>
                                    <Form.Group hidden controlId="Id">
                                        <Form.Control type={"hidden"} value={infos.id}/>
                                    </Form.Group>

                                    <Col lg={12}>
                                        <Form.Group className="my-2" controlId="empNo">
                                            <Form.Label>{t.t("cms.empNo")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.empNo} required
                                                          aria-label={t.t("cms.empNo")}
                                                          aria-describedby="msg_empNo"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNo">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="empNameHe">
                                            <Form.Label>{t.t("cms.empNameHe")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.empNameHe} required
                                                          aria-label={t.t("cms.empNameHe")}
                                                          aria-describedby="msg_empNameHe"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameHe">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="empNameAr">
                                            <Form.Label>{t.t("cms.empNameAr")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.empNameAr} required
                                                          aria-label={t.t("cms.empNameAr")}
                                                          aria-describedby="msg_empNameAr"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empNameAr">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="empPositionHe">
                                            <Form.Label>{t.t("cms.empPositionHe")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.empPositionHe} required
                                                          aria-label={t.t("cms.empPositionHe")}
                                                          aria-describedby="msg_empPositionHe"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empPositionHe">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="my-2" controlId="empPositionAr">
                                            <Form.Label>{t.t("cms.empPositionAr")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.empPositionAr} required
                                                          aria-label={t.t("cms.empPositionAr")}
                                                          aria-describedby="msg_empPositionAr"/>
                                            <Form.Control.Feedback type="invalid" id="msg_empPositionAr">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="my-2" controlId="phone">
                                            <Form.Label>{t.t("cms.phone")}:</Form.Label>
                                            <Form.Control type="text" defaultValue={infos.phone}
                                                          aria-label={t.t("cms.phone")}
                                                          aria-describedby="msg_phone"/>
                                            <Form.Control.Feedback type="invalid" id="msg_phone">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="my-2" controlId="email">
                                            <Form.Label>{t.t("cms.email")}:</Form.Label>
                                            <Form.Control type="email" defaultValue={infos.email}
                                                          aria-label={t.t("cms.email")}
                                                          aria-describedby="msg_email"/>
                                            <Form.Control.Feedback type="invalid" id="msg_email">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="my-2" controlId="orderNo">
                                            <Form.Label>{t.t("cms.order")}:</Form.Label>
                                            <Form.Control type="number" defaultValue={infos.orderNo}
                                                          aria-label={t.t("cms.order")}
                                                          aria-describedby="msg_orderNo"/>
                                            <Form.Control.Feedback type="invalid" id="msg_orderNo">
                                                Required Field
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                        </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" className={"text-uppercase"} onClick={handleClose}>{t.t("iot.global.close")}</Button>
                    {info &&  <Button type={"submit"} className={"text-uppercase"}  variant="warning">{t.t("iot.global.save")}</Button>}
                </Modal.Footer>
            </Form>
        </Modal>


    </div>
)
}

export default Telephone;
