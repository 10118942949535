import React, {useEffect, useState} from "react";
import {Edit, Trash, Trash2} from "react-feather";
import {Button, Table, Spinner} from "react-bootstrap";
import axios from "axios";
import {Lang, websetting} from "../../../../../../helpers/websetting";
import DataTable from "react-data-table-component";

function PickupSettings(prop:any){
    const [validated, setValidated] = useState(false);
    const [data, setData] = useState([]);
    const [historydata, setHistorydata] = useState([]);
    const [taskdata, setTaskdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    const del = (id:any) => {
    }
    const showInfo = (id:any) => {
    }
    const edit = (id:any) => {
    }

    const columns = [
        {
            name: 'Name',
            selector: (row : any) => row.node.name,
        },{
            name: 'Days',
            selector: (row : any) => <div>{row.node.pickupDay !=null && Object.keys(row.node.pickupDay["edges"]).map((titles, i) => { console.log(row.node.pickupDay["edges"][i]); return (<Table className={"mb-0"}><tr><td style={{width:"33.3% !important"}} ><span className={"badge outline-badge-danger py-1 w-100 d-block"}>{row.node.pickupDay["edges"][i]["node"]["weekday"]}</span></td><td style={{width:"33.3% !important"}} ><span className={"badge py-1 outline-badge-info d-block w-100"}>{row.node.pickupDay["edges"][i]["node"]["timeFrom"]}</span></td> <td  style={{width:"33.3% !important"}}><span  className={"badge d-block w-100 py-1 outline-badge-dark"}>{row.node.pickupDay["edges"][i]["node"]["timeTo"]}</span></td></tr></Table> ); })}</div>,
        },{
            name: 'Collection Per Day',
            selector: (row : any) => row.node.amountDaysBetweenPickups,
        },{
            name: 'Weeks between collections',
            selector: (row : any) => row.node.collectionPerWeek,
        },{
            name: 'Min Day Between collections',
            selector: (row : any) => row.node.minimumDaysBetweenPickup,
        },{
            name: 'Actions',
            cell : (row : any) => <div><Button variant={"danger"} onClick={()=>del(row.id)} className={"rounded-circle"}><Trash size={15} /></Button> <Button onClick={()=>edit(row.id)}  variant={"info"} className={"rounded-circle"}><Edit size={15} /></Button>  </div>,
        }
    ];

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(websetting.apiUrl + 'waste-service/api/v1/wastehero/pickupSettings/',
            {
                method: "get",
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.edges);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    useEffect(() => {
        fetchDta(1); // fetch page 1 of users
    }, []);
    return(
        <div className={"lightbox"}>
            <div className={"pagetitle text-uppercase"}>
                <Trash2 className={"feather"} />
                <span className={"d-inline-block mx-2"}>PICKUP SETTINGS</span>
            </div>
            <hr className={"shodow-hr"} />
            <div>
                {
                    loading ? <div className={"text-center"}><Spinner animation={"border"} className={"my-5 "} variant={"warning"} /></div> : <DataTable pagination paginationPerPage={10} columns={columns} data={data} />
                }
            </div>
        </div>
    );
}
export default PickupSettings