import React, {useState} from "react";
import {Button, Col, Form, FormLabel, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {Link} from "react-router-dom";
import {MDBBtn} from 'mdb-react-ui-kit'
function ProfileForm() {
    const [checked, setChecked] = useState(false);
    const [radioValue, setRadioValue] = useState('1');
    const radios = [
        { name: ' דוא׳׳ל ', value: '1' },
        { name: ' מסרון ', value: '2' },
        { name: ' טלפון ', value: '3' },
    ];
    const [value, setValue] = useState([]);
    const handleChange = (val:any) => setValue(val);


    return (
            <div className={"horizontal"}>
                <div className="pageTitle d-flex justify-content-between mb-3">
                    <h3 className={"d-inline-flex bold"}>מידע אישי</h3>
                    <div className={"float-end d-inline-flex  align-items-center   align-content-end"}>
                        <Link className={"DarkBtn-sm  py-2  mx-5"} to={"../ChangePassword"}>تغير كلمة المرور</Link>
                        <Link   className={" d-inline-flex"} to={"../Profile"}> الى الخلف
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 className="feather mx-1 feather-chevron-left">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                        </Link>
                    </div>
                </div>

                <Row className={"py-4"}>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>תעודת זהות</FormLabel>
                            <Form.Control type="text" defaultValue={"1111111"} readOnly placeholder="תעודת זהות" aria-required="true" required aria-label="תעודת זהות"/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>שם פרטי</FormLabel>
                            <Form.Control type="text" defaultValue={"Qasem"}  placeholder="שם פרטי" aria-required="true" required aria-label="שם פרטי"/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>שם משפחה</FormLabel>
                            <Form.Control type="text" defaultValue={"Khamayse"} placeholder="שם משפחה" aria-required="true" required aria-label="שם משפחה"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={"py-4"}>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>ישוב</FormLabel>
                            <Form.Select required placeholder="ישוב" aria-label="ישוב">
                                <option></option>
                                <option value="1">الرينة</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>רחוב</FormLabel>
                            <Form.Select required   aria-label="רחוב">
                                <option></option>
                                <option value="1">المدينة</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>מס' בית</FormLabel>
                            <Form.Control type="text"    aria-required="true" required aria-label="מס' בית"/>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className={"py-4"}>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>טלפון</FormLabel>
                            <Form.Control type="text" defaultValue={"0503633113"}  placeholder="טלפון" aria-required="true" required aria-label="טלפון"/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>אימייל</FormLabel>
                            <Form.Control type="text"  placeholder="אימייל" defaultValue={"gupozuvu@mailinator.com"} aria-required="true" required aria-label="אימייל"/>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>סטטוס העסקה</FormLabel>
                            <Form.Select required placeholder="סטטוס העסקה" aria-label="סטטוס העסקה">
                                <option value="1">שכיר</option>
                                <option value="2">עצמאי</option>
                                <option value="3">מובטל</option>
                                <option value="4">מקבל קצבה</option>
                                <option value="5">רווחה</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>מצב משפחתי</FormLabel>
                            <Form.Select required placeholder="מצב משפחתי" aria-label="מצב משפחתי">
                                <option value="1">רווק /ה</option>
                                <option value="2">נשוי/אה</option>
                                <option value="3">גרוש/ ה</option>
                                <option value="4">אלמן /נה</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>

                <Row className={"py-4"}>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>ערוץ תקשורת מועדף:</FormLabel>
                            <br />
                             <ToggleButtonGroup className={"w-100"} type="checkbox" value={value} onChange={handleChange}>
                                <ToggleButton id="tbg-btn-1" value={1} variant={'outline-secondary'}>
                                    דוא׳׳ל
                                </ToggleButton>
                                <ToggleButton id="tbg-btn-2" value={2} variant={'outline-secondary'}>
                                    מסרון
                                </ToggleButton>
                                <ToggleButton id="tbg-btn-3" value={3} variant={'outline-secondary'}>
                                    טלפון
                                </ToggleButton>
                            </ToggleButtonGroup>


                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group className="mt-2" controlId="LName">
                            <FormLabel>תמונה אישית:</FormLabel>
                            <Form.Control type="file"    aria-required="true" required aria-label="תמונה אישית"/>
                        </Form.Group>
                    </Col>
                </Row>


                <div className={"border-top by-5"}>
                    <Row className={"justify-content-center"}>
                        <Col className={"text-center"} md={3} >

                            <Button type="submit" variant={"danger"} className=" w-100 py-2 d-inline-block mt-5 mb-4">حفظ البيانات</Button>
                        </Col>
                    </Row>
                </div>


            </div>
    )
}
export default  ProfileForm