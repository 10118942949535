import Moment from "moment";
import {websetting} from "../../../../../helpers/websetting";
import {Image, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import axios from "axios";
import DataTable from "react-data-table-component";

function CaseHistory(props:any){
    const columns = [
        {
            cell: (row : any) => <div className={"fs-6"}>{Moment(row.date).format("HH:m:s " + websetting.dateFormat )}</div>,
            center:true,
        },{
            cell : (row : any) =>  <div  className={"fs-6"}><Image srcSet={row.userPhoto ? row.userPhoto : "http://saasdemo.smsm-it.com/Content/images/man.jpg"} width={20} height={20} className="rounded-circle"/>   {row.roleName}</div>   ,
            style: {
                backgroundColor: 'rgba(187, 204, 221, 0.1)',
                justifyContent:"center"
            }
        },{
            cell: (row : any) => <div className={"fs-6"}>{row.message}</div>,
        }
    ];
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.post(websetting.apiCitizen + 'EmployeeCaseHistory/GetCaseHistory',
            {
                caseId: props.caseId,
                page: page,
                rows: perPage
            },
            {
                method: "post",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type':'application/json',
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setTotalRows(100);
            setLoading(false);
        }).catch(function (error) {

        });
    };
    const handlePageChange = (page :any) => {
        fetchDta(page);
    };
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        setLoading(true);
        axios.post(websetting.apiCitizen + 'CitizenServiceHistory/GetCaseHistory',
            {
                caseId: props.caseId,
                page: page,
                rows: perPage
            },
            {
                method: "post",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type':'application/json',
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setPerPage(newPerPage);
            setLoading(false);
        }).catch(function (error) {
        });
    };
    useEffect(() => {
        fetchDta(1);
    }, [props.caseId,localStorage.getItem("lang")]);


    return(
        <div>
            {loading ? <div className={"text-center mt-5"}><Spinner animation={"border"} variant={"warning"}/></div>  :
                <DataTable   noTableHead={true} data={data} columns={columns}
                           progressPending={loading}
                           pagination
                           paginationServer
                           paginationTotalRows={totalRows}
                           onChangeRowsPerPage={handlePerRowsChange}
                           onChangePage={handlePageChange} />
            }
        </div>
    );
}

export default CaseHistory