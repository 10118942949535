import React, {useEffect, useRef, useState} from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import LoginHeader from "./header";
import axios from "axios";
import {websetting} from "../../helpers/websetting";
import {SystemSetting} from "../../constants/system";
import {useTitle} from "../../helpers/function";
import {useTranslation} from "react-i18next";


function PageContent() {
    const t = useTranslation()

    const inputRef = React.createRef<HTMLTextAreaElement>();
    const inputRef2 = React.createRef<HTMLTextAreaElement>();

    useTitle(websetting.title);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenValue = params.get('token') || "";

    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const [done, setDone] = useState('');
    const [validated, setValidated] = useState(false);

    const [token, setToken] = useState(tokenValue);
    const [validToken, setValidToken] = useState('');

    if (token !== "" && validToken =="") {
        axios.get(websetting.apiUrl + 'auth/public/api/v1/account/' + token,
            {
                method: "get",
                headers: {
                    'accept': '*/*'
                }
            }
        ).then(async function (response) {
            if(response.data.length != 0 && response.data.username!=="" && response.status == 200){
                setValidToken("true");
            }else{
                setValidToken("");
            }
        }).catch(function (error) {
            setValidToken("");
        });
    }

    const resetPassword = async (event: any) => {

        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.target.Password.focus();
            event.preventDefault();
            event.stopPropagation();
        }

        const password = inputRef.current;
        const password2 = inputRef2.current;


        if ( (password2?.value != password?.value) ) {
            event.target.Password.focus();
            setError(t.t("iot.global.mismatchResetPassword"));
            return false;
        } else {
            setValidated(true);
            if (form.checkValidity() === true) {
                event.preventDefault();
                setError("");

                setLoading("true");
                axios.put(websetting.apiUrl + 'auth/public/api/v1/account/resetPassword' ,
                    {
                        "cofirmNewPassword": password2?.value,
                        "newPassword": password?.value,
                        "token": token,
                    },
                    {
                        method: "put",
                        headers: {
                            'accept': '*/*'
                        }
                    }
                ).then(async function (response) {
                    if(response.status == 200){
                        setLoading("")
                        setDone("")
                        setError("")
                       if(response.data === "true" || response.data === true){
                           setDone( t.t("iot.global.successMsgResetPassword"))
                           setTimeout(function (){
                               window.location.href = "./";
                           },SystemSetting.MsgTimeOut);
                       }else{
                           setError(t.t("iot.global.emailNotFound"))
                       }
                    }else{
                        setLoading("")
                    }
                }).catch(function (error) {
                    setError(t.t("iot.global.emailNotFound"))
                    setLoading("")
                });
            }
        }
    }


    const handleSubmitForget = async (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.target.ForgetEmail.focus();
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            var email = event.target.ForgetEmail.value.trim();
            setLoading("true");
            setDone("");
            setError("");

            await axios.put(websetting.apiUrl + 'auth/public/api/v1/account/forgotPassword',
                {
                    "email": email
                },
                {
                    method: "put",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'accept': '*/*'
                    }
                }
            ).then(async function (response) {
                if (response.data.status == "Success") {
                    setLoading("");
                    setError("");
                    setDone(t.t("iot.global.linkSentSuccessfullyMsg"));
                } else {
                    setLoading("");
                    setDone("");
                    setError(t.t("iot.global.emailNotFound"));
                }
            }).catch(function (error) {
                setLoading("");
                setDone("");
                setError(t.t("iot.global.emailNotFound"));
            });
        }
    };

    if (error !== "") {
        setTimeout(function () {
            setError("");
        }, SystemSetting.MsgTimeOut);
    }

    return (


        <div className="formDiv slide-top">
            <LoginHeader/>

            {done && <Alert className="text-center" variant="success">{done}</Alert>}
            {error && <Alert id="AlertMsg" className="text-center" variant="danger">{error}</Alert>}
            {loading && <div className="text-center"><Spinner animation="border" variant="secondary" role="status"><span
                className="visually-hidden">انتظر من فضلك</span></Spinner></div>}


            {!validToken &&
            <Form noValidate validated={validated} onSubmit={handleSubmitForget}>

                <div>
                    <h5 className="font500">{t.t("iot.global.forgetPassword")}</h5>
                    <Form.Group  className="my-3" controlId="ForgetEmail">
                        <Form.Control className={"text-center"}  type="email" required placeholder={t.t("iot.global.enterEmail")} autoComplete="off"
                                      defaultValue="" aria-label={t.t("iot.global.enterEmail")} aria-describedby="msgForget"/>
                        <Form.Control.Feedback type="invalid" id="msgForget">
                            {t.t("iot.global.enterEmail")}
                        </Form.Control.Feedback>
                    </Form.Group> <Button type="submit" className="DarkBtn w-100 mt-5">{t.t("iot.global.resetYourPassword")}</Button>
                    <div className="text-center   mt-4"><Link className="underline" to="/Registration"><span
                        className="h5 font700">{t.t("DontHaveAccount")}</span></Link></div>

                </div>
            </Form>
            }
            {validToken &&
            <Form noValidate validated={validated} onSubmit={resetPassword}>

                <div>
                    <h5 className="font500">{t.t("iot.global.changePassword")}</h5>
                    <Form.Group className="my-3" controlId="Password">
                        <Form.Control type="password" required  ref={inputRef as React.RefObject<any>}
                                      placeholder={t.t("iot.global.newPassword")} autoComplete="off" defaultValue=""
                                      aria-label={t.t("iot.global.newPassword")} aria-describedby="msgPassword"/>
                        <Form.Control.Feedback type="invalid" id="msgForget">
                            {t.t("iot.global.newPassword")}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-3" controlId="NewPassword">

                        <Form.Control type="password"  ref={inputRef2 as React.RefObject<any>} required
                                      placeholder={t.t("iot.global.confirmNewPassword")} autoComplete="off" defaultValue=""
                                      aria-label={t.t("iot.global.confirmNewPassword")} aria-describedby="msgNewPassword"/>
                        <Form.Control.Feedback type="invalid" id="msgNewPassword">
                            {t.t("iot.global.confirmNewPassword")}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button type="submit" className="DarkBtn w-100 mt-5">{t.t("iot.global.changePassword")}</Button>
                </div>
            </Form>
            }


        </div>
    )
}



function ForgetPassword(){
    return(
        <div className="h-100">
            <Container className="h-100" fluid={true}>
                <Row className="h-100" >
                    <Col className="mx-auto my-auto py-3"  xl={4} lg={5} md={6} >
                        <PageContent/>
                    </Col>
                    <Col className={" d-none d-md-block"} xl={8} lg={7} md={6}   style={{backgroundImage:'url('+window.location.origin+'/images/bg.jpg)',backgroundPosition:'fixed',backgroundRepeat:"no-repeat",backgroundSize:"cover"}}  >
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default ForgetPassword;
