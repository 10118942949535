import {Col, Image, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {Doughnut} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import {options} from "../lighting";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {websetting} from "../../../../../../helpers/websetting";
import {MapPin, PieChart} from "react-feather";
import {GoogleMap, LoadScript, Marker} from "@react-google-maps/api";
import {SystemSetting} from "../../../../../../constants/system";

function Dashboard(prop : any){

    const t = useTranslation()
    const connectivityLabels = [t.t("iot.global.connected"), t.t("iot.global.disconnected")]
    const statusLabels = [t.t("iot.global.on"), t.t("iot.global.dim"), t.t("iot.global.off")]
    //const percentLabels =  [t.t("iot.global.on"), t.t("iot.global.dim"), t.t("iot.global.off"),t.t("iot.global.disconnected")]
    const percentLabels =  [t.t("iot.global.connected"),t.t("iot.global.disconnected")]




    const options2 = {
        responsive: true,
        plugins: {
            labels: {
                formatter: function (value :any, context :any) {
                    return value + '% ';
                }
            },
            legend: {
                position: 'bottom' as const,
            },
            title: {
                display: true,
            },
        },
    };


    let data = {
        labels: percentLabels,
        datasets: [
            {
                label: '%',
                data: [],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.8)',
                    /*'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                     */
                    'rgba(255, 99, 132, 0.8)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    /*
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                     */
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1,
            },
        ],
   }

    let data2 = {
        labels: connectivityLabels,
        datasets: [
            {
                label: '# of Votes',
                data: [],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(255, 99, 132, 0.8)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    let data3 = {
        labels: statusLabels,
        datasets: [
            {
                label: '# of Votes',
                data: [],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(255, 99, 132, 0.8)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(255, 99, 132, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    const [percents, setPercent] = useState( data)
    const [connectivity, setconnectivity] = useState( data2)
    const [status, setStatus] = useState( data3)
    const [alldata, setdata] = useState( [])
    const [loading, setloading] = useState( true)


    useEffect(() => {
        setloading(true)
        axios.get(websetting.apiUrl + 'lighting-service/api/v1/streetLightDashboard/'+prop.trvId+'/service',
            {
                method: "get",
                headers: {
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(function (response) {
            setloading(false)

            //const d0 :any = [response.data.onDevicesCount,response.data.dimDevicesCount,response.data.offDevicesCount,response.data.disconnectedDevicesCount]

            const total = response.data.connectedDevicesCount +  response.data.disconnectedDevicesCount

            const d0 :any = [ ((response.data.connectedDevicesCount * 100) / total).toFixed(1),((response.data.disconnectedDevicesCount * 100) / total).toFixed(1)]
            data.datasets[0].data = d0
            setPercent(data)


            const d1 :any = [response.data.onDevicesCount,response.data.dimDevicesCount,response.data.offDevicesCount]
            data3.datasets[0].data = d1
            setconnectivity(data3)

            const d2 :any = [response.data.connectedDevicesCount,response.data.disconnectedDevicesCount]
            data2.datasets[0].data = d2
            setStatus(data2)

            setdata(response.data)


        }).catch(function (error) {
        });
    },  [localStorage.getItem("lang")]);

    const info = JSON.parse(JSON.stringify(alldata))

    return(
        <div>
            {
                loading ? <div className={"text-center"}><Spinner animation={"border"} className={"my-5 "} variant={"warning"} /></div> :

            <div>
            <Row className={"text-center justify-content-center"} >
                <Col className={"mt-4"}>
                    <div className={"lightbox"}>
                        <Image srcSet={window.location.origin + "/images/icon8.svg"} height={50} />
                        <div className={"h5 mt-2"}>{info && info.organizationName}</div>
                    </div>
                </Col>
                <Col className={"mt-4"}>
                    <div className={"lightbox"}>
                        <div className={"text-red h1 bold"}>{info && info.numberOfDevices}</div>
                        <div className={"h5 "}>{t.t("iot.global.numOfDevices")}</div>
                    </div>
                </Col>
                <Col className={"mt-4"}>
                    <div className={"lightbox"}>
                        <Image srcSet={window.location.origin + "/images/icon19.svg"} height={50} />
                        <div className={"h5 mt-2"}>{t.t("iot.global.streetLighting")}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={"mt-4"}>
                    <div className={"lightbox"}>
                        <div className={"pagetitle text-uppercase"}>
                            <PieChart size={24}  className="feather feather-pie-chart" />
                            <span className={"d-inline-block mx-2"}>{t.t("iot.global.currentDevicesStatus")} </span>
                        </div>
                        <Tabs className={"justify-content-center"} defaultActiveKey={"perstab"}>
                            <Tab title={t.t("iot.global.percentages")} eventKey={"perstab"} >
                                <div className={"d-flex mt-3 justify-content-center"}>
                                    <div style={{height:350,width:350}} className={"d-inline-flex justify-content-center my-auto"}>
                                        <Doughnut options={options2}  data={percents}></Doughnut>
                                    </div>
                                </div>

                            </Tab>
                        </Tabs>

                    </div>
                </Col>
                <Col className={"mt-4"}>
                    <div className={"lightbox"}>
                        <div className={"pagetitle text-uppercase"}>
                            <PieChart size={24}  className="feather feather-pie-chart" />
                            <span className={"d-inline-block mx-2"}>{t.t("iot.global.activeVsDeactive")}</span>
                        </div>
                        <Tabs className={"justify-content-center"} defaultActiveKey={"t1"}>
                            <Tab title={t.t("iot.global.connectivity")} eventKey={"t1"} >
                                <div className={"d-flex flex mt-3 justify-content-center"}>
                                    <div style={{height:350,width:350}} className={"d-inline-flex justify-content-center my-auto"}>
                                        <Doughnut options={options} data={connectivity}></Doughnut>
                                    </div>
                                </div>
                            </Tab>
                            <Tab title={t.t("iot.global.status")} eventKey={"t2"}>
                                <div className={"d-flex flex mt-3 justify-content-center"}>
                                    <div style={{height:350,width:350}} className={"d-inline-flex justify-content-center my-auto"}>
                                        <Doughnut options={options} data={status}></Doughnut>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>

                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={"mt-4"}>
                    <div className={"lightbox"}>
                        <div className={"pagetitle text-uppercase"}>
                            <MapPin className="feather feather-map-pin" size={24} />
                            <span className={"d-inline-block mx-2"}>{t.t("iot.global.devicesLocation")} </span>
                        </div>
                        <div className={"mt-2"}>

                            {info.devicesList  &&
                            <LoadScript googleMapsApiKey={SystemSetting.mapKey}>
                                <GoogleMap
                                    mapContainerStyle={{width: '100%', height: '350px'}}
                                    center={{
                                        lat: parseFloat(info.devicesList[0].latitude),
                                        lng: parseFloat(info.devicesList[0].longitude)
                                    }}
                                    zoom={10}>
                                    {Object.keys(info.devicesList).map((titles, i) => {
                                        return (
                                            <Marker icon={websetting.webUrl + "/images/map/"+(info.devicesList[i].status == 0 ? "red" : (info.devicesList[i].status == 1 && info.devicesList[i].eventDesc == "Off" ? "grey" : "yellow") )+".svg"} position={{
                                                lat: parseFloat(info.devicesList[i].latitude),
                                                lng: parseFloat(info.devicesList[i].longitude)
                                            }}></Marker>
                                        )
                                    })}
                                </GoogleMap>
                            </LoadScript>
                            }

                        </div>
                    </div>
                </Col>
            </Row>
            </div>
            }
        </div>
    );
}
export  default Dashboard;
