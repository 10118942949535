import React, {useEffect, useRef, useState} from "react";
import {ChevronLeft, Clipboard, Menu, PieChart, Search, Settings, Sliders} from "react-feather";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {ModulaIds, websetting} from "../../../../../helpers/websetting";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import Broad from "../../../../../containers/kanban/broad";
import {set} from "react-hook-form";
import {stat} from "fs";
import {SystemSetting} from "../../../../../constants/system";
import {useTranslation} from "react-i18next";

let checkstatus:any = []

function Kanban() {
    const inputRef = [React.createRef<HTMLTextAreaElement>()]
    const t = useTranslation()

    let {id} = useParams()
    let {cid} = useParams()
    let categoryId = "";
    if(cid == null){
        const url = window.location.search;
        const params = new URLSearchParams(url);
        categoryId = params.get('categoryId') || "";
    }else{
        categoryId = cid
    }
    const [items,setItems] = useState([])
    const [lists,setLists] = useState([])
    const [data,setData] = useState([])
    const [loading,setloading] = useState(false);
    const [category, setcategory] = useState([])
    const [form, setform] = useState([])
    const info =  JSON.parse(JSON.stringify(category))
    const forms =  JSON.parse(JSON.stringify(form))
    const [showSearch, setShowSearch] = useState(false);
    const [searchtxt, setSearchtxt] = useState('');
    const [search, setSearch] = useState({
        startDate: null,
        endDate: null,
        requesterName: null,
        requesterIdNo: null,
        caseId: null,
        search: null,
        caseStatus:""
    });
    const [status,setStatus] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]); //plain object as state

    const SelectedStatus = (event:any,index:any) => {
        if(event.target.checked){
            checkstatus.push({sid:event.target.value})
            checkedItems.concat(checkstatus)
            setCheckedItems(checkedItems)
        }else{
            checkstatus = checkstatus.filter((array:any) => {
                return array["sid"] !== event.target.value
            });
            setCheckedItems(checkstatus)
        }
    }

    const fetchDta = (page:any) => {
        setloading(true)
        axios.post(websetting.automationUrl + 'Case/GetStatusIncludeCases?module='+ModulaIds.Automation,
            {
                search: search.search != null ? search.search : null,
                startDate: search.startDate != null ? search.startDate : null,
                endDate: search.endDate != null ? search.endDate : null,
                requesterName: search.requesterName != null ? search.requesterName : null,
                requesterIdNo: search.requesterIdNo != null ? search.requesterIdNo : null,
                caseId: search.caseId != null ? search.caseId : null,
                caseStatus: search.caseStatus != "" ? search.caseStatus : "",
                formId:id,
            },
            {
                method: "get",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setloading(false)
            const json:any = []
            for(var i=0 ; i<response.data.length ; i++) {
                const allcase:any = []
                for (var ii = 0; ii < response.data[i].cases.length; ii++) {
                    allcase.push({
                        id:"item"+response.data[i].cases[ii].id,
                        content:response.data[i].cases[ii].customerName,
                        createdOn:response.data[i].cases[ii].createdOn,
                    })
                }
                json.push({
                    name: response.data[i].name,
                    color: response.data[i].color,
                    id: "id"+response.data[i].id,
                    items:allcase
                })
            }
            setData([])
            setData(json)
        }).catch(function (error) {
        });
    }
    useEffect(() => {
        fetchDta(1)
    }, [localStorage.getItem("lang"),id]);

    const CloseAdvance = (e:any) => {
        setShowSearch(showSearch ? false : true)
        if(showSearch == false){
            checkstatus = []
        }
    }
    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
            search.search = e.currentTarget.value
            search.startDate = null
            search.endDate = null
            search.requesterName = null
            search.requesterIdNo = null
            search.caseId = null
            search.caseStatus = ""
        }
        fetchDta(1)
    }

    const advanceSearch = (e:any) => {
        e.preventDefault()
        search.search = null

        if(e.currentTarget.startDate.value != ""){
            search.startDate = e.currentTarget.startDate.value
        }else{
            search.startDate = null
        }
        if(e.currentTarget.endDate.value != ""){
            search.endDate = e.currentTarget.endDate.value
        }else{
            search.endDate = null
        }
        if(e.currentTarget.requesterName.value != ""){
            search.requesterName = e.currentTarget.requesterName.value
        }else{
            search.requesterName = null
        }
        if(e.currentTarget.requesterIdNo.value != ""){
            search.requesterIdNo = e.currentTarget.requesterIdNo.value
        }else{
            search.requesterIdNo = null
        }
        if(e.currentTarget.caseId.value != ""){
            search.caseId = e.currentTarget.caseId.value
        }else{
            search.caseId = null
        }
        if(checkstatus.length > 0){
            let st:any = ""

            for(var i=0;i<checkstatus.length;i++){
                st += checkstatus[i].sid + ","
            }
            search.caseStatus = st.substring(0,st.length - 1)
        }else{
            search.caseStatus = ""
        }
        fetchDta(1);
    }


    useEffect(() => {
        axios.get(websetting.automationUrl + 'ConfigFormStatus/Get/' + id + '?module=' + ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setStatus(response.data)
        }).catch(function (error) {
        });

        setcategory([])
        setform([])

        axios.get(websetting.automationUrl + 'AutoForm/GetFormWithCategory/' + id + "?module=" + ModulaIds.Automation,
            {
                method: "GET",
                headers: {
                    'Accept-Language':localStorage.getItem("lang") as string,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }).then(async function (response) {
            setcategory(response.data)
        }).catch(function (error) {
        });
    }, [localStorage.getItem("lang"),id]);

    return(
    <div>
        <div className={"lightbox mb-3"}>
            <div className={"pagetitle d-flex justify-content-between  text-uppercase"}>
                    <span className={"d-inline-flex align-items-center mx-2"}><Clipboard className={"feather"} />בקשות שבטיפול שלי ‎
                        {category &&
                        <span className={"d-inline-flex text-black-50 align-items-center fs-6 px-3"}>

                                        <div>
                                            <div className={""}>
                                                 <Link to={"../CategoryCase/" + info.categoryId}>{info.categoryName}</Link>
                                                  <span>
                                    <ChevronLeft size={13}/>
                                    <Link
                                        to={"../FormCase/" + info.formId + "?categoryId=" + info.categoryId}>{info.formName}</Link>
                                    </span>
                                             </div>
                                         </div>

                                </span>
                        }
                    </span>


                <div className={"d-inline-flex align-items-center"}>
                    <div className={"btn position-relative caseSearch p-0 btn-sm btn-outline-dark overflow-hidden mx-1 btn-rounded"}>
                        <Form    action={""} method={"post"}  >
                            <Form.Group controlId={"input"}>
                                <Form.Control onBlur={(e)=>{SearchCases(e)}}  onChange={()=>setShowSearch( false )}  placeholder={t.t("TxrAdvanceSearch")}  className={"overflow-hidden"} style={{border:"0px",height:"30px"}} />
                            </Form.Group>
                        </Form>
                        <a onClick={(e)=>{CloseAdvance(e)}} href={"#"} className={"position-absolute end-0 top-50 me-2 translate-middle-x translate-middle-y"} style={{zIndex:3}}><Sliders /></a>
                    </div>
                    <Link title={"setting"} to={"../CaseSla/"+id+"?categoryId="+categoryId} className={"btn btn-dark btn-sm btn-rounded"}><Settings/></Link>
                    <Link title={"dashboard"} to={cid == null ?  "../DashBoard/"+id+"?categoryId="+categoryId : "../cDashBoard/"+cid }  className={"btn btn-info mx-1 btn-sm btn-rounded"}><PieChart /></Link>
                    <Link   to={"../FormCase/"+id+"?categoryId="+categoryId}   className={"btn btn-sm btn-outline-danger rounded-circle ms-1"}><Menu className={"mt-1"}/></Link>
                </div>
            </div>
            {showSearch &&
            <div>
                <hr className={"shodow-hr"}/>
                <Form onSubmit={(e)=>{advanceSearch(e)}} method={"post"} >
                    <Row>
                        <Col xl={10}>
                            <Row>
                                <Col >
                                    <Form.Group controlId={"startDate"}  >
                                        <Form.Control   size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"date"} placeholder={t.t("LblFormDate")}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group  controlId={"endDate"}>
                                        <Form.Control  size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"date"}
                                                       placeholder={t.t("LblToDate")}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group  controlId={"requesterName"}>
                                        <Form.Control  size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"text"}
                                                       placeholder={t.t("LblRequesterName")}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group  controlId={"requesterIdNo"}>
                                        <Form.Control size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"text"}
                                                      placeholder={t.t("LbRequesterIdNo")}/>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group  controlId={"caseId"}>
                                        <Form.Control size={"sm"} style={{height: "inherit",fontSize:"14px"}} type={"text"}
                                                      placeholder={t.t("LblNumber")}/>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Col>
                        <Col xl={2}>
                            <Button type={"submit"} variant={"dark"} aria-label={"search"}
                                    className={"w-100"}><Search/></Button>
                        </Col>
                    </Row>
                    {cid == null &&

                    <Row>
                        <Col md={12} className={"mt-3 changeStaus"}>
                            <span className={"d-inline-block me-2"}>{t.t("LblCaseStatus")} :</span>
                            {Object.keys(status).map((titles2, i) => {
                                return (
                                    <Form.Label className={"my-0"}>
                                        <input onChange={(e)=>{SelectedStatus(e,i)}}  value={status[i]["id"]} type={"checkbox"}   />
                                        <span className={"statusLabel"} >
                                            <span className={"status-dot"} style={{backgroundColor: status[i]["color"]}}></span> {status[i]["name"]}
                                        </span>
                                    </Form.Label>
                                )
                            })}
                        </Col>
                    </Row>
                    }
                </Form>
            </div>
            }

        </div>
        <div className=" h-100">
            {loading ? <div className={"mt-5 text-center"}><Spinner title={"Please Waite"} animation="border"  variant={"warning"} ></Spinner></div> :
                <div className={"kanbanDiv"}  >
                    {data.length > 0 &&
                    <Broad lists={data} categoryId={categoryId} formId={id}/>
                    }
                </div>
            }
        </div>
    </div>
)
}
export default Kanban