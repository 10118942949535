import React, {useEffect, useRef, useState, Component} from 'react';
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import LoginHeader from "./header";
import axios from 'axios'
import {Auth, authorities, CredentialsAuthToken, Lang, UserProfile, websetting} from "../../helpers/websetting";
import {SystemSetting} from "../../constants/system";
import { useNavigate } from "react-router-dom"
import { useTitle} from "../../helpers/function";
import {Citizen, SsoHome} from "../../constants/routes";
import {useTranslation} from "react-i18next";
import {decode as base64_decode, encode as base64_encode} from 'base-64';

function PageContent() {
    const t = useTranslation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState('');
    const [error, setError] = useState('');
    const [validated, setValidated] = useState(false);
    const usernameInput = useRef(null);
    useTitle(websetting.title );
    var result = null;

    const handleSubmit = async (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.target.formIdentify.focus();
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            var username = event.target.formIdentify.value.trim();
            var password = event.target.formPassword.value.trim();
            setLoading("true");
            setError("");
            result = await axios.post(websetting.apiUrl + 'auth/oauth/token',
                "grant_type=password&username=" + username + "&password=" + password,
                {
                    method: "post",
                    headers: {
                        'Authorization': 'Basic '+base64_encode(CredentialsAuthToken.ClientId +":"+CredentialsAuthToken.Password)
                    }
                }
            ).then(async function (response) {
                if (response.status == 200) {
                    if (response.data.access_token != "") {
                        await axios.get(websetting.apiUrl + 'auth/oauth/validate',
                            {
                                method: "get",
                                headers: {
                                    'Authorization': 'Bearer ' + response.data.access_token
                                }
                            }
                        ).then(function (resProfile) {

                            let myInitObject;
                            if (resProfile.status == 200) {
                                Auth.access_token = response.data.access_token;
                                Auth.jti = response.data.jti;
                                Auth.expires_in = response.data.expires_in;
                                Auth.refresh_token = response.data.refresh_token;
                                Auth.token_type = response.data.token_type;

                                sessionStorage.setItem("access_token", response.data.access_token);
                                sessionStorage.setItem("refresh_token", response.data.refresh_token);
                                sessionStorage.setItem("token_type", response.data.token_type);
                                sessionStorage.setItem("jti", response.data.jti);
                                sessionStorage.setItem("token_type", response.data.token_type);
                                sessionStorage.setItem("expires", response.data.expires_in);
                                sessionStorage.setItem("expires_in", String(Math.floor(Date.now() / 1000)));

                                authorities.pop()
                                for (var i = 0; i < resProfile.data.authorities.length; i++) {
                                    const v: any = resProfile.data.authorities[i];
                                    authorities.push(v)
                                }

                                if (resProfile.data.user.username != "") {
                                    sessionStorage.setItem("username", resProfile.data.user.username);
                                    sessionStorage.setItem("fisrtName", resProfile.data.user.firstName);
                                    sessionStorage.setItem("lastName", resProfile.data.user.lastName);
                                    UserProfile.organizationId = resProfile.data.user.organizationIdFk;
                                    UserProfile.userType = resProfile.data.user.userType;
                                    UserProfile.username = resProfile.data.user.username;
                                    UserProfile.userIdPk = resProfile.data.user.userIdPk

                                    if(resProfile.data.user.userType == "ADMIN" || resProfile.data.user.userType == "EMPLOYEE") {
                                        navigate(SsoHome);
                                    }else if(resProfile.data.user.userType == "CITIZEN") {
                                        navigate(Citizen);
                                    }
                                } else {
                                    setLoading("");
                                    setError(t.t("iot.global.invalidSignature"));
                                    return false;
                                }
                            } else {
                                setLoading("");
                                setError(t.t("iot.global.invalidSignature"));
                            }
                        }).catch(function (error) {
                            setLoading("");
                            setError(t.t("iot.global.invalidSignature"));
                        });

                    } else {
                        setLoading("");
                        setError(t.t("iot.global.invalidSignature"));
                        return false;
                    }

                    return response.data
                } else {
                    setLoading("");
                    setError(t.t("iot.global.invalidSignature"));
                }
            }).catch(function (error) {
                setLoading("");
                setError(t.t("iot.global.invalidSignature"));
            });
        }
    };


    if(error !== ""){
        setTimeout(function (){
            setError("");
        },SystemSetting.MsgTimeOut);
    }

    return (
            <div className="formDiv" >
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <LoginHeader />
                    {error && <Alert id="AlertMsg" className="text-center" variant="danger">{error}</Alert>}
                    {loading && <div className="text-center"><Spinner animation="border"  variant="secondary" role="status" ><span className="visually-hidden">انتظر من فضلك</span></Spinner></div>}
                    <h4>{t.t("iot.global.login")}</h4>
                    <Form.Group className="my-3" controlId="formIdentify" >
                        <Form.Control type="tel" className={"text-center"} placeholder={t.t("iot.global.username")}  ref={usernameInput}  defaultValue="" autoComplete="off" required aria-label={t.t("iot.global.Username")} aria-describedby="msgIdentify" />
                        <Form.Control.Feedback type="invalid"  id="msgIdentify" >
                            {t.t("iot.global.username")}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="my-3" controlId="formPassword" >
                        <Form.Control type="password" className={"text-center"} placeholder={t.t("iot.global.password")} defaultValue="" required aria-label={t.t("iot.global.password")} aria-describedby="msgPassword"  />
                        <Form.Control.Feedback type="invalid"  id="msgPassword">
                            {t.t("iot.global.password")}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="text-start">
                        <Link to="/ForgetPassword" className="underline font500">{t.t("iot.global.forgetPassword")}</Link></div>
                    <Button type="submit" className="DarkBtn w-100 mt-4">{t.t("iot.global.login")}</Button>
                   <div className="text-center  mt-4"><Link className="underline" to="/Registration"><span className="h5 font700">{t.t("DontHaveAccount")}</span></Link></div>
                </Form>
            </div>
        )


}


function Login(){
    return(
        <div className="h-100">
            <Container className="h-100" fluid={true}>

                <Row className="h-100" >
                    <Col className="mx-auto my-auto py-3"  xl={4} lg={5} md={6} >
                        <PageContent/>
                    </Col>
                    <Col className={" d-none d-md-block"} xl={8} lg={7} md={6}   style={{backgroundImage:'url('+window.location.origin+'/images/bg.jpg)',backgroundPosition:'fixed',backgroundRepeat:"no-repeat",backgroundSize:"cover"}}  >
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Login;
