import React, {useRef, useState} from "react";
import {FilePlus} from "react-feather";
import {Button, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import Moment from "moment";
import {websetting} from "../../../../../helpers/websetting";
import {useParams} from "react-router-dom";

function FormStatus(props:any) {
    const table = React.createRef<HTMLTextAreaElement>();
    let {id}:any = useParams()
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [data, setData] = useState([]);

    if( id == "New"){
        id = 0
    }

    const AddStatus = () => {
      //table.current?.("<tr><td>dsd</td><td>dsd</td><td>dsd</td></tr>")
    }

    return (
        <Row>
            <Col lg={7}>

            <div className={"lightbox"}>
                <div className={"pagetitle text-uppercase"}>
                    <FilePlus className={"feather"} />
                    <span className={"d-inline-block mx-2"}>تتبع الحالات</span>
                </div>
                <hr className={"shodow-hr"} />
                <div>
                    {loading ? <div className="text-center"><Spinner animation="border"  variant="secondary" role="status" ><span className="visually-hidden">انتظر من فضلك</span></Spinner></div> :
                        <div>
                            <Row className={"pb-5"}>
                                <Col className={"mb-3"} lg={12}>
                                    <Form.Group controlId={"input1"}>
                                        <Form.Label>بداية الحالة</Form.Label>
                                        <Form.Select>
                                            <option></option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className={"mb-2"} lg={12}>
                                    <Form.Group controlId={"input2"}>
                                        <Form.Label>نهاية الحالة</Form.Label>
                                        <Form.Select>
                                            <option></option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            </div>
            </Col>
            <Col lg={5}>

                <div className={"lightbox"}>
                    <div className={"pagetitle text-uppercase"}>
                        <FilePlus className={"feather"} />
                        <span className={"d-inline-block mx-2"}>أنواع الحالات</span>
                        <Button variant={"warning"} size={"sm"} onClick={()=>{AddStatus()}}  className="text-uppercase bold float-end">+ حالة جديدة </Button>
                    </div>
                    <hr className={"shodow-hr"} />
                    <div>
                        {loading2 ? <div className="text-center"><Spinner animation="border"  variant="secondary" role="status" ><span className="visually-hidden">انتظر من فضلك</span></Spinner></div> :
                            <div>
                                <Table >
                                    <tbody ref={table as React.RefObject<any>}>

                                    </tbody>
                                 </Table>
                            </div>
                        }

                    </div>
                </div>
            </Col>
        </Row>

    )
}
function AddRow(){
    return(
        <tr><td>dsd</td><td>dsd</td><td>dsd</td></tr>
    )
}
export default FormStatus